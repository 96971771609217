import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByDepartment(department) {
  const axios = axiosInstance().primoLab;
  let testWorkQeuePhase = [];

  await axios
    .get("/test-work-queue-phases/live-department", {
      params: {
        department_slug: department,
      },
    })
    .then(async (response) => {
      testWorkQeuePhase = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQeuePhase };
}
