<template>
  <template v-if="loading === false">

    <template v-if="Object.keys(dataHistoryWorks).length > 0">
      <div v-if="dataHistoryWorks.prescriptionTest.feedback !== null">
        <div class="d-flex align-items-center" style="max-width: fit-content;">
          <h5 class="strong">Feedback clinica: </h5>
          <img v-if="dataHistoryWorks.prescriptionTest.feedback" class="p-3 like" src="@/assets/icons/like.svg" width="70" height="70" alt="like" />
          <img v-else class="p-3 dislike" src="@/assets/icons/dislike.svg" width="70" height="70" alt="dislike" />
          <p class="fst-italic"> {{ dataHistoryWorks.prescriptionTest.feedback_note ?? 'Nessuna nota presente' }}</p>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center bg-light-violet border-dark-violet rounded p-4 mb-1" style="max-width: fit-content;">
        {{ dataHistoryWorks.prescriptionTest.clinic_note ?? 'Nessuna nota presente' }}
      </div>

      <div class="p-4" v-if="prescriptionTestMedia.length">
        <SliderImage
          :elements="prescriptionTestMedia"
          :item-to-show="itemToShowSlider"
          :item-to-show-when-resize="itemToShowWhenResize"
        />
      </div>

      <div class="d-flex flex-column-reverse">
        <template v-for="(phase, index) in testWorkQueuePhases" :key="index">
          <div class="d-flex flex-column align-items-start" :data-id="phase.id">
            <TestWorkQueuePhase
              :twqPhase="phase"
              @push-iso-phase="pushIsoPhase" 
              :is-current-prescription-test="$props.currentPrescriptionTestId === $props.prescriptionTestId"
            />
          </div>
        </template>
        <template v-if="canAddIsoPhase()">
          <AddIsoPhase @push-iso-phase="pushIsoPhase($event, true)" :twqPhase="lastElementOf(testWorkQueuePhases)" />
        </template>
      </div>

    </template>

    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        Seleziona una prova per vedere il suo storico
      </div>
    </template>

  </template>

  <template v-else>
    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="spinner-border text-violet" role="status"></div>
    </div>
  </template>


</template>

<script>
import _ from 'lodash';
import { onMounted, ref, watch, computed, inject } from 'vue';
import moment from 'moment';

import { timeDiffCalc } from '@/use/utilities/time/timeDiffCalc';

import TestWorkQueuePhase from "@/components/Consulting/TestWorkQueuePhase";
import SliderImage from "@/components/general/SliderImages";
import AddIsoPhase from '@/components/Consulting/AddIsoPhase';

import { fetchById as fetchPrescriptionTestById } from "@/use/repositories/prescriptionTests/fetchById";
import { show as fetchMediaByPrescriptionTest } from "@/use/repositories/media-uploader/prescription-tests/show";
import { sortAndAddParentState } from "@/use/utilities/array/sortAndAddParentState";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
export default {
  name: "History",
  props: {
    prescriptionTestId: {
      type: Number,
      required: false,
    },
    currentPrescriptionTestId: {
      type: Number,
      required: false,
    },
    itemToShowSlider: {
      type: Number,
      required: false,
      default: 2,
    },
    itemToShowWhenResize: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  components: {
    TestWorkQueuePhase,
    SliderImage,
    AddIsoPhase,
  },
  setup(props) {
    const dataHistoryWorks = ref([]);
    let prescriptionTest = [];
    const loading = ref(false);
    const prescriptionTestMedia = ref([]);
    const { states } = inject("constants");

    onMounted(() => {
      if (props.prescriptionTestId !== null) {
        getData(props.prescriptionTestId);

        fetchMediaByPrescriptionTest(props.prescriptionTestId).then((response) => prescriptionTestMedia.value = response.prescriptionTest.meta.temporary_urls.urls.map((item) => ({createdAt: item.created_at, temporaryUrl: item.url})));
      }
    });

    watch(() => props.prescriptionTestId, (value) => {
      getData(value);

      fetchMediaByPrescriptionTest(value).then((response) => prescriptionTestMedia.value = response.prescriptionTest.meta.temporary_urls.urls.map((item) => ({createdAt: item.created_at, temporaryUrl: item.url})));
    });

    const testWorkQueuePhases = computed (() => {
      const data = [ ...dataHistoryWorks.value.testWorkQueuePhases ];
      return _.chain(sortAndAddParentState(data))
        .map((value) => ({
            id: value.id,
            name: value.internal_phase.name,
            state_slug: value.state.slug,
            department_name: value.internal_phase.department.name,
            technician: value.user ? getTechnician(value.user) : null,
            started_at: value.started_at,
            finished_at: value.completed_at,
            work_duration: timeDiffCalc(new Date(value.completed_at), new Date(value.started_at)),
            feedbacks: value.feedbacks ? getFeedbacks(value.feedbacks) : [],
            internal_notes: value.feedbacks ? getInternalNote(value.feedbacks) : [],
            orders: value.warehouse_requests ? getOrders(value.warehouse_requests) : [],
            parent: value.parent,
            child: value.child,
          })
        )
        .value()
    })

    const getData = (value) => {
      loading.value = true;
      fetchPrescriptionTestById(value, 'with_all_relations').then((response) => {
        prescriptionTest = response.prescriptionTest;
        dataHistoryWorks.value = {
          prescriptionTest: {
            clinic_note: prescriptionTest.notes,
            feedback: prescriptionTest.feedback,
            feedback_note: prescriptionTest.feedback_note,
            state: prescriptionTest.state,
          },
          testWorkQueue: prescriptionTest.test_work_queue,
          testWorkQueuePhases: prescriptionTest.test_work_queue !== null ? prescriptionTest.test_work_queue.test_work_queue_phases : [],
        }

        loading.value = false;
      });
    }
    const canAddIsoPhase = () => {
      return props.currentPrescriptionTestId === props.prescriptionTestId &&
        testWorkQueuePhases.value.length &&
        testWorkQueuePhases.value.every(item => item.state_slug === 'completed') &&
        dataHistoryWorks.value.prescriptionTest.state.slug == 'in-lavorazione';
    }

    const pushIsoPhase = (isoPhase, allPhasesComnpleted = false) => {
      const isoPhaseParent =  isoPhase.parent_test_work_queue_phases?.length ? _.head(isoPhase.parent_test_work_queue_phases).id : null;
      updateObjectByNestedId(dataHistoryWorks.value.testWorkQueuePhases, isoPhaseParent, { id: isoPhase.id }, allPhasesComnpleted);
      dataHistoryWorks.value.testWorkQueuePhases.push(isoPhase);

      if (allPhasesComnpleted) updateTestWorkQueue({id: isoPhase?.test_work_queue_id, state_slug: states.IN_THE_WORKS, action: "update_state"});
    }

    const updateObjectByNestedId = (testWorkQueuePhases, parentId, updatedProperties, allPhasesComnpleted = false) => {
      const objectToUpdate = () => {
        if (parentId && allPhasesComnpleted)
          return _.find(testWorkQueuePhases, item => ! item.child)
        else if (parentId)
          return _.find(testWorkQueuePhases, (item) => _.find(item.parent_test_work_queue_phases, { id: parentId }))
        else
          return _.find(testWorkQueuePhases, (item) => ! item.parent_test_work_queue_phases.length)
      }
      // if there are only one pending phase || if all phases.length are in pending || if all phases.length are completed
      if (! objectToUpdate().parent_test_work_queue_phases.length && ! allPhasesComnpleted || objectToUpdate().parent_test_work_queue_phases.length && allPhasesComnpleted)
        objectToUpdate().parent_test_work_queue_phases.push(updatedProperties);
      // if parent is completed and phase is pending || if there are only one phase completed;
      else _.assign(_.head(objectToUpdate().parent_test_work_queue_phases), updatedProperties);
    }

    const getFeedbacks = (feedbacks) => {
      let feed = [];
      feedbacks.forEach((feedback) => {
        // When feedback_id is null become a note.
        if (feedback.feedback_id === null) return;
        let obj = {
          note: feedback.feedback.description,
          technician_first_name: feedback.user.first_name,
          technician_last_name: feedback.user.last_name,
          updated_at: moment(feedback.updated_at),
        }
        feed.push(obj);
      });

      return feed;
    }

    const getInternalNote = (feedbacks) => {
      let notes = [];
      feedbacks.forEach((feedback) => {
        if (feedback.note !== null) {
          let note = {
            note: feedback.note,
            technician_first_name: feedback.user.first_name,
            technician_last_name: feedback.user.last_name,
            updated_at: moment(feedback.updated_at),
          }
          notes.push(note);
        }
      });

      return notes;
    }

    const getOrders = (warehouseRequests) => {
      let orders = [];
      warehouseRequests.forEach((item) => {
        let order = {};
        order.created_at = moment(item.created_at);
        order.quantity = item.approved_quantity;
        order.technician_first_name = item.user.first_name;
        order.technician_last_name = item.user.last_name;
        order.article_code = item.article.code;
        order.article_name = item.article.name;
        orders.push(order);
      });

      return orders;
    }

    const getTechnician = (user) => {
      if (user !== null) {
        return {
          first_name: user.first_name,
          last_name: user.last_name,
        }
      }

      return null;
    }

    const lastElementOf = (array) => _.last(array);

    return {
      dataHistoryWorks,
      loading,
      pushIsoPhase,
      testWorkQueuePhases,
      canAddIsoPhase,
      lastElementOf,
      prescriptionTestMedia
    }
  }
}
</script>
