<template>
  <div class="d-flex justify-content-between w-50">
    <Logout />
    <Brand :width="120" :height="120" />
  </div>
  <div class="container-fluid mt-5">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-xl-4 col-md-12 col-sm-12 d-flex justify-content-center align-items-center" v-if="showBatteryAlert">
        <BatteryAlert class="mb-2"/>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-12 col-sm-12"
        :class="{ 'offset-xxl-4 col-xl-4 offset-xl-4': !showBatteryAlert }">
        <div class="card rounded-custom shadow border-0 p-4 mb-5">
          <div class="card-body">
            <h3 class="mb-4 fw-normal text-center">Check-in</h3>
            <PrescriptionRejected :clear="clearRejectedInfo" :prescription-rejected-reasons="prescriptionRejectedReasons"
              @enabled="(value) => (disabledCheckIn = value)" @rejected-reason-ids-selected="(id) => (prescriptionRejectedReasonsId = id)"/>

            <div class="mb-3">
            <!-- Validazione checkin con tag e  -->
              <form onsubmit="return false;">
                  <label for="exampleInputEmail1">
                    <span class="badge bg-violet text-wrap text-uppercase rounded-custom  mb-2">1</span>
                    Inserisci il codice della prescrizione
                  </label>
                <div class="input-group mb-3">
                  <span class="input-group-text">
                    <img src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="30" alt="scanner-barcode" />
                  </span>
                  <input type="text" id="prescriptionScanBarcode" autocomplete="off" class="form-control"
                    placeholder="Scansiona il codice a barre" v-model="prescriptionCode" :disabled="disablePrescriptionInput"
                    @input="onInput"/>
                </div>
                <div class="mb-3">
                  <Toggle v-model="tagInputEnabled"/>
                  <label class="ms-2 fw-bold">{{ tagInputEnabled ? 'Disabilita' : 'Abilita' }} associazione tag</label>
                </div>
                <template v-if="tagInputEnabled">
                  <label for="exampleInputEmail1" class="mb-2">
                    <span class="badge bg-violet text-wrap text-uppercase rounded-custom mb-2">2</span>
                    Inserisci il codice dell'etichetta elettronica
                  </label>
                  <InputDevice
                    @result="completeCheckin"
                    :disabled="disableTagInput"
                    :reset="resetTagInput"
                    :prescriptionTestId="prescriptionTest.id"
                    @battery-alert="(value) => { return showBatteryAlert = value }"/>
                </template>
              </form>
              <div class="valid-feedback"></div>
              <div class="invalid-feedback"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-4 col-md-12 col-sm-12">
        <template v-if="result.success">
          <ModalPrescriptionDetails
            :data="result.data"
            :priorityDate="priorityDate"
            :selectedFork="selectedFork"
            @focus="setFocusInput('prescriptionScanBarcode')"
          />
        </template>
      </div>
    </div>
  </div>

  <Empty v-if="showStoreRejectedModal" :size="'md'" :modal_id="'storeRejectedModal'" @hidden="hideStoreRejectedModal">
    <SpecialActions style="height: 400px" :title="'Seleziona il motivo della tua richiesta'"
      :placeholderNameSelect="'Seleziona la motivazione'" :optionsSelect="prescriptionRejectedReasons" :notes="false"
      @selected="setMotivationIdSelected" @clear="clearRejectedInfo" @store="rejectPrescription(true)"
      @close="hideModal('storeRejectedModal');" />
  </Empty>
</template>

<script>
import { computed, inject, nextTick, onMounted, reactive, ref, watch } from "vue";
import _head from "lodash/head";
import _forIn from "lodash/forIn";
import debounce from "lodash/debounce";
import moment from "moment";

import Logout from "@/components/Logout.vue";
import Brand from "@/components/Brand.vue";
import ModalPrescriptionDetails from "@/components/PrescriptionDetails";
import PrescriptionRejected from "@/components/PrescriptionRejected.vue";
import Empty from "@/components/Modal/Empty";
import SpecialActions from "@/components/SpecialActions";
import InputDevice from "@/components/devices/InputDevice.vue";

import { hideModal } from "@/use/modal/hide";
import { update as updateState } from '@/use/repositories/testWorkQueue/update';
import { store as storeTestWorkQueue } from "@/use/repositories/testWorkQueue/store";
import { error } from "@/use/toast/error";
import { storeAsRejected } from "@/use/repositories/prescriptionTestRejected/storeAsRejected";
import { fetchRejectedReasons } from "@/use/repositories/prescriptionTestRejected/fetchRejectedReasons";
import { warning } from "@/use/toast/warning";
import { success } from "@/use/toast/success";
import { dateFormatter } from "@/use/utilities/time/dateFormatter";
import { useI18n } from "vue-i18n";
import { fetchPrescriptionTest } from "@/use/repositories/prescriptions/fetchPrescriptionTest";
import { fetchById as validatePrescriptionTest } from "@/use/repositories/prescriptionTests/fetchById";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { print } from "@/use/repositories/devices/print";

import BatteryAlert from "@/components/devices/BatteryAlert.vue";
import Toggle from '@vueform/toggle';
export default {
  name: "Home",
  methods: { dateFormatter },
  components: {
    PrescriptionRejected,
    Logout,
    Brand,
    ModalPrescriptionDetails,
    Empty,
    SpecialActions,
    InputDevice,
    BatteryAlert,
    Toggle
},
  setup() {
    const tagInputEnabled = ref(false);
    const disablePrescriptionInput = ref(false);
    const disableTagInput = ref(true);
    const resetTagInput = ref(false);
    const testWorkQueueId = ref(null);
    const setMotivationIdSelected = (motivationId) => {
      prescriptionRejectedReasonsId.value = [motivationId];
    }
    const {
      devicesType,
      departments,
    } = inject("constants");
    const prescriptionCode = ref("");
    const slotCode = ref("");
    const token = ref(null);
    const data = reactive({});
    const prescription = reactive({});
    const slot = reactive({
      success: false,
      data: null,
    });
    const history = reactive({});
    const result = reactive({
      success: false,
      data: {},
    });
    const priorityDate = ref(null);
    const color = ref({});
    const selectedFork = ref({});
    const i18n = useI18n();
    const disabledCheckIn = ref(false);
    const prescriptionRejectedReasonsId = ref([]);
    const clearRejectedInfo = ref(false);
    const disabledInput = computed(() => {
      return disabledCheckIn.value && !prescriptionRejectedReasonsId.value.length;
    });
    const prescriptionAlreadyRejected = ref(false);
    const prescriptionRejectedId = ref(null);
    const prescriptionsRejected = ref([]);
    const testWorkQueues = ref([]);
    const selectAll = ref(false);
    const prescriptionTest = ref([]);
    const prescriptionRejectedReasons = ref([]);
    const showBatteryAlert = ref(false);
    onMounted(() => {
      fetchRejectedReasons().then((response) => {
        prescriptionRejectedReasons.value = response.rejectedReasons;
      });
      setFocusInput("prescriptionScanBarcode");
    });

    watch(
      () => prescriptionRejectedReasonsId.value.length,
      (value) => {
        if (value > 0) setFocusInput("prescriptionScanBarcode");
      }
    );

    watch(
      () => disabledCheckIn.value,
      (value) => {
        if (!value) setFocusInput("prescriptionScanBarcode");
      }
    );

    const syncTesWorkQueues = async (id) => {
      let index = testWorkQueues.value.findIndex((twq) => twq.id === id);
      if (index !== -1) {
        testWorkQueues.value.splice(index, 1);
      }
      testWorkQueueIdSelected.value = null;
    };

    // Set focus on input by id and clear value.
    const setFocusInput = async (id) => {
      prescriptionCode.value = "";
      resetTagInput.value = !resetTagInput.value;
      await nextTick(() => {
        const input = document.getElementById(id);
        if (input !== null) {
          input.focus();
        }
      });
    };

    const enableCheckinTag = () => {
      disablePrescriptionInput.value = true;
      disableTagInput.value = false;
    }

    const completeCheckin = () => {
      // Print Label
      fetchAllTesWorkQueue({
        action: 'with_base_64_labels',
        ids: [
          testWorkQueueId.value,
        ]
      }, true)
        .then((response) => print({
          device_type_slug: devicesType.ANALOG_LABEL,
          department_slug: departments.LOGISTICS,
          data: _head(response.testWorkQueue).raw_data,
        }))
        .then(() => {
          success(i18n.t("Barcodes print successfully!"));
        })
        .catch((messages) => {
          _forIn(messages, function (value) {
            value.forEach((message) => {
              error(message);
            });
          });
      })

      disablePrescriptionInput.value = false;
      disableTagInput.value = true;
      setFocusInput("prescriptionScanBarcode");
    }
    // Formatted datetime.
    const formattedDate = (value) => {
      return moment(value).format("YYYY-MM-DD hh:mm");
    };

    // Listener on prescriptionCode.
    const onInput = debounce(async () => {

      result.success = false;

      // No api is empty.
      if (prescriptionCode.value.trim() === "") return;

      prescriptionAlreadyRejected.value = false;
      prescriptionRejectedId.value = null;
      prescriptionTest.value = [];
      ///
      fetchPrescriptionTest(prescriptionCode.value.trim(), "with_valid_prescription_test")
      .then((response) => {
        prescriptionTest.value = response.data;

        if (prescriptionRejectedReasonsId.value.length) return rejectPrescription();

        validatePrescriptionTest(prescriptionTest.value.id, "validate_for_checkin").then(() => {
          approveWork();
        }).catch(() => {
          if (tagInputEnabled.value) enableCheckinTag();
        })
      });
    }, 1000);

    const approveWork = () => {
      storeTestWorkQueue(prescriptionTest.value.id, "analog_check_in")
        .then((response) => {
          if (
            ! response.testWorkQueue.data.test_work_queue_phases.length
          ) {
            warning(i18n.t("Work flow not defined!"));
          }

          priorityDate.value = response.testWorkQueue.data.delivery_date;
          color.value = response.testWorkQueue.meta.color;
          result.success = true;
          result.data = response.testWorkQueue.data;

          // Set first fork.
          selectedFork.value = _head(response.testWorkQueue.data.test_work_queue_phases);

          prescription.value = response.testWorkQueue;
          testWorkQueueId.value = response.testWorkQueue.data.id;
          if (tagInputEnabled.value) enableCheckinTag();
          if (! tagInputEnabled.value) {
            // Print Label
            fetchAllTesWorkQueue({
              action: 'with_base_64_labels',
              ids: [
                testWorkQueueId.value,
              ]
            }, true).then((response) => print({
              device_type_slug: devicesType.ANALOG_LABEL,
              department_slug: departments.LOGISTICS,
              data: _head(response.testWorkQueue).raw_data,
            })).then(() => {
              success(i18n.t("Barcodes print successfully!"));
              setFocusInput("prescriptionScanBarcode");
            })
              .catch((messages) =>
                _forIn(messages, function (value) {
                  value.forEach((message) => {
                    error(message);
                  });
                })
              )
          };
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const testWorkQueueIdSelected = ref();
    const showStoreRejectedModal = ref(false);
    const hideStoreRejectedModal = () => {
      hideModal("storeRejectedModal");
      showStoreRejectedModal.value = false;
      prescriptionRejectedReasonsId.value = [];
    };

    const rejectPrescription = (existing = null) => {
      const reasonSlug = prescriptionRejectedReasons.value.find((reason) => reason.value == _head(prescriptionRejectedReasonsId.value)).slug;
      storeAsRejected(prescriptionTest.value.id.toString(), 'sent', reasonSlug)
        .then(() => {
          clearRejectedInfo.value = true;
          disabledCheckIn.value = false;
          prescriptionRejectedReasonsId.value = [];
          if (existing) {
            updateState({
              id: testWorkQueueIdSelected.value,
              state_slug: "non-complient",
              action: "update_state"
            });
            syncTesWorkQueues(testWorkQueueIdSelected.value);
            hideModal('storeRejectedModal');
          }
          success("Prescrizione rifiutata, inviata comunicazione in chat");
          if (tagInputEnabled.value) {
            enableCheckinTag();
          }

        })
        .finally(() => {
          setFocusInput("prescriptionScanBarcode");
        });
    };

    const getReasons = (reasons) => {
      let descriptions = [];
      reasons.map((reason) => descriptions.push(reason.description));

      return descriptions.join(", ");
    };

    const onChange = (input) => {
      prescriptionCode.value = input;
    };
    const onKeyPress = (button) => {
      // No api is empty.
      if (prescriptionCode.value.trim() === "") return;
      if (button === "{enter}") {
        onInput();
      }
    };

    const onInputChange = (input) => {
      prescriptionCode.value = input.target.value;
    };

    return {
      data,
      prescriptionCode,
      token,
      result,
      slotCode,
      slot,
      history,
      formattedDate,
      moment,
      setFocusInput,
      onInput,
      priorityDate,
      color,
      onChange,
      onKeyPress,
      onInputChange,
      selectedFork,
      disabledCheckIn,
      prescriptionRejectedReasonsId,
      disabledInput,
      clearRejectedInfo,
      prescriptionAlreadyRejected,
      prescriptionsRejected,
      getReasons,
      selectAll,
      approveWork,
      rejectPrescription,
      prescriptionRejectedReasons,
      prescriptionTest,
      hideStoreRejectedModal,
      hideModal,
      showStoreRejectedModal,
      setMotivationIdSelected,
      disablePrescriptionInput,
      completeCheckin,
      showBatteryAlert,
      disableTagInput,
      resetTagInput,
      tagInputEnabled
    };
  },
};
</script>

<style scoped>
#slot-details {
  position: absolute;
  width: 20%;
  left: 20%;
  top: 33%;
}

input[type="text"]:focus {
  border-color: rgb(225 210 242);
  box-shadow: 0 0 0 0.25rem rgb(225 210 242);
  outline: 0 none;
}

tr th {
  text-transform: uppercase;
}

.trash:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.overflow-card {
  max-height: 65vh;
  overflow-y: scroll;
}

.not-focused {
  opacity: 0.5;
}

/* PRESCRIPTIONS */
.prescription {
  border: 1px solid;
  padding: 3px 6px;
  margin-bottom: 6px;
  border-radius: 0.5rem;
}
</style>
