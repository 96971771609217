<template>
  <div class="d-flex vw-100 vh-100 justify-content-center align-items-center" v-if="dataUploaded">
    <div class="col-sm-12 col-md-12 col-lg-10 col-xl-9 col-xxl-8">
      <brand :width="150" :height="150" />
      <h3 class="mb-4 fw-normal text-center">Prescrizione - {{ $route.query.prescription }}</h3>
      <div class="d-flex w-100 h-100 overflow-hidden border-dark-violet rounded-custom">
        <!-- All internal phases -->
        <div class="col position-relative overflow-auto">
          <small class="column-title fw-bold d-flex justify-content-center align-items-center bg-dark-violet text-white text-uppercase rounded-only-top-left position-absolute w-100">
            Tutte le fasi
          </small>
          <div class="content overflow-auto">
            <draggable
              class="list-group"
              tag="transition-group"
              :component-data="{ tag: 'ul', type: 'transition-group', name: !drag ? 'flip-list' : null }"
              v-model="allInternalPhases"
              v-bind="dragOptions"
              :group="{ name: 'phases', pull: 'clone', put: false }"
              @start="onDragStart"
              @end="onDragEnd"
              item-key="order">
              <template #item="{ element }">
                <div
                  class="list-group-item"
                  :class="{'disabled': itemDisabled(element.name.slug)}"
                  :data-item="JSON.stringify(element.name)"
                >
                  {{ element.name.name.toUpperCase() }}
                </div>
              </template>
            </draggable>
          </div>
        </div>
        <!-- Custom flow -->
        <div class="col position-relative overflow-auto">
          <small class="column-title fw-bold d-flex justify-content-center align-items-center bg-dark-violet text-white text-uppercase text-center position-absolute w-100">
            Personalizzato
          </small>
          <div class="content overflow-auto">
            <draggable
              class="list-group"
              tag="transition-group"
              :component-data="{ tag: 'ul', type: 'transition-group', name: !drag ? 'flip-list' : null }"
              v-model="customFlow"
              v-bind="dragOptions"
              group="phases"
              @start="drag = true"
              @end="drag = false"
              item-key="order">
              <template #item="{ element }">
                <div class="list-group-item align-items-center d-flex" :data-item="JSON.stringify(element.name)">
                  {{ element.name.name.toUpperCase() }}
                  <span class="close position-absolute end-0 me-2 fs-3" @click="remove(element)" aria-hidden="true">&times;</span>
                </div>
              </template>
            </draggable>
          </div>
        </div>
        <!-- Original tree -->
        <template v-if="treeExists">
          <div class="col position-relative overflow-auto">
            <small class="column-title fw-bold d-flex justify-content-center align-items-center bg-dark-violet text-white text-uppercase text-center rounded-only-top-right position-absolute w-100">
              Originale
            </small>
            <div class="content overflow-auto">
              <ul class="list-group" v-for="(element, index) in tree" :key="index">
                <li class="list-group-item" style="opacity: 0.5">
                  {{ element.name.name.toUpperCase() }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
      <template v-if="$store.state.mainRole.team.name === departments.ISO_PROGETTAZIONE">
          <div class="d-flex justify-content-center p-1">
            <Toggle v-model="isAnalog"/>
            <label class="ms-2">La prova segue il flusso analogico</label>
          </div>
        </template>
      <!-- Buttons -->
      <div class="d-flex w-100 h-100 justify-content-center">
        <button type="button" class="btn btn-outline-violet text-uppercase mt-4 me-2" @click="reset()">Resetta Modifiche</button>
        <button type="button" class="btn btn-violet text-uppercase mt-4" :disabled="disabled" @click="save()">conferma flusso personalizzato</button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref } from "vue";
import brand from "@/components/Brand";
import {fetchAll as fetchAllInternalPhases} from "@/use/repositories/internalPhaseTest/fetchAll";
import {fetchTreeById as fetchTree} from "@/use/repositories/testWorkQueue/fetchTreeById";
import draggable from 'vuedraggable'
import _head from "lodash/head";
import _ from "lodash";
import _difference from "lodash/difference";
import _clone from "lodash/clone";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import {useI18n} from "vue-i18n";
import { storeCustomFlow } from "@/use/repositories/testWorkQueuePhase/storeCustomFlow";
import { update as updatePrescriptionTest } from "@/use/repositories/prescriptionTests/update";
import { store as storeCrossCheckin } from "@/use/repositories/crossCheckin/store";
import {useStore} from "vuex";
import Toggle from '@vueform/toggle';

export default {
  name: "CustomFlow",
  components: {
    brand,
    draggable,
    Toggle,
  },
  props: {
    testWorkQueuePhaseId: {
      type: Number,
      required: true,
    },
    testWorkQueueId: {
      type: Number,
      required: true,
    },
    prescriptionId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { departments } = inject("constants");
    const store = useStore();
    const i18n = useI18n();
    const toast = useToast();
    const treeExists = ref(false);
    const tree = ref([]);
    let treeNative = [];
    const allInternalPhases = ref([]);
    const dataUploaded = ref(false);
    const customFlow = ref([]);
    const internalPhasesWithNextItem = ref({
      "progettazione": "fresaggio",
      "colatura": "rifinitura",
    });

    const disabled = computed(() => {
      let idsCustom = customFlow.value.map((el) => { return el.name.id });
      let idsOriginal = treeNative.map((el) => { return el.name.id });
      return (_difference(idsOriginal, idsCustom).length === 0 && _difference(idsCustom, idsOriginal).length === 0);
    });
    const isAnalog = ref(false);
    const router = useRouter();
    const route = useRoute();
    // Draggable.
    const drag = ref(false);
    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    });
    const phaseException = reactive({
      item: null,
      trackingPhases: [],
    })

    const formattedData = (array) => {
      return array.map((name, index) => {
        return { name, order: index + 1 };
      });
    }

    const itemDisabled = (item) => {
      return Object.values(internalPhasesWithNextItem.value).includes(item);
    }

    onMounted(() => {
      // Fetch all phases.
      fetchAllInternalPhases().then((response) => {
        allInternalPhases.value = removeInternalPhases(formattedData(response.internalPhases));
        // Fetch tree.
        fetchTree(props.testWorkQueueId).then((response) => {
          treeExists.value = (response.tree.meta.fork_tree.length > 0);
          if (treeExists.value) {
            let array = [];
            response.tree.meta.fork_tree.forEach((item) => {
              recursiveMapData(item, array);
            });
            treeNative = formattedData(array);
            tree.value = formattedData(array);
            customFlow.value = formattedData(array);
          }
          dataUploaded.value = true;
        });
      });
    });

    const removeInternalPhases = (allInternalPhases) => {
      return _.filter(allInternalPhases, (internalPhase) => !_.includes([
      "iso-progettazione"
      ], internalPhase.name.slug));
    };

    const recursiveMapData = (item, array) => {
      if (item !== null) {
        let obj = {
          'id': item.internal_phase.id,
          'name': item.internal_phase.name,
          'departmentName': item.internal_phase.department.name,
        };
        if (item.children.length) {
          array.push(obj);
          recursiveMapData(_head(item.children), array);
        } else {
          array.push(obj);
        }
      }
    }

    const onDragStart = (event) => {
      drag.value = true;

      phaseException.item = JSON.parse(event.item.getAttribute('data-item'));
      if (internalPhasesWithNextItem.value.hasKey(phaseException.item.slug)) {
        phaseException.trackingPhases = customFlow.value;
      }
    };

    const onDragEnd = (event) => {
      drag.value = false;

      if (phaseException.item && internalPhasesWithNextItem.value[phaseException.item.slug]) {
        const nextPhaseSlug = internalPhasesWithNextItem.value[phaseException.item.slug];
        const nextPhase = allInternalPhases.value.find(element => element.name.slug === nextPhaseSlug);
        if (nextPhase) {
          customFlow.value.splice(event.newIndex + 1, 0, nextPhase);
          phaseException.trackingPhases = [];
        }
      }

      phaseException.item = null;
    };

    const reset = () => {
      // Use clone for set loose reactivity.
      customFlow.value = _clone(treeNative);
    }

    const save = () => {
      let ids = customFlow.value.map((phase) => {
        return phase.name.id;
      });

      console.log(`CURRENT TEST WORK QUEUE PHASES: ${props.testWorkQueuePhaseId}`);
      console.log(`INTERNAL PHASE TEST TO SAVE: ${ids}`);

      storeCustomFlow(props.testWorkQueuePhaseId, ids).then((response) => {
        console.log(response.message);
        // Show message
        toast.success(i18n.t("Flow saved successfully!"), {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        if (
            store.state.mainRole.team.name === departments.ISO_PROGETTAZIONE
            && isAnalog.value
            ) {
          updatePrescriptionTest(
            route.query.prescription_test_id,
            {
              action: "update_intraoral_scanner",
              intraoral_scanner_id: null,
            },
            true
          ).then(() => {
            storeCrossCheckin(route.query.prescription_test_id, "to-analog");
          });
        }

        setTimeout(() => {
          if (store.state.mainRole.team.name === departments.ISO_PROGETTAZIONE) return router.go(-1);

          return router.push({
            name: 'ConsultingDashboard',
            params: {
              prescription_id: props.prescriptionId,
            },
            query: {
              read_only: false,
              test_work_queue_phase_id: props.testWorkQueuePhaseId,
            }
          });
        }, 1000);
      });
    }

    const remove = (element) => {
      let index = customFlow.value.findIndex((el) => el.name.id === element.name.id);
      customFlow.value.splice(index, 1);
    }

    return {
      departments,
      treeExists,
      allInternalPhases,
      removeInternalPhases,
      dataUploaded,
      drag,
      tree,
      dragOptions,
      customFlow,
      reset,
      save,
      disabled,
      remove,
      isAnalog,
      onDragStart,
      onDragEnd,
      itemDisabled,
    }
  }
}
</script>

<style scoped>
.column-title {
  height: 40px;
  align-items: center;
}
.list-group-item {
  padding: 5%;
}
.content {
  margin-top: 40px;
  height: 500px;
}
.close:hover {
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
