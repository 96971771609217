import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function treePreview(params) {
  const axios = axiosInstance().primoLab;
  let tree = [];

  await axios
    .get(`/tests/tree-preview`, {
      params,
    })
    .then(async (response) => {
      tree = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { tree };
}
