import { default as axios } from "@/use/utilities/axios/media-uploader/axios-instance";

export async function show(id) {
  let prescriptionTest = [];

  await axios
    .get(`/prescription-tests/${id}`)
    .then(async (response) => {
      prescriptionTest = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionTest };
}
