import axios from "axios";
import router from "../../../../router";
import { useToast } from "vue-toastification";
import _head from "lodash/head";
import store from "@/store";
import getAuthToken from "@/use/get-auth-token";

export function axiosInstance() {
  // Get toast interface
  const toast = useToast();

  const primoLab = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
  });

  primoLab.interceptors.request.use(
    (config) => {
      const token = getAuthToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a request interceptor
  primoLab.interceptors.request.use(
    function (config) {
      // Start loading.
      store.dispatch("toggleLoading", true);

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  primoLab.interceptors.response.use(
    function (response) {
      // Stop loading.
      store.dispatch("toggleLoading", false);

      return response;
    },
    function (error) {
      if (error.request.status === 401 && error.request.statusText === "Unauthorized") {
        router.push({ path: "/login" });
      }

      toast.error(
        _head(error?.response?.data?.errors) ??
          "Si è verificato un errore. Contattare il servizio tecnico.",
        {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );

      // Stop loading.
      store.dispatch("toggleLoading", false);

      return Promise.reject(error);
    }
  );

  return {
    primoLab,
  };
}
