<template>

  <template v-if="withBackgroundColor">
    <div class="d-flex justify-content-center flex-column align-items-center w-100 h-100 bg-green text-white">
      <div class="p-2">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" fill="#FFFFFF" class="bi bi-check2-circle" viewBox="0 0 16 16">
          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
        </svg>
      </div>
      <h2 class="d-flex">{{ $props.message }}</h2>
    </div>
  </template>

  <template v-else>
    <div class="d-flex justify-content-center flex-column align-items-center w-100 h-100 text-green">
      <div class="p-2">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" fill="#28A745" class="bi bi-check2-circle" viewBox="0 0 16 16">
          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
        </svg>
      </div>
      <h2 class="d-flex">{{ $props.message }}</h2>
    </div>
  </template>

</template>

<script>
export default {
  name: "Print",
  props: {
    message: {
      type: String,
      required: true,
    },
    withBackgroundColor: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: Number,
      required: false,
      default: 100,
    }
  }
}
</script>
