<template>
  <div class="d-flex align-items-center justify-content-between">

    <div class="d-flex align-items-center">
      <h5 class="fw-bold mb-0">Elementi: </h5>
      <div class="d-flex flex-column p-2">
        <Render
          v-if="showRenderComponent"
          :data="data"
        />
      </div>
    </div>

    <template v-if="$props.isAbleToProject">
      <div>
        <button class="btn btn-violet text-uppercase position-relative" @click="showModal = true">
          <img class="me-2" src="@/assets/icons/upload.svg" alt="upload">
          carica file
          <span class="position-absolute fs-6 top-0 start-0 translate-middle badge rounded-pill bg-white text-violet border-violet shadow">
            P {{ numberElementsUploaded }}/{{ $props.elements.length }}
          </span>
          <template v-if="(modelUploaded + modelToBeUpload) !== 0">
            <span class="position-absolute fs-6 top-0 translate-middle badge rounded-pill bg-white text-violet border-violet shadow">
              M {{ modelUploaded }}/{{ modelUploaded + modelToBeUpload }}
            </span>
          </template>
        </button>
      </div>
    </template>

  </div>

  <Modal v-if="showModal"
         :size="'xl'"
         :modal_id="'project_upload'"
         :title="'caricamento file'"
         :subtitle="null"
         :decline_label="null"
         :lightLayout="false"
         @hidden="closeModal">
    <Upload
      :elements="data"
      :has-uploaded-some-models="hasUploadedSomeModels"
      :show-projects-title="(numberElementsUploaded !== $props.elements.length)"
      :show-models-title="(modelUploaded !== (modelUploaded + modelToBeUpload))"
      @removeBridge="removeBridge"
      @createBridge="createBridge"
      @asset="addAsset"
      @assetsIdsRemoved="removeAssets"
      @reset="removeSelection"
      @allFilesUploaded="readAllFilesUploaded"
      @clear-models-selected="clearModelSelected"
      @sync-models="syncModels"

      @add-stump="addStump"
      @remove-stump="removeStump"
      @add-gum="addGum"
      @remove-gum="removeGum"
      @add-custom-pin="addCustomPin"
      @remove-custom-pin="removeCustomPin"
      @force-models-upload="forceModelsHandle"
      @select="select"
    />
  </Modal>

</template>

<script>
import { computed, onMounted, reactive, ref, toRaw, watch } from 'vue';

import Upload from "./Upload";
import Modal from '@/components/Modal/Standard';
import Render from '@/components/PrescriptionRows/Render';

import { updateBridge } from '@/use/repositories/prescriptionRows/updateBridge';
import { groupedByBridgesAndTooth } from '@/use/repositories/prescriptionRows/groupedByBridgesAndTooth';

import _find from "lodash/find";

export default {
  name: "Elements",
  props: [
    'elements',
    'models',
    'prescriptionMaterial',
    'prescriptionColorId',
    'defaultMaterials',
    'modelTypes',
    'isAbleToProject',
  ],
  emits: [
    'allFilesIsUploaded',
    'atLeastOneFileIsUploaded',
    'clear-models-selected',
    'sync-models',
    'remove-assets-models',
    'all-models-is-uploaded',

    'add-stump',
    'remove-stump',

    'add-gum',
    'remove-gum',

    'add-custom-pin',
    'remove-custom-pin',
    'force-models-upload',
  ],
  components: {
    Upload,
    Modal,
    Render,
  },
  setup(props, {emit}) {
    const showModal = ref(false);
    const showRenderComponent = ref(false);
    const numberElementsUploaded = ref(0);

    const data = reactive({
      bridges: [],
      tooth: [],
      models: props.models,
      prescription_material: props.prescriptionMaterial,
      prescription_color_id: props.prescriptionColorId,
      default_materials: props.defaultMaterials ?? [],
    });

    const select = (itemId) => {
      const teeth = _find(data.bridges, bridge => _find(bridge.tooth, el => el.id == itemId)).tooth;
      const tooth = _find(teeth, tooth => tooth.id == itemId);
      tooth.selected = !tooth.selected;
    }

    const modelToBeUpload = computed(() => {
      let counter = 0;
      props.models.arches.forEach((arch) => counter += (arch.assets.length === 0));
      props.models.gums.forEach((gum) => counter += (gum.assets.length === 0));
      props.models.stumps.forEach((stump) => counter += (stump.assets.length === 0));
      props.models.custom_pins.forEach((pin) => counter += (pin.assets.length === 0));

      return counter;
    });
    const modelUploaded = computed(() => {
      let counter = 0;
      props.models.arches.forEach((arch) => counter += (arch.assets.length > 0));
      props.models.gums.forEach((gum) => counter += (gum.assets.length > 0));
      props.models.stumps.forEach((stump) => counter += (stump.assets.length > 0));
      props.models.custom_pins.forEach((pin) => counter += (pin.assets.length > 0));

      return counter;
    });

    onMounted(() => {
      syncPrescriptionRows();
      emit('allFilesIsUploaded', (numberElementsUploaded.value === props.elements.length));
      emit('atLeastOneFileIsUploaded', (numberElementsUploaded.value >= 1));
    });

    watch(() => modelUploaded.value, () => {
      emit('all-models-is-uploaded', (modelUploaded.value === (modelUploaded.value + modelToBeUpload.value)));
    });

    const syncPrescriptionRows = async() => {
      // Hide render component 
      showRenderComponent.value = false;
      
      // Simulate data processing
      const { bridges, tooth } = await new Promise(resolve => resolve(groupedByBridgesAndTooth(toRaw(props.elements))));
      data.bridges = bridges;
      data.tooth = tooth;

      if (props.isAbleToProject) {
        calculateToothUploaded();
      }

      // Show render component
      showRenderComponent.value = true;
    }

    const calculateToothUploaded = () => {
      numberElementsUploaded.value = 0;

      data.bridges.forEach((bridge) => {
        bridge.tooth.forEach((teeth) => {
          if (teeth.assets.length > 0) {
            numberElementsUploaded.value++;
          }
        });
      });

      data.tooth.forEach((teeth) => {
        if (teeth.assets.length > 0) {
          numberElementsUploaded.value++;
        }
      });
    }

    const removeBridge = async (ids) => {
      props.elements.forEach((el) => {
        if (ids.includes(el.id)) {
          el.bridge = null;
        }
      });

      let bridge = {
        ['null'] : ids
      };

      syncBridge(bridge);
    }

    const createBridge = async (ids) => {
      console.log("CREATE BRIDGE");

      let bridgeId = null;
      props.elements.forEach((el) => {
        if (ids.includes(el.id)) {
          // Not exist bridge.
          if (data.bridges.length === 0) {
            // Set the default index.
            el.bridge = 0;
            bridgeId = el.bridge;
          } else {
            // Set the last index bridge.
            el.bridge = data.bridges.length-1 !== -1 ? data.bridges.length : data.bridges.length-1;
            bridgeId = el.bridge;
          }
        }
      });

      let bridge = {
        [bridgeId]: ids
      };

      syncBridge(bridge);
    }

    const forceModelsHandle = (value) => {
      emit('force-models-upload', value);
    }

    const syncBridge = (data) => {
      updateBridge(data).then(async () => {
        // Sync data.
        syncPrescriptionRows();

      }).catch((error) => {
        console.log(error);
        alert("Non è stato possibile eseguire l'azione richiesta.");

      }).finally(async () => {
        await deselectedAll();
      });
    }

    const hasUploadedSomeModels = computed(() => {
      return Object.values(props.models).some((value) => {
        if (Array.isArray(value)) {
          return value.some((item) => Array.isArray(item.assets) && item.assets.length);
        }
        return false;
      })
    });

    const addAsset = async (obj) => {
      props.elements.forEach((el) => {
        if ('ids' in obj && obj.ids.includes(el.id)) {
          el.assets.push(obj.asset);
        }
      });

      // Sync data.
      syncPrescriptionRows();

      // Sync counter.
      calculateToothUploaded();
    }

    const removeAssets = async (ids) => {
      // TODO check if assets model's ids.

      props.elements.forEach((el) => {
        let prescriptionRow =  el;
        if (prescriptionRow.assets.length > 0) {
          if (ids.includes(prescriptionRow.id)) {
            prescriptionRow.assets = [];
          }
        }
      });

      // Sync data.
      syncPrescriptionRows();

      // sync model.
      emit('remove-assets-models', ids);

      // Sync counter.
      calculateToothUploaded();
    }

    const removeSelection = () => {
      console.log("RESET");
      deselectedAll();
    }

    const deselectedAll = () => {
      props.elements.forEach((el) => {
        el.selected = false;
      });
    }

    // Read emits.
    const closeModal = (value) => showModal.value = value;
    const readAllFilesUploaded = (value) => {
      emit('allFilesIsUploaded', value);
      emit('atLeastOneFileIsUploaded', (numberElementsUploaded.value >= 1));
    }

    const clearModelSelected = () => emit("clear-models-selected");
    const syncModels = (obj) => emit("sync-models", obj);

    const addStump = () => emit("add-stump");
    const removeStump = () => {
      emit("remove-stump");
      emit('all-models-is-uploaded', (modelUploaded.value === (modelUploaded.value + modelToBeUpload.value)));
    }

    const addGum = () => emit("add-gum");
    const removeGum = () => {
      emit("remove-gum");
      emit('all-models-is-uploaded', (modelUploaded.value === (modelUploaded.value + modelToBeUpload.value)));
    }

    const addCustomPin = () => emit("add-custom-pin");
    const removeCustomPin = () => {
      emit("remove-custom-pin");
      emit('all-models-is-uploaded', (modelUploaded.value === (modelUploaded.value + modelToBeUpload.value)));
    }

    return {
      showModal,
      closeModal,
      data,
      removeBridge,
      createBridge,
      addAsset,
      removeAssets,
      removeSelection,
      readAllFilesUploaded,
      numberElementsUploaded,
      clearModelSelected,
      syncModels,
      modelToBeUpload,
      modelUploaded,

      addStump,
      removeStump,

      addGum,
      removeGum,

      addCustomPin,
      removeCustomPin,
      forceModelsHandle,
      hasUploadedSomeModels,
      select,
      showRenderComponent
    }
  }
}
</script>
