import store from "../../store/index";
import router from "../../router/index";

import _head from "lodash/head";
import _filter from "lodash/filter";
import _find from "lodash/find";

import { getUserData } from '@/use/getUserData.js';
import { redirectTo } from "@/use/repositories/redirectTo";
import { departmentException } from "@/use/departmentException";
import { updateMainDepartment } from "@/use/repositories/users/updateMainDepartment";

export async function handleLogin() {

  const userData = await getUserData();

  if (!store.state.mainRole) await store.dispatch("setUserRoles", userData.roles);

  if (departmentException(store.state.userData)) {
    await store.dispatch("setMainRole", _find(store.state.userData, (item => item.role.slug == _head(userData.roles))));
    await store.dispatch("setUserDepartments", _filter(userData.departments, item => item.role_slug == store.state.mainRole.role.slug));
    await updateMainDepartment(_head(store.state.user.departments).team_id, store.state.mainRole.role_id);
  }

  else if (!store.state.mainRole && userData.roles.length)
    return await redirectTo(store.state.user.roles, store.state.user.departments, store).then(async (response) => {
      await router.push({ name: response.routeName });
    });
}
