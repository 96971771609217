import { default as axiosWithoutLoader } from "@/use/utilities/axios/chat/axios-instance-chat";
import { default as axiosWithLoader } from "@/use/utilities/axios/chat/axios-instance-chat-loader";
/**
* @param action String
* by_clinic_unseen - by_clinic_my_unseen - by_favorite -
*
*/
export async function index(action, loader = false) {
  const axios = loader ? axiosWithLoader : axiosWithoutLoader;
  let data = [];

  await axios
    .get("/channels", {
      params: { action },
    })
    .then(response => data = response.data.data)
    .catch(error => { throw error.response.data.errors });

  return { data };
}

