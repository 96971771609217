import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function create(prescriptionId, listRowId, quantity) {
  const axios = axiosInstance().primoLab;
  let receipt = [];

  await axios
    .post(`/receipts`, {
        prescription_id: prescriptionId,
        list_row_id: listRowId,
        quantity: quantity,
    })
    .then(async (response) => {
      receipt = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { receipt };
}
