<template>

  <template v-if="wareHouseHistory.length > 0 && ! isCamDepartment">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item border-0">

        <div class="accordion-header text-center p-2 text-uppercase fw-bold bg-home-violet collapsed" id="headingOne" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          ordini effettuati
          <img v-if="showCaretUp" class="mx-2" src="@/assets/icons/caret-up-dark.svg" width="20" alt="caret-up-dark" @click="showCaretUp = false">
          <img v-else class="mx-2" src="@/assets/icons/caret-down-dark.svg" width="20" alt="caret-down-dark" @click="showCaretUp = true">
        </div>

        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body p-0">

            <SummaryOrdersSent
              :data="wareHouseHistory"
              :warehouseRequestUpdate="warehouseRequestUpdate"
            />

          </div>
        </div>
      </div>
    </div>
  </template>


  <div class="d-flex bg-light-violet p-3 justify-content-center fw-bold text-uppercase">elenco prodotti da richiedere</div>
    <div class="d-flex flex-column h-100 overflow-auto" :class="{'justify-content-center': ordersSent}" style="margin-bottom: 15%;">

      <template v-if="ordersSent">
        <Completed
          :withBackgroundColor="false"
          :width="150"
          :message="'Ordine inviato al magazzino'"
        />
      </template>

      <template v-else>
        <RequestProducts
          class="p-3"
          :data="orders"
          @remove="removeOrder"
        />
      </template>

      <div class="d-flex position-absolute bottom-0 bg-white w-100 justify-content-end rounded-only-bottom">
        <button type="button" class="btn btn-violet text-uppercase m-3" @click="sendingOrder()" :disabled="orders.length === 0">
          <img src="@/assets/icons/telegram-white.svg" alt="telegram-white" class="me-2"/>
          invia ordine
        </button>
      </div>

    </div>

</template>

<script>
import { onMounted, ref, watch } from 'vue';
import {useRoute} from "vue-router";
import _head from 'lodash/head';

import RequestProducts from "@/components/warehouse/technician/RequestProducts";
import Completed from "@/components/Completed";
import SummaryOrdersSent from "@/components/warehouse/technician/SummaryOrdersSent";

import {store as stostoreWarehouseArticle} from '@/use/repositories/warehouse/store';
import { history } from "@/use/repositories/warehouse/history";
import {useStore} from "vuex";

export default {
  name: "Order",
  inheritAttrs: false,
  components: {
    SummaryOrdersSent,
    Completed,
    RequestProducts,
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    warehouseRequestUpdate: {
      type: Object,
      required: false,
    }
  },
  setup(props) {
    const showCaretUp = ref(false);
    const orders = ref([]);
    const ordersSent = ref(false)
    const route = useRoute();
    const store = useStore();
    const wareHouseHistory = ref([]);
    const counter = ref(0);
    const warehouseArticles = ref([]);
    const isCamDepartment = ref(store.state.mainRole.team.name === 'cam');
    const apiStoreActionType = (isCamDepartment.value ? 'without_test_work_queue_phase' : 'with_test_work_queue_phase');

    onMounted(() => {
      // Fetch history only technician not is the CAM department.
      if (! isCamDepartment.value) {
        getHistoryData();
      }
    });

    const getHistoryData = () => {
      history(route.params.test_work_queue_phases_id).then((response) => {
        wareHouseHistory.value = response.wareHouseHistory.value;
      });
    }

    watch(counter, (value) => {
      if (value === warehouseArticles.value.length) {
        let element = document.getElementById('headingOne');
        if (element !== null) {
          element.classList.add('collapsed');
          document.getElementById('collapseOne').classList.remove('show');
        }

        // Show message.
        ordersSent.value = true;

        setTimeout(() => {
          ordersSent.value = false;

          if (! isCamDepartment.value) {
            // Refresh data.
            getHistoryData();
          }
        }, 3000);

        counter.value = 0;
      }
    });

    watch(() => props.data, (order) => {
      if (order.selected === false) {
        return;
      }

      let index = orders.value.findIndex((item) => item.id === order.id);
      if (index !== -1) {
        return;
      }

      addQuantityProperty(order);
      orders.value.push(order);

    });

    const addQuantityProperty = (order) => {
      // Set to default quantity.
      order.quantity = 1;
    }

    const removeOrder = (orderId) => {
      let index = orders.value.findIndex((item) => item.id === orderId);
      orders.value.splice(index, 1);
    }

    const prepareDataStore = () => {
      warehouseArticles.value = [];
      orders.value.forEach((el) => {
        let obj = {
          id: el.wareHouseArticleId,
          quantity: el.quantity,
        }
        warehouseArticles.value.push(obj);
      });
    }

    const sendingOrder = () => {
      let test_work_queue_phase_id = route.params.test_work_queue_phases_id;
      prepareDataStore();
      counter.value = 0;

      warehouseArticles.value.forEach((warehouseArticle) => {
        let params = {
          action: apiStoreActionType,
          warehouse_articles: [warehouseArticle],
        }

        if (isCamDepartment.value) {
          params['department_slug'] = store.state.mainRole.team.name;
        } else {
          params['test_work_queue_phase_id'] = test_work_queue_phase_id;
        }

        stostoreWarehouseArticle(params).then((response) => {
          console.log("id to remove: " + _head(response.warehouseRequest.value).article.id);
          removeOrder(_head(response.warehouseRequest.value).article.id);
          counter.value++;

        }).catch(() => {
          warehouseArticles.value = [];
        });
      });
    }

    return {
      showCaretUp,
      orders,
      ordersSent,
      removeOrder,
      sendingOrder,
      wareHouseHistory,
      counter,
      warehouseArticles,
      isCamDepartment,
    }
  }
}
</script>

<style scoped>
button {
  right: 0;
}
.accordion-header:hover {
  cursor: pointer;
}
</style>
