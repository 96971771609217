import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAll() {
  const axios = axiosInstance().primoLab;
  let internalPhases = [];

  await axios
    .get(`/internal-phases`)
    .then(async (response) => {
      // Remove external phase type.
      internalPhases = response.data.data.filter((internalPhases) => {
        return internalPhases.slug !== 'external';
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { internalPhases };
}

