import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchTreeById(id) {
  const axios = axiosInstance().primoLab;
  let tree = [];

  await axios
    .get(`/test-work-queues/${id}`, {
      params: { action: 'with_tree' }
    })
    .then(async (response) => {
      tree = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { tree };
}
