<template>
  <div class="card shadow rounded-custom w-100 h-100 overflow-auto">

    <template v-if="showActions === false">
      <div class="show-action d-flex w-100 h-100 flex-column justify-content-evenly align-items-center" @click="expandToggle()">
        <img src="@/assets/icons/manager/gears.svg" width="70" height="70" alt="gears">
        <p class="text-decoration-underline text-violet">Mostra azioni</p>
      </div>
    </template>
    <template v-else>
      <div class="d-flex w-100 h-100">
        <div class="d-flex w-100 justify-content-evenly align-items-center">
          <div class="d-flex w-100 justify-content-around align-items-baseline">

            <template v-if="isDigitalCheckIn">
              <div class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/manager/resume.svg" width="50" alt="resume" @click="setAction(states.COMPLETED)">
                <span style="color: #28A745;">Approva</span>
              </div>
              <div class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/iso-in/suspend.svg" width="50" alt="resume" @click="setAction(states.ON_HOLD)">
                <span style="color:#A58ABA">Sospendi</span>
              </div>
              <div class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/iso-in/refuses.svg" width="50" alt="resume" @click="setAction(states.ISO_FAILED)">
                <span style="color:#DC3545">Boccia</span>
              </div>
              <div class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/iso-in/reassign.svg" width="50" alt="resume" @click="setAction(states.ISO_IN_CHANGED_TEST)">
                <span style="color:#FFC107">Cambio prova</span>
              </div>
              <div class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/logistic-checkin/calendar.svg" width="50" alt="iso-change-date" @click="setAction(states.ISO_IN_CHANGED_DATE)">
                <span style="color: #9D9D9D;">Cambio data</span>
              </div>
              <div class="d-flex icon flex-column align-items-center" @click="setAction(states.ISO_CUSTOM)">
                <img src="@/assets/icons/iso-in/custom.svg" alt="iso-custom" width="50" />
                <span style="color: #9D9D9D;">Personalizza</span>
              </div>
            </template>

            <template v-else-if="isIsoDepartment && ! route.query.by_manager">
              <template v-if="! $props.hasChildren && ! $props.hasParent">
                <a class="d-flex flex-column text-center m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ISO_IN_CHOOSE_FORK)">
                  <img class="mb-2" src="@/assets/icons/iso-in/fork-right.svg" alt="fork-right" width="60" height="60"/>
                  <span style="color:#87A2FB;">Scegli percorso</span>
                </a>
              </template>
              <template v-if="$props.hasChildren || $props.hasParent">
                <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.COMPLETED)">
                  <img class="mb-2" src="@/assets/icons/iso-in/accept.svg" alt="accept" width="60" height="60"/>
                  <span style="color:#28A745">Accetta</span>
                </a>
              </template>

              <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ISO_FAILED)">
                <img class="mb-2" src="@/assets/icons/iso-in/refuses.svg" alt="refuses" width="60" height="60"/>
                <span style="color:#DC3545">Rifiuta</span>
              </a>

              <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ON_HOLD)">
                <img class="mb-2" src="@/assets/icons/iso-in/suspend.svg" alt="suspend" width="60" height="60"/>
                <span style="color:#A58ABA">Sospendi</span>
              </a>

                <a class="d-flex flex-column text-center m-3 text-decoration-none align-items-center" :class="{ 'disabled': ! canChangeTest }" href="javascript:void(0)" @click="setAction(states.ISO_IN_CHANGED_TEST)">
                  <img class="mb-2" src="@/assets/icons/iso-in/reassign.svg" alt="reassign" width="60" height="60"/>
                  <span style="color:#FFC107">Cambio prova</span>
                </a>

                <a class="d-flex flex-column text-center m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ISO_IN_CHANGED_DATE)">
                  <img src="@/assets/icons/logistic-checkin/calendar.svg" alt="iso-custom" width="55" height="68" class="mb-2" style="filter: contrast(0);"/>
                  <span style="color: #9D9D9D;">Cambio data</span>
                </a>

              <template v-if="$props.hasChildren || $props.hasParent">
                <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ISO_REASSIGNED)">
                  <img class="mb-2" src="@/assets/icons/iso-in/changed-test.svg" alt="change-test" width="60" height="60"/>
                  <span style="color:#9D9D9D">Riassegna</span>
                </a>
              </template>

              <!-- Custom -->
              <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="setAction(states.ISO_CUSTOM)">
                <img class="mb-2" src="@/assets/icons/iso-in/custom.svg" alt="custom" width="60" height="60"/>
                <span style="color:#9D9D9D">Personalizza</span>
              </a>
              <template v-if="($props.hasChildren || $props.hasParent) && $props.isMobileDepartment">
                <a class="d-flex flex-column m-3 text-decoration-none align-items-center" href="javascript:void(0)" @click="emit('external')">
                  <img src="@/assets/icons/van.svg" alt="van" width="75" height="68">
                  <span style="color:rgb(151, 129, 176)">Esterna</span>
                </a>
              </template>
            </template>

            <template v-else-if="route.query.by_manager">
              <template v-if="isFailedWork">
                <div :class="{ 'disabled': isFailedWork }" class="d-flex icon flex-column align-items-center">
                  <img src="@/assets/icons/manager/resume.svg" width="50" alt="resume" @click="setAction('resume-failed')">
                  <span style="color: #28A745;">Riprendi</span>
                </div>
              </template>
              <template v-else>
                <div class="d-flex icon flex-column align-items-center">
                  <img src="@/assets/icons/manager/resume.svg" width="50" alt="resume" @click="setAction('resume')">
                  <span style="color: #28A745;">Riprendi</span>
                </div>
              </template>
              <div :class="{ 'disabled': isFailedWork }" class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/manager/faild.svg" width="50" alt="faild" @click="setAction(states.MANAGER_FAILED)">
                <span style="color: #DC3545;">Fallito</span>
              </div>
              <div :class="{ 'disabled': isFailedWork }" class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/manager/reassign.svg" width="50" alt="reassign" @click="setAction('change-technician')">
                <span style="color: #FFC107;" class="text-center">Cambio <br>tecnico</span>
              </div>
              <div :class="{ 'disabled': isFailedWork }" class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/iso-in/changed-test.svg" width="50" alt="manager-reassigned" @click="setAction(states.MANAGER_REASSIGNED)">
                <span style="color: #9D9D9D;" class="text-center">Cambia <br>fase interna</span>
              </div>
              <div :class="{ 'disabled': isFailedWork }" class="d-flex icon flex-column align-items-center">
                <img src="@/assets/icons/manager/change-test.svg" width="50" alt="change-test" @click="setAction(states.MANAGER_CHANGED_TEST)">
                <span style="color: #A58ABA;" class="text-center">Cambia <br>prova</span>
              </div>
            </template>

            <div class="d-flex icon flex-column align-items-center" @click="expandToggle()">
              <img src="@/assets/icons/manager/chevron-rigth-violet.svg" width="50" alt="chevron-rigth-violet">
              <span class="text-decoration-underline text-violet">Riduci</span>
            </div>

          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { computed, inject, ref, watch } from 'vue';
import { useRoute } from "vue-router";
import { isTechnicianIso } from "@/use/utilities/isTechnicianIso";

export default {
  name: "ManagerActions",
  props: {
    isDigitalCheckIn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: true
    },
    hasChildren: {
      type: Number,
      required: false
    },
    hasParent: {
      type: Number,
      required: false
    },
    isMobileDepartment: {
      type: Boolean,
      required: false
    },
    canChangeTest: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  emits: [
    'open',
    'action',
    'external',
  ],
  setup(props, {emit}) {
    const { states } = inject("constants");
    const route = useRoute();
    const showActions = ref(false);
    const isIsoDepartment = ref(! props.isDigitalCheckIn && isTechnicianIso());
    const isFailedWork = computed(() => {
      return  route.query.test_work_queue_phase_state_slug === states.ISO_FAILED
              ||
              route.query.test_work_queue_phase_state_slug === states.MANAGER_FAILED
    });

    watch(() => props.disabled, (value) => {
      if (value === false) {
          expandToggle();
      }
    });

    const expandToggle = () => {
      // Toggle template.
      showActions.value = ! showActions.value;
      emit('open');
    }

    const setAction = (action) => {
      emit('action', action);
    }

    return {
      expandToggle,
      showActions,
      setAction,
      states,
      isFailedWork,
      emit,
      route,
      isIsoDepartment
    }
  }
}
</script>

<style lang="scss" scoped>
  .show-action {
    &:hover {
      cursor: pointer;
    }
  }
  .disabled {
    opacity: 0.2;
    cursor: not-allowed !important;
    pointer-events: none;
  }
  .icon {
    &:hover {
      cursor: pointer;
    }
  }
</style>
