<template>
  <div class="modal fade" id="modalTWQPCompleted" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3 p-4">
        <div class="p-1 border-0 text-center">
          <h3 class="mb-4 fw-normal text-danger">Lavoro terminato! <img class="me-1" src="@/assets/icons/warning.svg" width="20" alt="warning"></h3>
          <small>Complimenti, hai terminato il tuo lavoro!</small>
        </div>
        <div class="modal-body">
          <p>Il lavoro sta per essere mandato in <strong>{{ getNextPhase() }}</strong></p>
          <Note
            label="Nota per lavorazione successiva:"
            :clipboard="nota"
            @message="readNota">
          </Note>
        </div>
        <div class="modal-footer p-1 border-0 justify-content-center">
          <button type="button" class="btn btn-violet text-uppercase mt-4"
                  @click="save($props.test_work_queue_phase_id, 'completed')">
            conferma
          </button>
          <button type="button" class="btn btn-outline-violet text-uppercase mt-4" data-bs-dismiss="modal">
            annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {Modal} from "bootstrap";

import Note from '@/components/general/Note';

import {store as storeFeedback} from "@/use/repositories/feedbacks/store";
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import {changeState} from '@/use/repositories/users/changeState';

import {hideModal} from '@/use/modal/hide';
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import _head from "lodash/head";

export default {
  name: "Completed",
  props: [
    'test_work_queue_phase_id',
    'test_work_queue_id',
    'is_last',
    'children_test_work_queue_phase'
  ],
  emits: [
    'hidden'
  ],
  components: {
    Note
  },
  setup(props, context) {
    const store = useStore();
    const nota = ref(store.state.clipboardDashboardTechnician);
    const router = useRouter();

    onMounted(() => {
      showModal("modalTWQPCompleted");
      dismissModal("modalTWQPCompleted", "hidden");
    });

    // Modal function.
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal));
      modal.show();
    }
    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

    // Change state and save note
    const save = async (testWorkQueuePhaseId, state) => {
      // Clear clipboard saved on vuex.
      await store.dispatch("setClipboardDashboardTechnician", "");

      await storeFeedback({
        test_work_queue_phase_id: parseInt(testWorkQueuePhaseId),
        state: state,
        is_iso: false,
        note: nota.value,
        action: "without_media",
      }).then(async () => {
        if (props.is_last) {
          // Update test work queue "in-the-work" state.
          updateTestWorkQueue({
            id: props.test_work_queue_id,
            state_slug: "completed",
            action: "update_state"
          }).then((response) => {
            console.log(`UPDATE TWQ ID: ${response.testWorkQueue.id} STATE IN ${response.testWorkQueue.state.slug}`);
          });
        }
        // Change user state.
        changeState('available').then(async () => {
          // Hide modal
          await hideModal("modalTWQPCompleted");
          // Redirect
          await router.push({ name: "Checkin" });
        })
      });
    }

    const readNota = (value) => {
      store.dispatch("setClipboardDashboardTechnician", value);
      nota.value = value;
    }

    const getNextPhase = () => {
      return props.is_last ? 'check-out' : _head(props.children_test_work_queue_phase).internal_phase.name;
    }

    return {
      save,
      readNota,
      nota,
      getNextPhase,
    }
  }
}
</script>
