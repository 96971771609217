<template>
  <div>
    <div class="mb-3">
      <Toggle v-model="enabled"/>
      <label class="ms-2">Rifiuta prescrizione</label>
    </div>
    <Multiselect
      v-if="enabled"
      label="Selezionare il problema riscontrato"
      :max="1"
      :class="'mb-3'"
      :options="$props.prescriptionRejectedReasons"
      @selected="reasonsIdsSelected"
    />
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRaw, watch} from "vue";
import Multiselect from "@/components/Multiselect";
import Toggle from '@vueform/toggle';

export default {
  name: "PrescriptionRejected",
  props: {
    clear: {
      type: Boolean,
      required: true
    },
    prescriptionRejectedReasons: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Multiselect,
    Toggle
  },
  emits: [
    'enabled',
    'rejectedReasonIdsSelected'
  ],
  setup(props, {emit}) {
    const enabled = ref(false);
    const prescriptionRejectedReasonsIdsSelected = reactive({ ids: [] });

    onMounted(() => {
    });

    watch(() => props.clear, (value) => {
      if (value) {
        enabled.value = false;
        prescriptionRejectedReasonsIdsSelected.ids = [];
      }
    });

    watch(() => enabled.value, (value) => {
      if (value === false) prescriptionRejectedReasonsIdsSelected.ids = [];
      emit('enabled', value);
    });

    watch(() => prescriptionRejectedReasonsIdsSelected.ids.length, (value) => {
      if (value !== null) emit('rejectedReasonIdsSelected', prescriptionRejectedReasonsIdsSelected.ids);
    });

    const reasonsIdsSelected = (value) => {
      prescriptionRejectedReasonsIdsSelected.ids = toRaw(value);
    }

    return {
      enabled,
      prescriptionRejectedReasonsIdsSelected,
      reasonsIdsSelected
    };

  },
};
</script>
