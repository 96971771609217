<template>
  <template v-if="notes.length">
    <template v-for="(prescriptionTest, index) in notes" :key="index">
      <div class="card border-light" v-if="prescriptionTest.laboratory_notes.length">
        <div class="card-header text-uppercase fw-bold">
          {{ (notes.length-index) }} . {{ prescriptionTest.name }}
        </div>
        <div class="card-body" :class="{'bg-light-violet': ($props.prescriptionTestId === prescriptionTest.id)}">
          <template v-if="prescriptionTest.laboratory_notes.length">
            <template v-for="(note, index) in prescriptionTest.laboratory_notes" :key="index">
              <div class="d-flex">
                <template v-if="readConfirmation && ($props.prescriptionTestId === prescriptionTest.id) && !alreadyRead(note.users)">
                  <Checkbox
                    class="me-2"
                    @click="syncReadConfirmationId(note.id)"
                  />
                </template>
                <div class="d-flex flex-column w-100">
                  <small class="text-start fw-bold">{{ note.user.last_name }}</small>
                  <p class="card-text text-start">
                    {{ note.note }}
                  </p>
                  <small class="d-flex justify-content-end text-muted datetime">
                    {{ dateFormatter(note.created_at) }}
                  </small>
                  <template v-if="note.media.length">
                    <SliderImage
                      :elements="note.media.map((item) => {
                    return {
                      createdAt: item.createdAt,
                      temporaryUrl: item.temporary_url,
                    }
                  })"
                      :item-to-show="note.media.length"
                    />
                  </template>
                  <hr v-if="index !== (prescriptionTest.laboratory_notes.length-1)">
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            Nessuna nota presente
          </template>
        </div>
      </div>
    </template>
  </template>
  <template v-else>
    Nessuna nota presente
  </template>
</template>

<script>
import SliderImage from "@/components/general/SliderImages";
import Checkbox from "@/components/general/Checkbox";
import {dateFormatter} from '@/use/utilities/time/dateFormatter';
import {ref, computed, onMounted, watch} from "vue";
import {updateReadingNota} from "@/use/repositories/prescriptionTests/updateReadingNota";
import {useStore} from "vuex";

export default {
  name: "Notes",
  components: {
    SliderImage,
    Checkbox,
  },
  props: {
    notes: {
      type: Array,
      required: true,
    },
    prescriptionTestId: {
      type: [Number, null],
      required: false,
      default: null
    },
    readConfirmation: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: [
    "readAll",
  ],
  setup(props, {emit}) {
    const store = useStore();
    let idsNotes = ref([]);
    let idsConfirmed = ref([]);
    let idsSaved = ref([]);

    const readAllConfirmation = computed(() => {
      console.log(`notes to read: ${idsNotes.value.length}`);
      return (idsNotes.value.length === idsConfirmed.value.length);
    });

    onMounted(() => {
      emit("readAll", readAllConfirmation.value);
      if (props.readConfirmation) {
        props.notes.forEach((prescriptionTest) => {
          if (prescriptionTest.id === props.prescriptionTestId) {
            prescriptionTest.laboratory_notes.forEach((note) => {
              const usersUnique = [...new Set(note.users.map(user => user.id))];
              if (!usersUnique.includes(store.state.user.id)) {
                idsNotes.value.push(note.id);
              }
            });
          }
        });
      }
    });

    watch(() => readAllConfirmation.value, (value) => {
      emit("readAll", value);
    });

    const syncReadConfirmationId = (id) => {
      let index = idsConfirmed.value.indexOf(id);
      if (index === -1) {
        if (!idsSaved.value.includes(id)) {
          updateReadingNota(id, false).then((response) => {
            console.log(response);
            idsSaved.value.push(id);
          });
        }
        idsConfirmed.value.push(id);
      } else {
        idsConfirmed.value.splice(index, 1);
      }
    }

    const alreadyRead = (users) => {
      let usersIds = users.map(user => user.id);
      return usersIds.includes(store.state.user.id);
    }

    return {
      dateFormatter,
      syncReadConfirmationId,
      readAllConfirmation,
      idsNotes,
      idsConfirmed,
      alreadyRead,
    }
  }
}
</script>

<style scoped>
  .datetime {
    font-size: 10px;
  }
</style>
