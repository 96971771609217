export function canPlasterCastingCheckin({ next, store }) {
  if (
    typeof store.state.user.state !== "undefined" &&
    store.state.user.roles.includes("checkin-colatura-gesso")
  ) {
    return next();
  } else {
    return next("/login");
  }
}
