import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function attachDevice(prescriptionTestId, deviceId) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .put(`/prescription-tests/${prescriptionTestId}`, {
      device_id: deviceId,
      action: "update_device",
    })
    .then(async (resp) => {
      data = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
