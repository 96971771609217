<script>
import { ref, onMounted, reactive, computed, inject } from "vue";
import TableLite from "vue3-table-lite";
import _remove from "lodash/remove";
import _map from "lodash/map";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

import { goToConsulting } from "@/use/utilities/goToConsulting";
import { formatDate } from "@/use/utilities/time/formatDate";
import { hideModal as closeModal } from "@/use/modal/hide";
import { fetchRejectedReasons } from "@/use/repositories/prescriptionTestRejected/fetchRejectedReasons";
import { updateRejected } from "@/use/repositories/prescriptionTestRejected/updateRejected.js";
import { updateReason } from "@/use/repositories/prescriptionTestRejected/updateReason.js";
import { fetchAllRejectedByState } from "@/use/repositories/prescriptionTestRejected/fetchAllRejectedByState.js";
import { update as updateState } from '@/use/repositories/testWorkQueue/update';
import { update as updatePrescriptionTest } from "@/use/repositories/prescriptionTests/update";

import Brand from "@/components/Brand";
import ButtonBack from "@/components/general/ButtonBack";
import SpecialActions from "@/components/SpecialActions";
import Empty from "@/components/Modal/Empty";

export default {
  name: "RejectedApproval",
  components: {
    TableLite,
    ButtonBack,
    Brand,
    SpecialActions,
    Empty,
  },
  setup() {
    const toast = useToast();
    const i18n = useI18n();
    const { prescriptionTestStates } = inject("constants");

    const tabActiveType = ref("pending");
    const rejectedReasons = ref([]);
    const rejectedReasonId = ref(null);
    const showEditRejectedModal = ref(false);
    const prescriptionsRejected = ref([]);
    const rejectedId = ref(null);
    const setActiveTab = (nameTab) => {
      tabActiveType.value = nameTab;
      doSearch(nameTab);
    };
    onMounted(() => {
      setActiveTab("pending");
    });

    const clearRejectedReasonSelected = () => {
      rejectedReasonId.value = null;
    };

    const setMotivationIdSelected = (reasonId) => {
      rejectedReasonId.value = reasonId;
    };

    const closeEditRejectedModal = () => {
      closeModal("editRejectedModal");
      showEditRejectedModal.value = false;
      rejectedReasonId.value = null;
      rejectedId.value = null;
    };

    const updatePrescriptionRejectedReason = () => {
      const prescriptionTestRejectedId = rejectedId.value;
      updateReason(prescriptionTestRejectedId, rejectedReasons.value.find((reason) => reason.value == rejectedReasonId.value).slug).then(() => {
        toast.success("Motivazione modificata");
        updatePrescriptionRejectedState(prescriptionTestRejectedId, 'sent');
      })
      closeEditRejectedModal();
    };
    const rejectedIds = [];
    const updatePrescriptionRejectedState = (prescriptionTestRejectedId, state) => {
      updateRejected(prescriptionTestRejectedId, state)
      .then((response) => {
        toast.success(i18n.t("Reason edited correctly"));
        _remove(prescriptionsRejected.value, (test) => {
          return test.id == response.prescriptionRejected.id;
        });
      })
      .catch(() => _remove(rejectedIds, prescriptionTestRejectedId));
    }

    onMounted(() => {
      fetchRejectedReasons().then((response) => {
        rejectedReasons.value = response.rejectedReasons;
      });
    });

    const doSearch = (param) => {
      table.isLoading = true;
      fetchAllRejectedByState(param).then((response) => {
        prescriptionsRejected.value = response.prescriptionsRejected;
        table.isLoading = false;
      });
    };

    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains("edit-reject")) {
          element.addEventListener("click", function () {
            rejectedId.value = element.attributes['data-id'].value;
            showEditRejectedModal.value = true;
          });
        }
        if (element.classList.contains("approve")) {
          element.addEventListener("click", function () {

            const id = element.attributes['data-id'].value;

            if (rejectedIds.includes(id)) return

            rejectedIds.push(id);

            updatePrescriptionRejectedState(id, 'sent');
          });
        }

        if (element.classList.contains("reset")) {
          element.addEventListener("click", function () {
            const wasInLab = element.attributes['data-test-work-queue-id'].value !== "undefined";

            if (wasInLab) {
              updateState({
                id: element.attributes['data-test-work-queue-id'].value,
                state_slug: "in-the-works",
                action: "update_state",
              });
            } else {
              updatePrescriptionTest(
                element.attributes['data-prescription-test-id'].value,
                {
                  state_slug: element.attributes['data-intraoral-scanner-id'].value !== "null" ?
                    prescriptionTestStates.INVIATA_AL_LAB
                    : prescriptionTestStates.WITH_DATE,
                  action: "change_state",
                },
                true
              );
            }

            // Update rejected to done
            updatePrescriptionRejectedState(element.attributes['data-id'].value, 'done');
          });
        }

        if (element.classList.contains("consulting-link")) {
          element.addEventListener("click", function () {
            goToConsulting(element.attributes['data-id'].value, true);
          });
        }
      });
    };

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Prescrizione",
          field: "prescription",
          width: "10%",
          sortable: false,
          display: function (row) {
            return (
              `<a href="javascript:void(0)" class="is-rows-el consulting-link" style="text-decoration-line: none; color: #A58ABA;" data-id="` + row.prescriptionId + `">` + row.prescription + `</a>`
            );
          },
        },
        {
          label: "Clinica",
          field: "clinic",
          width: "15%",
          sortable: false,
        },
        {
          label: "Paziente",
          field: "customer",
          width: "15%",
          sortable: false,
        },
        {
          label: "Data richiesta",
          field: "createdDate",
          width: "15%",
          sortable: false,
        },
        {
          label: "Richiedente",
          field: "applicant",
          width: "20%",
          sortable: false,
        },
        {
          label: "Motivazione",
          field: "reason",
          width: "40%",
          sortable: false,
        },
        {
          label: "",
          field: "",
          width: "15%",
          sortable: false,
          display: function (row) {
            if (tabActiveType.value === "pending") {
              return (
                `<div class="d-flex">
                <a href="javascript:void(0)" class="is-rows-el edit-reject me-4" style="text-decoration-line: none;" data-id="` + row.id + `">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="51" height="51" viewBox="0 0 51 51"> <defs> <filter id="Ellisse_25" x="0" y="0" width="51" height="51" filterUnits="userSpaceOnUse"> <feOffset dx="2" dy="2" input="SourceAlpha"/> <feGaussianBlur stdDeviation="1" result="blur"/> <feFlood flood-opacity="0.161"/> <feComposite operator="in" in2="blur"/> <feComposite in="SourceGraphic"/> </filter> </defs> <g id="Raggruppa_1579" data-name="Raggruppa 1579" transform="translate(-822 -591)"> <g transform="matrix(1, 0, 0, 1, 822, 591)" filter="url(#Ellisse_25)"> <circle id="Ellisse_25-2" data-name="Ellisse 25" cx="22.5" cy="22.5" r="22.5" transform="translate(1 1)" fill="#A58ABA"/> </g> <g id="pencil" transform="translate(830.753 599.75)"> <path id="Tracciato_890" data-name="Tracciato 890" d="M20.074,2.757a1.732,1.732,0,0,1,2.448,0l3.464,3.464a1.732,1.732,0,0,1,0,2.448L10.4,24.255a1.732,1.732,0,0,1-.676.419l-5.2,1.732a1.732,1.732,0,0,1-2.193-2.19l1.732-5.2a1.732,1.732,0,0,1,.419-.677L20.073,2.757ZM21.3,3.982l3.464,3.464L9.176,23.031l-5.2,1.732,1.732-5.2Z" fill="#fff" fill-rule="evenodd"/> <path id="Tracciato_891" data-name="Tracciato 891" d="M22.589,12.633,18.26,8.3l1.226-1.226,4.329,4.329-1.225,1.226ZM6.75,18.948v.866a.866.866,0,0,0,.866.866h.866v.866a.866.866,0,0,0,.866.866h.866v.866a.866.866,0,0,0,.866.866h.866v-2.6a.866.866,0,0,0-.866-.866h-.866v-.866a.866.866,0,0,0-.866-.866Z" transform="translate(-1.037 -1.112)" fill="#fff" fill-rule="evenodd"/> </g> </g> </svg>
                </a>
                <a href="javascript:void(0)" class="is-rows-el approve me-4" style="text-decoration-line: none;" data-id="` + row.id + `">
                  <svg id="check-circle-fill" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 22 22"> <path id="Path_877" data-name="Path 877" d="M22,11A11,11,0,1,1,11,0,11,11,0,0,1,22,11ZM16.541,6.834a1.032,1.032,0,0,0-1.485.03l-4.775,6.084L7.4,10.069a1.032,1.032,0,0,0-1.457,1.458l3.638,3.639a1.032,1.032,0,0,0,1.484-.028l5.489-6.862a1.032,1.032,0,0,0-.013-1.444Z" fill="#A58ABA" fill-rule="evenodd"/> </svg>
                </a>
              </div>`
              );
            }

            if (tabActiveType.value === "sent") {
              return (
                `<div class="d-flex">
                  <a href="javascript:void(0)"
                    class="is-rows-el reset me-4"
                    style="text-decoration-line: none;"
                    data-test-work-queue-id="` + row.test_work_queue_id + `"
                    data-prescription-test-id="` + row.prescription_test.id + `"
                    data-intraoral-scanner-id="` + row.prescription_test.intraoral_scanner_id + `"
                    data-id="` + row.id + `"
                    >
                    <svg fill="#A58ABA" width="51" height="51" viewBox="0 0 24 24" id="log-in" xmlns="http://www.w3.org/2000/svg" class="icon line"><path id="primary" d="M10.56,18.35,9,17.05a1,1,0,0,1-.11-1.41L10.34,14H3V10h7.34L8.93,8.36A1,1,0,0,1,9,7l1.52-1.3A1,1,0,0,1,12,5.76l4.79,5.59a1,1,0,0,1,0,1.3L12,18.24A1,1,0,0,1,10.56,18.35ZM17,4h3a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H17" style="fill: none; stroke: #A58ABA; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5;"></path></svg>
                  </a>
              </div>`
              );
            }

            return;
          },
        },
      ],
      rows: computed(() => {
        return _map(prescriptionsRejected.value, item => {
          return {
            id: item.id,
            test_work_queue_id: item.prescription_test?.test_work_queue?.id,
            prescription_test: item.prescription_test,
            createdDate: formatDate(item.created_at),
            sortableDate: item.created_at,
            prescription: item.prescription_test.prescription.number_text,
            prescriptionId: item.prescription_test.prescription.id,
            clinic: item.prescription_test.prescription.clinic.name,
            customer: `${item.prescription_test.prescription.customer.first_name} ${item.prescription_test.prescription.customer.last_name}`,
            applicant: `${item.user.first_name} ${item.user.last_name}`,
            reason: item.reason.description,
          };
        });
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "sortableDate",
        sort: "desc",
      },
      messages: {
        pagingInfo: "Mostra {0}-{1} di {2}",
        pageSizeChangeLabel: "Numero righe:",
        gotoPageLabel: "Vai alla pagina:",
        noDataAvailable: "Nessun record trovato",
      },
    });

    return {
      table,
      tableLoadingFinish,
      doSearch,
      setActiveTab,
      rejectedReasons,
      rejectedReasonId,
      tabActiveType,
      prescriptionsRejected,
      showEditRejectedModal,
      closeEditRejectedModal,
      clearRejectedReasonSelected,
      setMotivationIdSelected,
      updatePrescriptionRejectedReason,
      goToConsulting,
      closeModal,
    };
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex flex-column justify-content-start align-items-start">
        <brand :width="150" />
        <div class="mb-5"></div>
        <ButtonBack :label="'indietro'" />
      </div>
      <div class="container mt-5">
        <div class="d-flex flex-column rounded-only-bottom ms-4 h-100 position-relative overflow-hidden">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item col-4" role="presentation">
              <button @click="setActiveTab('pending')" class="nav-link text-uppercase rounded-only-top active w-100"
                id="pending-tab" data-bs-toggle="pill" data-bs-target="#pending" type="button" role="tab"
                aria-controls="pending" aria-selected="true">
                <span class="m-auto">Da approvare</span>
              </button>
            </li>
            <li class="nav-item col-4" role="presentation">
              <button @click="setActiveTab('sent')"
                class="nav-link text-uppercase rounded-only-top d-flex align-items-center w-100" id="sent-tab"
                data-bs-toggle="pill" data-bs-target="#sent" type="button" role="tab" aria-controls="sent"
                aria-selected="false">
                <span class="m-auto">Approvate</span>
              </button>
            </li>
            <li class="nav-item col-4" role="presentation">
              <button @click="setActiveTab('done')" class="nav-link text-uppercase rounded-only-top w-100" id="done-tab"
                data-bs-toggle="pill" data-bs-target="#done" type="button" role="tab" aria-controls="done"
                aria-selected="false">
                <span class="m-auto">Completate</span>
              </button>
            </li>
          </ul>
          <div class="tab-content bg-white" id="pills-tabContent">
            <div ref="pending" class="tab-pane fade show active" id="pending" role="tabpanel"
              aria-labelledby="pending-tab">
              <template v-if="tabActiveType === 'pending'">
                <table-lite @is-finished="tableLoadingFinish" :is-static-mode="true" :is-loading="table.isLoading"
                  :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                  :messages="table.messages" @do-search="doSearch('pending')">
                </table-lite>
              </template>
            </div>
            <div class="tab-pane fade" id="sent" role="tabpanel" aria-labelledby="sent-tab">
              <template v-if="tabActiveType === 'sent'">
                <table-lite @is-finished="tableLoadingFinish" :is-static-mode="true" :is-loading="table.isLoading"
                  :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                  :messages="table.messages" @do-search="doSearch('sent')">
                </table-lite>
              </template>
            </div>
            <div class="tab-pane fade" id="done" role="tabpanel" aria-labelledby="done-tab">
              <template v-if="tabActiveType === 'done'">
                <table-lite @is-finished="tableLoadingFinish" :is-static-mode="true" :is-loading="table.isLoading"
                  :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                  :messages="table.messages" @do-search="doSearch('done')">
                </table-lite>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Empty v-if="showEditRejectedModal" :size="'md'" :modal_id="'editRejectedModal'" @hidden="closeEditRejectedModal">
      <SpecialActions style="height: 400px" :title="'Seleziona il motivo della tua richiesta'"
        :modelNameSelect="rejectedReasonId" :placeholderNameSelect="'Seleziona la motivazione'"
        :optionsSelect="rejectedReasons" :notes="false" @selected="setMotivationIdSelected"
        @clear="clearRejectedReasonSelected" @store="updatePrescriptionRejectedReason" @close="closeModal('editRejectedModal');"/>
    </Empty>
  </div>
</template>
<style scoped>
/* Change style table */
:deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #f3e9ff;
  border-color: #f3e9ff;
}

:deep(.vtl-table td),
:deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  vertical-align: middle;
}

:deep(.vtl-paging-info),
:deep(.vtl-paging-pagination-div),
:deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7e6492;
}

:deep(.vtl-paging-count-label),
:deep(.vtl-paging-page-label) {
  color: #7e6492;
}

:deep(.vtl-paging-pagination-page-link) {
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #7e6492;
}
</style>
