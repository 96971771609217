import { default as axios } from "@/use/utilities/axios/up/axios-instance-primo-up";

export async function getPdf(prescriptionId, action) {
  let prescription = [];

  await axios
    .get(`/prescriptions/${prescriptionId}`, {
      params: {
        action: action,
      }
    })
    .then(async (response) => {
      prescription = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescription };
}
