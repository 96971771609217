import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function storeCustomFlow(id, internalPhasesIds) {
  const axios = axiosInstance().primoLab;
  let message = [];

  await axios
    .post(`/test-work-queue-phases/store-custom-flow`, {
      id: id,
      internal_phase_ids: internalPhasesIds,
    })
    .then(async (response) => {
      message = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
