<template>
  <div class="d-flex flex-column p-3 align-items-start">
    <div class="mb-2">ciao,
      <strong class="ms-1">
        {{ $store.state.user.first_name }} {{ $store.state.user.last_name }}
        <span class="badge bg-violet text-wrap text-uppercase">{{ $store.state.mainRole.team.display_name }}</span>
      </strong>
    </div>
    <router-link to="/logout" class="btn btn-violet text-uppercase d-flex align-items-center">
      <img class="me-2" src="@/assets/icons/logout.svg" width="30" height="30" alt="logout">
      esci
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logout"
}
</script>
