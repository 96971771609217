<script>
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import MultiselectCustom from "@vueform/multiselect";
import { lightCommand } from "@/use/repositories/devices/lightCommand";
import _map from "lodash/map";
import _head from "lodash/head";

export default {
  name: "DeviceCommand",
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    MultiselectCustom
},
  emits: ['result'],
  setup(props) {
    const { tagColors } = inject("constants");
    const i18n = useI18n();
    const colors = _map(tagColors, (color) => { return { label: i18n.t(color), value: color } });
    const colorSelected = ref(_head(colors)?.value);

    const lightDevice = () => {
      if (! props.multiple) return lightCommand(props.data, colorSelected.value).then(response => { console.log(response) });

      props.data.forEach(async element => await lightCommand(element.device_id, colorSelected.value));
    }

    return {
      lightDevice,
      colorSelected,
      colors,
    };
  }
}
</script>

<template>
  <div class="p-4 d-flex flex-column justify-content-between">
    <MultiselectCustom id="selection_color" class="multiselect-violet mb-3" v-model="colorSelected"
      placeholder="Seleziona un colore" :options="colors" @clear="() => { console.log('clear') }" />
    <button class="btn btn-violet rounded-custom text-uppercase d-flex align-items-center m-auto p-2 mt-3"
      @click="lightDevice()">
      {{ $props.multiple ? "Illumina le caselle selezionate" : "Illumina la casella" }}
    </button>
  </div>
</template>

