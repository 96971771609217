<template>
  <Counters></Counters>
  <div class="d-flex vw-100 vh-100 p-5">
    <div class="m-3 overflow-auto" style="width: 65%;">
      <SortingOfWorks
        :by-pusher="byPusher"
        :data-assets="assets"
        @reset-by-pusher="byPusher.value = false"
        @numberTextToBeSelected="readNumberText"
        @reset="resetFinishWorkSelected = true"
      />
    </div>
    <div class="m-3 overflow-auto" style="width: 35%;">
      <FinishWork
        :data-assets="assets"
        :numberText="numberText"
        :reset="resetFinishWorkSelected"
        @idsToRemove="readIdsToRemove"
        @resetNumberTextProp="resetNumberText"
        @restore="resetFinishWorkSelected = false"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, getCurrentInstance, onUnmounted, toRaw, reactive } from "vue";
import { fetchAll } from "@/use/repositories/assets/fetchAll";
import { getLabelsNameByAsset } from '@/use/utilities/getLabelsNameByAsset';

import SortingOfWorks from "@/components/cam/SortingOfWorks";
import FinishWork from "@/components/cam/FinishWork";
import Counters from "@/components/devices/Counters.vue";

export default {
  name: "Dashboard",
  components: {
    SortingOfWorks,
    FinishWork,
    Counters,
  },
  setup() {
    const assets = ref([]);
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const numberText = ref(null);
    const resetFinishWorkSelected = ref(false);
    const byPusher = reactive({value: false});

    onMounted(() => {
      let params = {
        states: ['in-the-works', 'pending'],
        action: "by_states",
      }
      // Fetch in-the-works and pending state.
      fetchAll(params).then(async (response) => {
        formattedData(response.assets);
      });
      // Subscribe to channel
      subscribe("private-live", "asset_change-state");
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel("private-live");
    });

    const formattedData = (data) => {
      data.forEach((asset) => {
        // Create same properties.
        let obj = {
          checked: false,
          first_name: asset.first_name,
          id: asset.id,
          last_name: asset.last_name,
          material: asset.material,
          material_slug: asset.material.toLowerCase().replaceAll(' ', '-'),
          media: asset.media,
          number_text: asset.number_text,
          delivery_date: asset.delivery_date,
          state: asset.state,
          test_work_queue_phase_id: asset.test_work_queue_phase_id,
          type_slug: asset.type_slug,
          prescription_test: asset?.prescription_test,

        }
        // Models (no prescription rows).
        if (asset.prescription_rows.length === 0) {
          obj.code = '-';
          obj.application = getLabelsNameByAsset(asset.type_slug);
          obj.bridge = false;
          obj.zone_number = '';
          assets.value.push(obj);
        } else {
          // Prescription rows (projects).
          asset.prescription_rows.forEach((row) => {
            obj.code = asset.code;
            obj.application = row.application;
            obj.bridge = row.bridge;
            obj.zone_number = row.zone_number;
            assets.value.push(obj);
          });
        }
      });
    };

    // Subscribe to channel
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, async (event) => {
        console.log("event received", event);
        // Force asset state.
        event.asset.state = event.state_slug;

        // Get index of asset.
        let assetIndex = assets.value.findIndex((item) => item.id === event.asset.id);

        // Remove asset if found
        if (assetIndex !== -1) {
          byPusher.value = true;
          console.log('REMOVE ASSET', byPusher.value);
          assets.value.splice(assetIndex, 1)
        }
        // Rerender all data
        if (event.asset.state !== "completed") {
          byPusher.value = true;
          console.log('ADD ASSET', byPusher.value);
          formattedData([toRaw(event.asset)])
        }
      });
    };

    // Unsubscribe to channel
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    const readIdsToRemove = async (arrayIds) => {
      console.log('ids to remove: ' + arrayIds);
      // Remove ids completed.
      arrayIds.forEach((id) => {
        let index = assets.value.findIndex((work) => work.id === id);
        if (index !== -1) {
          // Remove element.
          assets.value.splice(index, 1);
        }
      });
    }

    const readNumberText = (value) => {
      numberText.value = value;
    }

    const resetNumberText = (value) => {
      numberText.value = value;
    }

    return {
      assets,
      readIdsToRemove,
      readNumberText,
      numberText,
      resetNumberText,
      resetFinishWorkSelected,
      byPusher,
    }
  }
};
</script>
