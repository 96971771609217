import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { ref } from "vue";
import _head from "lodash/head";

export async function fetchNotes(twqpId) {
  const axios = axiosInstance().primoLab;
  const notes = ref([]);

  await axios
    .get(`/test-work-queue-phases/note`, {
      params: {
        test_work_queue_phase_id: twqpId,
      },
    })
    .then(async (response) => {
      notes.value = _head(response.data.data);
    })
    .catch(async (error) => {
      console.log(error.response);
    });

  return { notes };
}
