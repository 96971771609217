import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function externalPhases(testWorkQueuePhaseId, isMobile, vendorId) {
  const axios = axiosInstance().primoLab;
  let res = [];

  await axios
    .post(`/test-work-queue-phases/external-phase`, {
      id: testWorkQueuePhaseId,
      include_original_phase: isMobile,
      user_id: vendorId,
    })
    .then(async (response) => {
      res = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { res };
}
