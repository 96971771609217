import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function assign(params) {
  const axios = axiosInstance().primoLab;
  let assignedTestWorkQueuePhase = {};

  await axios
    .get("/test-work-queue-phases/assign", {
      params,
    })
    .then(async (response) => {
      assignedTestWorkQueuePhase = response.data.data;
    })
    .catch(async (response) => {
      throw response.response.data.errors;
    });

  return { assignedTestWorkQueuePhase };
}
