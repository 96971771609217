<template>

  <div class="product d-flex align-items-center p-1 w-100" :class="{'bg-home-violet': $props.article.selected}" @click="select($props.article.id)">

    <div class="text-uppercase text-truncate w-25">
      {{ $props.article.code }}
    </div>

    <div class="w-50" data-toggle="tooltip" data-placement="top" :title="$props.article.name">
      {{ $props.article.name }}
    </div>

    <span class="badge bg-secondary">{{ $props.article.quantity }}</span>

    <template v-if="$props.article.selected">
      <div class="d-flex w-25 justify-content-end" @click="addToRequestArea($props.article)">
        <img class="me-2" src="@/assets/icons/shopping-cart-add.svg" width="25" alt="shopping-cart-add.svg">
        <span class="text-uppercase fw-bold text-violet">ordina</span>
      </div>
    </template>
    <template v-else>
      <div class="w-25"></div>
    </template>

  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'select',
    'add'
  ],
  setup(props, {emit}) {
    const select = (id) => {
      emit('select', id);
    }

    const addToRequestArea = (article) => {
      // Remove selected property for keep reactivity.
      let articleToAdd = {
        id: article.id,
        wareHouseArticleId: article.wareHouseArticleId,
        name: article.name,
        code: article.code
      }
      emit('add', articleToAdd);
    }

    return {
      select,
      addToRequestArea,
    }
  }
}
</script>

<style scoped>
.add:hover,
.product:hover{
  cursor: pointer;
}
</style>
