import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchBySlug(params) {
  const axios = axiosInstance().primoLab;
  let feedbacksBySlug = [];

  await axios
    .get("/test-work-queue-states", {
      params,
    })
    .then(async (response) => {
      feedbacksBySlug = response.data.data.map((item) => {
        return {
          value: item.id,
          label: item.description,
        };
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { feedbacksBySlug };
}
