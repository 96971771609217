<script setup>

import { ref, reactive, defineProps } from "vue";
import Projects from '@/components/Consulting/Projects';
import Scene from '@/components/Scene/Modal';
import { fetchTemporaryUrl } from "@/use/repositories/assets/fetchTemporaryUrl";

const props = defineProps({
    apiParams: Object,
});

const showModal = ref(false);

const scene = reactive({
    id: null,
    modal_id: null,
    title: null,
    subtitle: null,
    decline_label: null,
    temporary_url: null,
});

const readAssetId = (value) => {
    preview3D(value);
}

const preview3D = async (assetId) => {
    scene.id = assetId;
    scene.modal_id = assetId;
    scene.decline_label = "chiudi";
    fetchTemporaryUrl(assetId).then(async (response) => {
        scene.temporary_url = response.temporary_url;
        showModal.value = true;
    });
}

const closeScene = async (value) => {
    scene.show = null;
    scene.id = null;
    scene.decline_label = null;
    scene.temporary_url = null;
    showModal.value = value;
}

</script>

<template>
    <div>
        <Projects :call-type="props.apiParams" @preview="readAssetId" />
        <Scene v-if="showModal" :data="scene" @hidden="closeScene" />
    </div>
</template>
