import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function update(stateSlug, warehouseArticles) {
  const axios = axiosInstance().primoLab;

  await axios
    .put("/warehouse-requests", {
      state_slug: stateSlug,
      requests: warehouseArticles
    }).then(async (response) => {
      return response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });
}
