import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByDepartmentSlug(departmentSlug, testWorkQueueStateSlugs, action, page = null, keyword = null) {
  const axios = axiosInstance().primoLab;
  let departmentTestWorkQueuePhases = [];

  let data = {
    action: action,
    department_slug: departmentSlug,
    test_work_queue_state_slugs: testWorkQueueStateSlugs,
  };
  if (page !== null) {
    data.page = page;
  }
  if (keyword !== null) {
    data.keyword = keyword;
  }

  await axios
    .get(`/test-work-queue-phases`, {
      params: data
    })
    .then(async (response) => {
      departmentTestWorkQueuePhases = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { departmentTestWorkQueuePhases };
}
