import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function remove(ids) {
  const axios = axiosInstance().primoLab;
  let message = [];

  await axios
    .delete("/assets", {
      data: {
        asset_ids: ids,
      },
    })
    .then(async (response) => {
      message = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
