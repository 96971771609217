import { default as axiosWithoutLoader } from "@/use/utilities/axios/chat/axios-instance-chat";
import { default as axiosWithLoader } from "@/use/utilities/axios/chat/axios-instance-chat-loader"

export async function show(id, action, loader = false) {
  const axios = loader ? axiosWithLoader : axiosWithoutLoader;

  let message = {};

  await axios
    .get(`/messages/${id}`, { action })
    .then(async (response) => {
      message = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
