import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function updateReason(rejectedId, stateSlug) {
  const axios = axiosInstance().primoLab;
  let prescriptionRejected = [];

  await axios
    .put(`/prescriptions-rejected/${rejectedId}`, {
      action: "update_reason",
      reason_slug: stateSlug,
    })
    .then(async (response) => {
      prescriptionRejected = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionRejected };
}
