import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function fetchPrescriptionsByCustomerId(id) {
  let prescriptions = [];

  await axios
    .get(`/prescriptions`, {
      params: {
        customer_id: id,
        action: "by_customer_with_channel",
      },
    })
    .then(async (response) => {
      prescriptions = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptions };
}
