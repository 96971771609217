import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function assignUrl(fileName) {
  let assignUrl = null;

  await axios
    .post('/messages/media-signed-url', {
      filename: fileName,
    })
    .then(async (response) => {
      assignUrl = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

    return {assignUrl};
}
