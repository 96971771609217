<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="$props.width" :height="$props.height" :fill="$props.color">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path
      d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 14H6v-2h2v2zm0-3H6V9h2v2zm0-3H6V6h2v2zm7 6h-5v-2h5v2zm3-3h-8V9h8v2zm0-3h-8V6h8v2z"/>
  </svg>
</template>

<script>
export default {
  name: "Notes",
  props: {
    color: {
      type: String,
      required: false,
      default: '#0000000',
    },
    width: {
      type: Number,
      required: false,
      default: 24,
    },
    height: {
      type: Number,
      required: false,
      default: 24,
    }
  }
}
</script>
