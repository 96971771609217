import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function show(prescriptionId, params) {
  const axios = axiosInstance().primoLab;
  let clinicDelivery = [];

  await axios
    .get(`/clinic-deliveries/${prescriptionId}`, {
      params,
    })
    .then(async (response) => {
        clinicDelivery = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { clinicDelivery };
}
