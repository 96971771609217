<template>

  <button class="btn btn-outline-violet bg-white rounded-circle p-2" @click="requestSITU()">
    <div class="d-flex align-items-center">
      <img src="@/assets/icons/situ/scan-3d.svg" width="30" alt="scan-3d" />
    </div>
  </button>

  <Confirm v-if="showConfirmModal"
           :modal_id="'modalConfirmSitu'"
           :title="'Stai per inviare la lavorazione in scansione (SITU)'"
           :subtitle="'Sicuri di voler procedere?'"
           :confirm_label="'Si'"
           :decline_label="'No'"
           @hidden="hideModal"
           @confirm="proceedAnyway"
           @decline="hideModal">
  </Confirm>
</template>

<script>
import {useRouter} from "vue-router";
import {ref} from "vue";

import Confirm from "@/components/Modal/Confirm";

import { duplicateRelationship } from '@/use/repositories/testWorkQueuePhase/duplicateRelationship';
import { changeState } from '@/use/repositories/users/changeState';

export default {
  name: "Situ",
  props: {
    testWorkQueuePhaseId: {
      type: Number,
      required: true,
    },
    testWorkQueuePhaseChildId: {
      type: Number,
      required: true,
    }
  },
  components: {
    Confirm,
  },
  setup(props) {
    const router = useRouter();
    const showConfirmModal = ref(false);

    const requestSITU = () => {
      showConfirmModal.value = true;
    }

    const proceedAnyway = () => {
      duplicateRelationship(props.testWorkQueuePhaseId, props.testWorkQueuePhaseChildId).then(() => {
        // Change user state.
        changeState('available').then(() => {
          // Redirect to check in.
          return router.push({ name: "Checkin" });
        });
      });
    }

    const hideModal = (value) => {
      showConfirmModal.value = value;
    }

    return {
      showConfirmModal,
      requestSITU,
      hideModal,
      proceedAnyway,
    }
  }
}
</script>

<style scoped>
.btn-outline-violet:hover {
  color: #A58ABA !important;
  background-color: #fff !important;
  border-color: #A58ABA !important;
}
</style>
