<template>
  <div>
    <p class="mb-1">{{ $props.subtitle }}</p>

    <div class="main flex-nowrap">
      <p v-for="(node, index) in tree" :key="index" class="d-inline-block py-3">
        <a class="text-decoration-none nav-link-violet-lab node text-uppercase" href="#" @click="setChoose(node)"
          :class="{ 'opacity': !node.selected }">
          {{ node.name }} <small>({{ node.departmentName.substring(0, 4) }})</small>
        </a>
        <span class="text-violet mx-1 opacity" v-if="index !== tree.length - 1">
          <img src="@/assets/icons/dash.svg" width="30" alt="dash">
        </span>
      </p>
    </div>

    <h4 class="text-center text-violet mb-3 text-uppercase">{{ choose }}</h4>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import _head from 'lodash/head'
import _find from 'lodash/find'
import _ from "lodash";

export default {
  name: "Timeline",
  props: {
    'items': {
      type: Array,
      required: true,
    },
    'subtitle': {
      type: String,
      required: true,
    },
    'selectedNode': {
      required: false,
    }
  },
  emits: [
    'selected',
  ],
  setup(props, { emit }) {
    const choose = ref("");
    const tree = ref([]);
    const internalPhasesToRemove = ["Iso - Progettazione"];

    onMounted(() => {
      formattedData();
    });

    const formattedData = () => {
      let array = props.items.map((item) => {
        return {
          'id': item.id,
          'internalPhaseTestId': item.internal_phase.id,
          'name': item.internal_phase.name,
          'departmentName': item.internal_phase.department.name,
          'selected': false,
        }
      });

      setElement(array);

      // Assign.
      tree.value = removeInternalPhases(array);
    }

    const setElement = (array) => {
      let selectedElement = props.selectedNode ? _find(array, item => item.id == props.selectedNode.id) : _head(array);
      selectedElement.selected = true;
      choose.value = `${selectedElement.name} (${selectedElement.departmentName.substring(0, 4)})`;

      emit('selected', selectedElement);
    }

    const setChoose = async (node) => {
      let id = node.id;
      choose.value = `${node.name} (${node.departmentName.substring(0, 4)})`;

      tree.value.forEach((node) => node.selected = false);
      tree.value.find((node) => node.id === id).selected = true;

      emit('selected', node);
    }

    const removeInternalPhases = (allInternalPhases) => {
      return _.filter(allInternalPhases, (internalPhase) => !_.includes(internalPhasesToRemove, internalPhase.name));
    };

    return {
      setChoose,
      choose,
      tree,
    }
  }
}
</script>

<style scoped>
.main {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
