<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center align-items-center">
      <Button class="start-0"
              label="Indietro"
              route-name="CheckinCam"
      />
      <Brand width="150" height="150" />
    </div>
    <div class="d-flex mb-2 justify-content-end">
        <button
          class="btn btn-violet text-uppercase me-2"
          type="button"
          @click="orderByPriority()"
        >
          riordina per priorità
        </button>
        <button
          class="btn btn-violet text-uppercase"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#historyPrints"
          aria-controls="historyPrints"
          @click="getHistory()"
        >
          etichette stampate
        </button>
    </div>

    <template v-if="prescriptions.length > 0">
      <div class="card">
        <div class="card-body">
          <template v-for="(prescription, index) in prescriptions" :key="index">
            <Accordion :id="index" :background="'orange'" :collapsed="true">
              <template v-slot:header>
                <span class="fw-bold">{{ prescription.number_text }}</span>
                <span v-if="prescription.prescription_test?.device?.name"
                  class="fw-bold ms-1">
                  / {{ prescription.prescription_test?.device?.name }}
                </span>
                <small class="ms-3">{{ prescription.delivery_date.format('YYYY-MM-DD HH:mm:ss') }}</small>
              </template>
              <template v-slot:action>
                <template v-if="showPrintIcon(prescription.assets) && !isPrinted(prescription.assets)">
                  <Print
                    class="print ms-3 cursor-pointer"
                    @click="print(getDataPrint(prescription))"
                  />
                </template>
                <template v-if="isPrinted(prescription.assets)">
                  <CheckCircle
                    class="ms-2"
                    :width="20"
                  />
                </template>
                <template v-if="isCrossCheckin(prescription.assets)">
                  <span class="inline-block border border-dark rounded ms-2 small py-1 px-2">CROSS-CHECKIN</span>
                </template>
                <template v-if="prescription.prescription_test?.device">
                  <span class="slot-not-found float-end position-relative cp ms-2 mr-2" @click="findDevice(prescription.prescription_test.device.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="'black'" class="bi bi-inbox" viewBox="0 0 16 16">
                      <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
                    </svg>
                    <span class="question-icon position-absolute">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :fill="'black'" class="bi bi-question" viewBox="0 0 16 16">
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                    </span>
                  </span>
                </template>
              </template>
              <template v-slot:body>
                <ul class="list-unstyled">
                  <li class="d-flex align-items-center mb-1" v-for="(asset, index) in prescription.assets" :key="index">
                    <img
                      class="me-3"
                      :src="require(`@/assets/icons/${getIconByState(asset.state)}.svg`)"
                      width="25"
                      :alt="`icon-${asset.state}-state`" />
                    <small class="fst-italic">
                      {{ getFileName(asset.media) }}
                    </small>
                  </li>
                </ul>
              </template>
            </Accordion>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <h1 class="text-center">Nessuna prescrizione presente</h1>
    </template>

    <Offcanvas title="Storico stampe" id="historyPrints" position="end">
      <template v-slot:body>
        <GlobalSearch
          class="mb-4 flex-grow-1"
          :disabled="canvassLoader"
          :debounceTime="800"
          ref="inputSearch"
          placeholder="Cerca per codice..."
          :minCharacters="minSearchHistoryCharacters"
          @toSearch="getTerm"
        />
        <template v-if="canvassLoader">
          <Spinner />
        </template>
        <template v-else>
          <template v-if="digitalWithPrints.length">
            <ul class="list-unstyled">
              <template v-for="(twq, index) in digitalWithPrints" :key="index">
                <li class="d-flex justify-content-between align-items-center">
                  <div class="info">
                    <span class="fw-bold me-2">{{ twq.prescription_test.prescription.number_text }}</span>
                    <small>{{ dateFormatter(twq.delivery_date) }}</small>
                  </div>
                  <div class="asctions d-flex align-items-center">
                    <Print class="print ms-3 cursor-pointer" @click="printLabel(twq.id)"/>
                    <span class="material-icons-round print ms-3 cursor-pointer"
                          @click="showPdf(twq.prescription_test.prescription.id)">
                      picture_as_pdf
                    </span>
                  </div>
                </li>
                <hr>
              </template>
              <Spinner v-if="historyLoader" />
              <section ref="scrollRef"></section>
            </ul>
          </template>
          <template v-else>
            Nessun risultato trovato
          </template>
        </template>
      </template>
    </Offcanvas>
    <Standard
      v-if="showModal"
      :size="'md'"
      :modal_id="'summary_find_device'"
      :title="'Cerca la casella'"
      :subtitle="'Seleziona il colore e illumina il device selezionato'"
      :decline_label="null"
      :lightLayout="true"
      @hidden="closeModal"
      >
      <DeviceCommand :data="deviceId"></DeviceCommand>
    </Standard>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted, reactive,
  ref,
  toRaw,
  watch
} from "vue";
import { useI18n } from "vue-i18n";
import _ from 'lodash';

import { dateFormatter } from "@/use/utilities/time/dateFormatter"
import { fetchAll as fetchAllAssets } from "@/use/repositories/assets/fetchAll";
import { getPdf as showPrescription } from "@/use/repositories/prescriptions/getPdf";
import { hideModal } from "@/use/modal/hide";
import { onIntersect } from "@/composables/onIntersect";

import Brand from "@/components/Brand.vue";
import Accordion from "@/components/general/Accordion.vue";
import CheckCircle from "@/components/Icons/CheckCircle.vue";
import Button from "@/components/general/Button.vue";
import Offcanvas  from "@/components/general/Offcanvas.vue";
import Spinner from "@/components/general/Spinner";
import Print from "@/components/Icons/Print.vue";
import GlobalSearch from "@/components/GlobalSearch.vue";
import DeviceCommand from "@/components/devices/DeviceCommand.vue";
import Standard from "@/components/Modal/Standard";
import { print as printAnalogLabel} from "@/use/repositories/devices/print";
import { index as fetchAllDigitalWithPrints } from "@/use/repositories/testWorkQueue/index";

import { success } from "@/use/toast/success";
import { error } from "@/use/toast/error";
import { info } from '@/use/toast/info';
import moment from "moment";

export default {
  name: "Summary",
  components: {
    Print,
    Accordion,
    CheckCircle,
    Button,
    Brand,
    Offcanvas,
    Spinner,
    GlobalSearch,
    DeviceCommand,
    Standard
},
  setup() {
    const { states, devicesType, departments } = inject("constants");
    const i18n = useI18n();
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const accordion = ref(null);
    const isDigital = (asset) => {
      return asset.is_digital;
    }
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, {$pusher: pusher});
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, (event) => {
        console.log("event received", event);
        if (isDigital(event.asset)) {
          sync(event);
        }
      });
    };
    const unsubscribe = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {$pusher: pusher});
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };
    const prescriptions = ref([]);
    const digitalWithPrints = ref([]);
    const canvassLoader = ref(false);
    const historyLoader = ref(false);
    const totalPages = ref(1);
    const page = ref(1);
    const existsMoreHistory = computed(() => {
      return (page.value < totalPages.value && totalPages.value > 1);
    });
    let digitalAssets = [];
    const minSearchHistoryCharacters = 3;
    const inputSearch = ref(null);
    const apiParams = reactive({
      action: 'digital_with_prints',
      page: computed(() => {
        return page.value;
      })
    });

    // Observer data.
    const observer = ref({});
    const scrollRef = ref({});

    onMounted(() => {
      subscribe("private-live", "asset_change-state");
      fetchAllAssets({
        states: [
          states.PENDING,
          states.IN_THE_WORKS
        ],
        action: "by_states_digital",
      }).then(async (response) => {
        digitalAssets = getDigital(response.assets);
        if (! digitalAssets.length) return;
        prescriptions.value = groupByPrescription(digitalAssets);
        prescriptions.value = _.orderBy(toRaw(prescriptions.value), 'delivery_date', 'asc');
      });
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribe("private-live");
      observer.value.disconnect();
    });

    watch(() => scrollRef.value, (el) => {
        observer.value = onIntersect(el, onEnter, false, {
          threshold: 0.1,
        });
    });

    const resetDataHistoryPaginator = () => {
      page.value = 1;
      totalPages.value = 1;
    }

    const fetchMoreData = () => {
      getDigitalWithPrints(apiParams, pushNewDigitalWithPrints);
    }

    const pushNewDigitalWithPrints = (response) => {
      response.testWorkQueue.data.forEach((twq) => {
        digitalWithPrints.value.push(twq);
      });
    }

    const defaultActionOnHistoryData = (response) => {
      digitalWithPrints.value = response.testWorkQueue.data;
      totalPages.value = response.testWorkQueue.meta.last_page;
    }

    const onEnter = () => {
      if (existsMoreHistory.value) {
        historyLoader.value = true;
        page.value++;
        fetchMoreData();
        historyLoader.value = false;
      }
    };

    const getHistory = () => {
      resetDataHistoryPaginator();
      getDigitalWithPrints(apiParams, defaultActionOnHistoryData);
    }

    const getDigitalWithPrints = (params, processingResponse) => {
      canvassLoader.value = true;
      fetchAllDigitalWithPrints(params, false)
        .then((response) => {
          processingResponse(response);
          setTimeout(() => {
            inputSearch.value.globalSearch.focus();
          }, 500);
      }).finally(() => {
        canvassLoader.value = false;
      });
    }

    const getDigital = (assets) => {
      return assets.filter((asset) => {return asset.is_digital});
    }

    const groupByPrescription = (digitalAssets) => {
      return _.chain(digitalAssets)
        .groupBy('prescription_id')
        .map((value, key) => ({
          delivery_date: moment(_.head(value).delivery_date),
          id: parseInt(key),
          number_text: _.head(value).number_text,
          prescription_test: _.head(value).prescription_test,
          assets: value
        })).value();
    }

    const getIconByState = (slug) => {
      switch (slug) {
        case states.PENDING:
          return 'consulting/pending-violet';
        case states.IN_THE_WORKS:
          return 'gear-violet';
        case states.COMPLETED:
          return 'like-green';
      }
    }

    const getFileName = (media) => {
      return _.find(media).file_name;
    };

    const sync = (event) => {
      let assetIndex = -1;
      let prescriptionIndex = prescriptions.value.findIndex((prescription) => prescription.id === event.asset.prescription_id);
      if (prescriptionIndex !== -1) {
        assetIndex = prescriptions.value[prescriptionIndex].assets.findIndex((asset) => asset.id === event.asset.id);
      }

      switch (event.state_slug) {
        case states.IN_THE_WORKS:
        case states.COMPLETED:
          changeAssetState(event, prescriptionIndex, assetIndex);
          if (prescriptions.value[prescriptionIndex].assets.every((asset) => asset.state === states.COMPLETED)) {
            prescriptions.value.splice(prescriptionIndex, 1);
            info(`Le lavorazione della prescrizione ${event.asset.number_text} sono state completate`);
          }
          break;
        case states.PENDING:
          // Sync status.
          event.asset.state = event.state_slug;
          if (prescriptionIndex === -1) {
            let newPrescription = {
              delivery_date: moment(event.asset.delivery_date),
              id: parseInt(event.asset.prescription_id),
              number_text: event.asset.number_text,
              assets: []
            }
            newPrescription.assets.push(event.asset);
            prescriptions.value.push(newPrescription);
            prescriptions.value = _.orderBy(toRaw(prescriptions.value), 'delivery_date', 'asc');
            info(`Le prescrizione ${event.asset.number_text} è stata aggiunta all'elenco`);
          } else {
            prescriptions.value[prescriptionIndex].assets.push(event.asset);
          }
          break;
      }
    }

    const changeAssetState = (event, prescriptionIndex, assetIndex) => {
      prescriptions.value[prescriptionIndex].assets[assetIndex].state = event.state_slug;
    }

    const downloadPDF = (pdf, fileName) => {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    const showPdf = (prescriptionId) => {
      showPrescription(prescriptionId, 'download_pdf').then((response) => {
        downloadPDF(response.prescription, prescriptionId + '.pdf');
        }).catch((errors) => {
          error(i18n.t('An error has occurred. Contact the technical service!'));
          throw new Error(errors);
      });
    }

    const collapseAll = () => {
      let buttons = document.querySelectorAll('.accordion-button');
      buttons.forEach(button => {
        button.classList.add("collapsed");
        button.setAttribute("aria-expanded", "false")
      });
      let accordions = document.querySelectorAll(".accordion-collapse.collapse");
      accordions.forEach(accordion => {
        accordion.classList.remove("show");
      });
    }

    const orderByPriority = () => {
      collapseAll();
      prescriptions.value = _.orderBy(toRaw(prescriptions.value), 'delivery_date', 'asc');
      success(i18n.t('Reordering successful!'));
    }

    const print = (array) => {
      let [twqId, prescriptionId] = array;
      showPdf(prescriptionId);
      printLabel(twqId, prescriptionId);
    }

    const printLabel = async (twqId, prescriptionId) => {
       // Print Label
       fetchAllDigitalWithPrints({
        action: 'with_base_64_labels',
        ids: [
          twqId,
        ]
      }, true)
        .then((response) => printAnalogLabel({
          device_type_slug: devicesType.ANALOG_LABEL,
          department_slug: departments.CAM,
          data: _.head(response.testWorkQueue).raw_data,
        }))
        .then((response) => {
          success(i18n.t('Barcodes print successfully!'));
          pushPrint(response, prescriptionId);
        })
        .catch((error) => {
          error(i18n.t('An error has occurred. Contact the technical service!'));
        });
    }

    const getDataPrint = (prescription) => {
      return [_.head(prescription.assets).test_work_queue_id, _.head(prescription.assets).prescription_id];
    }

    const showPrintIcon = (assets) => {
      return assets.some((asset) => asset.state === states.IN_THE_WORKS);
    }

    const isPrinted = (assets) => {
      return _.head(assets).test_work_queue_prints.length > 0;
    }

    const isCrossCheckin = (assets) => {
      return _.head(assets)?.cross_checkin;
    };

    const pushPrint = (response, prescriptionId) => {
      let prescription = _.find(prescriptions.value, function(prescription) { return prescription.id === prescriptionId});
      _.head(prescription.assets).test_work_queue_prints.push(_.head(response));
    }

    const getTerm = (term) => {
      if (term === "") {
        delete apiParams.keyword;
      } else {
        apiParams.keyword = term;
      }
      resetDataHistoryPaginator();
      getDigitalWithPrints(apiParams, defaultActionOnHistoryData);
    }

    const findDevice = (device) => {
      deviceId.value = device;
      showModal.value = true;
    }

    const showModal = ref(false);

    const closeModal = () => {
      hideModal('summary_find_device');
      deviceId.value = null;
      showModal.value = false;
    }

    const deviceId = ref();

    return {
      showModal,
      closeModal,
      deviceId,
      ///////////
      prescriptions,
      print,
      getIconByState,
      getFileName,
      accordion,
      getDataPrint,
      showPrintIcon,
      digitalWithPrints,
      dateFormatter,
      getHistory,
      canvassLoader,
      printLabel,
      showPdf,
      observer,
      scrollRef,
      historyLoader,
      getTerm,
      minSearchHistoryCharacters,
      inputSearch,
      orderByPriority,
      isPrinted,
      isCrossCheckin,
      findDevice,
    }
  }
}
</script>

<style scoped>
  .print:hover {
    scale: 1.1;
    transition: .2s;
  }
</style>
