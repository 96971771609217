<template>
  <div class="main flex-nowrap">
    <p v-for="(node, index) in tree" :key="index" class="d-inline-block py-3">
      <span class="text-violet-dark text-uppercase">{{ node.name }}</span>
      <span class="text-violet mx-1 opacity" v-if="index !== tree.length-1">
        <img src="@/assets/icons/dash.svg" width="30" alt="dash">
     </span>
    </p>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import _head from "lodash/head";

export default {
  name: "ForkPreview",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const tree = ref([]);

    onMounted(() => {
      dataTransform();
    });

    const dataTransform = () => {
      let array = [];
      props.items.forEach((item) => {
        recursiveMapData(item, array);
      });
      tree.value = array;
    }

    const recursiveMapData = (item, array) => {
      if (item !== null) {
        if (item.children.length) {
          array.push({
            'id': item.id,
            'internalPhaseTestId': item.internal_phase.id,
            'name': item.internal_phase.name,
            'departmentName': item.internal_phase.department.name,
            'selected': false,
          });
          recursiveMapData(_head(item.children), array);
        } else {
          array.push({
            'id': item.id,
            'internalPhaseTestId': item.internal_phase.id,
            'name': item.internal_phase.name,
            'departmentName': item.internal_phase.department.name,
            'selected': false,
          });
        }
      }
    }

    return {
      tree,
    }
  }
}
</script>

<style scoped>
.main {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
