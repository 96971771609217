<script>
import { defineComponent, reactive, ref, computed, createApp, h, onMounted } from "vue";
import TableLite from "vue3-table-lite";
import { index } from "@/use/repositories/devices/index";
import { formatDate } from "@/use/utilities/time/formatDate.js";

import Brand from "@/components/Brand";
import ButtonBack from "@/components/general/ButtonBack";

const searchTerm = ref("");
const searchTerm2 = ref("");

export default {
  name: "DigitalLabels",
  components: { TableLite,  Brand, ButtonBack },
  setup() {
    onMounted(() => {
      index('by_digital_labels').then(response => {
        data.value = response.data.map((item) => {
          return {
            code: item?.name,
            area: item?.extra_data?.area ? item?.extra_data?.area : '-',
            number_text: item?.prescription_test?.prescription?.number_text ?? '-',
            battery_life: item?.extra_data?.battery ? item?.extra_data?.battery : 'sconosciuto',
            mac_address: item?.extra_data?.mac ?? 'sconosciuto',
            customer: `${item?.prescription_test?.prescription?.customer?.first_name ?? '-'} ${item?.prescription_test?.prescription?.customer?.last_name ?? ''}`,
            test: item?.prescription_test?.test?.name ?? '-',
            delivery_date: item?.prescription_test?.test_work_queue?.delivery_date ? formatDate(item?.prescription_test?.test_work_queue?.delivery_date) : '-',
          };
        });
      })
    })
    const data = ref([]);

    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Codice",
          field: "code",
          width: "3%",
        },
        {
          label: "Posizione",
          field: "area",
          width: "10%",
        },
        {
          label: "Prescrizione",
          field: "number_text",
          width: "15%",
        },
        {
          label: "Stato batteria",
          field: "battery_life",
          width: "10%",
        },
        {
          label: "Mac Address",
          field: "mac_address",
          width: "10%",
        },
        {
          label: "Paziente",
          field: "customer",
          width: "15%",
        },
        {
          label: "Prova",
          field: "test",
          width: "15%",
        },
        {
          label: "Data",
          field: "delivery_date",
          width: "15%",
        },
      ],
      rows: computed(() => {
        return data.value.filter(
          (x) =>
            x.area.toLowerCase().includes(searchTerm.value.toLowerCase()) &&
            x.number_text.toLowerCase().includes(searchTerm2.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
    });

    const initTable = ({ el: tableComponent }) => {
      let headerTr = tableComponent.getElementsByClassName("vtl-thead-tr");
      if (! headerTr[0]) {
        return;
      }
      let cloneTr = headerTr[0].cloneNode(true);
      let childTh = cloneTr.getElementsByClassName("vtl-thead-th");
      for (let i = 0; i < childTh.length; i++) {
        childTh[i].innerHTML = "";
      }

      createApp(
        defineComponent({
          setup() {
            return () =>
              h("input", {
                value: searchTerm.value,
                onInput: (e) => {
                  searchTerm.value = e.target.value;
                },
              });
          },
        })
      ).mount(childTh[1]);
      createApp(
        defineComponent({
          setup() {
            return () =>
              h("input", {
                value: searchTerm2.value,
                onInput: (e) => {
                  searchTerm2.value = e.target.value;
                },
              });
          },
        })
      ).mount(childTh[2]);

      headerTr[0].after(cloneTr)
    };

    return {
      searchTerm,
      searchTerm2,
      table,
      initTable,
    };
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex flex-column justify-content-start align-items-start">
        <brand :width="150" />
        <ButtonBack :label="'indietro'" />
      </div>
     <div class="container mt-5">
       <h4 class="text-center m-2">Di seguito la lista di tutte le etichette elettroniche registrate</h4>
      <table-lite :is-static-mode="true" :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount"
        @VnodeMounted="initTable">
      </table-lite>
     </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #f3e9ff;
  border-color: #f3e9ff;
}

:deep(.vtl-table td),
:deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  vertical-align: middle;
}

:deep(.vtl-paging-info),
:deep(.vtl-paging-pagination-div),
:deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7e6492;
}

:deep(.vtl-paging-count-label),
:deep(.vtl-paging-page-label) {
  color: #7e6492;
}

:deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>
