<template>
  <div class="history py-3">
    <div class="history__body overflow-auto" :style="{ height: height + 'px' }">
      <template v-for="(clinic, index) in clinics" :key="index">
        <div class="clinic w-100">
          <CollapseRow
            :idElement="`clinic_${clinic.id}`"
            :id="clinic.id"
            :index="index"
            :label="clinic.name"
            :marginLeftClass="'ms-3'"
            @selected="getCustomers"
          />
        </div>
        <div class="customers collapse" :id="`collapse_clinic_${clinic.id}`">
          <template v-for="(customer, customerIndex) in clinic.customers" :key="customerIndex">
            <div class="customer w-100">
              <CollapseRow
                :idElement="`customer_${customer.id}`"
                :id="customer.id"
                :index="customerIndex"
                :label="`${customer.first_name} ${customer.last_name}`"
                :marginLeftClass="'ms-5'"
                @selected="getPrescriptions"
              />
            </div>
            <div
              class="prescriptions collapse"
              role="button"
              :id="`collapse_customer_${customer.id}`"
            >
              <template v-for="(prescription, index) in clinic.customers[customerIndex].prescriptions" :key="index">
                <div class="chat">
                  {{ prescription.number_text }}
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "vue";

import CollapseRow from "@/components/chat/CollapseRow";

import { fetchCustomersByClinicId } from "@/use/repositories/chat/fetchCustomersByClinicId";
import { fetchPrescriptionsByCustomerId } from "@/use/repositories/chat/fetchPrescriptionsByCustomerId";

export default {
  name: "History",
  components: {
    CollapseRow,
  },
  props: {
    clinicsProp: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 250,
    },
  },
  setup(props) {
    const customers = ref([]);
    const prescriptions = ref([]);
    const clinics = toRef(props, "clinicsProp");
    const selectedClinic = ref(null);

    const getCustomers = ({ id, index }) => {
      fetchCustomersByClinicId(id).then((response) => {
        clinics.value[index].customers = response.customers;
        selectedClinic.value = index;
      });
    };
    const getPrescriptions = ({ id, index }) => {
      fetchPrescriptionsByCustomerId(id).then((response) => {
        clinics.value[selectedClinic.value].customers[index].prescriptions = response.prescriptions;
      });
    };

    return {
      clinics,
      getCustomers,
      customers,
      getPrescriptions,
      prescriptions,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/abstracts/_variables.scss";

.clinic,
.customer,
.chat {
  &:hover {
    background-color: $home_violet;
  }
}

.chat {
  padding-left: 20%;
}
</style>
