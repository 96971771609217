import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { fetchByDepartmentSlug } from "@/use/repositories/testWorkQueuePhase/fetchByDepartmentSlug";
import { isTechnicianIso } from "@/use/utilities/isTechnicianIso";

export default function () {
    const store = useStore();
    const { states } = inject("constants");
    const { departments } = inject("constants");
    const hasManagerRole = ref(store.state.user.roles.includes('manager'));
    const currentDepartmentSlug = store.state.mainRole.team.name;
    const canShowExternalWorks = ref(currentDepartmentSlug === departments.MOBILE);
    const operatorAllowedSlugs = ['SUM', 'SUBTRACTION'];
    const isIso = isTechnicianIso();

    // Spinners.
    const spinnerWarningInfo = ref(true);
    const spinnerInTheWorks = ref(true);
    const spinnerAssign = ref(true);
    const spinnerSendToExternal = ref(true);
    const spinnerFailed = ref(true);

    // Counters.
    const warningInfoDataCounter = ref(0);
    const inTheWorksDataCounter = ref(0);
    const assignDataCounter = ref(0);
    const sendToExternalDataCounter = ref(0);
    const failedDataCounter = ref(0);

    const dataExists = computed(() => {
      let tot = inTheWorksDataCounter.value +
                warningInfoDataCounter.value +
                assignDataCounter.value +
                failedDataCounter.value +
                (canShowExternalWorks.value ? sendToExternalDataCounter.value : 0);

      return tot > 0;
    });

    onMounted(() => {
      let statesSlug = [];

      // Manager ISO.
      if (hasManagerRole.value && isIso) {
        statesSlug.push(states.ON_HOLD);
        statesSlug.push(states.ISO_FAILED);

        if (departments.ISO_PROGETTAZIONE) {
          statesSlug.push(states.ON_HOLD);
          statesSlug.push(states.ASSIGNED);
        }
      }

      // Manager.
      if (hasManagerRole.value && isIso === false) {
        statesSlug.push(states.ON_HOLD);
        statesSlug.push(states.IN_THE_WORKS);
        statesSlug.push(states.ASSIGNED);
        statesSlug.push(states.MANAGER_FAILED);
      }

      if (canShowExternalWorks.value) {
        statesSlug.push(states.SENT_TO_EXTERNAL);
      }

      console.log(`HAS MANAGER ROLE: ${hasManagerRole.value}`);
      console.log(`IS ISO: ${isIso}`);
      console.log(statesSlug);

      statesSlug.forEach((slug) => {
        // Fetch manager counters.
        fetchByDepartmentSlug(
          currentDepartmentSlug,
          [slug],
          'by_department_and_states'
        ).then((response) => {
          let total = response.departmentTestWorkQueuePhases.meta.total;
          setTotManagerCountFromSlug(slug, total);
        });
      });

    });

    const setTotManagerCountFromSlug = (slug, total) => {
      switch (slug) {
        case states.IN_THE_WORKS:
          inTheWorksDataCounter.value = total;
          spinnerInTheWorks.value = false;
          break;
        case states.SENT_TO_EXTERNAL:
          sendToExternalDataCounter.value = total;
          spinnerSendToExternal.value = false;
          break;
        case states.ASSIGNED:
          assignDataCounter.value = total;
          spinnerAssign.value = false;
          break;
        case states.ISO_FAILED:
        case states.MANAGER_FAILED:
          failedDataCounter.value = total;
          spinnerFailed.value = false;
          break;
        case states.ON_HOLD:
          warningInfoDataCounter.value = total;
          spinnerWarningInfo.value = false;
          break;
      }
    }

    const syncCounter = (dataCounterType, operatorSlug) => {
      switch (operatorSlug) {
        case 'SUM':
          return dataCounterType.value++;
        case 'SUBTRACTION':
          return dataCounterType.value--;
      }
    }

    const manageManagerCounters = (stateSlug, operatorSlug) => {
      if (!operatorAllowedSlugs.includes(operatorSlug)) return;

      switch (stateSlug) {
        case states.IN_THE_WORKS:
          syncCounter(inTheWorksDataCounter, operatorSlug);
          break;
        case states.SENT_TO_EXTERNAL:
          syncCounter(sendToExternalDataCounter, operatorSlug);
          break;
        case states.ASSIGNED:
          syncCounter(assignDataCounter, operatorSlug);
          break;
        case states.ISO_FAILED:
        case states.MANAGER_FAILED:
          syncCounter(failedDataCounter, operatorSlug);
          break;
        case states.ON_HOLD:
          syncCounter(warningInfoDataCounter, operatorSlug);
          break;
      }
    }


    return {
      spinnerWarningInfo,
      spinnerInTheWorks,
      spinnerAssign,
      spinnerSendToExternal,
      spinnerFailed,

      warningInfoDataCounter,
      inTheWorksDataCounter,
      assignDataCounter,
      sendToExternalDataCounter,
      failedDataCounter,

      dataExists,
      manageManagerCounters,
      canShowExternalWorks,
      hasManagerRole,
      isIso
    }
}
