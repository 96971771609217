<template>
  <tr @click="selectedTestWorkQueue()" class="twqp-row" :class="{ highlight: $props.selected }">
    <td class="d-flex align-items-center justify-content-between py-3">
      <template v-if="$props.selected">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          class="bi bi-check2-circle"
          viewBox="0 0 16 16"
        >
          <path
            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
          />
          <path
            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
          />
        </svg>
      </template>
      <template v-else>
      </template>
      <span>
        {{ $props.test_work_queue_phase.test_work_queue.prescription_test.prescription.number_text }}
      </span>
      <span v-if="$props.test_work_queue_phase.test_work_queue.prescription_test.device" class="fw-bold">
        {{ $props.test_work_queue_phase.test_work_queue.prescription_test.device?.name }}
      </span>
    </td>
    <td class="p-3 text-center">
      {{
        customerFullName(
          $props.test_work_queue_phase.test_work_queue.prescription_test.prescription.customer
        )
      }}
    </td>
    <td class="p-3 text-center">
      {{ formatDeliveryDate($props.test_work_queue_phase.test_work_queue) }}
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "TestWorkQueuePhase",
  props: {
    test_work_queue_phase: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    missingFlow: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const selectedTestWorkQueue = () => {
      if (! props.missingFlow) return;
      emit("selected", props.test_work_queue_phase);
    };

    const customerFullName = (customer) => {
      return `${customer.first_name}  ${customer.last_name}`;
    };

    const formatDeliveryDate = (testWorkQueue) => {
      return moment(testWorkQueue.delivery_date).format("DD/MM/YYYY HH:mm:ss");
    };

    return {
      selectedTestWorkQueue,
      customerFullName,
      formatDeliveryDate,
    };
  },
};
</script>

<style scoped>
.twqp-row:hover {
  cursor: pointer;
}
.highlight {
  cursor: pointer;
  background-color: #e0d6e7;
}
</style>
