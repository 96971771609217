<template>
  <div>
    <template v-if="userExists">
        <ManagerNav :text="fetchInitials()" />
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import ManagerNav from "@/components/Manager/ManagerNav.vue";

export default {
  name: "UserPanel",
  components: {
    ManagerNav
  },
  setup() {
    const store = useStore();
    const userExists = computed(() => {
      return (store.state.user !== null && store.state.user.departments);
    });

    const user = computed(() => {
      return store.state.user;
    });

    const fetchInitials = () => {
      return `${user.value.first_name.charAt(0).toUpperCase()}${user.value.last_name.charAt(0).toUpperCase() }`;
    }

    return {
      fetchInitials,
      userExists,
      store,
    }
  }
}
</script>

<style scoped>
 .user-panel {
   z-index: 9999;
 }
</style>
