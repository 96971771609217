<template>

  <div class="d-none">
    {{ $props.data.length }}
    {{ wareHouseHistory.length }}
  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th nowrap scope="col">Stato</th>
          <th nowrap scope="col">Q.tà</th>
          <th nowrap scope="col">Codice</th>
          <th nowrap scope="col">Prodotto</th>
          <th nowrap scope="col">Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in wareHouseHistory" :key="index">
          <td nowrap><img :src="require(`@/assets/icons/${getIconByState(item.state.slug)}.svg`)" width="25" alt="state"/></td>
          <td nowrap v-html="getQuantity(item)"></td>
          <td nowrap>{{ item.article.code }}</td>
          <td nowrap>{{ item.article.name }}</td>
          <td nowrap>{{ moment(item.created_at).format("DD/MM/YYYY HH:mm:ss") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {watch, computed, reactive} from "vue";
import moment from "moment";

export default {
  name: "SummaryOrdersSent",
  props: {
    warehouseRequestUpdate: {
      type: Object,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const wareHouseHistory = computed(() => {
      return reactive(props.data);
    });

    // Watch on warehouseRequestUpdate.
    watch(() => props.warehouseRequestUpdate, () => {
        let index = wareHouseHistory.value.findIndex((item) => item.id === props.warehouseRequestUpdate.id);

        // Map data.
        const data = {
          id: props.warehouseRequestUpdate.id,
          requested_quantity: props.warehouseRequestUpdate.requested_quantity,
          approved_quantity: props.warehouseRequestUpdate.approved_quantity,
          created_at: props.warehouseRequestUpdate.created_at,
          article: props.warehouseRequestUpdate.article,
          state: props.warehouseRequestUpdate.state,
        };

        // Item found.
        if (index !== -1) {
          wareHouseHistory.value[index] = data;
        } else {
          wareHouseHistory.value.push(data);
        }

      }, { deep: true }
    );

    const getQuantity = (item) => {
      if (item.state.slug === 'approved' && item.approved_quantity !== null && (item.approved_quantity !== item.requested_quantity)) {
        return `<span style="opacity: 0.3;">${item.requested_quantity}</span> ${item.approved_quantity}`;
      }

      return item.requested_quantity;
    }

    const getIconByState = (slug) => {
      switch (slug) {
        case "pending":
          return "clock";
        case "approved":
          return "check-circle-green";
        case "rejected":
          return "close-circle-red";
      }
    }

    return {
      wareHouseHistory,
      moment,
      getQuantity,
      getIconByState,
    };
  },
};
</script>

<style scoped>
tbody {
  border-style: hidden !important;
}
tr td,
tr tbody th {
  border: 0;
}
tr th,
tr td {
  text-align: center;
}
tr th {
  font-weight: normal;
}
.table-responsive {
  max-height: 200px;
}
.table-responsive thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #E9E9E9;
}
</style>
