<template>
  <tr>

    <td class="text-center">
      <img :src="require(`@/assets/icons/${getIconByState(item.state.slug)}.svg`)" width="25" :alt="`icon-${item.state.slug}-state`" />
    </td>

    <td>{{ item.list_row.name }}</td>
    <td class="text-center">{{ moment(item.created_at).format("DD/MM/YYYY") }}</td>
    <td class="text-center">{{ euroFormatter(item.list_row.net_price) }}</td>

    <td class="text-center">
      <div class="d-flex align-items-center" :class="[{
            'justify-content-between': toShow(item.state.name),
            'justify-content-center': ! toShow(item.state.name),
          }]">

        <button v-if="toShow(item.state.name)" class="circle btn d-flex bg-violet text-white fs-3 align-items-center justify-content-center"
                @click="decrease()"
                :disabled="item.quantity === 1">
          <span class="operator">-</span>
        </button>

        <div>{{ item.quantity }}</div>

        <button v-if="toShow(item.state.name)" class="circle btn d-flex bg-violet text-white fs-3 align-items-center justify-content-center"
                @click="increment()">
          <span class="operator">+</span>
        </button>

      </div>
    </td>

    <td class="text-center">{{ euroFormatter(item.totalAmount) }}</td>

    <td class="text-center" v-if="toShow(item.state.name) && item.state.slug !== 'automated'">
      <div class="d-flex align-items-center justify-content-end">
        <img class="trash ms-2" src="@/assets/icons/close-circle-red.svg" width="25" alt="close-circle-red" @click="remove()"/>
      </div>
    </td>

    <td v-else></td>

  </tr>
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import { euroFormatter } from "@/use/utilities/euroFormatter";
import { remove as removeByReceiptId }  from '@/use/repositories/receipt/remove';

export default {
  name: "PaymentItem",
  props: {
    listRow: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updateQuantity',
  ],
  setup(props, { emit }) {
    const item = ref(props.listRow);

    const toShow = (state) => {
      return ['pending', 'automated'].includes(state.toLowerCase());
    };

    const updateItem = (operation, listRow) => {
      emit("updateQuantity", {
        index: props.index,
        operation: operation,
        listRow: listRow,
      });
    };

    const increment = () => {
      updateItem("increment", props.listRow);
    };

    const decrease = () => {
      if (props.listRow.quantity === 1) {
        return;
      }
      updateItem("decrease", props.listRow);
    };

    const remove = () => {
      removeByReceiptId(props.listRow.id).then(() => {
        emit('remove', props.listRow.id);
      });
    };

    const getIconByState = (slug) => {
      switch (slug) {
        case "approved":
          return "approval";

        case "rejected":
          return "dislike-red";

        case "waiting-for-approval":
          return "waiting-for-approval";

        case "pending":
          return "pending";
        
        case "automated":
          return "automated";
      }
    }

    return {
      decrease,
      toShow,
      increment,
      item,
      euroFormatter,
      moment,
      remove,
      getIconByState,
    };
  },
};
</script>

<style scoped>
tr th,
tr td {
  border-bottom: 1px solid lightgray;
  padding: 1em;
  vertical-align: middle;
  text-transform: uppercase;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.operator {
  font-size: 20px;
}
.trash:hover,
.circle:hover,
.update:hover {
  cursor: pointer;
}
</style>
