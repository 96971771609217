import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function pending(params, loading = true) {
  const data = { ...params, action: "with_pendings" };

  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let pendingTestWorkQueuePhases = [];

  await axios
    .get("/test-work-queue-phases", {
      params: data
    })
    .then(async (response) => {
      pendingTestWorkQueuePhases = response.data;
    })
    .catch(async (response) => {
      throw response.response.data.errors;
    });

  return { pendingTestWorkQueuePhases };
}
