import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAllTypes(testId) {
  const axios = axiosInstance().primoLab;
  let branches = [];

  await axios
    .get(`/internal-phase-test-types`, {
      params: {
        action: "change_branch",
        test_id: testId
      }
    })
    .then(async (response) => {
      branches = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { branches };
}

