export function canPlasterStation({ next, store }) {
  if (
    typeof store.state.user.state !== "undefined" &&
    store.state.user.roles.includes("postazione-colatura-gesso")
    ||
    store.state.user.roles.includes("postazione-finitura-gesso")
  ) {
    return next();
  } else {
    return next("/login");
  }
}
