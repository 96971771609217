import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function store(prescriptionTestId, typeSlug) {
  const axios = axiosInstance().primoLab;
  let message = null;

  await axios
    .post("/cross-checkins/", {
      prescription_test_id: prescriptionTestId,
      type_slug: typeSlug
    })
    .then(async (response) => {
      message = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
