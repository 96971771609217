import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function store(prescriptionTestId, action) {
  const axios = axiosInstance().primoLab;
  let testWorkQueue = [];

  await axios
    .post(`/test-work-queues`, {
      prescription_test_id: prescriptionTestId,
      action: action,
    })
    .then(async (response) => {
      testWorkQueue = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueue };
}
