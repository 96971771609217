import { default as axiosWithoutLoader } from "@/use/utilities/axios/chat/axios-instance-chat";
import { default as axiosWithLoader } from "@/use/utilities/axios/chat/axios-instance-chat-loader"

export async function fetchAll(params, loader = false) {
  const axios = loader ? axiosWithLoader : axiosWithoutLoader;

  let data = [];

  await axios
    .get("/messages", {
      params,
    })
    .then(async (response) => {
      data = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
