<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { warning } from "@/use/toast/warning";

export default {
  name: 'LeafletMap',
  props: {
    positions: {
      type: Array,
      required: true,
    }
  },
  mounted() {
    const planimetry = L.map(this.$refs.map, {
      crs: L.CRS.Simple,
      zoom: 100,
      zoomControl: true,
      scrollWheelZoom: false,
      touchZoom: true,
      zoomAnimation: false,
      doubleClickZoom: false,
      boxZoom: false,
      dragging: true,
      trackResize: true,
    });
    planimetry.attributionControl.setPrefix('');
    // Used to load and display tile layers on the map
    L.tileLayer("", {
      attribution:
        'From Innovation Factory with &#9829;',
    }).addTo(planimetry);
    const planimetryImg = require('@/assets/images/planimetry.jpg'); // Import the image
    const imageBounds = [[0, 0], [45.27, 48.72]];
    const planimetryOverlay = L.imageOverlay(planimetryImg, imageBounds);

    planimetryOverlay.addTo(planimetry);
    setTimeout(() => {
      planimetry.fitBounds(imageBounds);
    }, 2000);

    const LeafIcon = L.Icon.extend({
      options: {
        iconSize: [25, 25],
        popupAnchor: [-25, -25]
      }
    });

    const iconImg = require('@/assets/icons/bullet.svg');
    const redBullet = new LeafIcon({ iconUrl: iconImg });
    const xOffset = 4.65;
    const yOffset = 5;
    this.positions.forEach(element => {
      if (element.x && element.y)
        L.marker([(element.y + yOffset), (element.x + xOffset)], { icon: redBullet })
          .bindPopup(`<strong><center><u>DETTAGLI</u></center><strong>AREA:</strong> ${element.area}<br><strong>${element.prescription}</strong>`)
          .addTo(planimetry);
      else
        warning(`Prescrizione n ${element.prescription} non trovata!`);
    });
  },
};
</script>

<template>
  <div class="d-flex flex-column justify-content-between">
    <div ref="map" class="leaflet-map"></div>
  </div>
</template>

<style scoped>
.leaflet-map {
  height: 90vh;
}

.leaflet-container {
  width: 100%;
}
</style>
