import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function index(params) {
  const axios = axiosInstance().primoLab;
  let dates = [];

  await axios
    .get("/dates", {
      params,
    })
    .then(async (response) => {
      dates = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { dates };
}
