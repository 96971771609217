import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { ref } from "vue";

export async function store(params) {
  const axios = axiosInstance().primoLab;
  const warehouseRequest = ref([]);

  await axios
    .post("/warehouse-requests", params)
    .then(async (response) => {
      warehouseRequest.value = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return {warehouseRequest};
}
