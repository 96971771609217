<script>
import { toRaw } from "vue";
import { findIconByPrescriptionRows } from "@/use/repositories/findIconByPrescriptionRows";
import Icon from "./Project/Icon.vue";

export default {
  props: {
    elements: {
      type: Object,
      required: true,
    },
    showModels: {
      type: Boolean,
      default: false
    },
    showProjects: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['select', 'remove'],
  components: {
    Icon
  },
  setup() {
    const getIds = (array) => {
      let ids = [];
      array.forEach((el) => {
        ids.push(el.id);
      });
      return ids;
    }

    const getIcons = (tooth) => {
      // Render only tooth with empty assets
      if (tooth.assets?.length > 0) return;

      return findIconByPrescriptionRows(tooth);
    }
    return { getIcons, getIds, toRaw }
  }
}

</script>
<template>
  <template v-if="$props.showModels">
    <!-- Arches -->
    <template v-for="(arch, index) in $props.elements.models.arches" :key="index">
      <template v-if="arch.assets.length === 0">
        <Icon class="m-1" :data="arch" :path="`assets/icons/model/${arch.icon}.svg`"
          :path-selected="`assets/icons/model/${arch.icon}-sel.svg`" :width="100" @click="$emit('select', arch)" />
      </template>
    </template>
    <!-- Gums -->
    <template v-for="(gum, index) in $props.elements.models.gums" :key="index">
      <template v-if="gum.assets.length === 0">
        <Icon class="m-1" label="&nbsp;" :data="gum" :path="`assets/icons/model/${gum.icon}.svg`"
          :path-selected="`assets/icons/model/${gum.icon}-sel.svg`" :width="100" :ids="[gum.slug]"
          @click="$emit('select', gum)" @remove="$emit('remove', { event_type: 'remove-gum', data: $event })" />
      </template>
    </template>
    <!-- Stump -->
    <template v-for="(stump, index) in $props.elements.models.stumps" :key="index">
      <template v-if="stump.assets.length === 0">
        <Icon class="m-1" label="&nbsp;" :data="stump" :path="`assets/icons/model/${stump.icon}.svg`"
          :path-selected="`assets/icons/model/${stump.icon}-sel.svg`" :width="50" :ids="[stump.slug]"
          @click="$emit('select', stump)" @remove="$emit('remove', { event_type: 'remove-stump', data: $event })" />
      </template>
    </template>
    <!-- Custom pins -->
    <template v-for="(pin, index) in $props.elements.models.custom_pins" :key="index">
      <template v-if="pin.assets.length === 0">
        <Icon class="m-1" label="&nbsp;" :data="pin" :path="`assets/icons/model/${pin.icon}.svg`"
          :path-selected="`assets/icons/model/${pin.icon}-sel.svg`" :width="50" :ids="[pin.slug]"
          @click="$emit('select', pin)" @remove="$emit('remove', { event_type: 'remove-custom-pin', data: $event })" />
      </template>
    </template>
  </template>
  <template v-if="$props.showProjects">

    <!-- Bridges -->
    <template v-if="$props.elements.bridges.length > 0">
      <template v-for="(bridge, index) in $props.elements.bridges" :key="index">
        <template v-if="!bridge.tooth[0].assets || bridge.tooth[0].assets.length === 0">
          <Icon :data="bridge.tooth[0]"
            :label="`D${bridge.tooth[0].zone_number}/${bridge.tooth[bridge.tooth.length - 1].zone_number}`"
            :path="`assets/icons/project/bridge/bridge.svg`"
            :path-selected="`assets/icons/project/bridge/bridge-sel.svg`" :width="180"
            :ids="getIds(toRaw(bridge.tooth))" @click="$emit('select', toRaw(bridge.tooth))"
            :readonly="$props.readonly"
            @remove="$emit('remove', { event_type: 'remove-bridge', data: $event })" />
        </template>
      </template>
    </template>

    <!-- Tooth -->
    <template v-if="$props.elements.tooth.length > 0">
      <template v-for="(teeth, index) in $props.elements.tooth" :key="index">
        <template v-for="(icon, index) in getIcons(teeth)" :key="index">
          <Icon :data="teeth" :path="`assets/icons/project/${icon}.svg`"
            :path-selected="`assets/icons/project/${icon}-sel.svg`" :width="100" :ids="getIds(toRaw([teeth]))"
            @click="$emit('select', toRaw([teeth]))" />
        </template>
      </template>
    </template>
  </template>
</template>
