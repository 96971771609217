<script>
import { defineComponent, reactive, ref, computed, onMounted } from "vue";

import { index } from "@/use/repositories/shippingCode/index";
import { show } from "@/use/repositories/shippingCode/show";
import { index as fetchClinics } from "@/use/repositories/clinic/index";

import { dateFormatter } from "@/use/utilities/time/dateFormatter";
import { goToConsulting } from "@/use/utilities/goToConsulting";

import TableLite from "vue3-table-lite";
import Empty from "@/components/Modal/Empty";
import Brand from "@/components/Brand";
import ShippingCode from "@/components/Consulting/ShippingCode.vue";

import _find from "lodash/find";

export default defineComponent({
  name: "ShippingCodes",
  components: { TableLite, Empty, Brand, ShippingCode },
  setup() {
    const clinics = ref(null);
    const searchTerm = ref(null);
    const data = reactive({ rows: [] });
    const shippingCode = ref(null);
    const prescriptions = ref([]);
    const showPrescriptionsModal = ref(false);
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Codice spedizione e PDF",
          field: "code",
          width: "25%",
        },
        {
          label: "Fornitore",
          field: "vendor",
          width: "25%",
        },
        {
          label: "Creazione",
          field: "created_at",
          width: "25%",
        },
        {
          label: "Clinica",
          field: "clinic",
          width: "25%",
        },
        {
          label: "",
          field: "quick",
          width: "30%",
          display: (row) =>
            `<button type="button" data-id="${row.id}" class="is-rows-el quick-btn btn btn-outline-violet text-uppercase rounded-custom text-nowrap">Mostra prescrizioni</button>`
        },
      ],
      rows: computed(() => data.rows),
    });

    onMounted(() => {
      fetchClinics().then((response) => (clinics.value = response.clinics));
      doSearch(0, 10);
    });

    const doSearch = (offset = 0, limit = 10) => {
      const page = Math.floor(offset / limit) + 1;
      const call = searchTerm.value
        ? index("by_clinic_id", page, searchTerm.value)
        : index("get_all", page);
      call.then((response) => {
        data.rows = response.data.map((el) => ({
          vendor: el.shipping_provider.name,
          clinic: el.clinic.name,
          code: el.code,
          created_at: dateFormatter(el.created_at),
          id: el.id,
        }));
      });
    };

    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
      Array.prototype.forEach.call(elements, (element) => {
        if (element.classList.contains("quick-btn")) {
          element.addEventListener("click", () => {
            shippingCode.value = _find(
              data.rows,
              (item) => item.id == element.attributes["data-id"].value
            );
            show(shippingCode.value.id, "with_prescriptions").then((response) => {
              prescriptions.value = response.data.data.prescriptions;
              showPrescriptionsModal.value = true;
            });
          });
        }
      });
    };

    const closeModal = () => {
      showPrescriptionsModal.value = false;
      shippingCode.value = null;
      prescriptions.value = [];
    };

    return {
      table,
      tableLoadingFinish,
      showPrescriptionsModal,
      prescriptions,
      shippingCode,
      searchTerm,
      doSearch,
      closeModal,
      clinics,
      goToConsulting,
    };
  },
});
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="
          col-sm-12 col-md-12 col-lg-2 col-xl-2
          d-flex
          flex-column
          justify-content-start
          align-items-start
        "
      >
        <Brand :width="150" />
      </div>
      <div class="container mt-5 p-5">
        <div
          class="bg-white d-flex justify-content-between align-items-center rounded-custom mb-2 p-4"
        >
          <h2 class="text-center m-3">Documenti di trasporto</h2>

          <div class="col-lg-3 col-md-5 col-sm-8 col col-xs-12">
            <label for="" class="p-2">Seleziona la clinica da filtrare</label>
            <select
              class="form-select rounded-custom border-violet"
              aria-label="Default select example"
              @change="doSearch()"
              v-model="searchTerm"
            >
              <option :value="null" selected>Tutte le cliniche</option>
              <option v-for="clinic in clinics" :key="clinic.id" :value="clinic.id">
                {{ clinic.name }}
              </option>
            </select>
          </div>
        </div>

        <TableLite
          class="p-3"
          v-if="clinics.length"
          is-slot-mode
          :is-loading="table.isLoading"
          :columns="table.columns"
          :rows="table.rows"
          @is-finished="tableLoadingFinish"
          @do-search="doSearch"
          >
          <template v-slot:code="data">
            <ShippingCode :shipping-code="{id: data.value.id, code: data.value.code }" class="cp"/>
          </template>
        </TableLite>
      </div>
    </div>
  </div>

  <Empty
    v-if="showPrescriptionsModal"
    :size="'lg'"
    :modal_id="'prescriptionsModal'"
    @hidden="closeModal"
  >
    <div class="text-center rounded-custom">
      <div class="card-header bg-home-violet">
        <ul class="nav nav-pills card-header-pills flex-column p-2">
          <li class="nav-item">
            <h5>
              Codice Spedizione: <span class="fw-bold">{{ shippingCode.code }}</span>
            </h5>
          </li>
          <li class="nav-item">
            <h5>
              Fornitore: <span class="fw-bold">{{ shippingCode.vendor }}</span>
            </h5>
          </li>
          <li class="nav-item">
            <h5>
              Spedizione: <span class="fw-bold">{{ shippingCode.created_at }}</span>
            </h5>
          </li>
          <li class="nav-item">
            <h5>
              Clinica: <span class="fw-bold">{{ shippingCode.clinic }}</span>
            </h5>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <table class="table table-hover" v-if="prescriptions.length">
          <thead>
            <tr>
              <th scope="col">Prescrizione</th>
              <th scope="col">Paziente</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr class="align-middle" v-for="prescription in prescriptions" :key="prescription.id">
              <td>{{ prescription?.number_text }}</td>
              <td>
                {{ prescription?.customer.first_name }} {{ prescription?.customer?.last_name }}
              </td>
              <td>
                <button
                  class="btn nav-link-violet align-middle text-uppercase"
                  @click="goToConsulting(prescription.id, true)"
                >
                  vedi in consultazione
                  <img
                    class="ms-2 mb-1"
                    src="@/assets/icons/external-link-violet.svg"
                    width="25"
                    alt="consulting"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 v-else class="text-center p-5">Nessuna prescrizione associata</h5>
      </div>
    </div>
  </Empty>
</template>

<style scoped>
:deep(.vtl.vtl-card) {
  border-radius: 20px;
}
:deep(.vtl-thead-tr .vtl-thead-th:first-child) {
  border-top-left-radius: 20px;
}
:deep(.vtl-thead-tr .vtl-thead-th:last-child) {
  border-top-right-radius: 20px;
}
:deep(.vtl-paging-count-label),
:deep(.vtl-paging-count-dropdown) {
  display: none;
}
:deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #f3e9ff;
  border-color: #f3e9ff;
  border: none;
}

:deep(.vtl-table td),
:deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  vertical-align: middle;
}

:deep(.vtl-paging-info),
:deep(.vtl-paging-pagination-div),
:deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7e6492;
}

:deep(.vtl-paging-count-label),
:deep(.vtl-paging-page-label) {
  color: #7e6492;
}

:deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>
