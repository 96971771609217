import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import _head from "lodash/head";

export async function update(params) {
  const axios = axiosInstance().primoLab;
  let testWorkQueuePhase = {};

  await axios
    .put(`/test-work-queue-phases/`, params)
    .then(async (response) => {
      testWorkQueuePhase = _head(response.data.data);
    })
    .catch(async (error) => {
      throw _head(error.response.data.errors);
    });

  return { testWorkQueuePhase };
}
