import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByDepartmentSlug(departmentName) {
  const axios = axiosInstance().primoLab;
  let usersDepartment = [];

  await axios
    .get(`/departments/${departmentName}`, {
      params: {
        action: 'with_users',
      }
    })
    .then(async (response) => {
      usersDepartment = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { usersDepartment };
}
