<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="$props.width"
    :height="$props.height"
    :fill="$props.color"
    class="bi bi-plus-lg"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Print",
  props: {
    color: {
      type: String,
      required: false,
      default: "#0000000",
    },
    width: {
      type: Number,
      required: false,
      default: 24,
    },
    height: {
      type: Number,
      required: false,
      default: 24,
    },
  },
};
</script>

<style scoped></style>
