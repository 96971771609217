<template>
  <div class="d-flex flex-column w-100">
    <table class="table table-bordered bg-white" id="scrollTop">
      <thead>
        <tr class="bg-violet text-white text-center p-3 text-uppercase">
          <th colspan="7" class="position-relative">
            <div class="position-absolute start-0 ms-2">
              <img class="sync" src="@/assets/icons/sync.svg" width="30" height="30" alt="sync" @click="orderByPriorityDate()">
            </div>
            <h3 class="m-0">Conclusione lavori</h3>
          </th>
        </tr>
        <tr class="text-center" v-if="worksGroupedByTestWorkQueuePhase.length > 0">
          <th class="fw-bold align-middle" nowrap scope="col">Prescrizione</th>
          <th class="fw-bold align-middle" nowrap scope="col">N. Elementi</th>
          <th scope="col">
            <button class="btn btn-sm btn-violet text-uppercase"
                    :disabled="numberOfSelected <= 1"
                    @click="completedAllSelected()">
              concludi tutti
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="worksGroupedByTestWorkQueuePhase.length > 0">
          <template v-for="(el, index) in worksGroupedByTestWorkQueuePhase" :key="index">
            <tr class="text-center" :class="{'higthlight': el.checked}">
              <td>{{ el.works[0].number_text }} - {{ dateFormatter(el.delivery_date, { onlyDate: true, onlyTime: false, dash: false, iso: false }) }}</td>
              <td>{{ el.works.length }}</td>
              <td>
                <button class="btn btn-violet text-uppercase"
                        :disabled="! el.checked"
                        @click="completed(el.test_work_queue_phase_id, el.works)">
                  concludi
                </button>
              </td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="7" class="text-center p-3 fw-bold">Nessun lavoro presente</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <Confirm v-if="showConfirmModal"
           :modal_id="'modalConfirmCompletedWorks'"
           :title="modalText.title"
           :subtitle="modalText.subtitle"
           :confirm_label="'Si'"
           :decline_label="'No'"
           @hidden="hideModal"
           @confirm="proceedAnyway"
           @decline="hideModal">
    <template v-if="worksNotCompleted.length > 0">
      <ul class="worksNotCompleted">
        <li v-for="(numberText, index) in worksNotCompleted" :key="index" >
          <div class="me-2">
            <span>Cod: </span>
            <span class="fw-bold">{{ numberText.number_text }}</span>
          </div>
          <div class="me-2">
            <span>Data: </span>
            <span class="fw-bold">{{ dateFormatter(delivery_date, {onlyDate: true, onlyTime: false, dash: false, iso: false}) }}</span>
          </div>
          <div class="me-2">
            <span>Colore: </span>
            <span class="fw-bold">{{ numberText.code }}</span>
          </div>
          <div class="me-2">
            <span>Materiale: </span>
            <span class="fw-bold">{{ numberText.material }}</span>
          </div>
          <div class="me-2">
            <span>Stato: </span>
            <span class="fw-bold">{{ numberText.state }}</span>
          </div>
          <hr>
        </li>
      </ul>
    </template>
  </Confirm>

</template>

<script>
import {computed, ref, toRaw, watch} from "vue";
import { useToast } from "vue-toastification";
import _ from 'lodash';
import _head from "lodash/head";

import Confirm from "@/components/Modal/Confirm";
import {update} from "@/use/repositories/assets/update";
import {dateFormatter} from '@/use/utilities/time/dateFormatter';
import {useI18n} from "vue-i18n";

export default {
  name: "FinishWork",
  components: {
    Confirm,
  },
  emits: [
    'idsToRemove',
    'resetNumberTextProp',
    'restore',
  ],
  props: [
    'dataAssets',
    'numberText',
    'reset',
  ],
  setup(props, { emit }) {
    const i18n = useI18n();
    const worksGroupedByTestWorkQueuePhase = ref([]);
    const idsToUpdate = ref([]);
    const showConfirmModal = ref(false);
    const toast = useToast();
    const numberOfSelected = computed(() => {
      let counter = 0;
      worksGroupedByTestWorkQueuePhase.value.forEach((item) => {
        if (item.checked) {
          counter++;
        }
      });

      return counter;
    });
    const worksNotCompleted = ref([]);

    const modalText = computed(() => {
      return {
        title: worksNotCompleted.value.length === 1 ? i18n.t('The following work is not completed!') : i18n.t('The following works are not completed!'),
        subtitle: worksNotCompleted.value.length === 1 ? i18n.t('Do you want to finish it the same?') : i18n.t('Do you want to finish them all the same?'),
      }
    });

    watch(() => props.reset, async (value) => {
      if (value) {
        worksGroupedByTestWorkQueuePhase.value.forEach((item) => item.checked = false);
        emit('restore');
      }
    });

    watch(() => props.dataAssets.length, async (value) => {
      // Reorder by priority date.
      value = _.orderBy(toRaw(props.dataAssets),'delivery_date', 'asc');
      // Grouped data.
      await groupedByTestWorkQueuePhase(toRaw(value));
    });

    watch(() => props.numberText, (value) => {
      // For reset props value.
      if (value === "") {
        return;
      }

      if (value !== null) {
        worksGroupedByTestWorkQueuePhase.value.forEach((item) => {
          if (item.checked) {
            if ((_head(item.works).number_text === value)) {
              item.checked = false;
            }
          } else {
            item.checked = (_head(item.works).number_text === value);
          }
        });

        // Sort by checked.
        worksGroupedByTestWorkQueuePhase.value = _.orderBy(toRaw(worksGroupedByTestWorkQueuePhase.value), 'checked', 'desc');

        // Scroll to top.
        document.getElementById('scrollTop').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }

      emit('resetNumberTextProp', "");
    });

    const groupedByTestWorkQueuePhase = async (allWorks) => {
      // Grouped by TWQP.
      worksGroupedByTestWorkQueuePhase.value =
          _.chain(allWorks)
            .groupBy('test_work_queue_phase_id')
            .map((value, key) => ({ test_work_queue_phase_id: key, works: value }))
            .value();

      // Add checked bool for field before render.
      worksGroupedByTestWorkQueuePhase.value.forEach((item) => item.checked = false);
      // Add priority date.
      worksGroupedByTestWorkQueuePhase.value.forEach((item) => {
        item.delivery_date = item.works[0].delivery_date;
      });
      // Order by priority date.
      worksGroupedByTestWorkQueuePhase.value = _.orderBy(toRaw(worksGroupedByTestWorkQueuePhase.value), 'delivery_date', 'asc');
    }

    const completed = async (testWorkQueuePhaseId, works) => {
      idsToUpdate.value = [];
      worksNotCompleted.value = [];

      // Find ids to remove.
      await fetchIdsToConclude(testWorkQueuePhaseId);

      syncWorksNotCompleted(works);

      if (!worksNotCompleted.value.length) {
        await finishWork(toRaw(idsToUpdate.value), 'completed').then(() => {
          // Show message
          toast.success('Lavorazione conclusa con successo!', {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
          });
        });

      } else {
        // Show confirm modal.
        showConfirmModal.value = true;
      }
    }

    const fetchIdsToConclude = async (testWorkQueuePhaseId) => {
      props.dataAssets.forEach((item) => {
        if (item.test_work_queue_phase_id === parseInt(testWorkQueuePhaseId)) {
          idsToUpdate.value.push(item.id);
        }
      });
    }

    const finishWork = async (ids, state) => {
      try {
        await update(ids, state).then(async () => {
          emit('idsToRemove', ids);
        });
      } catch (error) {
        alert(error);
      } finally {
        idsToUpdate.value = [];
      }
    }

    const proceedAnyway = async () => {
      console.log(_.uniq(toRaw(idsToUpdate.value)));

      await finishWork(_.uniq(toRaw(idsToUpdate.value)), 'completed').then(() => {
        // Show message
        toast.success('Operazione avvenuta con successo!', {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    }

    const hideModal = (value) => {
      showConfirmModal.value = value;
    }

    const orderByPriorityDate = () => {
      worksGroupedByTestWorkQueuePhase.value = _.orderBy(toRaw(worksGroupedByTestWorkQueuePhase.value), 'delivery_date', 'asc');
      // Show message
      toast.success("Ordinamento lavorazioni per data di priorità", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );
    }

    const completedAllSelected = async () => {
      worksNotCompleted.value = [];

      worksGroupedByTestWorkQueuePhase.value.forEach((item) => {
        if (item.checked) {
          // Find ids to remove.
          fetchIdsToConclude(item.test_work_queue_phase_id);
          syncWorksNotCompleted(item.works);
        }
      });

      if (worksNotCompleted.value.length) {
        showConfirmModal.value = true;
      } else {
        await proceedAnyway();
      }
    }

    const syncWorksNotCompleted = (works) => {
      works.forEach((work) => {
        if (work.state === 'pending') {
          worksNotCompleted.value.push(work);
        }
      });
    }

    return {
      worksGroupedByTestWorkQueuePhase,
      completed,
      idsToUpdate,
      showConfirmModal,
      hideModal,
      proceedAnyway,
      orderByPriorityDate,
      completedAllSelected,
      numberOfSelected,
      worksNotCompleted,
      dateFormatter,
      modalText,

    }
  }
}
</script>

<style scoped>
.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgb(225 210 242);
}
.higthlight {
  border-color: rgb(225 210 242);
  background-color:rgb(225 210 242);
  box-shadow: 0 0 0 0.25rem rgb(225 210 242);
  outline: 0 none;
}
.sync:hover {
  cursor: pointer;
}
.worksNotCompleted {
  overflow: auto;
  max-height: 350px;
}
</style>
