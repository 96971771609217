<template>
  <div class="card rounded-custom" style="width: 18rem">
    <div class="d-flex justify-content-around p-3">
      <img src="@/assets/icons/danger.svg" width="50" alt="danger" />
      <img src="@/assets/icons/battery-low.svg" width="50" alt="battery-low" />
    </div>
    <div class="p-3">
      <p>Il livello della batteria dell'etichetta digitale è basso.</p>
      <p>Provvedere con una sostituzione quanto prima.</p>
    </div>
  </div>
</template>
