import { createRouter, createWebHistory } from "vue-router";

import { auth } from "@/middleware/auth";

// Plaster.
import { canPlasterCastingCheckin } from "@/middleware/canPlasterCastingCheckin";
import { canPlasterFinishCheckin } from "@/middleware/canPlasterFinishCheckin";
import { canPlasterStation } from "@/middleware/canPlasterStation";

import { hasLogisticDepartment } from "@/middleware/hasLogisticDepartment";
import { canCheckIn } from "@/middleware/canCheckIn";
import { canDashboard } from "@/middleware/canDashboard";
import { middlewarePipeline } from "@/middleware/middlewarePipeline";
import { canCam } from "@/middleware/canCam";
import { canCamSummary } from "@/middleware/canCamSummary";
import { canManager } from "@/middleware/canManager";

import {
  Login,
  Home,
  Logistica,
  CheckinLogistica,
  CheckoutLogistica,
  CheckinTechnician,
  PlasterCheckinCasting,
  PlasterCheckinFinish,
  RecoverPassword,
  ResetPassword,
  ExpiredPassword,
  Dashboard,
  Logout,
  NegativeFeedback,
  Feedback,
  IsoInState,
  PlasterInTheWorks,
  CheckinCam,
  CamInTheWorks,
  CamSummary,
  WarehouseDashboard,
  ConsultingSearch,
  ExternalSummary,
  ConsultingDashboard,
  PageNotFound,
  ManagerDashboard,
  Summary,
  ExternalDevice,
  TwoFactorAuth,
  Profile,
  RejectedApproval,
  DigitalLabels,
  ShippingCodes,
  Tests,
  LoginChoose,
} from "@/views";

import store from "@/store";
import _head from "lodash/head";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/recover-password",
    name: "Recover",
    component: RecoverPassword,
  },
  {
    path: "/reset-password",
    name: "Reset",
    component: ResetPassword,
  },
  {
    path: "/expired-password",
    name: "ExpiredPassword",
    component: ExpiredPassword,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      middleware: [auth],
    },
  },

  // LoginChoose
  {
    path: "/login-choose",
    name: "LoginChoose",
    component: LoginChoose,
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/2fa/:type",
    name: "TwoFactorAuth",
    component: TwoFactorAuth,
    meta: {
      middleware: [auth],
    },
    props: true,
  },

  // Logistic.
  {
    path: "/logistica",
    name: "Logistica",
    component: Logistica,
    meta: {
      middleware: [auth, hasLogisticDepartment],
    },
  },
  {
    path: "/logistica/check-in",
    name: "CheckinLogistica",
    component: CheckinLogistica,
    meta: {
      middleware: [auth, hasLogisticDepartment],
    },
  },
  {
    path: "/logistica/:type",
    name: "CheckoutLogistica",
    component: CheckoutLogistica,
    props: true,
    meta: {
      middleware: [auth, hasLogisticDepartment],
    },
  },
  {
    path: "/rejected-approvals",
    name: "RejectedApproval",
    component: RejectedApproval,
    meta: {
      middleware: [ auth, hasLogisticDepartment ],
    }
  },
  {
    path: "/digital-labels",
    name: "DigitalLabels",
    component: DigitalLabels,
    meta: {
      middleware: [ auth, hasLogisticDepartment ],
    }
  },
  {
    path: "/shipping-codes",
    name: "ShippingCodes",
    component: ShippingCodes,
    meta: {
      middleware: [ auth, hasLogisticDepartment ],
    }
  },
  {
    path: "/tests",
    name: "Tests",
    component: Tests,
    meta: {
      middleware: [ auth, hasLogisticDepartment ],
    }
  },

  // Warehouse.
  {
    path: "/warehouse/dashboard",
    name: "WarehouseDashboard",
    component: WarehouseDashboard,
    meta: {
      middleware: [auth, hasLogisticDepartment],
    },
  },

  // Plaster.
  {
    path: "/gesso/colatura/check-in",
    name: "PlasterCheckinCasting",
    component: PlasterCheckinCasting,
    meta: {
      middleware: [auth, canPlasterCastingCheckin],
    },
  },
  {
    path: "/gesso/finitura/check-in",
    name: "PlasterCheckinFinish",
    component: PlasterCheckinFinish,
    meta: {
      middleware: [auth, canPlasterFinishCheckin],
    },
  },
  {
    path: "/gesso/in-the-works",
    name: "PlasterInTheWorks",
    component: PlasterInTheWorks,
    meta: {
      middleware: [auth, canPlasterStation],
    },
  },

  // Workflow.
  {
    path: "/check-in",
    name: "Checkin",
    component: CheckinTechnician,
    meta: {
      middleware: [auth, canCheckIn],
    },
  },
  {
    path: "/dashboard/:test_work_queue_phases_id?",
    name: "Dashboard",
    component: Dashboard,
    props: true,
    meta: {
      middleware: [auth, canDashboard],
    },
  },

  // Feedbacks.
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/negative-feedback",
    name: "NegativeFeedback",
    component: NegativeFeedback,
  },
  // Iso.
  {
    path: "/iso-in/state/:state_slug",
    name: "IsoInState",
    component: IsoInState,
    props: true,
  },

  // Cam.
  {
    path: "/cam/check-in",
    name: "CheckinCam",
    component: CheckinCam,
    meta: {
      middleware: [auth, canCam],
    },
  },
  {
    path: "/cam/in-the-works",
    name: "Cam",
    component: CamInTheWorks,
    meta: {
      middleware: [auth, canCam],
    },
  },
  {
    path: "/cam/summary",
    name: "CamSummary",
    component: CamSummary,
    meta: {
      middleware: [auth, canCamSummary],
    },
  },

  // External.
  {
    path: "/external/summary",
    name: "ExternalSummary",
    component: ExternalSummary,
    meta: {
      middleware: [auth, hasLogisticDepartment],
    },
  },

  // Consulting.
  {
    path: "/consulting/search",
    name: "ConsultingSearch",
    component: ConsultingSearch,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/consulting/dashboard/:prescription_id",
    name: "ConsultingDashboard",
    component: ConsultingDashboard,
    props: true,
    meta: {
      middleware: [auth],
    },
  },

  // Manager.
  {
    path: "/manager",
    name: "ManagerDashboard",
    component: ManagerDashboard,
    meta: {
      middleware: [auth, canManager],
    },
  },
  {
    path: "/manager/summary",
    name: "SummaryManager",
    component: Summary,
    meta: {
      middleware: [auth, canManager],
    },
  },
  {
    path: "/external/device/:type/:id", // TTL ???
    name: "ExternalDevice",
    component: ExternalDevice,
  },

  // Not found.
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthenticated = () => {

  return store.state.user != null;

};

router.beforeEach((to, from, next) => {
  // Set pages title
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;

  if (isAuthenticated() && store.state.user.password_expired && to.name != "ExpiredPassword" && to.name != "Login") {

    return next("/expired-password");

  }

  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return _head(middleware)({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
