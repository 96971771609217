<template>
  <div class="d-flex card shadow rounded-custom w-100 h-auto">
    <div class="d-flex align-items-center justify-content-between">
      <GlobalSearch
        class="p-4 flex-grow-1"
        :label="'Ricerca lavori'"
        :placeholder="'Ricerca per prescrizione o paziente...'"
        :reset="reset"
        @toSearch="readValueToSearch"
      />
      <div class="d-flex p-4 mb-2">
        <template v-if="$route.params.type === 'bagging'">
          <img src="@/assets/icons/bag.svg" width="50" alt="bag">
        </template>
        <template v-else>
          <img src="@/assets/icons/box-seam.svg" width="50" alt="bx-seam">
        </template>
      </div>
    </div>
    <TableData
      :globallySearch="toSearch"
      @reset-search="clear"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import GlobalSearch from "@/components/GlobalSearch";
import TableData from "@/components/checkout/TableData";

export default {
  name: "WorkSummary",
  components: {
    TableData,
    GlobalSearch
  },
  setup() {
    const toSearch = ref("");
    const reset = ref(false);

    const readValueToSearch = (value) => {
      toSearch.value = value;
    }

    const clear = (value) => {
      reset.value = value;
    }

    return {
      readValueToSearch,
      toSearch,
      clear,
      reset,
    }
  }
}
</script>
