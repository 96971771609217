<template>

  <template v-if="$props.state_slug === 'iso-reassigned' || $props.state_slug === 'manager-reassigned'">
    <IsoInReassign
      :test_work_queue_id="$route.query.test_work_queue_id"
      :test_work_queue_phase_id="$route.query.test_work_queue_phase_id"
      :internal_phase_id="$route.query.internal_phase_id"
      :prescription_test_id="$route.query.prescription_test_id"
      :state_slug="$props.state_slug"
      :prescription="$route.query.prescription" />
  </template>

  <template
    v-if="[
      'iso-failed',
      'manager-failed',
      'on-hold',
    ].includes($props.state_slug)">
    <IsoInFailed
      :test_work_queue_id="$route.query.test_work_queue_id"
      :test_work_queue_phase_id="$route.query.test_work_queue_phase_id"
      :internal_phase_id="$route.query.internal_phase_id"
      :state_slug="$props.state_slug"
      :prescription="$route.query.prescription" />
  </template>

  <template v-if="$props.state_slug === 'iso-in-changed-test' || $props.state_slug === 'manager-changed-test'">
    <IsoInChangedTest
      :test_work_queue_id="$route.query.test_work_queue_id"
      :test_work_queue_phase_id="$route.query.test_work_queue_phase_id"
      :test_id="$route.query.test_id"
      :internal_phase_id="$route.query.internal_phase_id"
      :prescription_test_id="$route.query.prescription_test_id"
      :state_slug="$props.state_slug"
      :laboratory_list_row_id="$route.query.laboratory_list_row_id"
      :prescription="$route.query.prescription" />
  </template>
  <template v-if="$props.state_slug === 'iso-in-changed-date'">
    <IsoInChangedDate
      :test_work_queue_id="$route.query.test_work_queue_id"
      :test_work_queue_phase_id="$route.query.test_work_queue_phase_id"
      :test_id="$route.query.test_id"
      :internal_phase_id="$route.query.internal_phase_id"
      :prescription_test_id="$route.query.prescription_test_id"
      :state_slug="$props.state_slug"
      :laboratory_list_row_id="$route.query.laboratory_list_row_id"
      :prescription="$route.query.prescription"
      :delivery_date="$route.query.delivery_date"/>
  </template>

  <template v-if="$props.state_slug === 'iso-in-choose-fork'">
    <IsoInChangeFork
      :test_work_queue_id="$route.query.test_work_queue_id"
      :test_work_queue_phase_id="$route.query.test_work_queue_phase_id"
      :internal_phase_id="$route.query.internal_phase_id"
      :prescription_test_id="$route.query.prescription_test_id"
      :test_id="$route.query.test_id"
      :state_slug="$props.state_slug"
      :prescription="$route.query.prescription" />
  </template>

  <template v-if="$props.state_slug === 'iso-custom'">
    <CustomFlow
      :test-work-queue-phase-id="parseInt($route.query.test_work_queue_phase_id)"
      :test-work-queue-id="parseInt($route.query.test_work_queue_id)"
      :prescription-id="parseInt($route.query.prescription_id)"
      :prescription="$route.query.prescription" />
  </template>

</template>

<script>
import {useRoute} from "vue-router";

import IsoInReassign from "./IsoInReassign";
import IsoInFailed from "./IsoInFailed";
import IsoInChangedTest from "./IsoInChangedTest";
import IsoInChangeFork from "./IsoInChangeFork";
import CustomFlow from "@/components/Iso-in/CustomFlow";
import IsoInChangedDate from "./IsoInChangedDate.vue";

export default {
  name: "IsoInState",
  props: ['state_slug'],
  components: {
    IsoInReassign,
    IsoInFailed,
    IsoInChangedTest,
    IsoInChangeFork,
    CustomFlow,
    IsoInChangedDate
},
  setup(props) {
    const route = useRoute();

    console.log("STATE SLUG: " + props.state_slug);
    console.log("TEST WORK QUEUE PHASE ID: " + route.query.test_work_queue_phase_id);
    console.log("INTERNAL PHASE ID: " + route.query.internal_phase_id);
    console.log("LABORATORY LIST ROW ID: " + route.query.laboratory_list_row_id);
  }
}
</script>
