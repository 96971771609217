<template>
  <template v-if="$props.bridge">
    <div class="d-flex mb-2 align-items-start flex-column">
      <div class="d-flex align-items-center">
        <img class="me-1" :src="require(`@/assets/icons/project/bridge/bridge-sel.svg`)" width="60" height="60">
        <span class="fw-bold text-violet me-1">
          {{ `D${$props.data.tooth[0].zone_number}/${$props.data.tooth[$props.data.tooth.length-1].zone_number}` }}
        </span>
        <img class="icon-3d" @click="callPreview3D($props.data.tooth[0].assets[0].id)" src="@/assets/icons/3d.svg" width="30" height="30" alt="3d">
      </div>
      <div class="d-flex align-items-center w-100">
        <div class="d-flex" @click="selected(toRaw($props.data.tooth))">
          <img v-show="! select" class="checkbox" src="@/assets/icons/checkbox.svg" width="20" height="20" alt="checkbox" style="background-color: white;"/>
          <img v-show="select" class="checkbox" src="@/assets/icons/checkbox-checked.svg" width="20" height="20" alt="checkbox-checked"/>
        </div>
        <div class="d-flex" style="min-width: 0;">
          <small ref="info" class="mx-2 text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" :title="getFileName($props.data.tooth[0].assets[0])">
            {{ getFileName($props.data.tooth[0].assets[0]) }}
          </small>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="d-flex mb-2 align-items-start flex-column">
      <div class="d-flex align-items-center">
        <img v-if="$props.isModel" class="me-2" :src="require(`@/assets/icons/model/${getIcons($props.data)}-sel.svg`)" width="60" height="60">
        <img v-else-if="! getIcons($props.data)[0].includes('teeth')" class="icon"
          :src="require(`@/assets/icons/project/${getIcons($props.data)}-sel.svg`)"
          :width="60"
          :height="60">
        <Teeth v-else
          :number="$props.data.zone_number"
          :selected="$props.data.selected"
          :width="60"
          :height="60"/>
        <img class="icon-3d" @click="callPreview3D($props.data.assets[0].id)" src="@/assets/icons/3d.svg" width="30" height="30" alt="3d">

        <small v-if="$props.isModel" class="ms-2" style="color: #E97B86;">{{ $props.data.name }}</small>
        <small v-else class="ms-2">PROGETTO</small>
      </div>
      <div class="d-flex align-items-center w-100">
        <div class="d-flex" @click="selected(toRaw([$props.data]))">
          <img v-show="! select" class="checkbox" :src="$props.isModel ? require('@/assets/icons/model/checkbox.svg') : require('@/assets/icons/checkbox.svg')" width="20" height="20" alt="checkbox" style="background-color: white;"/>
          <img v-show="select" class="checkbox" src="@/assets/icons/checkbox-checked.svg" width="20" height="20" alt="checkbox-checked"/>
        </div>
        <div class="d-flex" style="min-width: 0;">
          <small ref="info" class="mx-2 text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" :title="getFileName($props.data.assets[0])">
            {{ getFileName($props.data.assets[0]) }}
          </small>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {ref, toRaw, watch, onMounted} from 'vue';
import _find from "lodash/find";
import { Tooltip } from "bootstrap";
import {findIconByPrescriptionRows} from "@/use/repositories/findIconByPrescriptionRows";
import _head from "lodash/head";
import Teeth from '@/components/Project/Teeth';

export default {
  name: "File",
  props: {
    data: {
      type: Object,
      required: true
    },
    bridge: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    isModel: {
      type: Boolean,
      default: false
    },
  },
  emits: [
   'assetsToRemove',
   'resetCompleted',
   'preview',
   'asset-model-to-remove',
  ],
  components: {
    Teeth
  },
  setup(props, {emit}) {
    const select = ref(false);
    const info = ref(null);

    onMounted(() => {
      new Tooltip(info.value);
    });

    watch(() => props.reset, (value) => {
      if (value) {
        select.value = false;
        emit('resetCompleted', false);
      }
    })

    const selected = (array) => {
      // Toggle icon.
      select.value = !select.value;

      // Models.
      if (_head(array).id === undefined) {
        let model = _head(array);
        let assetId = _head(model.assets).id;
        emit('asset-model-to-remove', assetId);
      } else {
        let dataRemove = {
          prescriptionRowsIds: [],
          assetsIds: []
        };

        array.forEach((el) => {
          dataRemove.prescriptionRowsIds.push(el.id);
          if (el.assets.length > 0) {
            dataRemove.assetsIds.push(el.assets[0].id);
          }
        });

        emit('assetsToRemove', dataRemove);
      }
    }

    const getFileName = (file) => {
      return _find(file.media).file_name;
    }

    // Render icons.
    const getIcons = (element) => {
      return findIconByPrescriptionRows(element);
    }

    const callPreview3D = (assetId) => {
      emit('preview', assetId);
    }

    return {
      getIcons,
      select,
      selected,
      toRaw,
      getFileName,
      callPreview3D,
      info,
    }
  }

}
</script>

<style scoped>
.icon-3d:hover,
.checkbox {
  cursor: pointer;
}
</style>
