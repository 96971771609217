<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="$props.width" :height="$props.height" :fill="$props.color"
       class="bi bi-chevron-left"
       viewBox="0 0 16 16">
    <path fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  </svg>
</template>

<script>
export default {
  name: "ChevronLeft",
  props: {
    color: {
      type: String,
      required: false,
      default: '#0000000',
    },
    width: {
      type: Number,
      required: false,
      default: 16,
    },
    height: {
      type: Number,
      required: false,
      default: 16,
    }
  }
}
</script>

<style scoped>

</style>
