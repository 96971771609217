import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import moment from "moment";

export async function downloadZipByIds(ids, state) {
  const axios = axiosInstance().primoLab;

  await axios
    .post(
      "/assets/download",
      {
        asset_ids: ids,
        state: state,
      },
      {
        responseType: "blob",
      }
    )
    .then(async (response) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/x-zip" })
      );

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", moment().toDate().toLocaleDateString() + ".zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });
}
