<template>
  <div class="d-flex align-items-center justify-content-center">
    <img @click="focusSearch()"
         :id="`lent__${$props.name}`"
         src="@/assets/icons/search.svg"
         width="15"
         alt="lent">
    <template v-if="searchTemplate === false">
      <span class="ms-2">{{ $props.name }}</span>
    </template>
    <template v-else>
      <input
        type="text"
        :id="`searchBy__${$props.name}`"
        autocomplete="off"
        class="input-minimal ms-2"
        :style="{'width': `${width}em`}"
        v-model="searchTerm"
      />
    </template>
  </div>
</template>

<script>
import {nextTick, ref, watch} from 'vue';

export default {
  name: "InputSearchMinimal",
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    width: {
      type: Number,
      required: false,
      default: 7
    },
  },
  emits: [
    'termToSearch',
  ],
  setup(props, {emit}) {
    const searchTemplate = ref(false);
    const searchTerm = ref(null);

    const focusSearch = () => {
      searchTemplate.value = !searchTemplate.value;
      searchTerm.value = "";
      nextTick(() => {
        const input = document.getElementById( `searchBy__${props.name}`);
        if (input !== null) {
          input.focus();
        }
      });
    }

    watch(searchTerm, (value) => {
      emit('termToSearch', value);
    });

    return {
      searchTemplate,
      searchTerm,
      focusSearch,
    }
  }
}
</script>

<style scoped>
  .input-minimal,
  .input-minimal:focus {
    border-bottom: 1px solid black;
  }
  input[id^="searchBy__"] {
    height: 20px;
  }
  img[id^="lent__"]:hover {
    cursor: pointer;
  }
</style>
