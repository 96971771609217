import axios from "axios";
import getAuthToken from "@/use/get-auth-token";

const primoChatApi = axios.create({ baseURL: process.env.VUE_APP_API_CHAT_BASE_URL });

primoChatApi.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default primoChatApi;
