import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchShippingCodeById(clinicId) {
  const axios = axiosInstance().primoLab;
  let code = [];

  await axios
    .post("/clinics/random-shipping-code", {
      id: clinicId,
    })
    .then(async (response) => {
      code = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { code };
}
