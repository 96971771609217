<template>
  <div class="accordion">
    <div class="accordion-item">
      <h2 class="accordion-header" :id="`panelsStayOpen-heading-${$props.id}`">
        <button
          :id="`accordion-button-${$props.id}`"
          :class="`accordion-button ${$props.collapsed && 'collapsed'} ${$props.background} ${$props.showArrow || 'arrow-none'}`"
          data-bs-toggle="collapse"
          :data-bs-target="`#panelsStayOpen-collapse-${$props.id}`"
          aria-expanded="true"
          :aria-controls="`panelsStayOpen-collapse-${$props.id}`"
        >
          <slot name="checkbox"></slot>
          <slot name="header"></slot>
          <div
            class="actions d-flex align-items-center"
            @mouseleave="addAccordionToggle(`accordion-button-${$props.id}`)"
            @mouseover="removeAccordionToggle(`accordion-button-${$props.id}`)"
          >
            <slot name="action"></slot>
          </div>
        </button>
      </h2>
      <div
        :id="`panelsStayOpen-collapse-${$props.id}`"
        :class="`accordion-collapse collapse ${$props.collapsed || 'show'}`"
        :aria-labelledby="`panelsStayOpen-heading-${$props.id}`"
      >
        <div class="accordion-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    id: {
      type: [ String, Number ],
      required: true,
    },
    background: {
      type: String,
      default: 'dark-violet',
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const removeAccordionToggle = (id) => {
      document.getElementById(id).setAttribute("data-bs-toggle", " ");
    };
    const addAccordionToggle = (id) => {
      document.getElementById(id).setAttribute("data-bs-toggle", "collapse");
    };
    return {
      removeAccordionToggle,
      addAccordionToggle,
    };
  },
};
</script>

<style lang="scss">
@import "../../scss/abstracts/_variables.scss";

.accordion-button:focus {
  box-shadow: unset !important;
}
.accordion-button.orange:not(.collapsed) {
  color: $white !important;
  background-color: $orange !important;
}
.accordion-button.dark-violet:not(.collapsed) {
  color: $white !important;
  background-color: $dark_violet !important;
}
.accordion-button:not(.collapsed) svg {
  filter: invert(100%);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.arrow-none.accordion-button::after {
  background-image: initial !important;
  width: 0 !important;
  height: 0 !important;
}

.arrow-none.accordion-button:not(.collapsed)::after {
  background-image: initial !important;
  width: 0 !important;
  height: 0 !important;
}
</style>
