<script>
import { ref, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import debounce from "lodash/debounce";
import _head from "lodash/head";
import { error } from "@/use/toast/error";
import { success } from "@/use/toast/success";
import { index as fetchTag } from "@/use/repositories/devices/index.js";
import { attachDevice } from "@/use/repositories/prescriptionTests/attachDevice";
import { show as validate } from "@/use/repositories/devices/show.js"

export default {
  name: "InputDevice",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    prescriptionTestId: {
      required: true,
    }
  },
  emits: ['result', 'batteryAlert'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const tagCode = ref("");
    const input = ref(null)

    const onInput = debounce(async () => {
      if (tagCode.value.length < 4) { return }

      emit('batteryAlert', false);
      fetchTag( "by_name", tagCode.value, "name_term").then((response) => {

        if (! response.data.length) {
          tagCode.value = "";
          return error('Etichetta elettronica non trovata, riprovare');
        }

        const tagId = _head(response.data).id;

        validate(tagId).then(() => {
          attachDevice(props.prescriptionTestId, tagId).then(() => {
            success(i18n.t("Label associated correctly"));
            emit('result', true);
          })
        }).catch((errors) => {
          if (_head(errors) === "Device battery is low") {
            tagCode.value = "";
            emit('batteryAlert', true);
            return
          }
          return error(_head(errors));
        })
      });
    }, 1000);

    watch(() => props.reset, async () => {
      tagCode.value = "";
    });

    watch(() => props.disabled, async () => {
      await nextTick(() => {
        input.value.focus();
      });
    })

    return {
      tagCode,
      onInput,
      input
    };
  }
}
</script>

<template>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="tag_input">
        <img src="@/assets/icons/logistic-checkin/scanner-barcode.svg" width="30" alt="scanner-barcode" />
      </span>
    </div>
    <input v-model="tagCode" @input="onInput" :disabled="$props.disabled" ref="input" type="text" class="form-control"
      placeholder="Codice Tag" aria-label="Codice Tag" aria-describedby="basic-addon1" />
  </div>
</template>

