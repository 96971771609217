import axios from "axios";
import getAuthToken from "@/use/get-auth-token";
import store from "@/store";
import _head from "lodash/head";
import { useToast } from "vue-toastification";

const primoChatApi = axios.create({ baseURL: process.env.VUE_APP_API_CHAT_BASE_URL });

const toast = useToast();

  primoChatApi.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );


  primoChatApi.interceptors.request.use(
    (config) => {
      store.dispatch("toggleLoading", true);
      return config;
    },
    (error) => Promise.reject(error)
  );

  primoChatApi.interceptors.response.use(
    (response) => {
      store.dispatch("toggleLoading", false);
      return response;
    },
    (error) => {
      toast.error(_head(error?.response?.data?.errors) ?? "Si è verificato un errore. Contattare il servizio tecnico.");
      store.dispatch("toggleLoading", false);

      return Promise.reject(error);
    }
  );


export default primoChatApi;
