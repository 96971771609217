import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function attachPrescription(tagNumber, entityId, action) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .post(`/tag-requests/attach-prescription`, {
      tag_number: tagNumber,
      entity_id: entityId,
      action: action,
    })
    .then(async (resp) => {
      data = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
