<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">

      <Brand :width="120" :height="120" :withChat="false" />

      <div class="card col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-3 rounded-custom shadow border-0 p-3">
        <div class="card-body">

          <form @submit.prevent="submit" class="row g-3 needs-validation" novalidate>
            <h2 class="mb-3 fw-normal text-center">Login</h2>

            <div class="mb-3">
              <label for="email" class="form-label">{{ $t('Email address') }}</label>
              <input v-model="data.email" type="email"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'" placeholder="name@primolab.eu"
                required />
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input v-model="data.password" type="password"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'" required />
            </div>

            <div class="mb-3">
              <router-link to="/recover-password" href="#" class="link-dark">
                {{ $t('Reset password') }}
              </router-link>
            </div>

            <template v-if="errors.length > 0">
              <template v-for="(error, index) in errors" :key="index">
                <div class="alert alert-danger m-0 d-flex align-items-center" role="alert"
                  :class="{ 'mb-2': index !== errors.length }">
                  <small><strong class="me-1">Errore:</strong>{{ error }}</small>
                </div>
              </template>
            </template>

            <button class="w-100 btn btn-lg d-flex align-items-center justify-content-center btn-violet" type="submit"
              :disabled="loading">
              <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              <span v-if="loading">Loading...</span>
              <span v-else>{{ $t('Sign in') }}</span>
            </button>

          </form>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { departmentException } from "@/use/departmentException";
import { handleLogin } from "@/use/utilities/handleLogin.js"
import { login } from "@/use/repositories/auth/login.js";
import Brand from "@/components/Brand.vue";

export default {
  name: "Login",
  components: {
    Brand,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    let loading = ref(false);
    const errors = [];
    const data = reactive({
      email: "",
      password: "",
    });
    const setFocus = () => {
      let input = document.getElementById('email');
      input.focus();
    }
    onMounted(async () => {
        await store.dispatch("setUser", null);
        await store.dispatch("setMainRole", null);
    })

    const submit = async () => {
      loading.value = true;

      // Clear messages.
      errors.splice(0);

      login(data).then(async (response) => {
        // Set auth token in local storage.
        localStorage.setItem("authToken", response.data.meta.token);

        loading.value = false;

        await store.dispatch("setUser", response.data.data);

        await store.dispatch("setUserData", response.data.meta.roles);

        if (! departmentException(response.data.meta.roles, true)) {
          // Redirect to 2fa register
          if (response.data.data['2fa'] === null) {
            // Enable 2fa
            store.state.twoFactorAuth.enabled = true;
            // Set action
            store.state.twoFactorAuth.action = 'register';
          } else if (response.data.data['2fa'].google2fa_enable === 0) {
            // Enable 2fa
            store.state.twoFactorAuth.enabled = true;
            // Set action
            store.state.twoFactorAuth.action = 'confirm';
          } else if (response.data.data['2fa'].google2fa_enable === 1) {
            // Enable 2fa
            store.state.twoFactorAuth.enabled = true;
            // Set action
            store.state.twoFactorAuth.action = 'verify';
          }

          if (store.state.twoFactorAuth.enabled)
            return await router.push({
              name: "TwoFactorAuth",
              params: { type: store.state.twoFactorAuth.action }
            });
        }

        handleLogin();

      }).catch(async (error) => {
        // Set errors messages.
        let messages = error.response.data.errors;
        messages.forEach((message) => errors.push(message));
        setFocus();
      }).finally(() => {
        loading.value = false;
      });
    };

    return {
      data,
      submit,
      errors,
      loading,
    };
  },
};
</script>
