<template>
  <tr @click="selected($props.data)" :class="{ selected: $props.data.selected }">
    <td nowrap>{{ $props.data.number_text }}</td>
    <td nowrap>{{ $props.data.date }}</td>
    <td nowrap>{{ $props.data.technician }}</td>
    <td nowrap>{{ quantity }} pz.</td>
  </tr>
</template>

<script>
import { ref, watch, onMounted, toRaw } from "vue";
import _sumBy from "lodash/sumBy";

export default {
  name: "OrderPending",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const selected = (orderSelected) => {
      emit("selected", orderSelected);
    };

    const quantity = ref(0);

    watch(() => props.data.articles.length, () => {
        getQuantity();
      }, { deep: true }
    );

    const getQuantity = () => {
      quantity.value = _sumBy(toRaw(props.data.articles), "requested_quantity");
    };

    onMounted(() => {
      getQuantity();
    });

    return {
      selected,
      quantity,
    };
  },
};
</script>

<style scoped>
tr td {
  text-align: center;
  padding: 0.5em;
  border: 0;
  text-transform: uppercase;
}
.selected {
  background-color: #E1D2F2;
  border: 3px solid #7e6492;
}
tr:hover {
  cursor: pointer;
}
</style>
