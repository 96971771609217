import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function searchBy(keyword, action, page = 1) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let prescriptions = [];

  await axios
    .get("prescriptions/search", {
      params: {
        keyword: keyword.toUpperCase(),
        action,
        page,
      },
    })
    .then(async (response) => {
      prescriptions = response;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptions };
}
