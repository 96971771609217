<template>
  <div class="d-flex">
    <template v-if="shippingDays !== null">
      <strong class="fs-3">
        {{ shippingDays }}g
        <img src="@/assets/icons/clock-black.svg" width="40" height="40" alt="clock-black">
      </strong>
    </template>
    <template v-if="loading">
      <Spinner />
    </template>
  </div>
</template>

<script>
import moment from "moment";
import {calcWeekDays} from "@/use/utilities/time/calcWeekDays";
import {onMounted, ref} from "vue";
import { index as finishedByCustomerClinic } from "@/use/repositories/dates/index";
import Spinner from "@/components/general/Spinner.vue";
import _head from "lodash/head";

export default {
  name: "LastDate",
  props: {
    clinicId: {
      type: Number,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  components: {
    Spinner
  },
  setup(props) {
    const shippingDays = ref(null);
    const loading = ref(false);

    onMounted(() => {
      loading.value = true;
      finishedByCustomerClinic({
        action: "with_finished_by_customer_clinic",
        customer_id: props.customerId,
        clinic_id: props.clinicId
      }).then((response) => {
        if (response.dates.length === 0) return;
        console.log(`LAST DATE AVAILABLE: ${_head(response.dates).start_at}`);
        const formattedDate = moment(_head(response.dates).start_at, "YYYY-MM-DD HH:mm:ss");
        shippingDays.value = calcWeekDays(formattedDate);
        console.log(shippingDays.value);
      }).finally(() => {
        loading.value = false;
      });
    });

    return {
      shippingDays,
      loading
    }
  }
}
</script>
