import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function signedUrl(uuid) {
  const axios = axiosInstance().primoLab;
  let url = "";

  await axios
    .get("/media/signed-url", {
      params: {
        uuid: uuid,
      },
    })
    .then(async (response) => {
      url = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { url };
}
