<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="d-flex p-4" style="position: relative;">
      <div id="blob">
        <img src="@/assets/icons/blob.svg" height="200" alt="blob">
      </div>
      <div class="d-flex justify-content-center align-items-center customer flex-column">
        <img src="@/assets/icons/paziente.svg" height="90" alt="customer">
        <p class="p-1">{{ $props.firstName }} {{ $props.lastName }}</p>
      </div>
    </div>
    <div class="d-flex mb-2">
      <input
        type="text"
        id="prescription"
        autocomplete="off"
        class="form-control rounded-custom"
        placeholder="Cerca"
        v-model="searchTerm"
      />
    </div>
  </div>

  <vue-table-lite
    :is-static-mode="true"
    :columns="table.columns"
    @is-finished="tableLoadingFinish"
    :is-loading="table.isLoading"
    :rows="table.rows"
    :total="table.totalRecordCount"
    :sortable="table.sortable"
    :messages="table.messages"
  >
  </vue-table-lite>

  <Modal v-if="showModal"
         :data="scene"
         @hidden="closeModal">
  </Modal>

</template>

<script>
import {defineComponent, reactive, ref, computed, onMounted} from "vue";
import moment from "moment";
import VueTableLite from "vue3-table-lite";
import _ from "lodash";
import { fetchTemporaryUrl } from "@/use/repositories/assets/fetchTemporaryUrl";
import Modal from '@/components/Scene/Modal';

export default defineComponent({
  name: "LastAssets",
  props: {
    data: {
      type: Object,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
  components: {
    VueTableLite,
    Modal,
  },
  setup(props) {
    const searchTerm = ref(""); // Search text
    const result = reactive([]);
    let dataGrouped = [];
    const showModal = ref(false);
    const scene = reactive({
      id: null,
      modal_id: null,
      title: null,
      subtitle: null,
      decline_label: null,
      temporary_url: null,
    });

    onMounted(() => {
      // Grouped by test work queue id.
      dataGrouped = _.chain(_.orderBy(props.data, 'id', 'desc'))
        .groupBy('test_work_queue_phase_id')
        .map((value, key) => ({ test_work_queue_phase_id: key, teeth: value }))
        .value();

      // Grouped by bridge.
      dataGrouped.forEach((obj) => {
        obj.teeth = _.chain(obj.teeth)
          .groupBy('bridge')
          .map((value, key) => ({ bridge: key, teeth: value }))
          .value();
      });

      // Create obj for table.
      dataGrouped.forEach((item) => {
        findNumbetToothByApplication(item.teeth);
      });
    });

    const findNumbetToothByApplication = (teeth) => {
      teeth.forEach((el) => {

        if (el.bridge === 'null') {
          findNumberTeethByPrescriptionRow(_.head(el.teeth));

        } else {
          // Order bridge by zone number.
          let sortedTeeth = _.sortBy(el.teeth, 'zone_nunmber');

          let firstEl = _.head(sortedTeeth);
          let lastEl = _.last(sortedTeeth);
          let element = `PONTE D${firstEl.zone_number}/D${lastEl.zone_number}`;

          let tooth = _.head(sortedTeeth);

          result.push({
            id: tooth.asset_id,
            date: moment(tooth.created_at).format("MM/DD/YYYY"),
            prescription: tooth.number_text,
            element: element,
            material: tooth.material,
          });
        }
      });

      return result;
    }

    const findNumberTeethByPrescriptionRow = (tooth) => {
      let label = null;

      const customApplications = ['emiarcata', 'sestante'];

      if (customApplications.includes(tooth.application.toLowerCase())) {
        if (tooth.application.toLowerCase() === 'emiarcata') {
          switch (tooth.zone_number) {
            case 1:
              label = 'D11/18';
              break;
            case 2:
              label = 'D21/28';
              break;
            case 3:
              label = 'D31/38';
              break;
            case 4:
              label = 'D41/48';
              break;
          }
        }
        if (tooth.application.toLowerCase() === 'sestante') {
          switch (tooth.zone_number) {
            case 1:
              label = 'D14/18';
              break;
            case 2:
              label = 'D11/13 - D21/23';
              break;
            case 3:
              label = 'D24/28';
              break;
            case 4:
              label = 'D24/38';
              break;
            case 5:
              label = 'D31/33 - D41/43';
              break;
            case 6:
              label = 'D44/48';
              break;
          }
        }

      } else if (tooth.application.toLowerCase() === 'dente') {
        label = `D${tooth.zone_number}`;

      } else {
        label = tooth.application + ' ' + tooth.zone_number;
      }

      result.push({
        id: tooth.asset_id,
        date: moment(tooth.created_at).format("MM/DD/YYYY"),
        prescription: tooth.number_text,
        element: label,
        material: tooth.material,
      });

    }

    // Table config
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Data",
          field: "date",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Prescrizione",
          field: "prescription",
          width: "10%",
        },
        {
          label: "Elemento",
          field: "element",
          width: "15%",
        },
        {
          label: "Materiale",
          field: "material",
          width: "15%",
        },
        {
          label: "",
          field: "",
          width: "15%",
          sortable: false,
          display: function (row) {
            return (
              `<a href="javascript:void(0)" class="is-rows-el preview me-4" style="text-decoration-line: none;" data-id="` + row.id + `">
                <svg xmlns="http://www.w3.org/2000/svg" height="35" fill="#A58ABA" class="bi bi-badge-3d-fill" viewBox="0 0 16 16">
                    <path d="M10.157 5.968h-.844v4.06h.844c1.116 0 1.621-.667 1.621-2.02 0-1.354-.51-2.04-1.621-2.04z"/>
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm5.184 4.368c.646 0 1.055.378 1.06.9.008.537-.427.919-1.086.919-.598-.004-1.037-.325-1.068-.756H3c.03.914.791 1.688 2.153 1.688 1.24 0 2.285-.66 2.272-1.798-.013-.953-.747-1.38-1.292-1.432v-.062c.44-.07 1.125-.527 1.108-1.375-.013-.906-.8-1.57-2.053-1.565-1.31.005-2.043.734-2.074 1.67h1.103c.022-.391.383-.751.936-.751.532 0 .928.33.928.813.004.479-.383.835-.928.835h-.632v.914h.663zM8.126 11h2.189C12.125 11 13 9.893 13 7.985c0-1.894-.861-2.984-2.685-2.984H8.126V11z"/>
                </svg>
              </a>
              <a href="javascript:void(0)" class="is-rows-el download" style="text-decoration-line: none;" data-id="` + row.id + `">
                <svg xmlns="http://www.w3.org/2000/svg" height="35" fill="#A58ABA" class="bi bi-cloud-download" viewBox="0 0 16 16">
                  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                </svg>
              </a>`
            );
          },
        },
      ],
      rows: computed(() => {
        return result.filter(
          (field) =>
            field.date.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            field.prescription.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            field.element.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            field.material.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
            field.id.includes(searchTerm.value.toLowerCase())
        );
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
      messages: {
        pagingInfo: "Mostra {0}-{1} di {2}",
        pageSizeChangeLabel: "Numero righe:",
        gotoPageLabel: "Vai alla pagina:",
        noDataAvailable: "Nessun record trovato",
      },
    });

    // Actions
    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains("preview")) {
          element.addEventListener("click", function () {
            show3D(this.dataset.id);
          });
        }
        if (element.classList.contains("download")) {
          element.addEventListener("click", function () {
            download3D(this.dataset.id);
          });
        }
      });
    };

    const show3D = async (assetId) => {
      // Set data for modal.
      scene.id = assetId;
      scene.modal_id = assetId;
      scene.decline_label = "chiudi";

      // Fetch temporary url.
      await fetchTemporaryUrl(assetId).then(async (response) => {
        scene.temporary_url = response.temporary_url;
        showModal.value = true;
      });
    }

    const closeModal = async (value) => {
      // Reset data modal.
      scene.show = null;
      scene.id = null;
      scene.decline_label = null;
      scene.temporary_url = null;

      showModal.value = value;
    }

    const download3D = async(assetId) => {
      await fetchTemporaryUrl(assetId).then(async (response) => {
        window.open(response.temporary_url, "_blank");
      });
    }

    return {
      searchTerm,
      table,
      result,
      tableLoadingFinish,
      showModal,
      scene,
      closeModal,
    };

  },
});

</script>

<style scoped>
#blob {
  position: absolute;
  z-index: 1;
  transform: rotate(368deg);
  top: -12%;
  left: 2%;
}
.customer {
  z-index: 2;
}
.card {
  border-top: none;
}

/* Change style table */
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #F3E9FF;
  border-color: #F3E9FF;
}
::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9D9D9D;
  vertical-align: middle;
}
::v-deep(.vtl-paging-info),
::v-deep(.vtl-paging-pagination-div),
::v-deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7E6492;
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: #7E6492;
}
::v-deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>
