import { createStore } from "vuex";
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  state: {
    loading: false,
    user: null,
    // Pusher.
    IsConnected: false,
    // Chat.
    badge: false,
    // Plaster.
    orderSlots: [],
    // Dashboard.
    clipboardDashboardTechnician: "",
    // 3D Viewer
    showModelInfo: false,
    // File loading.
    fileURL: "",
    fileType: "",
    // Loaded model information.
    modelInfo: {},
    // Manager notes.
    managerNotesHaveChanged: false,
    // 2FA
    twoFactorAuth: {
      enabled: false,
      action: 'verify',
    },
  },
  mutations: {
    SET_USER_DEPARTMENTS: (state, departments) => (state.user.departments = departments),
    SET_USER_ROLES: (state, roles) => (state.user.roles = roles),
    SET_USER_DATA: (state, userData) => (state.userData = userData),
    SET_MAIN_ROLE: (state, role) => (state.mainRole = role),
    SET_USER: (state, user) => (state.user = user),
    IS_LOADING: (state, isLoading) => (state.loading = isLoading),

    // Pusher.
    SET_PUSHER_IS_CONNECTED: (state, IsConnected) => (state.IsConnected = IsConnected),

    // Chat.
    SET_BADGE: (state, badge) => (state.badge = badge),
    SET_USER_PERSONAL_MESSAGES_UNREAD: (state, userPersonalMessagesUnread) => (state.userPersonalMessagesUnread = userPersonalMessagesUnread),
    INCREASE_PERSONAL_MESSAGES_COUNT: (state, amount) =>  (state.userPersonalMessagesUnread = (state.userPersonalMessagesUnread + amount)),
    DECREASE_PERSONAL_MESSAGES_COUNT: (state, amount) => (state.userPersonalMessagesUnread = (state.userPersonalMessagesUnread - amount)),

    // Manager notes.
    SET_MANAGER_NOTES_HAVE_CHANGED: (state, managerNotesHaveChanged) => (state.managerNotesHaveChanged = managerNotesHaveChanged),

    // Plaster.
    SET_ORDER_SLOTS: (state, orderSlots) => (state.orderSlots = orderSlots),

    // Dashboard.
    SET_CLIPBOARD_DASHBOARD_TECHNICIAN: (state, clipboardDashboardTechnician) => (state.clipboardDashboardTechnician = clipboardDashboardTechnician),

    SET: (state, { key, value }) => (state[key] = value),
    SAVE_FILE_DATA: (state, { fileURL, fileType }) => {
      state.fileURL = fileURL;
      state.fileType = fileType;
    },
    IS_ISO_OUT_FORCEMENT_ACTIVE(state, isActive) {
      state.mainRole = {
        ...state.mainRole,
        isISoOutForcementActive: isActive,
      };
    }
  },
  actions: {
    setUserDepartments: ({ commit }, departments) => commit("SET_USER_DEPARTMENTS", departments),
    setUserRoles: ({ commit }, roles) => commit("SET_USER_ROLES", roles),
    setUserData: ({ commit }, userData) => commit("SET_USER_DATA", userData),
    setMainRole: ({ commit }, role) => commit("SET_MAIN_ROLE", role),
    setUser: ({ commit }, user) => commit("SET_USER", user),

    // Pusher.
    setIsConnected: ({ commit }, IsConnected) => commit("SET_PUSHER_IS_CONNECTED", IsConnected),

    // Chat.
    setBadge: ({ commit }, badge) => commit("SET_BADGE", badge),
    setUserPersonalMessagesUnread: ({ commit }, userPersonalMessagesUnread) => commit("SET_USER_PERSONAL_MESSAGES_UNREAD", userPersonalMessagesUnread),
    increasePersonalMessagesCount: ({ commit }, amount) => commit("INCREASE_PERSONAL_MESSAGES_COUNT", amount),
    decreasePersonalMessagesCount: ({ commit }, amount) => commit("DECREASE_PERSONAL_MESSAGES_COUNT", amount),

    // Manager notes.
    setManagerNotesHaveChanged: ({ commit }, managerNotesHaveChanged) => commit("SET_MANAGER_NOTES_HAVE_CHANGED", managerNotesHaveChanged),

    // Plaster.
    setOrderSlots: ({ commit }, orderSlots) => commit("SET_ORDER_SLOTS", orderSlots),

    // Dashboard.
    setClipboardDashboardTechnician: ({ commit }, clipboardDashboardTechnician) => commit("SET_CLIPBOARD_DASHBOARD_TECHNICIAN", clipboardDashboardTechnician),

    toggleLoading: ({ commit }, isLoading) => commit("IS_LOADING", isLoading),
    saveFileData({ commit }, fileData) {
      console.log("~~~ Saving file data to global state...");
      commit("SAVE_FILE_DATA", fileData);
    },
  },
  modules: {},
  plugins: [vuexLocalStorage.plugin]
});
