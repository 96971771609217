import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {redirectTo} from "@/use/repositories/redirectTo";
import router from "../../../router";
import store from "../../../store";

export async function updateMainDepartment(teamId, roleId) {
  const axios = axiosInstance().primoLab;

  await axios
    .put("/users", {
      action: 'update_main_team',
      team_id: teamId,
      role_id: roleId,
    })
    .then(async () => {
      // Set main role on vuex.
      await store.dispatch("setMainRole", store.state.userData.find(item => item.role_id === roleId && item.team_id === teamId));

      redirectTo(store.state.user.roles, store.state.user.departments, store).then(async (response) => {
        await router.push({
          name: response.routeName,
        });
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

}
