<script>
import { provide, ref } from 'vue';
import StepNavigation from '@/components/LoginWizard/StepNavigation.vue';
import Step from '@/components/LoginWizard/Step.vue';
import _find from 'lodash/find';

export default {
  name: 'AppWizard',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  components: { StepNavigation, Step },
  setup(props) {
    const currentStep = ref(0);

    const stepChanged = (step) => {
      if (_find(props.steps, item => item.completed == true)) return
      currentStep.value = step;
    }

    provide('currentStep', currentStep);
    provide('steps', props.steps);

    return { currentStep, stepChanged }
  }
}
</script>

<template>
  <div class="container rounded-custom wizard">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <StepNavigation />
        <template v-for="(step, index) in steps" :key="index">
          <slot v-if="currentStep === index"  :name="`step-${index}`"></slot>
        </template>
      <Step  :stepCount="3" :currentStep="currentStep" @step-change="stepChanged" @submit="$emit('submit')" :disable="$props.disable"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    @import "../../scss/components/_wizard.scss";
</style>
