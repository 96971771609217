import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function fetchById(prescriptionId, action, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let prescription = [];

  await axios
    .get(`/prescriptions/${prescriptionId}`, {
      params: {
        action: action,
      }
    })
    .then(async (response) => {
      prescription = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescription };
}
