<template>
  <button @click="$router.go(-1)" class="btn rounded-btn-custom shadow bg-violet text-white">
    <div class="d-flex align-items-center">
      <img src="@/assets/icons/arrow-left.svg" width="35" alt="arrow-left">
      <span class="fw-bold text-uppercase">{{ $props.label }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "ButtonBack",
  props: {
    label: {
      type: String,
      require: true,
    }
  }
}
</script>
