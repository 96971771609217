import store from "../store/index"

export async function getUserData () {

  const userData = store.state.userData.reduce((acc, role) => {
    acc.departments.push({
        ...role.team.teamable,
        team_id: role.team.id,
        role_id: role.role.id,
        role_slug: role.role.slug
    });
    //if (!acc.roles.map(item => item.role.slug).includes(role.role.slug)) acc.roles.push(role);
    if (! acc.roles.includes(role.role.slug)) acc.roles.push(role.role.slug);
    return acc;
  }, { 'roles': [], 'departments': [] });

  return userData ;
};
