import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function update(ids, state) {
  const axios = axiosInstance().primoLab;
  let assets = [];

  await axios
    .put("/assets", {
      asset_ids: ids,
      state: state,
    })
    .then(async (response) => {
      assets = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { assets };
}
