<template>

    <draggable
        class="d-flex"
        :component-data="{
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        :list="list"
        item-key="id">

      <template #item="{ element }">
        <Card
          :box="element"
          :selected="$props.idsTwqpSelected.includes(element.id)"
          @twqp_selected="passEmit"
        />
      </template>

    </draggable>

</template>

<script>
import {ref, reactive, computed, watch} from "vue";

import draggable from "vuedraggable";

import Card from "@/components/plaster/Card";
import {useStore} from "vuex";

export default {
  name: "Drag",
  display: "Transitions",
  props: [
    'elements',
    'idsTwqpSelected',
    'showOnlySelected',
  ],
  emits: [
    'twqp_selected',
    'showOnlySelected',
  ],
  components: {
    draggable,
    Card
  },
  setup(props, { emit }) {
    const drag = ref(false);
    const store = useStore();

    watch(() => props.elements, () => {
      const ids = [];
      props.elements.forEach((el) => { ids.push(el.id) });
      store.dispatch("setOrderSlots", ids);
    }, { deep: true });

    const list = computed(() => {
      if (props.showOnlySelected && props.idsTwqpSelected.length > 0) {
        const filtered = props.elements.filter(function (item) {
            return props.idsTwqpSelected.includes(item.id);
          });
        return reactive(filtered);
      }
      emit('showOnlySelected', false);
      return reactive(props.elements);
    })

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    });

    const passEmit = (value) => {
      emit('twqp_selected', value)
    };

    return {
      list,
      drag,
      dragOptions,
      passEmit,
    }

  }
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}
</style>
