<template>
  <div id="container-color-scale" class="d-flex flex-column position-relative">
    <template v-for="(item, index) in $props.data.colorScale" :key="index">
      <div class="d-flex color-scale align-items-center justify-content-between p-3"
          :class="{'select': item.selected}"
          @click="toggleColorSelected(index)">
        <span class="d-flex color-name text-violet">
          {{ getLabelsNameByAsset(item.name) }}
        </span>
        <div class="d-flex counts" style="width: max-content;">
          <span class="d-flex text-violet mx-2">
            {{ getCountToWorks(item.toWorkBeforeDayGroupedByColor.works, item.toWorkInTheDayGroupedByColor.works) }}
          </span>
          <span class="d-flex" style="color: darkgray;">|
            {{ getCountFutureWorks(item.toWorkGroupedByColor.works) }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {ref, watch} from "vue";
import _head from "lodash/head";
import {getLabelsNameByAsset} from '@/use/utilities/getLabelsNameByAsset';

export default {
  name: "ColorScale",
  props: [
    'data',
  ],
  emits: [
    'colorSelected',
  ],
  setup(props, {emit}) {
    const selected = ref(false);
    const assetsCount = (array) => {
      let count = 0;
      Object.values(array).forEach((el) => {
        el.forEach((el) => {
          count += el.assets.length;
        })
      });

      return count;
    }

    const getCountToWorks = (beforeDay, inTheDay) => {
      return assetsCount(beforeDay) + assetsCount(inTheDay);
    }

    const getCountFutureWorks = (future) => {
      return assetsCount(future);
    }

    watch(() => props.data.colorScale, () => {
      if (!props.data.colorScale.some((el) => el.selected === true)) {
        // Pass at day component default data selected.
        emit('colorSelected', _head(props.data.colorScale));
      } else {
        let index = props.data.colorScale.findIndex((el) => el.selected === true);
        emit('colorSelected', props.data.colorScale[index]);
      }
    });

    const toggleColorSelected = (indexSelected) => {
      props.data.colorScale.forEach((el, index) => {
        if (index === indexSelected) {
          if (!el.selected) {
            el.selected = true;
            selected.value = true;
            emit('colorSelected', el);
          }
        } else {
          el.selected = false;
          selected.value = false;
        }
      });
    }

    return {
      toggleColorSelected,
      getCountToWorks,
      getCountFutureWorks,
      getLabelsNameByAsset,
    }
  }
}
</script>

<style scoped>
  #container-color-scale {
    background-color: #F2F2F2;
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    width: 18em;
  }
  .color-name {
    font-size: 1.8em;
  }
  .counts {
    font-size: 1.3em;
  }
  .color-scale:hover {
    cursor: pointer;
  }
  .select {
    background-color: white;
    box-shadow: 0 4px 2px -2px #9D9D9D;
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
</style>
