export function getFileNameIconModelBySlug(slug) {
  switch (slug) {
    case 'lower-arch':
      return 'arch-inf';
    case 'upper-arch':
      return 'arch-sup';
  }

  return slug;
}

