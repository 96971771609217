export function formatFilename(file) {
  // Get filename.
  const [fileName, extension] = parseFileName(file.name);

  return `${fileName}_${new Date().getTime()}.${extension}`;
}

const parseFileName = (filename) => {
  let file = filename.split('/').pop();
  return [
    file.substr(0, file.lastIndexOf('.')),
    file.substr(file.lastIndexOf('.') + 1, file.length),
  ];
}
