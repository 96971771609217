<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center position-relative">
      <Logout class="position-absolute start-0"/>
      <brand :width="120" :height="120" />
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <template v-for="(logisticsSubDepartment, index) in logisticsSubDepartments">
        <div v-if="!(logisticsSubDepartment.name === 'Insacchettamento' && !hasWebcam)" :key="index" class="d-flex card rounded-custom shadow m-4"
          @click="redirectTo(logisticsSubDepartment.route, logisticsSubDepartment.route_type)">
          <div class="card-body align-items-center justify-content-center d-flex flex-column">
            <h3 class="text-violet text-uppercase text-center mb-4">{{ logisticsSubDepartment.name }}</h3>
            <img :src="require(`@/assets/icons/${logisticsSubDepartment.svg}`)" width="120" alt="scan">
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from "vue-router";
import { detectWebcam } from "@/use/utilities/detectWebcam";
import { logisticsSubDepartments } from "@/use/array/logisticsSubDepartments.js";

import Brand from '@/components/Brand';
import Logout from "@/components/Logout";

export default {
  name: "Logistica",
  components: {
    Brand,
    Logout,
  },
  setup() {
    const hasWebcam = ref(detectWebcam());
    const router = useRouter();

    const redirectTo = async (routeName, type = null) => {
      if (type !== null) {
        return await router.push({
          name: routeName,
          params: {
            type: type,
          },
        });
      }

      await router.push({
        name: routeName,
      });
    }

    return {
      hasWebcam,
      redirectTo,
      logisticsSubDepartments,
    }
  }
}
</script>

<style scoped>
.card {
  width: 20em;
  height: 20em;
  transition: .3s;
}
.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}
</style>
