import _ from 'lodash';

export function groupedByBridgesAndTooth(prescriptionRows) {

  let bridges = [];
  let teethHasBridge = [];
  let tooth = [];

  prescriptionRows.forEach((el) => {
    if (el.bridge !== null) {
      // Bridge.
      teethHasBridge.push(el);
    } else {
      // Tooth.
      tooth.push(el);
    }
  });

  if (teethHasBridge.length) {
    // Order by id desc and grouped by bridge.
    bridges = _.chain(_.orderBy(teethHasBridge, 'zone_number'))
      .groupBy('bridge')
      .map((value, key) => ({bridge: key, tooth: value}))
      .value();
  }

  tooth = _.sortBy(tooth, 'zone_number');

  return {
    bridges,
    tooth,
  }
}


