export const logisticsSubDepartments = [
  {
    name: "Check-in",
    route: "CheckinLogistica",
    route_type: null,
    svg: "scan.svg"
  },
  {
    name: "Prove di lavoro",
    route: "Tests",
    route_type: null,
    svg: "scan-done.svg"
  },
  {
    name: "Insacchettamento",
    route: "CheckoutLogistica",
    route_type: "bagging",
    svg: "bag.svg"
  },
  {
    name: "Inscatolamento",
    route: "CheckoutLogistica",
    route_type: "packaging",
    svg: "box-seam.svg",
  },
  {
    name: "Magazzino",
    route: "WarehouseDashboard",
    route_type: null,
    svg: "warehouse.svg",
  },
  {
    name: "Consultazione",
    route: "ConsultingSearch",
    route_type: null,
    svg: "search-violet.svg",
  },
  {
    name: "Lavorazioni esterne",
    route: "ExternalSummary",
    route_type: null,
    svg: "van.svg",
  },
  {
    name: "Approvazione richieste",
    route: "RejectedApproval",
    route_type: null,
    svg: "approval.svg"
  },
  {
    name: "Etichette digitali",
    route: "DigitalLabels",
    route_type: null,
    svg: "digital-label.svg",
  },
  {
    name: "Documenti di trasporto",
    route: "ShippingCodes",
    route_type: null,
    svg: "shipping-code.svg",
  },
];
