<template>

  <div class="row vh-100 pb-5">

    <div class="d-flex px-5 justify-content-center align-items-center h-20">
      <brand :width="200" :height="200" />
    </div>

    <input type="text"
           id="prescription"
           autocomplete="off"
           v-model="prescriptionCode"
           @input="onInput"
           style="opacity: 0;"
    />

    <div class="d-flex px-5 flex-column h-80">
      <div class="bg-violet text-white text-center p-3 text-uppercase rounded-only-top" colspan="5">
        <h3 class="m-0">Elenco lavorazioni esterne</h3>
      </div>
      <div class="card shadow rounded-only-bottom table-responsive h-100">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">colore casella</th>
            <th scope="col">laboratorio</th>
            <th scope="col">data di prioprità</th>
            <th scope="col">numero prescrizione</th>
            <th scope="col">paziente</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="testWorkQueuePhases.length">
            <tr v-for="(testWorkQueue, index) in testWorkQueuePhases" :key="index" @click="tapRow(testWorkQueue.number_text)">
              <td>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="bi bi-inbox" viewBox="0 0 16 16">
                  <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
                </svg>
              </td>
              <td>{{ testWorkQueue.laboratory_name }}</td>
              <td>{{ testWorkQueue.delivery_date }}</td>
              <td>{{ testWorkQueue.number_text }}</td>
              <td>{{ testWorkQueue.customer.first_name }} {{ testWorkQueue.customer.last_name }}</td>
            </tr>
          </template>
          </tbody>
        </table>
        <template v-if="testWorkQueuePhases.length === 0">
          <div class="d-flex w-100 h-100 justify-content-center align-items-center p-5">
            <h3>Nessuna lavorazione presente</h3>
          </div>
        </template>
      </div>
    </div>

  </div>

  <Confirm v-if="showConfirmModal"
           :modal_id="'modalConfirmToGoBack'"
           :title="`La lavorazione con codice prescrizione ${prescriptionCode} del laboratorio ${laboratoryName} sta per essere reimmessa nel flusso di lavoro`"
           :subtitle="'Sei sicuro di voler procedere?'"
           :confirm_label="'Si'"
           :decline_label="'No'"
           @hidden="hideModal"
           @confirm="toGoBack"
           @decline="hideModal">
  </Confirm>

</template>

<script>
import {ref, toRaw} from 'vue';
import {onMounted} from "vue";
import { useToast } from "vue-toastification";
import debounce from "lodash/debounce";
import moment from 'moment';

import Confirm from "@/components/Modal/Confirm";
import Brand from '@/components/Brand';

import {fetchAll as fetchAllExternalWorks} from '@/use/repositories/external/fetchAll';
import {update as updateTestWorkQueuePhase} from '@/use/repositories/testWorkQueuePhase/update';
import _ from "lodash";

export default {
  name: "Summary",
  components: {
    Brand,
    Confirm,
  },
  setup() {
    const showConfirmModal = ref(false);
    const testWorkQueuePhases = ref([]);
    const prescriptionCode = ref("");
    const laboratoryName = ref("");
    const testWorkQueuePhaseId = ref(null);
    const toast = useToast();

    onMounted(() => {
      fetchAllExternalWorks('with_test_work_queue_phase_prescription').then((response) => {
        filterData(response.vendors.users);
      });
    });

    const filterData = (users) => {
      users.forEach((user) => {
        user.test_work_queue_phases.forEach((testWorkQueuePhase) => {
          testWorkQueuePhases.value.push({
            laboratory_id: user.id,
            laboratory_name: user.last_name,
            test_work_queue_phase_id: testWorkQueuePhase.id,
            delivery_date: moment(testWorkQueuePhase.test_work_queue.delivery_date).format("YYYY-MM-DD hh:mm"),
            number_text: testWorkQueuePhase.test_work_queue.prescription.number_text,
            customer: testWorkQueuePhase.test_work_queue.prescription.customer,
          });
        });
      });
      // Order by delivery date.
      testWorkQueuePhases.value = _.orderBy(toRaw(testWorkQueuePhases.value), 'delivery_date');
    }

    const onInput = debounce(async () => {
      await findPresciptionCode();
    }, 1000);

    const tapRow = (code) => {
      prescriptionCode.value = code;
      findPresciptionCode();
    }

    const findPresciptionCode = async () => {
      let testWorkQueuePhaseSelected = testWorkQueuePhases.value.find((testWorkQueuePhase) => testWorkQueuePhase.number_text.toLowerCase() === prescriptionCode.value.toLowerCase());

      if (testWorkQueuePhaseSelected !== undefined) {
        laboratoryName.value = testWorkQueuePhaseSelected.laboratory_name;
        testWorkQueuePhaseId.value = testWorkQueuePhaseSelected.test_work_queue_phase_id;
        showConfirmModal.value = true;
        return;
      }

      await prescriptionCodeNotFound();
      await reset();
    }

    const reset = async () => {
      // Reset input value.
      prescriptionCode.value = "";
      // Reset laboratory name.
      laboratoryName.value = "";
    }

    const removeCompleted = async () => {
      let index = testWorkQueuePhases.value.findIndex((testWorkQueuePhase) => testWorkQueuePhase.test_work_queue_phase_id === testWorkQueuePhaseId.value);

      if (index !== -1) {
        testWorkQueuePhases.value.splice(index, 1);
        testWorkQueuePhaseId.value = null;
      }
    }

    const toGoBack = () => {
      updateTestWorkQueuePhase({
        source_ids: [testWorkQueuePhaseId.value],
        state: "completed",
        action: "update",
      }).then(async () => {
        await removeCompleted();
        await goToBackCompleted();
        await reset();
      });
    }

    const goToBackCompleted = async () => {
      toast.success("Lavorazione reinserita con successo!", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }

    const prescriptionCodeNotFound = async () => {
      toast.warning("La lavorazione non è presente!", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }

    const hideModal = (value) => {
      showConfirmModal.value = value;
      reset();
    }

    return {
      showConfirmModal,
      hideModal,
      toGoBack,
      testWorkQueuePhases,
      prescriptionCode,
      onInput,
      laboratoryName,
      findPresciptionCode,
      tapRow,
    }
  }
}
</script>

<style scoped>
.table-responsive thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #E9E9E9;
}
tr td:hover {
  cursor: pointer;
}
tr td,
tr th {
  text-align: center;
  vertical-align: middle;
}
tr th {
  text-transform: uppercase;
}
</style>
