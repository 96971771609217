import {groupedByBridgesAndTooth} from "./groupedByBridgesAndTooth";

export function getNumberElementsByPrescriptionRows(prescriptionRows) {

  let dataGrouped = groupedByBridgesAndTooth(prescriptionRows);
  let elementsCount = 0;

  if (dataGrouped.bridges.length) {
    let bridgesCountSingleElement = 0;
    dataGrouped.bridges.forEach((bridge) => {
      bridgesCountSingleElement += bridge.tooth.length;
    });

    elementsCount = bridgesCountSingleElement;
  }

  if (dataGrouped.tooth.length) {

    dataGrouped.tooth.forEach((teeth) => {
      let toFind = teeth.application.toLowerCase();

      if (teeth.application.toLowerCase() !== "dente" && teeth.application.toLowerCase() !== "bocca") {
        toFind += teeth.zone_number.toLowerCase();
      }

      switch (toFind) {
        case "dente":
          elementsCount += 1;
          break;

        case "bocca":
          elementsCount += 32;
          break;

        case "arcatainf":
        case "arcatasup":
        case "arcatadown":
        case "arcataup":
          elementsCount += 16;
          break;

        case "emiarcata1":
          elementsCount += 7;
          break;

        case "emiarcata2":
          elementsCount += 7;
          break;

        case "emiarcata3":
          elementsCount += 7;
          break;

        case "emiarcata4":
          elementsCount += 7;
          break;

        case "sestante1":
          elementsCount += 4;
          break;

        case "sestante2":
          elementsCount += 6;
          break;

        case "sestante3":
          elementsCount += 4;
          break;

        case "sestante4":
          elementsCount += 4;
          break;

        case "sestante5":
          elementsCount += 6;
          break;

        case "sestante6":
          elementsCount += 4;
          break;

        // Single Tooth.
        default:
          elementsCount += 1;
          break;
      }
    });
  }

  return elementsCount;
}
