import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader";

/**
 * @param {object} params -
  * action: 'with_base_64_labels', ids: []
  * action: 'checkout', shipping_provider_id: int
  * action: "get_all", type: 'analog'/'digital', checked_in_at: date
  * action: 'digital_with_prints', page: int
 * @param {boolean} loading
 */
export async function index(params, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let testWorkQueue = [];

  await axios
    .get(`/test-work-queues`, {params})
    .then(async (response) => {
      testWorkQueue = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueue };
}
