<template>

  <div class="d-flex flex-column">

    <template v-if="$props.label !== null">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span :class="{'text-violet': $props.data.selected }" style="color: lightgray;">{{ $props.label }}</span>
        <img v-if="!$props.readonly" @click.stop="removeBridge" class="remove" v-show="$props.data.selected" src="@/assets/icons/close.svg" width="25" height="25" alt="close">
      </div>
    </template>
    <img  v-if="! $props.path.includes('teeth')"
      class="icon"
      :class="{'mt-4': $props.label == null}"
      :src="require(`@/${imgPath()}`)"
      :width="$props.width"
      :height="$props.height">
    <Teeth v-else
      :number="$props.data.zone_number"
      :selected="$props.data.selected"
      :width="$props.width"
      :height="$props.height"
      :class="{'mt-4': $props.label == null}"
      class="icon" />
  </div>

</template>

<script>
import { ref } from 'vue';
import Teeth from '@/components/Project/Teeth';
export default {
  name: "icon",
  components: {
    Teeth
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    pathSelected: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: false
    },
    ids: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'remove',
  ],
  setup(props, {emit}) {
    const remove = ref([]);

    const imgPath = () => props.data.selected ? props.pathSelected : props.path;

    const removeBridge = () => {
      remove.value = props.ids;
      emit('remove', remove);
    }

    return {
      removeBridge,
      imgPath,
    }
  }
}
</script>

<style scoped>
  .icon:hover,
  .remove:hover {
    cursor: pointer;
  }
</style>
