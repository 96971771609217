import axios from "axios";
import { useToast } from "vue-toastification";
import _head from "lodash/head";
import store from "@/store";
import getAuthToken from "@/use/get-auth-token";

export function axiosInstanceWithLoader() {
    const toast = useToast();
    const primoUpApi = axios.create({
        baseURL: process.env.VUE_APP_API_PRIMO_UP_BASE_URL,
        headers: {
            Authorization: `Bearer ${getAuthToken()}`,
        },
    });
    primoUpApi.interceptors.request.use(
        function (config) {
            store.dispatch("toggleLoading", true);

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    primoUpApi.interceptors.response.use(
        function (response) {
            store.dispatch("toggleLoading", false);

            return response;
        },
        function (error) {
            toast.error(
                _head(error?.response?.data?.errors) ??
                "Si è verificato un errore. Contattare il servizio tecnico.",
                {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                }
            );
            store.dispatch("toggleLoading", false);

            return Promise.reject(error);
        }
    );

    return {
        primoUpApi,
    };
}
