<script>
import { inject, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { updateMainDepartment } from "@/use/repositories/users/updateMainDepartment";
import { redirectTo } from "@/use/repositories/redirectTo";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
import { detectWebcam } from "@/use/utilities/detectWebcam";
import { logisticsSubDepartments } from "@/use/array/logisticsSubDepartments.js";

import Accordion from "@/components/general/Accordion.vue";
export default {
  name: "ManagerNav",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    Accordion,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { departments } = inject("constants");
    const hasWebcam = ref(detectWebcam());
    const chooseDepartment = async (department) => {
      await updateMainDepartment(department.team_id, store.state.mainRole.role_id);
      redirectTo(store.state.user.roles, store.state.user.departments, store).then((response) => {
        const route = router.resolve({ name: response.routeName });
        const url = new URL(route.href, window.location.origin);
        window.location.href = url.toString();
      });
    };

    const goToRoute = (route, type = null) => {
      if (type) {
        return router.push({
          name: route,
          params: {
            type: type,
          },
        });
      }
      router.push({
        name: route,
      });
    }
    return {
      departments,
      chooseDepartment,
      isCurrentDepartment,
      logisticsSubDepartments,
      goToRoute,
      hasWebcam,
    };
  },
};

</script>
<template>
  <div class="
      nav-item
      dropdown
      user-panel
      position-absolute
      end-0
      top-0
      p-2
      m-3
      rounded-circle
      bg-dark-violet
      text-white
    ">
    <span href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      {{ $props.text }}
    </span>
    <template v-if="isCurrentDepartment(departments.LOGISTICS)">
      <ul class="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1" style="width: 18rem;">
        <li class="border border-1 rounded mb-1">
          <a @click="goToRoute('Profile')" class="dropdown-item" href="#"
            :class="{ disabled: $route.name === 'Profile' }">Profilo
          </a>
        </li>
        <Accordion :id="0">
          <template v-slot:header>
            <span>Home</span>
          </template>
          <template v-slot:body>
            <li v-for="(logisticsSubDepartment, index) in logisticsSubDepartments" :key="index">

              <a @click="goToRoute(logisticsSubDepartment.route, logisticsSubDepartment.route_type)"
                class="dropdown-item rounded list-unstyled"
                :class="{ disabled: logisticsSubDepartment.name === 'Insacchettamento' && !hasWebcam }" href="#">
                <img :src="require(`@/assets/icons/${logisticsSubDepartment.svg}`)" width="16" alt="scan" class="me-2">
                {{ logisticsSubDepartment.name }}
              </a>
            </li>
          </template>
        </Accordion>
      </ul>
    </template>
    <template v-else>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a @click="goToRoute('Profile')" class="dropdown-item" href="#"
            :class="{ disabled: $route.name === 'Profile' }">Profilo
          </a>
        </li>
        <li v-for="route in $store.state.user.departments" :key="route.id"
          @click="!isCurrentDepartment(route.slug) && chooseDepartment(route)">
          <a class="dropdown-item" :class="{ disabled: isCurrentDepartment(route.slug) }" href="#">{{ route.name }}
          </a>
        </li>
      </ul>
    </template>
  </div>
</template>
