<template>
  <div
    class="d-flex justify-content-between px-3 p-2"
    role="button"
    aria-expanded="false"
    data-bs-toggle="collapse"
    :href="`#collapse_${$props.idElement}`"
    :aria-controls="`collapse_${$props.idElement}`"
    @click="showMore()"
  >
    <span :class="$props.marginLeftClass">{{ $props.label }}</span>

    <Transition name="bounce">
      <img v-if="toggleCaret" src="@/assets/icons/chat/caret-down.svg" width="20" alt="caretdown">
<!--      <img src="@/assets/icons/chat/caret-up.svg" width="20" alt="caretup">-->
    </Transition>

  </div>
</template>

<script>
import { toRaw, ref } from "vue";

export default {
  name: "CollapseRow",
  props: {
    idElement: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    marginLeftClass: {
      type: String,
      required: true,
    },
  },
  emits: ["selected"],
  setup(props, { emit }) {
    const toggleCaret = ref(false);

    const showMore = () => {
      if (! toggleCaret.value) {
        console.log("emit");
        // Emit id selected.
        emit("selected", {
          id: props.id,
          index: props.index,
        });
      }

      // Toggle icon.
      toggleCaret.value = !toggleCaret.value;
    };

    return {
      toRaw,
      toggleCaret,
      showMore,
    };
  },
};
</script>

<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

</style>
