import moment from 'moment/moment'

export function dateFormatter(date, options = {}) {
  const { onlyDate = false, onlyTime = false, dash = false, iso = false } = options;

  const format = {
    iso: {
      onlyDate: dash ? "YYYY-MM-DD" : "YYYY/MM/DD",
      onlyTime: "HH:mm",
      dateTime: dash ? "YYYY-MM-DD HH:mm" : "YYYY/MM/DD HH:mm"
    },
    it: {
      onlyDate: dash ? "DD-MM-YYYY" : "DD/MM/YYYY",
      onlyTime: "HH:mm",
      dateTime: dash ? "DD-MM-YYYY HH:mm" : "DD/MM/YYYY HH:mm"
    }
  };

  const selectedFormat = iso ? format.iso : format.it;

  let formattedType;

  if (onlyDate) formattedType = selectedFormat.onlyDate;

  else if (onlyTime) formattedType = selectedFormat.onlyTime;

  else formattedType = selectedFormat.dateTime;

  return moment(date).format(formattedType);
}
