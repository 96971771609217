<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="$props.width" :height="$props.height" viewBox="0 0 22.857 20">
    <g id="printer" transform="translate(0 -2.25)">
      <path id="Tracciato_886" data-name="Tracciato 886"
            d="M15.714,3.656H7.143A1.417,1.417,0,0,0,5.714,5.061V7.872H4.286V5.061A2.834,2.834,0,0,1,7.143,2.25h8.571a2.834,2.834,0,0,1,2.857,2.811V7.872H17.143V5.061A1.417,1.417,0,0,0,15.714,3.656ZM20,9.278H2.857a1.417,1.417,0,0,0-1.429,1.406V14.9a1.417,1.417,0,0,0,1.429,1.406H4.286v1.406H2.857A2.834,2.834,0,0,1,0,14.9V10.684A2.834,2.834,0,0,1,2.857,7.872H20a2.834,2.834,0,0,1,2.857,2.811V14.9A2.834,2.834,0,0,1,20,17.712H18.571V16.306H20A1.417,1.417,0,0,0,21.429,14.9V10.684A1.417,1.417,0,0,0,20,9.278Z"
            :fill="$props.color"/>
      <path id="Tracciato_887" data-name="Tracciato 887"
            d="M17.774,19.513H9.506a1.45,1.45,0,0,0-1.378,1.513v4.538a1.45,1.45,0,0,0,1.378,1.513h8.268a1.45,1.45,0,0,0,1.378-1.513V21.026A1.45,1.45,0,0,0,17.774,19.513ZM9.506,18A2.9,2.9,0,0,0,6.75,21.026v4.538a2.9,2.9,0,0,0,2.756,3.026h8.268a2.9,2.9,0,0,0,2.756-3.026V21.026A2.9,2.9,0,0,0,17.774,18Z"
            transform="translate(-2.212 -6.339)" :fill="$props.color" fill-rule="evenodd"/>
      <path id="Tracciato_888" data-name="Tracciato 888"
            d="M6.013,16.506a.756.756,0,1,1-.756-.756A.756.756,0,0,1,6.013,16.506Z"
            transform="translate(-1.474 -4.967)" :fill="$props.color"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: "Print",
  props: {
    color: {
      type: String,
      required: false,
      default: '#0000000',
    },
    width: {
      type: Number,
      required: false,
      default: 24,
    },
    height: {
      type: Number,
      required: false,
      default: 24,
    }
  }
}
</script>

<style scoped>

</style>
