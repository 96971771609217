<template>
  <div class="d-flex flex-wrap w-100 h-100 flex-row card shadow rounded-custom p-3">

    <template v-if="Object.keys($props.data).length > 0">

      <div class="customer box d-flex overflow-auto width-custom p-2 flex-column justify-content-between">
        <div class="box-icon d-flex align-items-start">
          <img class="me-2" src="@/assets/icons/paziente.svg" width="25" alt="customer">
          <strong>{{ $props.data.customer_name }} {{ $props.data.customer_last_name }}</strong>
        </div>
        <div><span class="fw-bold">Studio:</span> {{ $props.data.clinic_name }}</div>
        <div><span class="fw-bold">Medico:</span> {{ $props.data.doctor_name }} {{ $props.data.doctor_surname }}</div>
      </div>
      <div class="prescription-row box d-flex overflow-auto width-custom p-2 flex-column justify-content-between border-end border-start border-dark border-2">
        <div class="box-icon d-flex align-items-start mb-1">
          <span><strong>{{ $props.data.number_text }}</strong><img class="me-2 cp" @click="copyText($props.data.number_text)" src="@/assets/icons/copy.svg" width="25" alt="copy"></span>
          <span><strong>Creata il {{ $props.data.created_at }}</strong></span>
        </div>
          <a class="btn px-0 text-start d-flex align-items-center" href="#" @click.stop.prevent role="button" @click="showPdf()">
            <img class="me-2" src="@/assets/icons/prescription.svg" width="25" alt="check-circle-fill-green">
            Vedi prescrizione
          </a>
        <template v-if="isDigitalCheckIn">
          <div><span class="fw-bold">Impronta: </span>Digitale</div>
          <div><span class="fw-bold">Scanner: </span>{{ $props.scanner?.name ?? '-' }}</div>
        </template>
        <div v-if="$props.data.prescription_row_name">
          {{ $props.data.prescription_row_name }} (<Render :data="$props.data.elements"/>)
        </div>
      </div>
      <div class="more-info box d-flex overflow-auto width-custom p-2 flex-column justify-content-between">
        <small class="fw-bold">
          {{ $props.data.material ? $props.data.material + ',' : '' }}
          {{ $props.data.color_code }},
          {{ getNumberElementsByPrescriptionRows(toRaw($props.data.prescription_rows)) }}
          {{ getNumberElementsByPrescriptionRows(toRaw($props.data.prescription_rows)) > 1 ? 'elementi' : 'elemento' }}
        </small>
        <small><span class="fw-bold">Impianto:</span> {{ $props.data.plant_type ?? '-' }}</small>
        <small><span class="fw-bold">Bruxista:</span> {{ $props.data.bruxism }}</small>
      </div>
    </template>

    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <div class="spinner-border text-violet" role="status"></div>
      </div>
    </template>

  </div>
</template>

<script>
import { toRaw } from 'vue';
import Render from '@/components/PrescriptionRows/Render';
import {getNumberElementsByPrescriptionRows} from "@/use/repositories/prescriptionRows/getNumberElementsByPrescriptionRows";
import { getPdf as showPrescription } from "@/use/repositories/prescriptions/getPdf";
import { useI18n } from "vue-i18n";
import { error } from "@/use/toast/error";
import { base64ToPdf } from '../../use/utilities/base64ToPdf';

export default {
  name: "PrescriptionDetails",
  props: {
    data: {
      type: Object,
      required: true,
    },
    scanner: {
      type: Object,
      required: false,
      default: null
    },
    isDigitalCheckIn: {
      type: Boolean,
      default: false
    },
    isCompleted: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    Render,
  },
  setup(props) {
    const i18n = useI18n();

    const showPdf = () => {
      showPrescription(props.data.id, 'download_pdf').then((response) => {
        base64ToPdf(response.prescription);
        }).catch(() => {
          error(i18n.t('An error has occurred. Contact the technical service!'));
      });
    }

    const copyText = async (textToCopy) => {
      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (err) {
        console.error("Could not copy text: ", err);
      }
    };

    return {
      getNumberElementsByPrescriptionRows,
      toRaw,
      showPdf,
      copyText,
    }
  }
}
</script>

<style scoped>
* {
  font-size: 1rem;
}
.width-custom {
  width: calc(100% / 3);
}
ul li {
  padding-bottom: .5em;
}
/* Responsive font */
@media screen and (max-width: 1390px) {
  * { font-size: 15px; }
  .box { flex-direction: column; }
}
@media screen and (max-width: 1300px) {

}
</style>
