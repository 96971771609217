import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function changePassword(param) {
  const axios = axiosInstance().primoLab;
  let message = [];

  await axios
    .post("/auth/password/change", param)
    .then(async (response) => {
      console.log(1, response.data.data);
      message = response.data.message;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
