<template>
  <span @click="checked = !checked">
    <img v-show="! checked" class="checkbox bg-white" src="@/assets/icons/checkbox.svg" width="20" height="20" alt="checkbox"/>
    <img v-show="checked" class="checkbox" src="@/assets/icons/checkbox-checked.svg" width="20" height="20" alt="checkbox-checked"/>
  </span>
</template>

<script>
import {ref} from 'vue';

export default {
  name: "Checkbox",
  emits: [
    'checked',
  ],
  setup() {
    const checked = ref(false);

    return {
      checked,
    }
  }
}
</script>

<style>
  .checkbox {cursor: pointer;}
</style>
