import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAll(action) {
  const axios = axiosInstance().primoLab;
  let vendors = [];

  await axios
    .get(`/roles/vendor`, {
      params: { action: action }
    })
    .then(async (response) => {
      vendors = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { vendors };
}
