import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByType(type, params) {
  const axios = axiosInstance().primoLab;
  let feedbacks = [];

  await axios
    .get(`/feedback-types/${type}`, {
      params,
    })
    .then(async (response) => {
      feedbacks = response.data.data.feedbacks.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.description,
        };
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { feedbacks };
}
