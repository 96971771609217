<template>
  <div class="d-flex flex-column p-5 vh-100 vw-100" v-if="dataHeader.length > 0">

    <Header class="mb-5"
        :data="dataHeader"
        :reset="filesIdsToRemove"
        :clear-global-search="clearGlobalSearch"
        @materialType="readMaterialType"
        @removeMaterial="readRemoveMaterialIndex"
        @toSearch="searchPrescription"
    />

    <div class="d-flex wv-100 h-80">

      <div class="d-flex">
        <ColorScale
            :data="dataColor"
            @colorSelected="readColorSelected"
        />
      </div>

      <div class="d-flex" style="width: calc(100vw - 33em);">
        <template v-if="Object.keys(dataDays).length">
          <Day
            :data="dataDays"
            @filesToDownload="syncFilesToDownload"
          />
        </template>
      </div>

      <div class="download d-flex">
        <Download
          :data="assetsToDownload"
          @reset="readFileIdsToRemove"
        />
      </div>

      <Modal v-if="showModal"
        size="xl"
        class="d-flex overflow-auto"
        title="Prescrizioni corrispondenti"
        modal_id="resultmodal"
        decline_label="chiudi"
        :lightLayout="true"
        @hidden="resetSearchBar">
        <div class="container" style="max-height: 70vh;">
          <div v-for="prescription in allDataFilteredByKey" :key="prescription.id" class="card mb-3">
            <div class="card-header">
            </div>
            <div class="card-body" style="">
              <h5 class="card-title">{{ prescription.number_text }} - {{ prescription.first_name }} {{ prescription.last_name }}</h5>
              <small>{{ prescription.delivery_date }} </small>
              <ul class="card-text">
                <li v-for="asset in prescription.assets" :key="asset.id" class="d-flex">
                  <div class="me-3">
                    <small>{{ asset.delivery_date }} </small>
                  </div>
                  <div class="me-3">
                    <span> Materiale: </span>
                    <span class="fw-bold"> {{ asset.material }} </span>
                  </div>
                  <div class="me-3">
                    <span>Colore: </span>
                    <span class="fw-bold"> {{ asset.code }}</span>
                  </div>
                  <div class="me-3">
                    <span>Stato: </span>
                    <span class="fw-bold">{{ getStateTranslate(asset.state) }}</span>
                    <img
                      class="ms-3"
                      :src="require(`@/assets/icons/${getIconByState(asset.state)}.svg`)"
                      width="25"
                      :alt="`icon-${asset.state}-state`"/>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer text-muted">
            </div>
          </div>
          <div>
          </div>
        </div>
      </Modal>
    </div>
  </div>

  <!-- Loading -->
  <template v-else>
    <Loading
      :title="'In attesa di lavorazioni'"
      :type="'CAM'"
      :subtitle="null"
    />

    <div class="d-flex justify-content-center p-5">
      <!-- Consulting -->
      <button @click="$router.push({ name: 'ConsultingSearch' })" class="btn btn-violet me-2 rounded-custom d-flex align-items-center justify-content-center w-10">
        <img class="me-2" src="@/assets/icons/search-white.svg" width="20" alt="bx-search">
        <small>Consultazione</small>
      </button>

      <!-- Digital checkin link -->
      <button @click="$router.push({ name: 'CamSummary' })" class="btn btn-violet me-2 rounded-custom d-flex align-items-center justify-content-center w-10">
        <img src="@/assets/icons/printer-white.svg" width="60" alt="printer-white">
      </button>

      <!-- Warehouse link -->
      <button @click="$router.push({ name: 'Dashboard' })" class="btn btn-violet me-2 rounded-custom d-flex align-items-center justify-content-center w-10">
        <img src="@/assets/icons/shopping-cart.svg" width="60" alt="shopping-cart">
      </button>

      <!-- In the works link -->
      <router-link to="/cam/in-the-works" class="btn btn-violet rounded-custom d-flex align-items-center justify-content-center w-10">
        <img src="@/assets/icons/view-list.svg" width="40" alt="view-list">
      </router-link>
    </div>

  </template>

</template>

<script>
import {
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRaw,
  watch
} from "vue";
import moment from "moment";
import _head from "lodash/head";
import _ from "lodash";

import Header from "@/components/cam/Header";
import ColorScale from "@/components/cam/ColorScale";
import Day from "@/components/cam/Day";
import Download from "@/components/cam/Download";
import Loading from "@/components/plaster/Loading";
import Modal from '@/components/Modal/Standard';

import {groupBy} from "@/use/utilities/array/groupBy";
import {array_value} from "@/use/utilities/array/array_value";
import {sortBy} from "@/use/utilities/array/sortBy";
import {fetchAll} from "@/use/repositories/assets/fetchAll";
import {info} from '@/use/toast/info';
import {useI18n} from "vue-i18n";

export default {
  name: "Checkin",
  components: {
    Header,
    ColorScale,
    Day,
    Download,
    Loading,
    Modal
},
  setup() {
    const i18n = useI18n();
    const dataHeader = ref([]);
    const dataColor = reactive({
      'material': "",
      'selected': false,
      'colorScale': [],
    });
    const { states } = inject("constants");
    const numberTextToSearch = ref("");
    const clearGlobalSearch = ref(false)
    const showModal = ref(false);
    const dataDays = ref([]);
    const assetsToDownload = ref([]);
    const filesIdsToRemove = ref([]);
    const data = ref([]);
    const allData = ref([]);
    const allDataFilteredByKey = computed(() => {
      if (numberTextToSearch.value.length === 0) return [];
      let filteredAssets = _.filter(allData.value, (asset) => {
        return asset.number_text.toLowerCase().includes(numberTextToSearch.value.toLowerCase());
      });
      if (filteredAssets.length === 0) {
        resetSearchBar();
        return info(i18n.t("No prescriptions found"));
      }

      return structurePrescriptions(filteredAssets);
    });
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const { assetsType } = inject("constants");

    watch(() => data.value, () => {
      prepareDataForHeader(data.value);
      prepareDataForColorScale(materialActive.value)
    }, { deep: true });

    const updateStateAssets = (event) => {
      console.log(JSON.stringify(event));

      // Set property has prints
      event.asset.has_prints = event.asset.test_work_queue_prints > 0;

      let assetIndex = data.value.findIndex((item) => item.id === event.asset.id);

      // Toggle asset based on event.
      switch (event.state_slug) {
        case states.IN_THE_WORKS:
          data.value.splice(assetIndex, 1);
          _.find(allData.value, function(asset) { return asset.id === event.asset.id; }).state = states.IN_THE_WORKS;
          break;
        case states.COMPLETED:
          data.value.splice(assetIndex, 1);
          allData.value.splice(assetIndex, 1);
          break;
        case states.PENDING:
          event.asset.state = event.state_slug;
          data.value.push(event.asset);
          allData.value.push(event.asset);
          break;
      }
    }

    const resetMaterialSelected = () => {
      dataColor.colorScale.forEach((item) => item.selected = false);
    }

    // Subscribe to channel
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, {
        $pusher: pusher,
      });

      const channel = pusher.subscribe(channelName);

      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));

      channel.bind(eventName, async (event) => {
        console.log("event received", event);

        if (event.state_slug === 'pending') {
          info(`Nuova prescrizione ${event.asset.number_text} - Materiale: ${event.asset.material}`);
        }

        updateStateAssets(event);
      });
    };

    // Unsubscribe to channel
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {
        $pusher: pusher,
      });

      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    // Fetch CAM test work queue phase.
    onMounted(async () => {
      let params = {
        states: [
          states.PENDING,
          states.IN_THE_WORKS
        ],
        action: "by_states",
      }
      fetchAll(params).then(async (response) => {
        data.value = _.filter(response.assets, (asset) => asset.state === states.PENDING);
        allData.value = response.assets;
      });


      // Subscribe to channel.
      subscribe("private-live", "asset_change-state");
    });

    onUnmounted(() => {
      console.log("unmount");

      unsubscribeChannel("private-live");
    });

    const createAssetStructure = (item) => {
      let element = {
        test_work_queue_phase_id: item.test_work_queue_phase_id,
        test_work_queue_id: item.test_work_queue_id,
        material: item.material,
        delivery_date: moment(item.delivery_date),
        delivery_date_unix_format: moment(item.delivery_date).unix(), // Unix for groupBy.
        color: item.type_slug === assetsType.MILLING ? item.code : item.type_slug,
        assets: [],
        prescription_test: item.prescription_test,
        is_digital: item.is_digital,
        has_prints: item.test_work_queue_prints.length > 0,
        prescription_id: item.prescription_id,
      };

      let assetsInfo = {
        id: item.id,
        number_text: item.number_text,
        child_internal_phase_id: item.child_internal_phase_id,
        child_id: item.child_id,
        test_work_queue_phase_id: item.test_work_queue_phase_id,
        test_work_queue_id: item.test_work_queue_id,
        state: item.state,
        checked: assetsToDownload.value.findIndex((asset) => asset.id === item.id) !== -1,
        media: item.media,
        slug: item.type_slug,
        is_digital: item.is_digital,
        has_prints: item.test_work_queue_prints.length > 0,
        prescription_id: item.prescription_id,
      }

      if (item.prescription_rows.length > 0) {
        assetsInfo.bridge = _head(item.prescription_rows).bridge != null;
      } else {
        // Model type.
        assetsInfo.bridge = false;
      }

      element.assets.push(assetsInfo);

      return element;
    }

    const materialActive = computed(() => {
      let material = null;
      dataHeader.value.forEach((item) => {
        if (item.selected) {
          material = item.material;
        }
      });
      return material;
    });

    const groupByDate = (array) => {
      return _.chain(array)
        .groupBy((item) => {
          return moment(item.delivery_date).format("DD/MM/YYYY");
        })
        .value();
    }

    // Prepare data for header component.
    const prepareDataForHeader = (items) => {
      let filteredByKeys = [];

      items.forEach((item) => {
        filteredByKeys.push(createAssetStructure(item));
      });

      // Grouped by material.
      const groupedByMaterial = groupBy(filteredByKeys, 'material');

      let data = [];
      for (const material in groupedByMaterial) {

        data[material] = {
          'selected': (dataColor.material === material),
          'material': material,
          'toWorkInTheDay': [],
          'toWork': [],
        };

        groupedByMaterial[material].forEach((item) => {
          if (item.delivery_date.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")) {
            return data[material]['toWorkInTheDay'].push(item);
          }
          return data[material]['toWork'].push(item);
        });
      }

      dataHeader.value = array_value(data);

      // Order by material.
      dataHeader.value = _.orderBy(toRaw(dataHeader.value), 'material', 'desc');
    }

    // Prepare data for color scale component.
    const prepareDataForColorScale = async (materialChoose) => {
      let code = null;
      dataColor.colorScale.forEach((item) => {
        if (item.selected) {
          code = item.name;
        }
      });

      dataHeader.value.forEach((itemProxy) => {
        if (itemProxy.material === materialChoose) {
          // Empty color scale data.
          dataColor.colorScale = [];

          dataColor.material = itemProxy.material;
          let itemArray = toRaw(itemProxy);
          let allItems = [];

          // Push all item.
          if (itemArray.toWork.length > 0) {
            itemArray.toWork.forEach((el) => {
              allItems.push(el);
            });
          }
          if (itemArray.toWorkInTheDay.length > 0) {
            itemArray.toWorkInTheDay.forEach((el) => {
              allItems.push(el);
            });
          }

          // Group by color all items.
          let groupedByColorScale = groupBy(allItems, 'color');

          let obj = {
            'name': "",
            'selected': true,
            'toWorkBeforeDayGroupedByColor': {
              works: [],
            },
            'toWorkInTheDayGroupedByColor': {
              works: [],
            },
            'toWorkGroupedByColor': {
              works: [],
            }
          };

          // Reset array keys.
          groupedByColorScale = array_value(groupedByColorScale);

          // Split data by date.
          groupedByColorScale.forEach((item) => {

            // Empty obj.
            obj = {
              'name': "",
              'selected': false,
              'toWorkBeforeDayGroupedByColor': {
                works: [],
              },
              'toWorkInTheDayGroupedByColor': {
                works: [],
              },
              'toWorkGroupedByColor': {
                works: [],
              },
            };

            item.forEach((el) => {

              // Set color scale name.
              obj.name = el.color;
              obj.selected = false;

              // Control when it is to work.
              if (moment(el.delivery_date).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) {
                obj.toWorkInTheDayGroupedByColor.works.push(el);

              } else if (moment(el.delivery_date).isBefore(moment())) {
                obj.toWorkBeforeDayGroupedByColor.works.push(el);

              } else {
                obj.toWorkGroupedByColor.works.push(el);
              }
            });

            dataColor.colorScale.push(obj);

            // Sort by color scale.
            dataColor.colorScale = sortBy(toRaw(dataColor.colorScale), 'name');
          });

          if (code !== null) {
            let index = dataColor.colorScale.findIndex((color) => color.name === code);
            if (index !== -1) {
              dataColor.colorScale[index].selected = true;
            }
          } else {
            // Selected first.
            _head(dataColor.colorScale).selected = true;
          }
        }
      });

      // Format data (create a structure).
      dataColor.colorScale.forEach((color) => {
        prepareDataForDays(color);
      });
    }

    // Prepare data for days component.
    const prepareDataForDays = (colorChoose) => {
      // Work before today exists.
      if (colorChoose.toWorkBeforeDayGroupedByColor.works.length > 0) {
        colorChoose.toWorkBeforeDayGroupedByColor.works = groupByDate(toRaw(colorChoose.toWorkBeforeDayGroupedByColor.works));
      }

      // Work today exists.
      if (colorChoose.toWorkInTheDayGroupedByColor.works.length > 0) {
        colorChoose.toWorkInTheDayGroupedByColor.works = groupByDate(toRaw(colorChoose.toWorkInTheDayGroupedByColor.works));
      }

      // Work after today exists.
      if (colorChoose.toWorkGroupedByColor.works.length > 0) {
        colorChoose.toWorkGroupedByColor.works = groupByDate(toRaw(colorChoose.toWorkGroupedByColor.works));
      }

      // Order by date.
      //console.log("TODO order by data");
      //console.log(colorChoose);

      dataDays.value = colorChoose;
    }

    // Read color choose to emit.
    const readColorSelected = (colorChoose) => {
      prepareDataForDays(colorChoose);
    }

    // Read material choose emit.
    const readMaterialType = async (materialChoose) => {
      console.log("MATERIAL SELECTED: " + materialChoose);
      resetMaterialSelected();
      // Update material active.
      await prepareDataForColorScale(materialChoose);
    }

    const syncFilesToDownload = (filesToDownload) => {
      filesToDownload.forEach((obj) => {
        // Sync assets to download.
        let indexAssetToDownload = assetsToDownload.value.findIndex((asset) => asset.id === obj.id);
        if (indexAssetToDownload !== -1) {
          // Remove element.
          assetsToDownload.value.splice(indexAssetToDownload, 1);
        } else {
          // Add element.
          assetsToDownload.value.push(obj);
        }
      });
    }

    const syncData = (idsToRemove) => {
      idsToRemove.forEach((id) => {
        let assetIndex = data.value.findIndex((item) => item.id === id);
        data.value.splice(assetIndex, 1);
        _.find(allData.value, (asset) => asset.id === id).state = states.IN_THE_WORKS;
      });
    }

    const readFileIdsToRemove = (idsToRemove) => {
      // Sync data.
      syncData(idsToRemove);
      // Sync ids to remove.
      filesIdsToRemove.value = idsToRemove;
      // Empty list file downloaded.
      assetsToDownload.value = [];
    }

    const readRemoveMaterialIndex = (index) => {
      dataHeader.value.splice(index, 1);
    }

    const searchPrescription = (numberText) => {
      numberTextToSearch.value = numberText;
    }

    watch(() => allDataFilteredByKey.value, (value) => {
        if (value.length > 0) {
          showModal.value = true;
          clearGlobalSearch.value = false;
        }
    });

    const structurePrescriptions = (arrayAssets) => {
      return _.chain(arrayAssets)
        .groupBy('prescription_id')
        .map((value) => ({
          number_text: _.head(value).number_text,
          delivery_date: _.head(value).delivery_date,
          id: _.head(value).id,
          first_name: _.head(value).first_name,
          last_name: _.head(value).last_name,
          assets: value
        })).value();
    }

    const resetSearchBar = () => {
      clearGlobalSearch.value = true;
      numberTextToSearch.value = "";
      showModal.value = false;
    }

    const getIconByState = (slug) => {
      switch (slug) {
        case states.PENDING:
          return 'consulting/pending-violet';
        case states.IN_THE_WORKS:
          return 'gear-violet';
        case states.COMPLETED:
          return 'like-green';
      }
    }

    const getStateTranslate = (slug) => {
      switch (slug) {
        case states.PENDING:
          return 'in attesa';
        case states.IN_THE_WORKS:
          return 'in lavorazione';
        case states.COMPLETED:
          return 'completato';
      }
    }

    return {
      dataHeader,
      readMaterialType,
      dataColor,
      readColorSelected,
      dataDays,
      syncFilesToDownload,
      assetsToDownload,
      readFileIdsToRemove,
      filesIdsToRemove,
      readRemoveMaterialIndex,
      data,
      searchPrescription,
      showModal,
      getIconByState,
      clearGlobalSearch,
      resetSearchBar,
      allDataFilteredByKey,
      getStateTranslate
    }
  }
}
</script>

<style scoped>
  .download {
    width: 17em;
  }
</style>
