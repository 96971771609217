export function sortByPriorityDate(testWorkQueuePhases) {
  testWorkQueuePhases.sort((a, b) => {
    let keyA = new Date(a.test_work_queue.delivery_date);
    let keyB = new Date(b.test_work_queue.delivery_date);

    // Compare the 2 dates
    if (keyA < keyB) {
      return -1;
    }

    if (keyA > keyB) {
      return 1;
    }

    return 0;
  });
}
