import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function fetchById(testWorkQueuePhaseId, action, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let testWorkQueuePhase = {};

  await axios
    .get(`/test-work-queue-phases/${testWorkQueuePhaseId}`, {
      params: {
        action: action,
      }
    })
    .then(async (response) => {
      testWorkQueuePhase = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueuePhase };
}
