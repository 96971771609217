<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from "vuex";
import getAuthToken from "@/use/get-auth-token";

export default {
  name: "Brand",
  props: {
    width: { type: Number },
    height: { type: Number },
    withChat: { type: Boolean, default: false },
  },
  setup() {
    const { state: { user } } = useStore();
    const primoChatRef = ref(null);
    const isChatOpen = ref(false);

    const onChatStateChanged = (event) => {
      isChatOpen.value = event.detail.isOpen;
    };

    watch(isChatOpen, (newValue) => {
      if (primoChatRef.value) {
        if (newValue) primoChatRef.value.classList.add('open');
        else primoChatRef.value.classList.remove('open');
      }
    }, { immediate: true });

    onMounted(() => {
      window.addEventListener('chat-state-changed', onChatStateChanged);

      if (primoChatRef.value) {
        primoChatRef.value.setAttribute('auth-token', getAuthToken());
        primoChatRef.value.setAttribute('user', JSON.stringify(user))
      }
    });

    onUnmounted(() => window.removeEventListener('chat-state-changed', onChatStateChanged));

    return { primoChatRef, isChatOpen, user }
  }
};
</script>

<template>
  <div class="d-flex">
    <img src="@/assets/images/primo-lab-logo.svg" :width="$props.width" :height="$props.height"/>
    <template v-if="$props.withChat">
      <primo-chat v-pre expanded ref="primoChatRef" id="primo-chat" />
    </template>
  </div>
  <slot></slot>
</template>

<style lang="scss">
#primo-chat {
  z-index: 100000;

  &.open {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }
}
</style>
