import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function lightCommand(deviceId, color) {
  console.log(deviceId, color);
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .get(`/devices/${deviceId}`, {
      params: {
        color: color,
        action: "with_color",
      }
    })
    .then(async (response) => {
      data = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
