<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-xxl-4 offset-xxl-3 col-xl-5 offset-xl-2 col-md-10 col-sm-10 p-0 me-xl-3 me-xxl-3 mb-3 me-sm-0 me-md-0">
        <div class="chat__container card shadow rounded-custom overflow-auto w-100">
          <div class="header sticky-top rounded-only-top bg-dark-violet p-3 text-center text-uppercase text-white">
            <h4 class="m-0 d-flex justify-content-center align-items-center">
              messaggi in arrivo
              <span class="ms-2 badge bg-violet text-wrap fs-6 text-uppercase">
               {{ getCountNewMessages(newMessages) }}
              </span>
            </h4>
          </div>
          <template v-if="apiCompleted === false">
            <Spinner />
          </template>
          <template v-else>
            <template v-if="newMessages.length === 0">
              <div class="d-flex w-100 h-100 justify-content-center align-items-center">
                Nessun nuovo messaggio
              </div>
            </template>
            <template v-else>
              <Messages
                :badgeColor="'#A58ABA'"
                :callback="details"
                :messages="newMessages"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="col-xxl-3 col-xl-3 col-md-10 col-sm-10 p-0">
        <template v-if="showConversation">
          <div class="d-flex flex-column position-relative rounded-custom w-100">

            <div class="header d-flex flex-column rounded-only-top bg-dark-violet p-3 text-center text-uppercase text-white">
              <div class="d-flex align-items-center">
                <a href="#" class="text-white text-decoration-underline badge bg-violet" @click="consulting(prescription)">{{ prescription.number_text }}</a>
                <small class="ms-2">{{ prescription.customer.last_name }} {{ prescription.customer.first_name }}</small>
              </div>
              <div class="text-start text-truncate" data-toggle="tooltip" data-placement="top" :title="prescription.care_plan_row.name">
                <em>{{ prescription.care_plan_row.name }}</em>
              </div>
            </div>

            <div class="bg-white rounded-only-bottom" style="height: 600px;">
              <!-- Conversation -->
              <Conversation
                :prescription="prescription"
              />
            </div>

          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRaw} from "vue";
import _sumBy from "lodash/sumBy";
import {sortBy} from "lodash/collection";
import {useRouter} from "vue-router";
import {error} from "@/use/toast/error";
import {useI18n} from "vue-i18n";

import Messages from "@/components/chat/Messages";
import Spinner from "@/components/general/Spinner";
import Conversation from "@/components/chat/Conversation";

import {fetchMessagesUnseen} from "@/use/repositories/chat/message/fetchMessagesUnseen";
import {updateReadings} from "@/use/repositories/chat/message/updateReadings";
import _head from "lodash/head";
import _filter from "lodash/filter";

export default {
  name: "Chat",
  components: {
    Spinner,
    Messages,
    Conversation,
  },
  emits: [
    'closeNewMessagesModal',
  ],
  setup(props, {emit}) {
    const router = useRouter();
    const newMessages = ref([]);
    const clinics = ref([]);
    const apiCompleted = ref(false);
    const showConversation = ref(false);
    const prescription = reactive({
      id: null,
      chat_channel: {},
      customer: {},
      care_plan_row: {},
      number_text: null,
    });
    const i18n = useI18n();

    onMounted(() => {
      getNewMessages();
    });

    const getNewMessages = async () => {
      fetchMessagesUnseen().then((response) => {
        // Order new messages by datetime to last message sent.
        newMessages.value = sortBy(response.messagesUnseen, item => item.messages[item.messages.length-1].created_at, ['asc']).reverse();
        newMessages.value = newMessages.value.map((message) => {
          return {
            ...message,
            "has_been_read": false,
          }
        });
        apiCompleted.value = true;
      });
    }

    const syncNewMessages = (id) => {
      const index = newMessages.value.findIndex(item => item.id === id);
      if (index !== -1) {
        // Mark to read.
        newMessages.value[index].has_been_read = true;
      }
    }

    const getMessagesIds = (message) => {
      return message.messages.map((message) => {
        return message.id;
      });
    }

    const setPrescriptionInfo = (message) => {
      prescription.id = message.chat_channelable.id;
      prescription.chat_channel = {
        id: message.id
      };
      prescription.customer = message.chat_channelable.customer;
      prescription.care_plan_row = _head(message.chat_channelable.care_plan_rows);
      prescription.number_text = message.chat_channelable.number_text;
    }

    // Redirect on chat in consulting page.
    const details = (message) => {
      showConversation.value = false;

      // Set prescription data.
      setPrescriptionInfo(message);

      // Conversation already read.
      if (message.has_been_read) {
        setTimeout(() => {
          // Open conversation.
          showConversation.value = true;
        });
        return;
      }

      updateReadings(getMessagesIds(message)).then(() => {
        // Open conversation.
        showConversation.value = true;
        // Remove conversation.
        syncNewMessages(message.id);
      }).catch(() => {
        // Show error alert.
        error(i18n.t('An error has occurred. Contact the technical service!'));
      });
    }

    const consulting = () => {
      // Redirect on consulting page.
      router.replace({
        name: "ConsultingDashboard",
        params: {
          prescription_id: prescription.id,
        },
        query: {
          show_chat: true,
        }
      });
      emit('closeNewMessagesModal', true);
    }

    const getCountNewMessages = (newMessages) => {
      return _sumBy(_filter(toRaw(newMessages), (messages) => {
        if (!messages.has_been_read) {
          return messages;
        }
      }), 'messages.length');
    }

    return {
      newMessages,
      clinics,
      details,
      apiCompleted,
      getCountNewMessages,
      showConversation,
      prescription,
      consulting,
    }
  }
}
</script>

<style scoped>
  .chat__container {
    font-size: 1rem;
    width: 500px;
    height: 675px;
  }
</style>
