import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function fetchAll(params, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let assets = [];

  await axios
    .get("/assets", { params })
    .then(async (response) => {
      assets = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { assets };
}
