<script>
import { computed, inject } from 'vue';

export default {
  name: 'Step',
  props: {
    stepCount: { type: Number },
    disable: { type: Boolean },
  },
  emits: ['step-change'],

  setup(props) {
    const currentStep = inject('currentStep')
    const firstStep = computed(() => currentStep.value === 0)
    const lastStep = computed(() => currentStep.value === (props.stepCount - 1))

    return {
      currentStep,
      firstStep,
      lastStep,
    }
  }
}
</script>
<template>
  <div class="step-wrapper active">
    <button class="btn btn-violet text-uppercase" @click="$emit('step-change', --currentStep)" :disabled="firstStep">Indietro</button>
    <button class="btn btn-violet text-uppercase" v-if="!lastStep" @click="$emit('step-change', ++currentStep)" :disabled="lastStep || $props.disable">Avanti</button>
    <button class="btn btn-violet text-uppercase" v-if="lastStep"  @click="$emit('submit')">Conferma</button>
  </div>
</template>
<style lang="scss" scoped>
@import "../../scss/components/_wizard.scss";
</style>
