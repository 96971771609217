<template>
  <div class="d-flex w-100 h-100 justify-content-center align-items-center">
    <div class="spinner-border text-violet" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner"
}
</script>
