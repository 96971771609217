
<script>
import { Modal } from "bootstrap";
import { onMounted } from "vue";

export default {
    name: "Standard",
    props: [
        'size',
        'modal_id',
        'title',
        'subtitle'
    ],
    emits: [
        'hidden'
    ],
    setup(props, context) {

        onMounted(() => {
            showModal(props.modal_id);
            dismissModal(props.modal_id, "hidden");
        });

        // Modal functions -> show/dismiss
        const showModal = async (idModal) => {
            let modal = new Modal(document.getElementById(idModal), { backdrop: 'static', keyboard: false});
            modal.show();
        }
        const dismissModal = async (idModal, nameEmit) => {
            let modal = document.getElementById(idModal);
            modal.addEventListener('hidden.bs.modal', function () {
                context.emit(nameEmit, false);
            });
        }
    }
}
</script>

<template>
    <div class="modal fade" :id="$props.modal_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" :class="'modal-' + $props.size">
            <div class="modal-content rounded-3">
                <div class="d-flex flex-column align-items-center justify-content-between p-3 border-0"
                    :class="{ 'bg-violet': !$props.lightLayout, 'text-white': !$props.lightLayout }">
                    <div class="d-flex align-items-center w-100">
                        <h3 class="fw-normal text-uppercase modal-title text-center m-auto"
                            :class="{ 'text-violet': $props.lightLayout }">
                            {{ $props.title }}
                        </h3>
                    </div>
                </div>
                <div class="modal-header text-center">
                    <template v-if="$props.subtitle !== null">
                        <span class="m-auto" v-if="$props.subtitle">{{ $props.subtitle }}</span>
                    </template>
                    <slot name="modal-header"></slot>
                </div>
                <div class="modal-body overflow-auto">
                    <slot name="modal-body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="modal-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>