<template>
  <div class="slot card rounded-custom shadow border-0 flex-column align-items-center justify-content-center w-100 m-2"
       @click="selected($props.index+1)"
       :style="{ height: 100 / $props.totStations + '%' }">

    <div class="d-flex flex-column w-50">
      <h1 class="fw-bold text-center">{{ $props.index + 1 }}</h1>
      <div class="d-flex overflow-auto">

        <!-- Slot color -->
        <div class="d-flex mb-2">
          <div class="p-1" v-for="(slot, index) in $props.slots" :key="index">
            <svg style="border:1px solid black;border-radius:50%;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-circle-fill" viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"/>
            </svg>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Station",
  props: [
      'index',
      'slots',
      'totStations',
  ],
  setup(props, {emit}) {
    const selected = async (index) => {
      emit('selected', index);
    }

    return {
      selected,
    }
  }
}
</script>

<style scoped>
  h1 {
    font-size: 6em;
  }
  .slot:hover {
    cursor: pointer;
  }
</style>
