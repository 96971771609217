<script setup>
import { onMounted, ref, defineProps, defineEmits, inject } from "vue";
import _ from "lodash";
import moment from "moment";
import { fetchAll as fetchAssets } from "@/use/repositories/assets/fetchAll";
import { getLabelsNameByAsset } from '@/use/utilities/getLabelsNameByAsset';
import Spinner from "@/components/general/Spinner";
import Accordion from "@/components/general/Accordion.vue";
// If not exist return a default value.
const prescriptionTestOrder = inject("prescriptionTestOrder", () => {});

const props = defineProps({
  callType: Object,
});

const emit = defineEmits({
  preview: String,
});

const prescriptionTests = ref([]);
const isLoaded = ref(false);
const defaultType = "milling";

onMounted(() => {
  let params = props.callType;
  fetchAssets(params, false)
    .then(async (response) => {
      prescriptionTests.value = buildDataAssets(response.assets);
    })
    .finally(() => {
      isLoaded.value = true;
    });
});

const isModel = (type) => {
  return (type !== defaultType);
}

const buildDataAssets = (data) => {
  let buildedData = data.map((item) => {
    return {
      id: item.id,
      test_work_queue_id: item.test_work_queue_phase.test_work_queue_id,
      prescription_test_id: item.test_work_queue_phase.test_work_queue.prescription_test_id,
      technician_id: item.user.id,
      technician: `${item.user.first_name} / ${item.user.last_name}`,
      prescription_test_name: item.test_work_queue_phase.test_work_queue.prescription_test.name,
      department: item.test_work_queue_phase.department.name,
      created_at: item.created_at,
      type: item.type.slug,
      application: isModel(item.type.slug) ? getLabelsNameByAsset(item.type.slug) : (item.prescription_rows),
      color_code: item.color.code,
      material: item.material.name,
    };
  });

  if (! prescriptionTestOrder.value) {
    return _.chain(buildedData)
      .groupBy("prescription_test_id")
      .map((group) => _.orderBy(group, "technician_id"))
      .value()
      .reverse();
  }

  return _.chain(buildedData)
    .groupBy("prescription_test_id")
    .map((group) => _.orderBy(group, "technician_id"))
    .sortBy(items => {
      return _.findIndex(prescriptionTestOrder.value, {
        'id': _.head(items).prescription_test_id
      })
    })
    .value();
};

const preview = (id) => {
  emit("preview", id);
};
</script>
<template>
  <template v-if="isLoaded">
    <template v-if="prescriptionTests.length > 0">
      <template v-for="(prescriptionTest, index) in prescriptionTests" :key="index">
        <Accordion :id="index">
          <template v-slot:header>
            <h5>{{ prescriptionTest[0].prescription_test_name }}</h5>
          </template>
          <template v-slot:body>
            <template v-for="(asset, index) in prescriptionTest" :key="index">
              <div class="projects d-flex flex-column align-items-center p-3">
                <div class="d-flex">
                  <span class="d-flex fw-bold"> {{ asset.department }} - {{ asset.technician }} </span>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  {{ moment(asset.created_at).format("DD/MM/YYYY") }} -
                  <template v-if="isModel(asset.type)">
                    <small class="fw-bold mx-1">M</small>
                    <small class="mx-1">{{ asset.application }}</small>
                  </template>
                  <template v-else>
                    <small class="fw-bold mx-1">P</small>
                    <small v-for="label in asset.application" :key="label.id">
                      {{ '-' }}
                      {{ label.application }}
                      {{ label.zone_number }}
                    </small>
                  </template>
                </div>
                <div class="d-flex align-items-center">
                  {{ asset.material }} - {{ asset.color_code }}
                  <img
                    class="icon-3d ms-2 mb-2"
                    @click="preview(asset.id)"
                    src="@/assets/icons/3d-rotation.svg"
                    width="30"
                    alt="3d"
                  />
                </div>
              </div>
            </template>
          </template>
        </Accordion>
      </template>
    </template>
    <template v-else>
      <div class="projects d-flex flex-column align-items-center p-3 overflow-auto h-100 w-100">
        <span>Nessun progetto trovato</span>
      </div>
    </template>
  </template>
  <template v-else>
    <div class="d-flex align-items-center justify-content-center p-5">
      <Spinner />
    </div>
  </template>
</template>

<style scoped>
.icon-3d:hover {
  cursor: pointer;
}
</style>
