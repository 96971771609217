<template>
  <div>
    <label class="form-label">{{ $props.label }}</label>
    <Multiselect
        :class="$props.class"
        class="multiselect-violet"
        :close-on-select="true"
        :clear-on-select="false"
        :max="$props.max"
        mode="tags"
        placeholder="Scegli una motivazione..."
        v-model="selectedMotivationId"
        :options="$props.options"
        @change="updateSelected()"
    />
  </div>
  <small class="text-danger" v-if="$props.showAlert">Si prega di selezione almeno una motivazione</small>
</template>

<script>
import {ref} from "vue";

import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Multiselect,
  },
  inheritAttrs: false,
  emits: ['selected'],
  props: {
    options: {
      type: Array,
      required: true
    },
    showAlert: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    class: {
      type: String,
      default: ""
    },
    max: {
      type: Number,
      default: 1
    }
  },
  setup(props, {emit}) {
    const selectedMotivationId = ref([]);

    const updateSelected = () => {
      emit('selected', selectedMotivationId);
    };

    return {
      selectedMotivationId,
      updateSelected,
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-violet {
  --ms-tag-bg: #A58ABA !important;
  --ms-tag-color: white !important;
  --ms-ring-color: #A58ABA !important;
}
</style>
