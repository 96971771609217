<template>
  <div id="prescription-details" class="card rounded-custom shadow border-0">
    <div class="card-body p-4">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <strong>{{ $props.data.prescription.number_text }}</strong>
        </div>
        <LastDate
          :clinic-id="$props.data.clinic.id"
          :customer-id="$props.data.customer.id"
        />
      </div>
      <div class="d-flex">
        <div class="d-flex flex-column me-3">
          <img src="@/assets/icons/logistic-checkin/calendar.svg" width="40" alt="calendar">
          <div class="d-flex h-100 justify-content-center align-items-center my-3">
            <img src="@/assets/icons/logistic-checkin/vertical-dots.svg" width="10" height="80" alt="vertical-dots">
          </div>
        </div>
        <div class="d-flex flex-column">
          <strong>{{ moment($props.data.prescription_test.date.start_at).format("DD/MM/YYYY - HH.mm") }}</strong>
          <small><strong>{{ $props.data.prescription_test.name }}</strong></small>
          <small>{{ $props.data.prescription_test.notes ?? i18n.t('No notes present') }}</small>
        </div>
      </div>
      <div class="d-flex align-items-center mb-4">
        <img class="me-3" src="@/assets/icons/logistic-checkin/van.svg" width="40" alt="van">
        <strong>{{ moment($props.priorityDate).format("DD/MM/YYYY - HH.mm") }}</strong>
      </div>
      <div class="row small text-center">
        <div class="col">
          <div class="mb-2">
            <img src="@/assets/icons/logistic-checkin/doctor.svg" width="40" alt="doctor">
          </div>
          <small class="d-block">{{ $props.data.doctor.first_name }}</small>
          <small>{{ $props.data.doctor.last_name }}</small>
        </div>
        <div class="col">
          <div class="mb-2">
            <img src="@/assets/icons/logistic-checkin/dental-chair.svg" width="40" alt="dental-chair">
          </div>
          <small class="d-block">{{ $props.data.clinic.name }}</small>
          <small>
            Tel.
            <a class="link-dark" :href="'tel:' + $props.data.clinic.phone">
              {{ $props.data.clinic.phone }}
            </a>
          </small>
        </div>
        <div class="col">
          <div class="mb-2">
            <img src="@/assets/icons/logistic-checkin/customer.svg" width="40" alt="customer">
          </div>
          <small class="d-block">{{ $props.data.customer.first_name }}</small>
          <small>{{ $props.data.customer.last_name }}</small>
        </div>
      </div>
    </div>

    <div class="info-departments card-header w-100 rounded-only-bottom border">
      <div class="d-flex justify-content-between">
        <strong>Fase interna: {{ $props.selectedFork.internal_phase.name }}</strong>
        <strong>Area: {{ $props.selectedFork.internal_phase.department.name }}</strong>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import { useI18n } from "vue-i18n";

import LastDate from "@/components/Checkin/LastDate.vue";

export default {
  name: "PrescriptionDetails",
  props: {
    data: {
      type: Object,
      require: true,
    },
    selectedFork: {
      type: Object,
      required: true,
    },
    priorityDate: {
      type: String,
      required: true,
    },
  },
  emits: [
    'focus'
  ],
  components: {
    LastDate,
  },
  setup() {
    const i18n = useI18n();

    return {
      moment,
      i18n
    };

  },
};
</script>
