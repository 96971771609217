<script setup>
import { defineProps, computed } from "vue";
import { show as showShippingCode } from '@/use/repositories/shippingCode/show';
import { base64ToPdf } from "../../use/utilities/base64ToPdf";
import { error } from "@/use/toast/error";
import { useI18n } from "vue-i18n";

const i18n = useI18n();

const props = defineProps({
    shippingCode: Object,
});

const fileName = computed(() => props.shippingCode.code + '.pdf');

const renderShippingCode = (id) => {
    // Print Label
    showShippingCode(id, 'with_pdf')
        .then((response) => base64ToPdf(response.data.meta.pdf))
        .catch(() => error(i18n.t('An error has occurred. Contact the technical service!')));
}

</script>
<template>
    <div class="d-flex align-items-center me-2">
        <span class="material-icons-outlined me-2">
            upload_file
        </span>
        <span class="text-decoration-underline" @click="renderShippingCode(props.shippingCode.id)">
            {{ fileName }}
        </span>
    </div>
</template>
