<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <Brand :width="120" :height="120"/>
      <div
        class="card col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-3 rounded-custom shadow border-0 p-3">
        <div class="card-body">
          <form @submit.prevent="reset">
            <h1 class="h3 mb-3 fw-normal text-center">
              Imposta la nuova password
            </h1>
            <input hidden v-model="data.token"/>
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input
                type="email"
                v-model="data.email"
                class="form-control"
                id="email"
                disabled
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                v-model="data.password"
                type="password"
                class="form-control"
                id="password"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                required
                autofocus
              />
            </div>
            <div class="mb-3">
              <label for="password_confirm" class="form-label">Conferma password</label>
              <input
                v-model="data.password_confirmation"
                type="password"
                class="form-control"
                id="password_confirm"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                required
              />
            </div>

            <template v-if="result.message">
              <div class="alert alert-success" role="alert">
                {{ result.message }}
              </div>
            </template>

            <template v-if="errors.length > 0">
              <div class="alert alert-danger" role="alert">
                <ul v-for="error in errors" :key="error.index">
                  <li>
                    {{ error }}
                  </li>
                </ul>
              </div>
            </template>

            <div class="mb-3" v-if="result.state === 'reset'">
              <router-link to="/login" href="#" class="link-dark">
                Vai alla login
              </router-link>
            </div>
            <button
              class="w-100 btn btn-lg btn-violet d-flex align-items-center justify-content-center"
              type="submit"
              :disabled="loading">
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"></span>
              <span v-if="loading">Loading...</span>
              <span v-else>Aggiorna</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import {useRoute} from "vue-router";
import Brand from "@/components/Brand.vue";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export default {
  name: "ResetPassword",
  components: {
    Brand
  },
  setup() {
    const axios = axiosInstanceWithoutLoader().primoLab;
    let loading = ref(false);
    const route = useRoute();
    const errors = [];
    const data = reactive({
      token: route.query.token,
      email: route.query.email,
      password: "",
      password_confirmation: "",
    });
    const result = reactive({
      state: "",
      message: "",
    });

    const setFocus = () => {
      let input = document.getElementById('password');
      input.focus();
    }

    const clearFileds = () => {
      // Empty data.
      data.password = "";
      data.password_confirmation = "";
    }

    const reset = async () => {
      // Show spinner.
      loading.value = true;

      // Reset value.
      errors.splice(0);
      result.message = "";

      // Reset password call.
      axios
        .post("/auth/password/reset", data)
        .then(async (response) => {
          // Set reset state.
          result.state = "reset";
          // Set error message.
          result.message = response.data.message;
        }).catch(async (error) => {
          // Set error message
          let messages = error.response.data.errors;
          messages.forEach((message) => errors.push(message));
          setFocus();
        }).finally(() => {
          clearFileds();
          // Hide spinner
          loading.value = false;
        });
    };

    return {
      data,
      reset,
      result,
      loading,
      errors,
    };
  },
};
</script>

<style scoped>
.alert {
  padding: 0.5rem 0;
}
ul {
  padding-left: 1.5em;
}
</style>
