import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function update(param) {
  const axios = axiosInstance().primoLab;
  let testWorkQueue = [];

  await axios
    .put(`/test-work-queues`, param)
    .then(async (response) => {
      testWorkQueue = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueue };
}
