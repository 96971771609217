<template>

  <!-- Filters section -->
  <template v-if="shippingProviders.length > 0">
    <div class="row mb-3 ms-4">
      <div class="col-3">
        <select class="form-select form-select" v-model="selectedShippingProviderId">
          <option v-for="(provider, index) in shippingProviders" :key="index" :value="provider.id">
            {{ provider.name }}
          </option>
        </select>
      </div>
      <div class="col-3">
        <input class="form-control" type="text" id="scanner" placeholder="scanner" autocomplete="off">
      </div>
    </div>
  </template>

  <!-- Header -->
  <div class="d-flex bg-light-violet justify-content-center">
    <!-- Searchbar -->
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">
      <img @click="focusSearch()" id="lent" src="@/assets/icons/search.svg" width="15" alt="search">
      <!-- Toggle search template -->
      <template v-if="searchTemplate === false">
        <span class="ms-1">clinica</span>
      </template>
      <template v-else>
        <input
          type="text"
          id="search"
          autocomplete="off"
          class="input-minimal ms-1"
          placeholder="Cerca"
          v-model="searchTerm"
          autofocus
        />
      </template>
    </div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">prescrizioni</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">Et. digitale</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">paziente</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">stato</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">data</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">appuntamento</div>
    <div class="cell d-flex justify-content-center align-items-center" :class="'widthCustom'">{{ isBaggingPage ? "video" : "" }}</div>
  </div>

  <div class="d-flex flex-wrap overflow-auto">

    <template v-if="searchResults.length > 0">
      <template v-for="(clinic, index) in searchResults" :key="index">
        <div class="d-flex w-100 flex-wrap">
          <ClinicGroup
            :clinic="clinic"
            :checkoutType="$route.params.type"
            :selectedShippingProvider="selectedShippingProvider"
            :allShippingProviders="shippingProviders"
            @selectedClinic="updateClinicGroupSelected"
            @uploadTestWorkQueueMedia="updateMedia"
            @updateTesWorkQueueState="updateTestWorkQueueState"
            @remove="removeClinicGroup"
            @setToCompleted="groupClinicIsCompleted"
          />
        </div>
      </template>
    </template>

    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center p-5">
        <h3>Nessuna lavorazione presente</h3>
      </div>
    </template>

  </div>

</template>

<script>
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import _ from 'lodash';
import moment from "moment";

import ClinicGroup from "./ClinicGroup";

import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { fetchAll as fetchAllShippingProviders } from '@/use/repositories/shippingProvider/fetchAll';
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import _head from "lodash/head";

export default {
  name: "TableData",
  props: {
    'globallySearch': {
      type: String,
      required: false,
    }
  },
  components: {
    ClinicGroup
  },
  emits: [
    'reset-search'
  ],
  setup(props, {emit}) {
    let testWorkQueueGroupedByClinic = [];
    const testWorkQueueDataTable = ref([])
    const searchTerm = ref("");
    const searchTemplate = ref(false);
    const numberText = ref("");
    const numberTextAllowed = ref([]);
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    let statesAllow = ['completed', 'bagged', 'packaged', 'shipped', 'iso-failed', 'manager-failed'];
    const isBaggingPage = route.params.type === 'bagging';
    const isPackagingPage = route.params.type === 'packaging';
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const shippingProviders = ref([]);
    const selectedShippingProviderId = ref(null);
    const shippingCurrentProviderSlug = ref(null);
    const selectedShippingProvider = computed (() => {
      return shippingProviders.value.find((item) => item.id === selectedShippingProviderId.value);
    });

    onMounted(async () => {
      // Fetch all shipping providers.
      await fetchAllShippingProviders().then(async (response) => {
        shippingProviders.value = response.shippingProviders;
        let provider = getProvider();
        // Set slug provider selected.
        shippingCurrentProviderSlug.value = provider.slug;
        // Set default id provider.
        selectedShippingProviderId.value = provider.id;
      });
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel("private-live");
    });

    const searchResults = computed(() => {
      // Filter clinic.
      if (searchTerm.value.length > 0) {
        return testWorkQueueDataTable.value.filter((item) => {
          return searchTerm.value.toLowerCase().split(' ').every(v => item.clinic_name.toLowerCase().includes(v));
        });
      }

      // Filter prescription or customer.
      if (props.globallySearch.length > 0) {
        // Dynamic filter on clinic, prescription and customer.
        let key = props.globallySearch.toLowerCase().split(' ');

        return testWorkQueueDataTable.value.map(item => ({
          ...item,
          test_work_queues: item.test_work_queues.filter((testWorkQueue) => {
            let firstNameAndLastName = testWorkQueue.customer_first_name + testWorkQueue.customer_last_name;

            return key.every(v => testWorkQueue.number_text.toLowerCase().includes(v)) || key.every(v => firstNameAndLastName.toLowerCase().includes(v))
          }),
        }));
      }

      return testWorkQueueDataTable.value;
    });

    watch(() => searchTerm.value, (value) => {
      if (value.trim().length > 0) {
        // Reset globally search.
        emit('reset-search', true);
      }
    });

    watch(() => props.globallySearch, (value) => {
      if (value.trim().length > 0) {
        // Reset value on clinic search.
        searchTerm.value = "";
        emit('reset-search', false);
      }
    });

    // Sync data when filter is changed.
    watch(() => selectedShippingProviderId.value, async () => {
      // Update route params.
      updateRouteParam(selectedShippingProviderId.value);
      // Sync current slug provider.
      shippingCurrentProviderSlug.value = shippingProviders.value.find((provider) => provider.id === selectedShippingProviderId.value).slug;
      // Empty data.
      testWorkQueueDataTable.value = [];
      // Fetch test work queue.
      await fetchAllTesWorkQueue( {
        action: 'checkout',
        shipping_provider_id: selectedShippingProviderId.value
      }).then(async (response) => {
        // Grouped by clinic.
        await groupedTestWorkQueueByClinic(response.testWorkQueue);
        // Prepare structure.
        await prepareDataStructure(testWorkQueueGroupedByClinic);
        // Subscribe to channel.
        subscribe("private-live", "test_work_queue_change-state");

        scanBarcode();
        orderByClinicCompleted();
      });
    });

    watch(numberText, (val) => {
      scroolTo(val);
      orderByClinicCompleted();
    });

    const updateRouteParam = (id) => {
      router.push({
        name: 'CheckoutLogistica',
        query: {
          shipping_provider: id,
        },
      });
    }

    const getProvider = () => {
      let provider;
      if (typeof route.query.shipping_provider === 'undefined') {
        // Find local provider obj.
        provider = shippingProviders.value.find((provider) => provider.slug === 'locale');
      } else {
        provider = shippingProviders.value.find((provider) => provider.id === parseInt(route.query.shipping_provider));
      }

      return provider;
    }

    // Subscribe to channel.
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, async (event) => {
        console.log("event received", event);
        // Push if delivery clinic slug is selected.
        let slugEvent;
        if (Object.keys(event.test_work_queue.prescription.delivery_clinic).length > 0) {
          slugEvent = event.test_work_queue.prescription.delivery_clinic.shipping_provider.slug;
        } else {
          slugEvent = event.test_work_queue.prescription.clinic.delivery_clinic.shipping_provider.slug;
        }

        if (shippingCurrentProviderSlug.value === slugEvent) {
          await pusherSync(getClinicObj(event.test_work_queue));
        }
      });
    };

    const clinicExists = (clinicId) => {
      return testWorkQueueDataTable.value.findIndex((item) => item.clinic_id === clinicId);
    }

    const parseTestWorkQueue = (testWorkQueue) => {
      return {
        customer_first_name: testWorkQueue.customer.first_name,
        customer_last_name: testWorkQueue.customer.last_name,
        id: testWorkQueue.id,
        media: testWorkQueue.media,
        prescription_id: testWorkQueue.prescription.id,
        number_text: testWorkQueue.prescription.number_text,
        delivery_date: moment(testWorkQueue.delivery_date),
        state_slug: testWorkQueue.state.slug,
        appointment_date: moment(testWorkQueue.date.start_at),
      };
    }

    const createClinic = (testWorkQueue) => {
      return {
        clinic_id: testWorkQueue.clinic.id,
        clinic_name: testWorkQueue.clinic.name,
        number_text_selected: null,
        completed: false,
        selected: false,
        test_work_queues: [{
          customer_first_name: testWorkQueue.customer.first_name,
          customer_last_name: testWorkQueue.customer.last_name,
          id: testWorkQueue.id,
          media: testWorkQueue.media,
          prescription_id: testWorkQueue.prescription.id,
          number_text: testWorkQueue.prescription.number_text,
          delivery_date: moment(testWorkQueue.delivery_date),
          state_slug: testWorkQueue.state.slug,
          appointment_date: moment(testWorkQueue.date.start_at),
        }],
        test_work_queue_id_selected: null,
        shipping_provider: testWorkQueue.clinic.shipping_provider,
      };
    }

    const pusherSync = async (testWorkQueue) => {

      let clinicIndex = clinicExists(testWorkQueue.clinic.id);

      // Exists.
      if (clinicIndex !== -1) {
        console.log("PUSHER: CLINIC EXIST");

        let testWorkQueueIndex = testWorkQueueDataTable.value[clinicIndex].test_work_queues.findIndex((item) => item.id === testWorkQueue.id);
        console.log(`PUSHER: INDEX TWQ: ${testWorkQueueIndex}`);

        // Test work queue exists.
        if (testWorkQueueIndex !== -1) {
          console.log("PUSHER: TWQ EXIST");

          // It's necessary to remove in various cases.
          if  (
                (isBaggingPage && (testWorkQueue.state.slug === 'shipped') )
                ||
                (isPackagingPage && testWorkQueue.state.slug === 'shipped')
              )
          {
            console.log("PUSHER: REMOVE TWQ");
            testWorkQueueDataTable.value[clinicIndex].test_work_queues.splice(testWorkQueueIndex, 1);

            // Check if last element.
            if (testWorkQueueDataTable.value[clinicIndex].test_work_queues.length === 0) {
              console.log("PUSHER: REMOVE CLINIC");
              // Remove clinic.
              testWorkQueueDataTable.value.splice(clinicIndex, 1);
            }

          } else {
            if (isBaggingPage) {
              // Check if all element with packaged state.
              if (testWorkQueueDataTable.value[clinicIndex].test_work_queues.every((el) => el.state_slug === 'packaged')) {
                console.log("PUSHER: ALL TWQ IN STATE PACKAGED");
                console.log("PUSHER: REMOVE CLINIC");
                // Remove clinic.
                return testWorkQueueDataTable.value.splice(clinicIndex, 1);
              }
            }

            console.log("PUSHER: ADD TWQ");
            testWorkQueueDataTable.value[clinicIndex].test_work_queues[testWorkQueueIndex] = parseTestWorkQueue(testWorkQueue);
          }

        } else {
          console.log("PUSHER: TWQ NOT EXIST");

          // Push the obj only if necessary.
          if (isBaggingPage && testWorkQueue.state.slug === 'shipped') {
            console.log("PUSHER: IS BAGGING PAGE AND TWQ STATE IS SHIPPED");
            return;
          }

          console.log("PUSHER: ADD TWQ");
          testWorkQueueDataTable.value[clinicIndex].test_work_queues.push(parseTestWorkQueue(testWorkQueue));

          // Order by delivery date.
          testWorkQueueDataTable.value[clinicIndex].test_work_queues = _.sortBy(testWorkQueueDataTable.value[clinicIndex].test_work_queues, 'delivery_date', 'ASC');
        }

      } else {
        console.log("PUSHER: CLINIC NOT EXIST");

        // Push the obj only if necessary.
        if ((isBaggingPage || isPackagingPage ) && testWorkQueue.state.slug === 'shipped') {
          console.log("PUSHER: IS BAGGING PAGE AND TWQ STATE IS SHIPPED");
          return;
        }

        console.log("PUSHER: CREATE CLINIC");
        let parsedData = createClinic(testWorkQueue);
        console.log("PUSHER: ADD CLINIC");
        testWorkQueueDataTable.value.push(parsedData);
      }
    };

    // Unsubscribe to channel
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    const scanBarcode = () => {
      // Define variables.
      let isEvent = false;
      let input = document.getElementById("scanner");

      input.addEventListener("focus", function () {
        if (!isEvent) {
          isEvent = true;
          input.addEventListener("keypress", function (event) {
            setTimeout(function () {
              if (event.key === "Enter") {

                numberText.value = input.value.toLowerCase().trim();

                if (isBaggingPage) {
                  console.log("ACTION: " + route.params.type);
                  findTestWorkQueueByNumberText('bagged', 'Il video è già stato caricato. Si prega di cancellare il video prima di procedere.');

                } else {
                  console.log("ACTION: " + route.params.type);
                  findTestWorkQueueByNumberText('packaged', 'La lavorazione è già stata processata.');
                }

                input.value = "";
                input.select();
              }
            }, 500);
          });
        }
      });

      document.addEventListener("keypress", function (e) {
        if (e.target.tagName !== "INPUT") {
          input.focus();
        }
      });
    }

    const groupClinicIsCompleted = (obj) => {
      testWorkQueueDataTable.value.forEach((el) => {
        if (el.clinic_id === obj.id) {
          el.completed = obj.value;
        }
      });

      orderByClinicCompleted();
    }

    const findTestWorkQueueByNumberText = async (stateSlug, message) => {
      // Reset filter applied to the technician.
      searchTerm.value = "";

      // Find clinic group;
      testWorkQueueDataTable.value.forEach((obj, index) => {
        obj.test_work_queues.forEach((el) => {
          if (el.number_text.toLowerCase() === numberText.value) {

            // If already processed with dynamic message.
            if (el.state_slug === stateSlug) {
              return dynamicWarningMessage(message);
            }

            // Not bip to arriving state in packaging page.
            if (isPackagingPage && el.state_slug === 'completed') {
              return notAvailable();
            }

            // Limit to completing one box at a time.
            if (! statesAllow.includes(el.state_slug)) {
              return notAvailable();
            }

            testWorkQueueDataTable.value[index].selected = true;
            testWorkQueueDataTable.value[index].number_text_selected = el.number_text;
            testWorkQueueDataTable.value[index].test_work_queue_id_selected = el.id;

            // Populate list of number text if only packaging page.
            if (isPackagingPage) {
              // Reset number_text allowed.
              numberTextAllowed.value = [];
              // Create allowed number_text
              testWorkQueueDataTable.value[index].test_work_queues.forEach((obj) => {
                numberTextAllowed.value.push(obj.number_text.toLowerCase())
              });
            }

          }
        });
      });
    }

    const dynamicWarningMessage = (message) => {
      return toast.warning(message, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    }

    const notAvailable = async () => {
      return toast.warning("Questa casella non è ancora disponibile.", {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    }

    const scroolTo = (val) => {
      testWorkQueueDataTable.value.forEach(clinic => {
        clinic.test_work_queues.forEach(testWorkQueue => {
          if (testWorkQueue.number_text.toUpperCase() === val.toUpperCase()) {
            console.log("Scroll to element");
            // Scroll to item.
            document.getElementById(`test_work_queue_${testWorkQueue.id}`).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          }
        });
      });
    }

    const orderByClinicCompleted = () => {
      testWorkQueueDataTable.value.sort(clinic => {
        return clinic.completed ? -1 : 1;
      });
    }

    const focusSearch = () => {
      searchTemplate.value = !searchTemplate.value;
      searchTerm.value = "";
    }

    const groupedTestWorkQueueByClinic = async (testWorkQueue) => {
      // Group by clinic name.
      testWorkQueueGroupedByClinic = _.chain(testWorkQueue)
        .groupBy('clinic_name')
        .map((value, key) => ({clinic: {id: _head(value).clinic_id, name: key}, testWorQueues: value}))
        .value();
      // Order by clinic name.
      testWorkQueueGroupedByClinic = _.orderBy(testWorkQueueGroupedByClinic, 'clinic.name');
    }

    const getClinicObj = (item) => {
      return {
        clinic: typeof item.prescription.delivery_clinic !== 'undefined'
                ? item.prescription.delivery_clinic
                : item.prescription.clinic,
        ...item,
      }
    }

    const prepareDataStructure = async (array) => {
      // Clear data.
      testWorkQueueDataTable.value = [];

      array.forEach((item) => {
        let data = {
          'clinic_id': item.clinic.id,
          'clinic_name': item.clinic.name,
          'selected': false,
          'number_text_selected': null,
          'completed': false,
          'test_work_queue_id_selected': null,
          'test_work_queues': [],
        }

        item.testWorQueues.forEach((twq) => {
          let objTwq = {
            'id': twq.id,
            'prescription_id': twq.prescription_id,
            'number_text': twq.number_text,
            'device_name': twq.device_name ?? '-',
            'customer_first_name': twq.first_name,
            'customer_last_name': twq.last_name,
            'media': twq.media,
            'delivery_date': moment(twq.delivery_date),
            'appointment_date': moment(twq.appointment_date),
            'state_slug': twq.state_slug,
          }
          // Push new data structure.
          data.test_work_queues.push(objTwq);
        });

        // Order by delivery date.
        data.test_work_queues = _.sortBy(data.test_work_queues, 'delivery_date', 'ASC');

        testWorkQueueDataTable.value.push(data);
      });
    }

    // Update clinic selected.
    const updateClinicGroupSelected = (obj) => {
      testWorkQueueDataTable.value.forEach((el) => {
        if (el.clinic_id === obj.id) {

          if (obj.value) {
            // Reset number_text allowed.
            numberTextAllowed.value = [];

            // Update number text allowed.
            el.test_work_queues.forEach((twq) => {
              numberTextAllowed.value.push(twq.number_text.toLowerCase());
            });

          } else {
            // Reset data.
            resetDataUtilsClinic(el);
          }

          el.selected = obj.value;
          //console.log(`SET CLINIC ID:${obj.id} TO ${obj.value}`);
        }
      });
    }

    const updateMedia = (obj) => {
      testWorkQueueDataTable.value.forEach((el) => {
        el.test_work_queues.forEach((item) => {
          if (item.id === obj.id) {
            console.log("UPDATE MEDIA AND CHANGE STATE SLUG");
            item.media = obj.media;
            item.state_slug = obj.state.slug;
            resetDataUtilsClinic(el);
          }
        });
      });
    }

    const updateTestWorkQueueState = (testWorkQueueId) => {
      testWorkQueueDataTable.value.forEach((el) => {
        el.test_work_queues.forEach((item) => {
          if (item.id === testWorkQueueId) {
            console.log("CHANGE STATE SLUG IN PACKAGED");
            updateTestWorkQueue({
              id: item.id,
              state_slug: "packaged",
              action: "update_state"
            }).then((response) => {
              console.log(`UPDATE TWQ ID: ${response.testWorkQueue.id} STATE IN ${response.testWorkQueue.state.slug}`);
              resetDataUtilsClinic(el);
            });
          }
        });
      });
    }

    // Reset utils variable in obj clinic.
    const resetDataUtilsClinic = (clinic) => {
      clinic.number_text_selected = null;
      clinic.test_work_queue_id_selected = null;
    }

    // Remove clinic when shipped is completed.
    const removeClinicGroup = (clinicIdToRemove) => {
      console.log(`CLINIC ID TO REMOVE:${clinicIdToRemove}`);
      let indexClinic = testWorkQueueDataTable.value.findIndex((clinic) => clinic.clinic_id === clinicIdToRemove);
      console.log(`CLINIC INDEX TO REMOVE:${indexClinic}`);
      if (indexClinic !== -1) {
        testWorkQueueDataTable.value.splice(indexClinic, 1);
        console.log(`CLINIC REMOVED`);
      }
    }

    return {
      searchTemplate,
      searchTerm,
      focusSearch,
      numberText,
      numberTextAllowed,
      updateClinicGroupSelected,
      updateMedia,
      updateTestWorkQueueState,
      removeClinicGroup,
      isBaggingPage,
      isPackagingPage,
      groupClinicIsCompleted,
      shippingProviders,
      selectedShippingProviderId,
      selectedShippingProvider,
      searchResults,
    }
  }
}
</script>

<style scoped>
.cell {
  text-transform: uppercase;
  padding: 1em;
  font-weight: bold;
}
#lent:hover {
  cursor: pointer;
}
.input-minimal,
.input-minimal:focus {
  border-bottom: 1px solid black;
  width: 7em;
}
.widthCustom {
  width: calc(100% / 6);
}
</style>
