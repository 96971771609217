<template>
  <div ref="qrcodeRef"></div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import QRCode from 'qrcodejs2';

const qrcodeRef = ref(null);
const props = defineProps({
  text: String,
})

onMounted(() => {
    if (qrcodeRef.value) {
        new QRCode(qrcodeRef.value, {
            text: props.text,
            colorDark: '#a58aba',
            correctLevel : QRCode.CorrectLevel.H
        });
    }
});
</script>
