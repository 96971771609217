import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {ref} from 'vue';

export async function history(testWorkQueuePhaseId) {
  const axios = axiosInstance().primoLab;
  const wareHouseHistory = ref([]);

  await axios
    .get("/warehouse-requests/test-work-queue-phase", {
      params: {
        id: testWorkQueuePhaseId,
      },
    }).then(async (response) => {
      wareHouseHistory.value = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { wareHouseHistory };
}
