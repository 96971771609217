<script setup>
  import {defineProps, ref} from "vue";

  const props = defineProps({
    vendors: {
      type: Array,
      required: true,
    },
    testWorkQueuePhaseId: {
      type: String,
      required: true
    },
    labelBtn: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    callback: {
      type: Function,
      required: true,
    },
  });

  const id = ref(null);
</script>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <select class="form-select form-select-sm"
            :style="{width: props.width}"
            v-model="id">
      <option :value="null" disabled>Seleziona il fornitore {{ id }}</option>
      <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.value">
        {{ vendor.label }}
      </option>
    </select>
    <button type="button"
            class="btn btn-sm btn-violet text-uppercase ms-2"
            @click="props.callback(props.testWorkQueuePhaseId, id)"
            :disabled="id === null">
      {{ props.labelBtn }}
    </button>
  </div>
</template>

<style scoped>
  select {
    width: fit-content;
  }
</style>
