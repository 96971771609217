<template>
  <div class="d-flex flex-column p-3 position-absolute top-0" :style="$props.style">
    <div class="btn btn-violet text-uppercase" @click="$router.push({ name: $props.routeName })">
      <slot></slot>
      <small>{{ $props.label }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    label: {
      type: String,
      required: false
    },
    routeName: {
      type: String,
      required: true,
    },
    style: {
      type: Object,
      required: false,
    }
  }
}
</script>
