<template>
  <div :class="`offcanvas offcanvas-${$props.position}`" tabindex="-1" :id="$props.id" data-bs-scroll="true" data-bs-backdrop="false">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">{{ $props.title }}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Offcanvas",
  props: {
    id: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    position: {
      type: String,
      require: true
    }
  }
}
</script>
<style>
.offcanvas.offcanvas-end {
  z-index: 9999;
}
.offcanvas-backdrop {
  pointer-events: none !important;
}
</style>
