<template>

  <div class="card shadow rounded-custom overflow-hidden w-100 h-100">

    <div class="card-header rounded-only-top bg-dark-violet p-3 text-center">
        <h3 class="text-white text-uppercase m-0">
          <template v-if="modify">
            <img class="me-2" src="@/assets/icons/pencil.svg" width="25" alt="pencil"/>
          </template>
          dettaglio ordine
          {{ Object.keys(orders).length ? ': ' + orders.number_text : '' }}
        </h3>
      </div>

    <div class="card-body p-0 overflow-auto">

      <template v-if="confirm">
        <Confirm
          class="p-3"
          :title="'Ordine incompleto'"
          :subtitle="'L\'ordine risulta incompleto: vuoi procedere comunque?'"
          :subtitle-width-class="'w-100'"
          :confirm-label="'SI'"
          :decline-label="'NO'"
          @continue="readChoice"
        />
      </template>

      <template v-if="completed">
        <Completed
          :message="'Ordine avaso correttamente'"
          :with-background-color="false"
        />
      </template>

      <template v-if="confirm === false && completed === false">

        <template v-if="Object.keys(orders).length">
          <div class="table-responsive h-75">
            <table class="table" :id="`order_details__${$props.data.id}`">
              <thead>
              <tr>
                <th>prescrizione</th>
                <th>tecnico</th>
                <th>reparto</th>
                <th>codice</th>
                <th colspan="1">prodotto</th>
                <th>quantità</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, index) in orders.articles" :key="index">
                <tr>
                  <template v-if="index === 0">
                    <td>{{ orders.number_text }}</td>
                    <td>{{ orders.technician }}</td>
                    <td>{{ orders.department }}</td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>
                  <td>
                    <span class="p-2" :class="{'border': modify}">{{ item.article.code }}</span>
                  </td>
                  <td>
                    <span class="p-2" :class="{'border': modify}">{{ item.article.name }}</span>
                  </td>

                  <td v-if="modify">
                    <Range
                      class="justify-content-center"
                      :value="item.approved_quantity"
                      :index="index"
                      :min="1"
                      :max="item.requested_quantity"
                      @value="readNewValue"
                    />
                  </td>

                  <td v-else>
                    {{ item.approved_quantity }}
                    <template v-if="readOnlyMode === false">
                      <img class="checkbox" @click="item.checked = false" v-if="item.checked" src="@/assets/icons/checkbox-checked-violet.svg" width="20" alt="checkbox-checked"/>
                      <img class="checkbox" @click="item.checked = true" v-else src="@/assets/icons/checkbox.svg" width="20" alt="checkbox"/>
                    </template>
                  </td>

                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="w-100 position-absolute bottom-0 bg-white">

            <template v-if="readOnlyMode">
              <div class="d-flex border-top w-90 flex-column align-items-end py-4">
                <span style="font-size: 1.2em;">TOT.<span class="fw-bold">
                  <span class="ms-3">{{ dynamicTotal }}</span>
                </span></span>
              </div>
            </template>

            <template v-else>
              <template v-if="modify">
                <div class="d-flex border-top flex-column align-items-end py-4 mx-3">
                  <div class="d-flex mb-3">
                    TOT.
                    <span class="fw-bold">
                      <span class="ms-3">{{ dynamicTotal }}</span>
                    </span>
                  </div>
                  <div class="d-flex">
                    <button @click="discardEdits()" type="button" class="btn btn-outline-violet text-uppercase me-3">annulla</button>
                    <button @click="modify = false" type="button" class="btn btn-violet text-uppercase">conferma modifiche</button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="d-flex border-top justify-content-between align-items-center py-4 mx-3">
                  <div id="edit" class="d-flex" @click="modify = ! modify">
                    <img src="@/assets/icons/edit.svg" width="40" alt="edit"/>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="btn btn-outline-violet text-uppercase me-3" @click="printOrder()">
                      <img class="me-2" src="@/assets/icons/printer.svg" width="25" alt="telegram"/>
                      stampa ordine
                    </button>
                    <button @click="sent()" type="button" class="btn btn-violet text-uppercase" :disabled="atLeastOneChecked === false">
                      <img class="me-2" src="@/assets/icons/telegram-white.svg" width="20" alt="telegram-white"/>
                      evadi ordine
                    </button>
                  </div>
                </div>
              </template>
            </template>


          </div>
        </template>

        <template v-else>
          <div class="d-flex justify-content-center align-items-center w-100 h-100">
            <h3 class="text-center">Seleziona un ordine per vedere il dettaglio</h3>
          </div>
        </template>

      </template>

    </div>

  </div>

</template>

<script>
import {computed, watch, ref} from 'vue';
import _ from 'lodash';

import Range from "@/components/Range";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Confirm from "@/components/Confirm";
import Completed from "@/components/Completed";
import {update} from '@/use/repositories/warehouse/update';

export default {
  name: "OrderDetails",
  props: {
    data: {
      type: Object,
      required: false,
    },
    readOnlyMode: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    Confirm,
    Range,
    Completed,
  },
  emit: [
    'resetOrders',
  ],
  setup(props, {emit}) {
    const confirm = ref(false);
    const completed = ref(false);
    const modify = ref(false);
    const orders = computed(() => {
        return props.data;
    });

    const atLeastOneChecked = computed(() => {
      return orders.value.articles.some((order) => order.checked === true);
    });

    // Print order as PDF
    const printOrder = async () => {
      // Define the document
      const doc = new jsPDF();

      doc.autoTable({
        html: `#order_details__${props.data.id}`,
        headStyles: {
          // Decimal color for the table header
          fillColor: [126, 100, 146]
        },
      })

      // Parse technician full name
      const technicianFullname = props.data.technician.replace(/\s/g, '-');

      // Filename for the PDF
      const fileName = `ordine_${props.data.number_text}-${technicianFullname}`;

      // Save the PDF
      doc.save(`${fileName}.pdf`);
    };

    const dynamicTotal = computed(() => {
      return _.sumBy(orders.value.articles, 'approved_quantity');
    });

    watch(orders,  (value) => {

      if (Object.keys(value).length === 0) {
        return;
      }

      // Reset modify mode.
      modify.value = false;

      orders.value.articles.forEach((order) => {
        order.checked = false;
      });
    });

    const allIsChecked = computed(() => {
      return orders.value.articles.every((order) => order.checked === true);
    });

    const discardEdits = () => {
      modify.value = false;
      orders.value.articles.forEach((order) => {
        order.approved_quantity = order.requested_quantity;
      });

    }

    const readNewValue = (obj) => {
      orders.value.articles[obj.index].approved_quantity = obj.value;
    }

    const sent = () => {
      if (allIsChecked.value) {
        console.log('ALL IS CHECKED');
        return store();
      }

      console.log('NOT ALL CHECKED');
      return confirm.value = true;
    }

    const readChoice = (value) => {
      if (value) {
        return store();
      }

      return confirm.value = false;
    }

    const store = () => {

      let requets = [];
      let idsToSelected = [];

      orders.value.articles.forEach((warehouseRequest) => {
        if (warehouseRequest.checked) {
          requets.push({ id: warehouseRequest.id, state_slug: "approved", approved_quantity: warehouseRequest.approved_quantity });
        } else {
          requets.push({ id: warehouseRequest.id, state_slug: "rejected", approved_quantity: 0 });
        }

        idsToSelected.push(warehouseRequest.id);
      });
      console.log(requets);

      update('approved', requets).then(() => {
        confirm.value = false;
        completed.value = true;

        setTimeout(() => {
          completed.value = false;
        }, 3000);

        // Empty box.
        emit('resetOrders', true);
      });

    }

    return {
      confirm,
      orders,
      readNewValue,
      modify,
      atLeastOneChecked,
      printOrder,
      discardEdits,
      dynamicTotal,
      allIsChecked,
      sent,
      readChoice,
      completed,
    }
  }
}
</script>

<style scoped>
tr th,
tr td {
  text-align: center;
  border: 0;
  padding: 1em;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
}
tr th {
  background-color: #E1D2F2;
}
.btn-outline-violet:hover {
  color: #A58ABA !important;
  background-color: #FFFFFF !important;
  border-color: #A58ABA !important;
}
#edit:hover,
.checkbox:hover {
  cursor: pointer;
}
.table-responsive thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
