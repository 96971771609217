<template>
  <div class="card shadow rounded-custom overflow-hidden w-100 h-100">
    <div class="card-header rounded-only-top bg-green p-3 text-center text-white">
      <h3 class="text-white text-uppercase m-0">
        ordini evasi
        <img class="icon__close float-end" @click="hideBox()" src="@/assets/icons/close-white.svg" width="35" alt="close-white">
      </h3>
    </div>
    <div class="card-body p-0 overflow-auto">
      <div class="table-responsive h-100 overflow-auto">
        <table class="table">
          <thead>
          <tr>
            <th nowrap><InputSearchMinimal :name="'prescrizione'" @termToSearch="prescriptionNumberTextTerm"/></th>
            <th nowrap><InputSearchMinimal :name="'data ordine'" @termToSearch="dataOrderTerm"/></th>
            <th nowrap><InputSearchMinimal :name="'richiedente'" @termToSearch="applicantTerm"/></th>
            <th nowrap>reparto</th>
            <th nowrap>quantità</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(order, index) in approvedOrdersFiltered" :key="index">
            <OrderApproved
              :data="order"
              @selected="readSelected"
            />
          </template>
          </tbody>
        </table>
        <template v-if="approvedOrdersFiltered.length === 0">
          <div class="d-flex justify-content-center align-items-center w-100 h-100">
            <h3 class="text-center">Nessun ordine evaso presente</h3>
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import InputSearchMinimal from "@/components/InputSearchMinimal";
import OrderApproved from "@/components/warehouse/supervisor/OrderApproved";

export default {
  name: "OrdersApproved",
  props: {
    data: {
      type: Array,
      required: true,
    }
  },
  components: {
    InputSearchMinimal,
    OrderApproved,
  },
  emit: [
    'orderDetails',
    'close',
    'resetOrderDetails',
  ],
  setup(props, {emit}) {
    const approvedOrders = ref(props.data);
    const approvedOrdersFiltered = ref([]);

    onMounted(() => {
      approvedOrdersFiltered.value = approvedOrders.value;
    });

    // To search.
    const prescription = ref("");
    const data = ref("");
    const technician = ref("");

    // Emits.
    const prescriptionNumberTextTerm = (term) => { prescription.value = term }
    const dataOrderTerm = (term) => { data.value = term }
    const applicantTerm = (term) => { technician.value = term }

    const filter = (term, field) => {
      watch(term, (value) => {
        // Clear data on details order box.
        emit('resetOrderDetails');
        // Reset selected.
        approvedOrdersFiltered.value.forEach((order) => order.selected = false);
        // Filter.
        approvedOrdersFiltered.value = approvedOrders.value.filter((order) => {
          return value.toLowerCase().split(' ').every(v => order[field].toLowerCase().includes(v));
        });
      });
    }

    // Watcher listeners.
    filter(prescription,'number_text');
    filter(data,'date');
    filter(technician,'technician');

    const readSelected = (orderSelected) => {
      approvedOrders.value.forEach((order) => {
        order.selected = order.id === orderSelected.id;
      });

      emit('orderDetails', {
        order: orderSelected,
        readOnlyMode: true
      });
    }

    const hideBox = () => {
      emit('close', false);
    }

    return {
      prescriptionNumberTextTerm,
      dataOrderTerm,
      applicantTerm,
      readSelected,
      approvedOrdersFiltered,
      hideBox,
    }
  }
}
</script>

<style scoped>
.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #E9E9E9;
}
tr th,
tr td {
  text-align: center;
  border: 0;
  padding: 1em;
  text-transform: uppercase;
}
.icon__close:hover {
  cursor: pointer;
}
</style>
