<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <Brand :width="120" :height="120" />
      <div class="card col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-3 rounded-custom shadow border-0 p-3">
        <div class="card-body">
          <form @submit.prevent="recovers">
            <h1 class="h3 mb-3 fw-normal text-center">Reset password</h1>
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input
                v-model="form.email"
                type="email"
                class="form-control"
                id="email"
                placeholder="name@example.com"
                required
                autofocus
              />
            </div>
            <div v-if="result.message"
              :class="result.hasError ? 'alert alert-danger' : 'alert alert-success'"
              role="alert">
              {{ result.message }}
            </div>
            <button class="w-100 btn btn-lg btn-violet d-flex align-items-center justify-content-center" type="submit" :disabled="loading">
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"></span>
              <span v-if="loading">Loading...</span>
              <span v-else>Reset</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Brand from "@/components/Brand.vue";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export default {
  name: "RecoverPassword",
  components: {
    Brand
  },
  setup() {
    let loading = ref(false);
    const form = reactive({ email: "" });
    const result = reactive({
      hasError: false,
      message: "",
    });
    const axios = axiosInstanceWithoutLoader().primoLab;

    const setFocus = () => {
      let input = document.getElementById('email');
      input.focus();
    }

    const recovers = async () => {
      // Show spinner.
      loading.value = true;

      // Reset value.
      result.hasError = false;
      result.message = "";

      // Recover password call.
      axios
        .post("/auth/password/email", form)
        .then(async (response) => {
          // Set message.
          result.message = response.data.message;
        })
        .catch(async (error) => {
          // Set error message.
          result.hasError = true;

         if (error.response.data.errors === undefined) {
           result.message = error.response.data.message;
         } else {
           result.message = error.response.data.errors[0];
         }

          setFocus();
        })
        .finally(() => {
        // Hide spinner.
        loading.value = false;
      });

    };

    return {
      form,
      recovers,
      result,
      loading,
    };
  },
};
</script>
