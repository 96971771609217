import { ref } from "vue";

export function detectWebcam() {
  let hasWebcam = ref(false);
  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return hasWebcam;

  md.enumerateDevices().then((devices) => {
    hasWebcam.value = devices.some((device) => "videoinput" === device.kind);
  });

  return hasWebcam;
}
