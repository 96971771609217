import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function create(testWorkQueuePhaseId, ids, file, materialId, colorId, typeSlug) {
  const axios = axiosInstance().primoLab;
  let asset = [];
  let formData = new FormData();
  formData.append("file", file);
  formData.append("test_work_queue_phase_id", testWorkQueuePhaseId);
  formData.append("material_id", materialId);
  formData.append("color_id", colorId);
  formData.append("type_slug", typeSlug);

  ids.forEach(function (element) {
    formData.append("prescription_row_ids[]", element);
  });

  await axios
    .post("/assets", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (response) => {
      asset = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { asset };
}
