import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function show(id, action) {
  let channel = {};
  await axios
    .get(`/channels/${id}`, {
      params: {
        'action': action
      }
    })
    .then(response => channel = response.data)
    .catch(error => { throw error.response.data.errors });

  return { channel };
}
