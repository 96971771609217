<template>
  <div class="container">
    <div class="row align-items-center vh-100">
      <div class="col-12 card shadow p-5">
        <Dropzone
          :upload="upload"
          :multi-upload="true"
          :max-files="10"
          :upload-through-qrcode="true"
          :assign-url-s3="assignUrlS3"
          @increment="mediaCounter++"
          @decrease="mediaCounter--"
          @s3-url-upload-completed="completed"
        >
          <svg
            width="10em"
            height="10em"
            viewBox="0 0 16 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style="pointer-events: none"
          >
            <path
              fill-rule="evenodd"
              d="m 8.0274054,0.49415269 a 5.53,5.53 0 0 0 -3.594,1.34200001 c -0.766,0.66 -1.321,1.52 -1.464,2.383 -1.676,0.37 -2.94199993,1.83 -2.94199993,3.593 0,2.048 1.70799993,3.6820003 3.78099993,3.6820003 h 8.9059996 c 1.815,0 3.313,-1.43 3.313,-3.2270003 0,-1.636 -1.242,-2.969 -2.834,-3.194 -0.243,-2.58 -2.476,-4.57900001 -5.1659996,-4.57900001 z m 2.3539996,5.14600001 -1.9999996,-2 a 0.5,0.5 0 0 0 -0.708,0 l -2,2 a 0.5006316,0.5006316 0 1 0 0.708,0.708 l 1.146,-1.147 v 3.793 a 0.5,0.5 0 0 0 1,0 v -3.793 l 1.146,1.147 a 0.5006316,0.5006316 0 0 0 0.7079996,-0.708 z"
            ></path>
          </svg>
          <br />
        </Dropzone>
        <!-- Buttons -->
        <div class="modal-footer p-1 border-0 justify-content-center">
          <button
            class="btn btn-violet text-uppercase mt-4"
            @click="upload = true"
            :disabled="mediaCounter === 0"
          >
            conferma
          </button>
          <button
            v-if="mediaCounter === 0"
            class="btn btn-outline-violet text-uppercase mt-4"
            @click="confirm = true"
          >
            torna indietro
          </button>
        </div>
      </div>
    </div>

    <template v-if="confirm">
      <Confirm
        :modal_id="'comeback'"
        :title="'Le tue modficihe andranno perse!'"
        :subtitle="'Sei sicuro di voler procedere?'"
        :confirm_label="'Si'"
        :decline_label="'No'"
        @confirm="$router.go(-1)"
        @decline="confirm = false"
      />
    </template>
  </div>
</template>

<script>
import Dropzone from "@/components/upload/Dropzone";
import Confirm from "@/components/Modal/Confirm";
import { onMounted, ref, toRaw } from "vue";
import { assignUrl as assignUrlFeedback } from "@/use/repositories/feedbacks/media/assignUrl";
import { assignUrl as assignUrlChat } from "@/use/repositories/chat/media/assignUrl";
import { customEvent } from "@/use/repositories/feedbacks/media/customEvent";
import { success } from "@/use/toast/success";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "ExternalDevice",
  components: {
    Dropzone,
    Confirm,
  },
  setup() {
    const i18n = useI18n();
    const upload = ref(false);
    const mediaCounter = ref(0);
    const confirm = ref(false);
    const assignUrlS3 = ref("");
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id;

    onMounted(() => {
      if (route.params.type === "feedback") {
        assignUrlS3.value = assignUrlFeedback;
      }

      if (route.params.type === "chat") {
        // Force auth token as signature
        localStorage.setItem("authToken", route.query.signature);
        assignUrlS3.value = assignUrlChat;
      }
    });

    const completed = (fileNames) => {
      console.log(toRaw(fileNames));
      let params = {
        event_name: id,
        data: fileNames,
      };
      // Lunch custom pusher event.
      customEvent(params).then(() => {
        // Show toast message.
        success(i18n.t("Multimedia upload successful"));
        // Redirect on root.
        setTimeout(() => {
          router.push({ path: "/" });
        }, 1000);
      });
    };

    return {
      upload,
      mediaCounter,
      confirm,
      assignUrlS3,
      completed,
    };
  },
};
</script>
