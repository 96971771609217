import axios from "axios";
import getAuthToken from "@/use/get-auth-token";

const mediaUploaderApi = axios.create({ baseURL: process.env.VUE_APP_API_MEDIA_UPLOADER_BASE_URL });

mediaUploaderApi.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default mediaUploaderApi;
