<template>
  <div class="d-flex justify-content-start align-items-center flex-column mt-5 p-3">
    <Brand :width="150" withChat />
    <div class="col-sm-12 col-md-10 col-xl-8 col-xxl-7 d-flex flex-column text-center">
      <label for="prescription_search" class="form-label text-violet fs-2">Ricerca prescrizione</label>
      <Searchbar @select="goToConsultingPage" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

import Searchbar from "./Searchbar.vue";

import Brand from "@/components/Brand";

export default {
  name: "Search",
  components: {
    Brand,
    Searchbar
  },
  setup() {
    const router = useRouter();
    const prescriptions = ref([]);

    const goToConsultingPage = (prescription) => {
      return router.push({
        name: "ConsultingDashboard",
        params: {
          prescription_id: prescription.id,
        },
      });
    }

    return {
      prescriptions,
      goToConsultingPage,
    };
  },
};
</script>

<style>
.multiselect-option.is-selected.is-pointed,
.multiselect-option.is-selected,
.multiselect-spinner {
  background: #A58ABA !important;
  color: white !important;
}
</style>
