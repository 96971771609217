<template>
  <div class="category flex" :class="{'bg-home-violet': $props.category.selected}" @click="select($props.category.id)">
    {{ $props.category.name }}
  </div>
</template>

<script>
export default {
  name: "Category",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'select',
  ],
  setup(props, {emit}) {
    const select = (id) => {
      emit('select', id);
    }

    return {
      select,
    }
  }
}
</script>

<style scoped>
.category:hover {
  cursor: pointer;
}
</style>
