<template>
  <div class="d-flex w-100 h-100">

    <div class="d-flex flex-column w-35">
      <div class="d-flex bg-light-violet p-3 justify-content-center fw-bold text-uppercase">categorie</div>
      <div class="categories d-flex flex-column overflow-auto border-right h-100">
        <template v-if="articlesAndCategoriesUploaded">
          <template v-for="(category, index) in categoryWithArticlesFiltered" :key="index">
            <Category
              class="py-1"
              style="padding-left: 2em;font-size: 0.8em;"
              :category="category"
              @select="readCategorySelection"
            />
          </template>
        </template>
        <template v-else>
          <div class="d-flex w-100 h-100 justify-content-center align-items-center">
            <div class="spinner-border text-violet" role="status"></div>
          </div>
        </template>
        <template v-if="articlesAndCategoriesUploaded && categoryWithArticlesFiltered.length === 0">
          <div class="d-flex w-100 h-100 justify-content-center align-items-center">
            Nessuna categoria trovata
          </div>
        </template>
      </div>
    </div>

    <div class="d-flex flex-column w-65">
      <div class="d-flex bg-light-violet p-3 justify-content-center fw-bold text-uppercase">prodotto</div>
      <div class="products d-flex flex-column overflow-auto h-100">
        <template v-if="articlesAndCategoriesUploaded">
          <template v-for="(article, index) in articles" :key="index">
            <Product
              class="p-2"
              style="font-size: 0.8em;"
              :article="article"
              @select="readArticleSelection"
              @add="addToRequestArea"
            />
          </template>
        </template>
        <template v-else>
          <div class="d-flex w-100 h-100 justify-content-center align-items-center">
            <div class="spinner-border text-violet" role="status"></div>
          </div>
        </template>
        <template v-if="articlesAndCategoriesUploaded && articles.length === 0">
          <div class="d-flex w-100 h-100 justify-content-center align-items-center">
            Nessuna articolo trovato
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {onMounted, watch} from "vue";
import {ref} from 'vue';
import _ from 'lodash';

import Product from "@/components/warehouse/technician/Product";
import Category from "@/components/warehouse/technician/Category";

import {fetchAllAticlesCategories} from '@/use/repositories/warehouse/fetchAllAticlesCategories';

export default {
  name: "Products",
  props: {
    search: {
      type: String,
      required: true,
    }
  },
  components: {
    Category,
    Product
  },
  emits: [
    'add',
  ],
  setup(props, {emit}) {
    const dataProcess = ref([]);
    const categoryWithArticles = ref([]);
    const categoryWithArticlesFiltered = ref([]);
    const articles = ref([]);
    const articlesAndCategoriesUploaded = ref(false);

    onMounted(async () => {
      fetchAllAticlesCategories().then(async (response) => {
        groupedDataByCategory(response.articlesCategories.value);
        categoryWithArticles.value = addPropertyUtils(dataProcess.value);
        categoryWithArticlesFiltered.value = categoryWithArticles.value;
        articlesAndCategoriesUploaded.value = true;
      });
    });

    // Reactive search.
    watch(() => props.search, (keyWord) => {
      if (keyWord.length > 0) {
        resetSelections();

        // Filter categories.
        categoryWithArticlesFiltered.value = categoryWithArticles.value.filter((item) => {
          return  keyWord.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        });

        // Fetch all articles and filter.
        articles.value = fetchAllArticles().filter((item) => {
          return  keyWord.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
                  ||
                  keyWord.toLowerCase().split(' ').every(v => item.code.toLowerCase().includes(v))
        });

      } else {
        categoryWithArticlesFiltered.value = categoryWithArticles.value;
        readCategorySelection(_.head(categoryWithArticles.value).id);
      }
    });

    const groupedDataByCategory = (response) => {
      response.map((item) => {

        let index = dataProcess.value.findIndex((category) => category.id === item.article.category.id);

        if (index !== -1) {
          let article = {
            id: item.article.id,
            wareHouseArticleId: item.id,
            quantity: item.quantity,
            name: item.article.name,
            code: item.article.code,
          }
          //console.log("CATEGORY FOUND... ADD ARTICLE");
          return dataProcess.value[index].articles.push(article);

        } else {
          let category = {
            id: item.article.category.id,
            name: item.article.category.name,
            articles: []
          }

          let article = {
            id: item.article.id,
            wareHouseArticleId: item.id,
            quantity: item.quantity,
            name: item.article.name,
            code: item.article.code,
          }

          //console.log("CATEGORY NOT FOUND... ADD CATEGORY");
          category.articles.push(article);
          dataProcess.value.push(category);
        }

      });
    }

    const fetchAllArticles = () => {
      let allArticles = [];
      categoryWithArticles.value.forEach((item) => {
        item.articles.forEach((article) => {
          allArticles.push(article);
        });
      });
      console.log(allArticles);
      return allArticles;
    }

    const addPropertyUtils = (ariclesCategories) => {
      ariclesCategories.map((category, categoryIndex) => {
        addSelectedProperty(category, categoryIndex);
      });

      return ariclesCategories;
    }

    const addSelectedProperty = (singleObj, categoryIndex) => {
      singleObj.selected = categoryIndex === 0;
      // Show articles by category selected.
      articles.value = singleObj.articles;
      singleObj.articles.map((article, articleIndex) => {
        article.selected = articleIndex === 0;
      });
    }

    const resetSelections = async () => {
      categoryWithArticlesFiltered.value.forEach((category) => {
        category.selected = false;
        category.articles.forEach((article) => {
          article.selected = false;
        });
      });
    }

    const resetArticleSelections = async (articles) => {
      articles.forEach((article) => {
        article.selected = false;
      });
    }

    // Emits.
    const readCategorySelection = async (id) => {
      await resetSelections();
      let index = categoryWithArticlesFiltered.value.findIndex((obj) => obj.id === id);
      if (index !== -1) {
        categoryWithArticlesFiltered.value[index].selected = true;
        console.log(categoryWithArticlesFiltered.value[index].articles)
        // Selected first article item.
        _.head(categoryWithArticlesFiltered.value[index].articles).selected = true;
        articles.value = categoryWithArticlesFiltered.value[index].articles;
      }
    }
    const readArticleSelection = async (id) => {
      let index = articles.value.findIndex((obj) => obj.id === id);
      if (index !== -1) {
        // Reset articles selection.
        await resetArticleSelections(articles.value);
        // Set selection.
        articles.value[index].selected = true;
      }
    }
    const addToRequestArea = (article) => {
      emit('add', article);
    }

    return {
      categoryWithArticlesFiltered,
      readCategorySelection,
      articles,
      readArticleSelection,
      addToRequestArea,
      articlesAndCategoriesUploaded,
    }
  }
}
</script>

<style scoped>
.categories,
.products {
  font-size: 1.2em;
  margin-bottom: 4em;
}
</style>
