<template>
  <div>
    <template v-if="readOnlyMode">
      <div class="btn rounded-btn-custom shadow pe-none"
           :class="{ disabled: ($props.counter === 0) }"
           :style="{ backgroundColor: bgColor, color: colorText }">
        <div class="d-flex align-items-center">
          <strong>{{ $props.label }}</strong>
          <span v-if="showLoading" class="spinner-grow spinner-grow-sm ms-1" role="status" aria-hidden="true"></span>
          <span v-else class="ms-1">({{ $props.counter }})</span>
        </div>
      </div>
    </template>
    <template v-else>
      <button class="btn rounded-btn-custom shadow"
              :class="{ disabled: ($props.counter === 0) }"
              :style="{ backgroundColor: bgColor, color: colorText, disabled: $props.counter }"
              @click="$props.active ? false : selected()">
        <span class="d-flex align-items-center">
          <template v-if="iconFill">
             <svg class="me-1" xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="15px" :fill="colorText">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
            </svg>
          </template>
          <template v-else>
            <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18">
              <g fill="none" :stroke="colorText" stroke-width="2">
                <circle cx="9" cy="9" r="9" stroke="none"/>
                <circle cx="9" cy="9" r="8" fill="none"/>
              </g>
            </svg>
          </template>
          <strong>{{ $props.label }}</strong>
          <span v-if="showLoading" class="spinner-grow spinner-grow-sm ms-1" role="status" aria-hidden="true"></span>
          <span v-else class="ms-1">({{ counter }})</span>
        </span>
      </button>
    </template>
  </div>
</template>

<script>
import {onMounted, ref, watch} from 'vue';

export default {
  name: "Counter",
  props: {
    counter: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    stateSlug: {
      type: [String, Array],
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    readOnlyMode: {
      type: Boolean,
      default: false,
    },
    preSelected: {
      type: Boolean,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'selected',
  ],
  setup(props, {emit}) {
    const bgColor = ref('white');
    const colorText = ref(props.color);
    const iconFill = ref(false);

    onMounted(() => {
      if (props.preSelected) {
        emit('selected', {'stateSlug': props.stateSlug, 'color': props.color});
      }
    });

    const selected = () => {
      iconFill.value = true;
      colorText.value = 'white';
      bgColor.value = props.color;
      if (props.active === false) {
        emit('selected', {'stateSlug': props.stateSlug, 'color': props.color});
      }
    }

    const deselect = () => {
      iconFill.value = false;
      colorText.value = props.color;
      bgColor.value = 'white';
    }

    watch(() => props.active, (value) => {
      if (value) {
        selected();
      } else {
        deselect();
      }
    });

    return {
      selected,
      colorText,
      bgColor,
      iconFill,
    }
  }
}
</script>

<style scoped>
  .btn {
    white-space: nowrap;
  }
</style>
