import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchRejectedReasons() {
  const axios = axiosInstance().primoLab;
  let rejectedReasons = [];

  await axios
    .get(`/prescriptions-rejected-reasons`)
    .then(async (response) => {
      rejectedReasons = response.data.data.map((item) => {
        return {
          value: item.id,
          slug: item.slug,
          label: item.description,
        };
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { rejectedReasons };
}
