<template>
  <nav>
    <ul class="pagination">
      <li class="page-item" v-if="$props.pageNumber > 1">
        <a class="page-link" href="#" tabindex="-1" @click="decrement()">Precedente</a>
      </li>
      <li class="page-item disabled"><a class="page-link" href="#">{{ $props.pageNumber }}</a></li>
      <li v-if="$props.pageNumber !== $props.totalPages" class="page-item disabled"><a class="page-link" href="#">...</a></li>
      <li class="page-item disabled"><a class="page-link" href="#">{{ $props.totalPages }}</a></li>
      <li class="page-item" v-if="$props.pageNumber < $props.totalPages">
        <a class="page-link" href="#" @click="increment()">Successivo</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    array: {
      type: Array,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: null,
      required: true,
    },
  },
  emits: [
    'increment',
    'decrement',
  ],
  setup(props, {emit}) {
    const increment = () => emit('increment');
    const decrement = () => emit('decrement');

    return {
      increment,
      decrement
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../scss/abstracts/_variables.scss";
  .page-link {
    color: $violet;
    &:hover {
      color: $dark_violet;
    }
    &:focus {
      color: $dark_violet;
      border-color: $rgb_violet;
      box-shadow: $shadow_violet;
    }
  }
  .page-item > .page-link {
    color: $violet!important;
  }

</style>
