

<script>
import Spinner from "@/components/general/Spinner.vue";

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Spinner
  },
  setup() {
    return {}
  }
};
</script>

<template>
  <div class="card rounded-custom shadow h-100 m-2" style="width: 12rem;">
    <div class="card-body d-flex align-items-center">
      <div class="card-title col-md-7">{{ $props.label }}</div>
      <template v-if="$props.loading">
      <Spinner></Spinner>
      </template>
      <template v-else>
        <div class="card-text col-md-5 text-center fw-bold">{{ $props.value }}</div>
      </template>
    </div>
  </div>
</template>

