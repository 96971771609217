import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function store(params) {
  const axios = axiosInstance().primoLab;
  let result = null;

  await axios
    .post('/feedbacks', params)
    .then(async (response) => {
      result = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return {result};
}
