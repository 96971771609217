import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function store(formData) {
  let message = [];

  await axios
    .post("/messages", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (response) => {
      message = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { message };
}
