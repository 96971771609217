<template>
  <form class="dropzone bt-b-radius mb-3 text-muted text-center">
    <slot></slot>
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div id="dropzone-message" style="display: none;">
      <div class="dropzone-title">{{ $props.label }}</div>
    </div>
  </form>
</template>

<script>
import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;
import 'dropzone/dist/dropzone.css';
import {formatFilename} from "@/use/utilities/media/formatFilename";
import { warning } from "@/use/toast/warning";
import { useI18n } from "vue-i18n";

export default {
  name: 'Dropzone',
  props: {
    label: {
      type: String,
      default: "Trascina il file qui o fai clic per selezionarlo.",
    },
    upload: {
      type: Boolean,
      require: true,
    },
    multiUpload: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 1,
    },
    maxFilesize: {
      type: Number,
      default: 10,
    },
    assignUrlS3: {
      type: Function,
      require: true,
    },
    storeUrl: {
      type: Function,
      require: false,
    },
    formData: {
      type: Object,
      required: false,
    },
    uploadThroughQrcode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mediaNames: [],
      file: null,
    };
  },
  watch: {
    upload(value) {
      if (value) {
        if (this.file !== null) {
          // Manually process all file
          this.dropzone.processQueue();
        }
      }
    },
  },
  mounted() {
    const vm = this;
    const i18n = useI18n();

    let options = {
      // The URL will be changed for each new file being processing
      url: '/',

      // Since we're going to do a `PUT` upload to S3 directly
      method: 'POST',

      // Set media options.
      acceptedFiles: 'image/*',

      /**
       * If not `null` defines how many files this Dropzone handles. If it exceeds,
       * the event `maxfilesexceeded` will be called. The dropzone element gets the
       * class `dz-max-files-reached` accordingly so you can provide visual
       * feedback.
       */
      maxFiles: vm.maxFiles,

      /**
       * The maximum filesize (in MiB) that is allowed to be uploaded.
       */
      maxFilesize: vm.maxFilesize, // MB

      // Customize the wording
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      /**
       * If `true`, this will add a link to every file preview to remove or cancel (if
       * already uploading) the file. The `dictCancelUpload`, `dictCancelUploadConfirmation`
       * and `dictRemoveFile` options are used for the wording.
       */
      addRemoveLinks: false,

      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: vm.maxFiles,

      /**
       * Whether to send multiple files in one request. If
       * this it set to true, then the fallback file input element will
       * have the `multiple` attribute as well. This option will
       * also trigger additional events (like `processingmultiple`). See the events
       * documentation section for more information.
       */
      uploadMultiple: false,

      /**
       * An optional object to send additional headers to the server. Eg:
       * `{ "My-Awesome-Header": "header value" }`
       */
      headers: {
        Accept: '*/*',
      },

      /**
       * Should the default headers be set or not?
       * Accept: application/json <- for requesting json response
       * Cache-Control: no-cache <- Request shouldnt be cached
       * X-Requested-With: XMLHttpRequest <- We sent the request via XMLHttpRequest
       */
      defaultHeaders: true,

      // Here we request a signed upload URL when a file being accepted
      accept(file, done) {
        vm.file = file;
        vm.assignUrlS3(file.upload.filename).then((response) => {
          // Set all inputs data.
          file.s3 = {
            url: response.assignUrl.attributes.action,
            inputs: response.assignUrl.inputs,
          };
          // Push file to upload.
          vm.mediaNames.push(file.upload.filename);
          done();
        });
      },

      // Called just before the file is sent. Gets the `xhr` object as second
      // parameter, so you can modify it (for example to add a CSRF token) and a
      // `formData` object to add additional information.
      sending(file, xhr, formData) {
        // Set the s3 options from preassiged url response.
        for (const [key, value] of Object.entries(file.s3.inputs)) {
          console.log(key, value);
          formData.append(key, value);
        }
      },

      // When the complete upload is finished and successful
      // Receives `file`
      success(file) {
        if (file.previewElement) {
          return file.previewElement.classList.add('dz-success');
        }
      },

      /**
       * A function that is invoked before the file is uploaded to the server and renames the file.
       * This function gets the `File` as argument and can use the `file.name`. The actual name of the
       * file that gets used during the upload can be accessed through `file.upload.filename`.
       */
      renameFile: function(file) {
        return formatFilename(file);
      },

      queuecomplete() {
        if (vm.uploadThroughQrcode) {
          // Save through pusher event in another page.
          vm.$emit('s3-url-upload-completed', vm.mediaNames);
          return;
        }

        // Append file names.
        vm.mediaNames.forEach((mediaName) => {
          vm.formData.append("file_names[]", mediaName);
        });

        // Save.
        vm.storeUrl(vm.formData).then(() => {
          // Clear input media and text.
          vm.dropzone.removeAllFiles(true);
          // Clear mediaNames.
          vm.mediaNames = [];
          // Uploaded completed.
          vm.$emit('upload-completed');
          // Restore.
          vm.$emit('restore');
        });
      },

      // Called whenever an error occurs
      // Receives `file` and `message`
      error(file, message) {
        if (file.previewElement) {
          file.previewElement.classList.add('dz-error');
          if (typeof message !== 'string' && message.error) {
            message = message.error;
          }
          for (let node of file.previewElement.querySelectorAll('[data-dz-errormessage]')) {
            node.textContent = message;
          }
        }
      },
    };

    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$el, options);

    // Called when a file is added to the queue
    // Receives `file`
    vm.dropzone.on('addedfile', () => {
      this.$emit('increment');
    });

    // Called whenever a file is removed.
    vm.dropzone.on('removedfile', (file) => {
      // Remove file name.
      let index = vm.mediaNames.findIndex((el) => el === file.upload.filename);
      vm.mediaNames.splice(index, 1);
      // TODO: invalid link S3.
      // Decrease counter.
      this.$emit('decrease');
    });

    // Set signed upload URL for each file
    vm.dropzone.on('processing', (file) => {
      // Set all inputs data.
      vm.dropzone.options.url = file.s3.url;
    });

    // Auto remove exceeded files
    vm.dropzone.on("maxfilesexceeded", (file) => {
      // Remove file
      vm.dropzone.removeFile(file);

      // Show warning message
      warning(i18n.t('You exceeded max files'));
    });
  },
};
</script>

<style>
  .dropzone {
    border: 2px dashed rgba(0, 0, 0, 0.2) !important;
  }
</style>
