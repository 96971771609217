<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <Brand :width="120" :height="120" />
      <div class="card col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-6 rounded-custom shadow border-0 p-3">
        <div class="card-body">

          <!-- Display recovery codes section -->
          <template v-if="data.action === 'register'">
            <h1 class="h2 mb-3 fw-normal text-center">Codici di recupero</h1>
            <p class="card-text">I codici di ripristino vengono utilizzati per accedere al tuo account nel caso in cui tu
              non possa ricevere i codici di autenticazione a due fattori.</p>
            <p>Scarica, stampa o copia i tuoi codici prima di continuare la configurazione dell'autenticazione a due
              fattori.</p>

            <ul>
              <li v-for="(item, index) in data.recovery_codes" :key="index">
                {{ item }}
              </li>
            </ul>

            <!-- Button to show QR code section -->
            <button class="w-100 btn btn-lg d-flex align-items-center justify-content-center btn-violet" type="submit"
              :disabled="loading" @click="confirmSecretStep()">
              <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              <span v-if="loading">Loading...</span>
              <span v-else>Continua</span>
            </button>
          </template>

          <!-- Display QR code section -->
          <template v-else-if="data.action === 'confirm'">
            <h1 class="h2 mb-3 fw-normal text-center">Autenticazione a due fattori</h1>
            <p class="card-text">L'autenticazione a due fattori (2FA) rafforza la sicurezza dell'accesso richiedendo due
              metodi (noti anche come fattori) per la verifica
              la tua identità. L'autenticazione a due fattori protegge da attacchi di phishing, ingegneria sociale e forza
              bruta delle password e
              protegge i tuoi accessi dagli aggressori che sfruttano credenziali deboli o rubate.</p>

            <p class="card-text">Per abilitare l'autenticazione a due fattori sul tuo account, devi eseguire i seguenti
              passaggi</p>
            <b>1. Verifica l'OTP dall'app mobile Google Authenticator</b>

            <div class="d-flex flex-column justify-content-center align-items-center">
              <!-- QR code display -->
              <QRCode class="m-2 m-auto" :text="data.url" />

              <!-- Form to confirm secret -->
              <form @submit.prevent="confirmSecret()">
                <div class="mb-3">
                  <label for="secret" class="form-label">Secret</label>
                  <input :value="data.secret.value" @input="event => data.secret.value = event.target.value" type="text" class="form-control" required />
                </div>

                <div v-if="data.secret.hasError" class='alert alert-danger'>
                  {{ data.secret.message }}
                </div>

                <!-- Button to confirm secret -->
                <button class="w-100 btn btn-lg btn-violet d-flex align-items-center justify-content-center" type="submit"
                  :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"
                    aria-hidden="true"></span>
                  <span v-if="loading">Loading...</span>
                  <span v-else>Conferma</span>
                </button>
              </form>
            </div>

          </template>

          <!-- Display verify section -->
          <template v-else-if="data.action === 'verify'">
            <h1 class="h2 mb-3 fw-normal text-center">Autenticazione a due fattori</h1>
            <p class="card-text">L'autenticazione a due fattori (2FA) rafforza la sicurezza dell'accesso richiedendo due
              metodi (noti anche come fattori) per la verifica
              la tua identità. L'autenticazione a due fattori protegge da attacchi di phishing, ingegneria sociale e forza
              bruta delle password e
              protegge i tuoi accessi dagli aggressori che sfruttano credenziali deboli o rubate.</p>

            <b>Inserisci il pin da Google Authenticator Abilita 2FA</b>

            <div class="d-flex flex-column justify-content-center align-items-center">

              <!-- Form to confirm secret -->
              <form @submit.prevent="confirmVerify()">

                <div class="mb-3">
                  <label for="secret" class="form-label">Password temporanea</label>
                  <input :value="data.secret.value" @input="event => data.secret.value = event.target.value" type="text" class="form-control" required />
                </div>

                <div v-if="data.secret.hasError" class='alert alert-danger'>
                  {{ data.secret.message }}
                </div>

                <!-- Button to confirm secret -->
                <button class="w-100 btn btn-lg btn-violet d-flex align-items-center justify-content-center" type="submit"
                  :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status"
                    aria-hidden="true"></span>
                  <span v-if="loading">Loading...</span>
                  <span v-else>Conferma</span>
                </button>
              </form>
            </div>

          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand.vue";
import QRCode from "@/components/QRCode.vue";
import { handleLogin } from "@/use/utilities/handleLogin.js"
import { register } from "@/use/repositories/2fa/register";
import { confirm } from "@/use/repositories/2fa/confirm";
import { verify } from "@/use/repositories/2fa/verify";
import { onMounted, ref, reactive } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "TwoFactorAuth",
  components: {
    Brand,
    QRCode
  },
  setup() {
    const loading = ref(false);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // Reactive data object
    const data = reactive({
      action: store.state.twoFactorAuth.action,
      url: '',
      recovery_codes: [],
      secret: {
        value: '',
        hasError: false,
        message: '',
      }
    })

    const confirmSecretStep = () => {
      // Enable 2FA
      store.state.twoFactorAuth.action = 'confirm';
      data.action = 'confirm';
    }

    const confirmVerify = () => {
      // Confirm secret and handle the response
      verify(data.secret.value).then(async (response) => {
        if (response.data) {
          // Enable 2FA
          store.state.twoFactorAuth.enabled = false;

          return handleLogin();
        } else {
          data.secret.hasError = true;
          data.secret.message = 'Invalid secret';
        }
      }).catch((error) => {
        data.secret.hasError = true;
        data.secret.message = error.message;
      })
    }

    const confirmSecret = async () => {
      // Confirm secret and handle the response
      confirm(data.secret.value).then(async () => {
        // Enable 2FA
        store.state.twoFactorAuth.enabled = false;

        return handleLogin();
      }).catch((error) => {
        data.secret.hasError = true
        data.secret.message = error.message;
      })
    }

    onMounted(() => {
      // Redirect if the route type doesn't match the action
      if (route.params.type !== data.action) {
        return router.push({
          name: "TwoFactorAuth",
          params: {
            type: data.action,
          },
        });
      }

      if (['register', 'confirm'].includes(route.params.type)) {
        // Register and get the recovery codes and QR code URL
        register().then((response) => {
          data.recovery_codes = response.data.recovery;
          data.url = response.data.url;
        });
      }
    })

    return {
      loading,
      data,
      confirmSecret,
      confirmSecretStep,
      confirmVerify,
    }
  }
}
</script>
