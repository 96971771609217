<template>
  <div class="modal fade" id="modalInternalNotesHistory" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-3">
        <div class="modal-header justify-content-center">
          <h4 class="modal-title" id="exampleModalLabel">{{ $props.title }}</h4>
        </div>
        <div class="modal-body overflow-auto bg-light p-4" id="content">
          <template v-if="Object.keys(testWorkQueuePhase).length > 0 && testWorkQueuePhase.test_work_queue_phases.length > 0">
            <div class="mb-5" v-for="(testWorkQueue, index) in testWorkQueuePhase.test_work_queue_phases" :key="index">
              <h5 class="text-uppercase">{{ testWorkQueue.internal_phase.name }}</h5>
              <template v-if="testWorkQueue.feedbacks.length">
                <template v-for="(nota, index) in testWorkQueue.feedbacks" :key="index">
                  <p>{{ nota.note }}</p>
                  <small class="text-muted">{{ nota.user.first_name }} {{ nota.user.last_name }}</small>
                </template>
              </template>
              <template v-else>
                Nessuna nota presente.
              </template>
            </div>
          </template>
          <template v-else>
            Nessuna nota interna trovata
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Modal } from "bootstrap";

import {fetchNotesById} from "@/use/repositories/testWorkQueue/fetchNotesById";

export default {
  name: "InternalNotesHistory",
  props: [
      'test_work_queue_id',
      'title'
  ],
  setup(props, {emit}) {
    const testWorkQueuePhase = ref({});

    onMounted(() => {
      getHistoryInternalNotes();
      eventModal();
    });

    // Get internal notes and show modal.
    const getHistoryInternalNotes = async () => {
      await fetchNotesById(props.test_work_queue_id).then(async (response) => {
        testWorkQueuePhase.value = response.testWorkQueuePhase;
        let modalInternalNotesHistory = new Modal(document.getElementById("modalInternalNotesHistory"));
        modalInternalNotesHistory.show();
      })
    }

    // Hide internal notes modal.
    const eventModal = () => {
      let modalTestsHistory = document.getElementById("modalInternalNotesHistory");
      modalTestsHistory.addEventListener('hidden.bs.modal', function () {
        emit('historyNotes', false);
      });
    }

    return {
      testWorkQueuePhase,
    }
  }
}
</script>

<style scoped>
  #content {
    height: 500px;
  }
  .modal-header {
    background-color: lightgrey;
  }
</style>
