import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function fetchMessagesUnseen() {
  let messagesUnseen = [];

  await axios
    .get("/channels", {
      params: {
        action: "by_clinic_unseen",
      },
    })
    .then(async (response) => {
      messagesUnseen = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { messagesUnseen };
}
