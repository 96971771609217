export function getLabelsNameByAsset(slug) {
  // TODO: how to import -> const { assetsType } = inject("constants");
  switch (slug) {
    //case assetsType.LOWER_ARCH:
    case "lower-arch":
      return "Arcata inferiore";
    //case assetsType.UPPER_ARCH:
    case "upper-arch":
      return "Arcata superiore";
    //case assetsType.GUM:
    case "gum":
      return "Gengiva";
    //case assetsType.STUMP:
    case "stump":
      return "Moncone";
    default:
      return slug;
  }
}
