<template>
  <div class="d-flex vw-100 vh-100 justify-content-center align-items-center">
    <div class="col-sm-10 col-md-8 col-lg-8 col-xl-8 col-xxl-6">
      <div class="card rounded-custom shadow border-0 p-4 flex-column justify-content-center">
        <h3 class="mb-4 fw-normal text-center">
          {{ `Valutazione ${filteredNodes.length > 1 ? "fasi precedenti" : "fase precedente"}` }}
        </h3>
        <template v-if="filteredNodes.length > 0 && selectedPhase">
          <Timeline class="text-center" :subtitle="subtitle" :items="filteredNodes" :selectedNode="selectedPhase"
            @selected="selectPhase" />
        </template>
        <template v-if="canSelectAsset()">
          <p>Seleziona gli elementi problematici</p>
          <div class="d-flex flex-wrap overflow-auto">
            <RenderAssetIcons :elements="assets" @select="selectAsset" :showProjects="true" :readonly="true" />
          </div>
        </template>
        <Multiselect v-if="Object.keys(failed).length" :options="failed" @selected="readMotivationIdSelected"
          :showAlert="alert" label="Selezionare il problema riscontrato">
        </Multiselect>
        <Note class="mt-3" label="Nota:" @message="readNote"></Note>
        <button class="btn btn-violet text-uppercase mt-4" @click="storeFeedbackNegative">
          conferma valutazione
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRaw, ref, inject, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import _head from "lodash/head";
import _find from "lodash/find";
import _map from "lodash/map";
import _flatMap from "lodash/flatMap";
import _findIndex from "lodash/findIndex";
import _isEqual from "lodash/isEqual";

import Multiselect from "@/components/Multiselect";
import Note from "@/components/general/Note";
import Timeline from "@/components/Iso-in/Timeline";

import { groupedByBridgesAndTooth } from "@/use/repositories/prescriptionRows/groupedByBridgesAndTooth";
import { update as updateTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/update";
import { store as storeFeedback } from "@/use/repositories/feedbacks/store";
import { fetchByType } from "@/use/repositories/feedbacks/fetchByType";
import { changeState as changeStateUser } from "@/use/repositories/users/changeState";
import { update as changeAssetState } from "@/use/repositories/assets/update";
import { fetchTreeById } from "@/use/repositories/testWorkQueue/fetchTreeById";
import { flattenForkTree } from "@/utils/flattenForkTree.js";
import { fetchById } from "@/use/repositories/testWorkQueuePhase/fetchById";
import RenderAssetIcons from "../components/RenderAssetIcons.vue";

export default {
  name: "NegativeFeedback",
  components: {
    Multiselect,
    Note,
    Timeline,
    RenderAssetIcons,
  },
  setup() {
    const failed = ref({});
    const note = ref(null);
    const alert = ref(false);
    const router = useRouter();
    const motivations = reactive({ ids: [] });
    const route = useRoute();
    const nodes = ref([]);
    const filteredNodes = ref([]);
    const selectedPhase = ref(null);
    const assets = reactive({
      bridges: [],
      tooth: [],
      selectedAssets: [],
    });
    const { states, userStates, internalPhases } = inject("constants");
    const subtitle = computed(() =>
      filteredNodes.value.length > 1
        ? "Di default è selezionata la fase precedente, ma hai la possibilità di selezionare la più corretta."
        : ""
    );
    const canSelectAsset = () => {
      return (
        selectedPhase.value?.internal_phase?.slug == internalPhases.FRESAGGIO &&
        (assets.tooth.length > 1 || assets.bridges.length > 1)
      );
    };

    const selectPhase = (value = false) => {
      if (assets.tooth.length) assets.tooth = [];

      selectedPhase.value = value
        ? _find(nodes.value, (item) => item.id == value.id)
        : _find(nodes.value, (item) => item.id == route.query.destination_id);

      failed.value = {};

      fetchByType("rating", {
        action: 'with_feedbacks',
        internal_phase_id: selectedPhase.value?.internal_phase?.id,
        state_slug: states.NEGATIVE_FEEDBACK,
      }).then(
        async (response) => {
          failed.value = response.feedbacks;
        }
      );

      if (selectedPhase.value.internal_phase.slug === internalPhases.FRESAGGIO) {
        fetchById(
          _find(nodes.value, (item) => _head(item.children).id == selectedPhase.value.id).id,
          "with_all_assets"
        ).then((response) => {
          const prescriptionRows = _flatMap(response.testWorkQueuePhase.assets, (asset) => {
            return _map(asset.prescription_rows, (prescriptionRow) => {
              return { ...prescriptionRow, asset_id: asset.id };
            });
          });
          const { bridges, tooth } = groupedByBridgesAndTooth(prescriptionRows);
          assets.bridges = bridges;
          assets.tooth = tooth;
        });
      }
    };

    onMounted(() => {
      fetchTreeById(route.query.test_work_queue_id).then((response) => {
        nodes.value = response.tree.meta.fork_tree;
        let array = [];
        nodes.value.forEach((element) => {
          flattenForkTree(element, array);
        });
        nodes.value = array;
        filteredNodes.value = array.filter(
          (phase, index) => index < array.findIndex((phase) => phase.id == route.query.source_id)
        );
        selectPhase();
      });
    });

    const readMotivationIdSelected = (value) => {
      motivations.ids = toRaw(value);
    };

    const storeFeedbackNegative = async () => {
      const validated =
        motivations.ids.length &&
        (!canSelectAsset() || assets.selectedAssets.length) &&
        (motivations.ids.length || !canSelectAsset() || !assets.selectedAssets.length);

      if (!validated) return (alert.value = true);

      if (!assets.selectedAssets.length && (assets.tooth.length == 1 || assets.bridges.length == 1)) selectAsset();

      return refuses();
    };

    const getPhaseIdsToUpdate = () => {
      const array = nodes.value.filter(
        (phase, index) =>
          index > nodes.value.findIndex((phase) => phase.id == selectedPhase.value.id) &&
          index <= nodes.value.findIndex((phase) => phase.id == route.query.source_id)
      );
      return array.map((phase) => phase.id);
    };

    const refuses = async () => {
      await storeFeedback({
        child_test_work_queue_phase_id: parseInt(route.query.source_id),
        state: states.NEGATIVE_FEEDBACK,
        note: note.value,
        is_iso: false,
        test_work_queue_phase_id: selectedPhase.value.id,
        feedback_id: _head(motivations.ids),
        action: "without_media",
      }).then(async () => {
        await updateTestWorkQueuePhase({
          source_ids: getPhaseIdsToUpdate(),
          state: states.PENDING,
          action: "update",
        }).then(async () => {
          if (selectedPhase.value?.internal_phase?.slug == internalPhases.FRESAGGIO)
            await changeAssetState(
              assets.selectedAssets.map((item) => item.asset_id),
              states.PENDING
            );
          await changeStateUser(userStates.AVAILABLE);

          return router.push({ name: "Checkin" });
        });
      });
    };

    // Read emit from modal.
    const readNote = (value) => {
      note.value = value;
    };

    const selectAsset = (elements = null) => {
      if (!elements) elements = assets.bridges.length ? _head(assets.bridges).tooth : assets.tooth;

      const isBridge = elements.length > 1;
      if (!isBridge) {
        const element = _head(elements);
        handleAsset(element, assets.tooth);
      } else {
        const bridge = _find(assets.bridges, (item) => _isEqual(item.tooth, elements));
        bridge.tooth.forEach((item) => handleAsset(item, bridge.tooth));
      }
    };

    const handleAsset = (element, array) => {
      const selectedIndex = _findIndex(assets.selectedAssets, (item) => item.id === element.id);
      const asset = _find(array, (item) => item.id == element.id);

      if (selectedIndex === -1) assets.selectedAssets.push(element);
      else assets.selectedAssets.splice(selectedIndex, 1);

      asset.selected = !asset.selected;
    };

    return {
      failed,
      storeFeedbackNegative,
      readMotivationIdSelected,
      selectPhase,
      motivations,
      alert,
      readNote,
      refuses,
      nodes,
      filteredNodes,
      selectedPhase,
      internalPhases,
      assets,
      selectAsset,
      canSelectAsset,
      subtitle,
    };
  },
};
</script>
