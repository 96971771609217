<template>
  <div class="d-flex align-items-start p-3" :class="{ 'bg-light-violet border-dark-violet rounded': wip}">
    <template v-if="isManagerState($props.twqPhase.state_slug)">
      <small class="text-uppercase">m</small>
    </template>
    <img class="me-2 pt-1"
        width="25"
        :src="require(`@/assets/icons/${getIconByState($props.twqPhase.state_slug)}.svg`)"
        :alt="`icon-${$props.twqPhase.state_slug}-state`"/>
    <div class="d-flex flex-column">
      <div class="d-flex">
        <span class="fw-bold">
          <small class="me-2 text-uppercase">{{ $props.twqPhase.name }}</small>
        </span>
        <template v-if="$props.twqPhase.technician !== null">
          <small class="text-uppercase"> - {{ $props.twqPhase.department_name }} - {{ $props.twqPhase.technician.first_name }} {{ $props.twqPhase.technician.last_name }}</small>
        </template>
      </div>

      <div class="d-flex flex-column">

        <div class="d-flex">
          <!-- DURATION -->
          <small v-html="formatDuration($props.twqPhase.work_duration, $props.twqPhase.started_at, $props.twqPhase.finished_at, $props.twqPhase.state_slug)"></small>
          <!-- CHEVRON ICON -->
          <template v-if="existsNotesOrOrders($props.twqPhase) && $props.twqPhase.feedbacks.length === 0">
            <div class="ms-2" data-bs-toggle="collapse" :data-bs-target="`#panelsStayOpen-collapse_${$props.twqPhase.id}`" aria-expanded="true" :aria-controls="`panelsStayOpen-collapse_${$props.twqPhase.id}`">
              <img class="icon" v-if="showIconUp" src="@/assets/icons/chevron-down.svg" width="20" alt="caret-up-dark" @click="showIconUp = false">
              <img class="icon" v-else src="@/assets/icons/chevron-up.svg" width="20" alt="caret-down-dark" @click="showIconUp = true">
            </div>
          </template>
        </div>

        <div class="d-flex" v-if="$props.twqPhase.feedbacks.length > 0">
          <!-- FEEDBACKS -->
          <template v-if="$props.twqPhase.feedbacks.length > 0">
            <div class="d-flex w-100 flex-column">
              <div v-for="(feedback, index) in $props.twqPhase.feedbacks" :key="index">
                <div class="feedback d-flex flex-column rounded p-2 mb-2" data-bs-toggle="collapse" :data-bs-target="`#panelsStayOpen-collapse_${$props.twqPhase.id}`" aria-expanded="true" :aria-controls="`panelsStayOpen-collapse_${$props.twqPhase.id}`"
                    :class="{'bg-on-hold': $props.twqPhase.state_slug === 'on-hold', 'btn-red': $props.twqPhase.state_slug !== 'on-hold'}">
                  <div class="d-flex justify-content-between mb-1">
                    <small>{{ feedback.updated_at.format("DD/MM/YYYY") }} - {{ feedback.updated_at.format("HH:mm") }}</small>
                  </div>
                  <div>{{ feedback.note }}</div>
                  <small class="d-flex mt-2 justify-content-end">{{ feedback.technician_first_name }} {{ feedback.technician_last_name }}</small>
                </div>
              </div>
            </div>
          </template>
          <!-- CHEVRON ICON -->
          <template v-if="existsNotesOrOrders($props.twqPhase)">
            <div class="ms-2 d-flex align-items-end" data-bs-toggle="collapse" :data-bs-target="`#panelsStayOpen-collapse_${$props.twqPhase.id}`" aria-expanded="true" :aria-controls="`panelsStayOpen-collapse_${$props.twqPhase.id}`">
              <img class="icon" v-if="showIconUp" src="@/assets/icons/chevron-down.svg" width="20" alt="caret-up-dark" @click="showIconUp = false">
              <img class="icon" v-else src="@/assets/icons/chevron-up.svg" width="20" alt="caret-down-dark" @click="showIconUp = true">
            </div>
          </template>
        </div>

      </div>

    </div>
  </div>

  <div :id="`panelsStayOpen-collapse_${$props.twqPhase.id}`" class="accordion-collapse collapse">
    <div class="d-flex flex-column">

      <!-- INTERNAL NOTES -->
      <template v-if="$props.twqPhase.internal_notes.length > 0">
        <div class="d-flex flex-column">
          <div class="note d-flex flex-column bg-light-violet rounded p-2 mb-2 ms-5 w-100" v-for="(note, index) in $props.twqPhase.internal_notes" :key="index">
            <div class="d-flex justify-content-between mb-1">
              <small>NOTA INTERNA</small>
              <small>{{ note.updated_at.format("DD/MM/YYYY") }} - {{ note.updated_at.format("HH:mm") }}</small>
            </div>
            <p>{{ note.note }}</p>
            <small class="d-flex mt-2 justify-content-end">{{ note.technician_first_name }} {{ note.technician_last_name }}</small>
          </div>
        </div>
      </template>

      <!-- ORDERS -->
      <template v-if="$props.twqPhase.orders.length > 0">
        <div class="d-flex flex-column">
          <div v-for="(order, index) in $props.twqPhase.orders" :key="index">
            <div class="d-flex flex-column bg-gray-lab rounded p-3 mb-2 ms-5">
              <div class="d-flex justify-content-between mb-1">
                <small>ORDINE</small>
                <small>{{ order.created_at.format("DD/MM/YYYY") }} - {{ order.created_at.format("HH:mm") }}</small>
              </div>
              <small class="article d-flex">{{ order.article_code }} - {{ order.article_name }} - {{ order.quantity }}</small>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
  <template v-if="$props.isCurrentPrescriptionTest && canAddIsoPhase">
    <AddIsoPhase @push-iso-phase="(data) => $emit('pushIsoPhase', data)" :twqPhase="$props.twqPhase"/>
  </template>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from "vuex";
import moment from "moment";
import AddIsoPhase from '@/components/Consulting/AddIsoPhase';
export default {
  name: "TestWorkQueuePhase",
  props: {
    twqPhase: {
      type: Object,
      required: true,
    },
    isCurrentPrescriptionTest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["pushIsoPhase"],
  setup(props) {
    const store = useStore();
    const showIconUp = ref(props.twqPhase.feedbacks.length > 0);
    const isoInStates = ['iso-in'];
    const { states } = inject("constants");
    const wip = ref(false);
    const canAddIsoPhase = ref((! props.twqPhase.parent && props.twqPhase.state_slug === 'pending') ||
      (props.twqPhase.parent?.slug === 'completed' && props.twqPhase.state_slug === 'pending' && store.state.user.roles.includes("manager")));
    const getIconByState = (slug) => {
      switch (slug) {
        case states.COMPLETED:
          return "like-green";

        case states.NEGATIVE_FEEDBACK:
          return "dislike-red";
        case states.MANAGER_FAILED:
          return "manager/faild"

        case states.ISO_FAILED:
        case states.ISO_REASSIGNED:
        case states.ISO_IN_SKIPPED:
        case states.MANAGER_REASSIGNED:
        case states.MANAGER_SKIPPED:
          return "iso-in/reassign";

        case states.ON_HOLD:
          return "consulting/pause";

        case states.ASSIGNED:
        case states.IN_THE_WORKS:
          wip.value = true;
          return "gear-violet";

        case states.PENDING:
          return "clock";
      }
    }

    const isManagerState = (slug) => {
      return (slug === states.MANAGER_REASSIGNED || slug === states.MANAGER_SKIPPED);
    }

    const existsNotesOrOrders = (twqPhase) => {
      return twqPhase.internal_notes.length > 0 || twqPhase.orders.length > 0;
    }

    const formatDuration = (duration, start, finish, state) => {
      if (state !== 'completed') {
        return;
      }

      let dateStart = moment(start).format("DD/MM/YYYY");
      let dateFinish = moment(finish).format("DD/MM/YYYY");
      let init = moment(start).format("HH:mm");
      let end = moment(finish).format("HH:mm");

      // In iso-in state.
      if (isoInStates.includes(state)) {
        return `<span class="fw-bold">(${dateStart} - ${init})</span>`;
      }

      // Check if has the same date.
      if (moment(start).format("DD/MM/YYYY") === moment(finish).format("DD/MM/YYYY")) {
        return `<span class="fw-bold"></span> (${dateStart} - ${init} / ${end})`;
      }

      // Render two date.
      return `<span class="fw-bold"></span> (${dateStart} - ${init} / ${dateFinish} - ${end})`;
    }

    return {
      getIconByState,
      formatDuration,
      showIconUp,
      existsNotesOrOrders,
      isManagerState,
      wip,
      canAddIsoPhase,
    }
  },
    components: { AddIsoPhase }
}
</script>


<style lang="scss" scoped>
  @import "../../scss/abstracts/_variables.scss";

  .icon:hover,
  .feedback:hover {
    cursor: pointer;
  }
  .article,
  .feedback,
  .note {
    font-size: 14px;
  }
  .highlights {
    border: 2px solid $violet;
  }
  .add-iso-phase {
    filter: grayscale(1);
    color: gray;
  }
  .add-iso-phase:hover {
    filter: grayscale(0);
    color: black;
  }
</style>
