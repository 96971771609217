<template>
  <Multiselect
    id="prescriptionSearch"
    v-model="keyword"
    placeholder="ricerca per codice"
    :filter-results="false"
    :min-chars="1"
    :resolve-on-load="false"
    :delay="0"
    :searchable="true"
    :options="async function(keyword) {
          return await fetchPrescriptionsByCode(keyword) // check JS block for implementation
        }"
    @select="goToConsultingPage()"
  />
</template>

<script>
import {ref, onMounted} from "vue";
import Multiselect from "@vueform/multiselect";
import {searchBy as searchByNumberText} from "@/use/repositories/prescriptions/searchBy";
import router from "@/router";

export default {
  name: "simpleSearch",
  components: {
    Multiselect,
  },
  setup() {
    const keyword = ref(null);
    const prescriptions = ref([]);

    onMounted(() => {
      scanBarcode();
    });

    const scanBarcode = () => {
      // Define variables
      let isEvent = false;
      let input = document.getElementById("prescriptionSearch");

      input.addEventListener("focus", function () {
        if (!isEvent) {
          isEvent = true;
          input.addEventListener("keypress", function (event) {
            setTimeout(function () {
              if (event.key === "Enter") {
                keyword.value = input.value;
                input.value = "";
                input.select();
              }
            }, 500);
          });
        }
      });
    };

    const fetchPrescriptionsByCode = async (keyword) => {
      const {prescriptions} = await searchByNumberText(keyword, "by_prescription");
      return prescriptions.data.data.map((item) => {
        return {
          value: item.id,
          label: `${item.number_text} - ${item.customer.last_name} ${item.customer.first_name}`,
        };
      });
    }

    const goToConsultingPage = () => {
      if (keyword.value === null) return;

      return router.push({
        name: "ConsultingDashboard",
        params: {
          prescription_id: keyword.value,
        },
      });
    }

    return {
      prescriptions,
      fetchPrescriptionsByCode,
      keyword,
      goToConsultingPage,
    };
  },
};
</script>

<style>
.multiselect-option.is-selected.is-pointed,
.multiselect-option.is-selected,
.multiselect-spinner {
  background: #A58ABA !important;
  color: white !important;
}
</style>
