import {
  fetchBySlug as fetchTestWorkQueuePhaseBySlug
} from "@/use/repositories/testWorkQueuePhase/fetchBySlug";
import _head from 'lodash/head';
import _orderBy from 'lodash/orderBy';

export function canManager({next, store}) {
  if (typeof store.state.user.state !== "undefined" && store.state.mainRole.role.slug === 'manager') {
    const currentDepartmentSlug = store.state.mainRole.team.name;

    if (currentDepartmentSlug === 'iso-progettazione') {
      fetchTestWorkQueuePhaseBySlug(
        store.state.user.id,
        [
          'in-the-works'
          // TODO: manage "assigned" state?
        ],
        currentDepartmentSlug
      ).then(async (response) => {
        let testWorkQueuePhases = response.user.test_work_queue_phases;

        if (testWorkQueuePhases.length === 0) return next();

        testWorkQueuePhases = _orderBy(testWorkQueuePhases, testWorkQueuePhase => testWorkQueuePhase.test_work_queue?.delivery_date, ['delivery_date']);
        const testWorkQueuePhase = _head(testWorkQueuePhases);

        next({
          name: "ConsultingDashboard",
          params: {
            prescription_id: testWorkQueuePhase.test_work_queue.prescription_test.prescription.id,
          },
          query: {
            test_work_queue_phase_id: testWorkQueuePhase.id,
            internal_phase_id: testWorkQueuePhase.internal_phase.id,
            test_work_queue_id: testWorkQueuePhase.test_work_queue.id,
            prescription_test_id: testWorkQueuePhase.test_work_queue.prescription_test_id,
            test_id: testWorkQueuePhase.test_work_queue.test_id,
            read_only: false,
          }
        });

      });
    } else {
      return next();
    }
  } else {
    return next("/login");
  }
}
