<script>
import { reactive, computed, onMounted } from "vue";
import { DatePicker } from "v-calendar";
import TableLite from "vue3-table-lite";
import Brand from "@/components/Brand";

import _map from "lodash/map";

import { dateFormatter } from "@/use/utilities/time/dateFormatter";
import { goToConsulting } from "@/use/utilities/goToConsulting";

import { index as fetchTestWorkQueue } from "@/use/repositories/testWorkQueue/index";

export default {
  name: "Tests",
  components: { Brand, TableLite, DatePicker },
  setup() {
    const data = reactive({ rows: [] });
    const searchTerm = reactive({ action: "get_all", type: "analog", checked_in_at: null });
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Prescrizione",
          field: "number_text",
          width: "10%",
          display: (row) =>
            `<a href="javascript:void(0)" class="is-rows-el consulting-link" style="text-decoration-line: none; color: #A58ABA;" data-id="${row.prescription_id}"> ${row.number_text}</a>`,
        },
        {
          label: "Stato",
          field: "state",
          width: "10%",
        },
        {
          label: "Tecnico",
          field: "technician",
          width: "10%",
        },
        {
          label: "Clinica",
          field: "clinic",
          width: "10%",
        },
        {
          label: "Paziente",
          field: "customer",
          width: "10%",
        },
        {
          label: "Spedizione",
          field: "delivery_date",
          width: "10%",
        },
        {
          label: "Check-in",
          field: "created_at",
          width: "10%",
        },
      ],
      rows: computed(() => data.rows),
    });

    onMounted(() => doSearch());

    const doSearch = (offset = 0, limit = 10) => {
      const page = Math.floor(offset / limit) + 1;
      searchTerm.checked_in_at = searchTerm.checked_in_at ? dateFormatter(searchTerm.checked_in_at, {onlyDate: true, onlyTime: false, dash: false, iso: true}) : null;
      searchTerm.page = page;
      fetchTestWorkQueue(searchTerm).then((response) => {
        data.rows = _map(response.testWorkQueue.data, (item) => {
          return {
            number_text: item?.prescription_test?.prescription?.number_text,
            prescription_id: item?.prescription_test?.prescription?.id,
            state: item?.state?.slug,
            clinic: item?.prescription_test?.prescription?.clinic?.name,
            customer: `${item?.prescription_test?.prescription?.customer?.first_name} ${item?.prescription_test?.prescription?.customer?.last_name}`,
            technician: `${item?.user?.first_name} ${item?.user?.last_name}`,
            delivery_date: dateFormatter(item?.delivery_date),
            created_at: dateFormatter(item?.created_at),
          };
        });
      });
    };

    const tableLoadingFinish = (elements) => {
      table.isLoading = false;
      Array.prototype.forEach.call(elements, (element) => {
        if (element.classList.contains("consulting-link"))
          element.addEventListener("click", () =>
            goToConsulting(element.attributes["data-id"].value, true)
          );
      });
    };

    return { table, searchTerm, tableLoadingFinish, doSearch };
  },
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="
          col-sm-12 col-md-12 col-lg-2 col-xl-2
          d-flex
          flex-column
          justify-content-start
          align-items-start
        "
      >
        <Brand :width="150" />
      </div>
      <div class="container mt-1 p-5">
        <div
          class="bg-white d-flex justify-content-between align-items-center rounded-custom mb-2 p-4"
        >
          <h2 class="text-center m-3">Prove di lavoro (storico check-in)</h2>
          <div class="col-lg-6 col-md-5 col-sm-5 col col-xs-12 m-1 d-flex justify-content-around flex-wrap">
            <div class="w-50 px-1">
              <label for="" class="form-label d-block mb-2">Seleziona la tipologia</label>
              <select
                v-model="searchTerm.type"
                @change="doSearch()"
                class="form-select rounded-custom border-violet p-2"
                aria-label="Default select example"
              >
                <option value="analog" selected>Analogica</option>
                <option value="digital">Digitale</option>
              </select>
            </div>
            <div class="w-50 px-1">
              <label for="datePicker" class="form-label d-block mb-2">Seleziona la data di check-in</label>
              <DatePicker v-model="searchTerm.checked_in_at" @dayclick="doSearch" v-slot="{ inputValue, togglePopover }">
                  <div class="d-flex flex-nowrap">
                    <span
                      class="btn text-uppercase rounded-custom border-violet p-2 px-3 me-1"
                      id="datePicker"
                      data-bs-toggle="dropdown"
                      @click="togglePopover"
                    >
                      <img src="@/assets/icons/logistic-checkin/calendar.svg" width="25" alt="calendar" />
                    </span>
                    <input :value="inputValue" class="btn form-input border-violet rounded-custom p-2 text-start" readonly />
                  </div>
              </DatePicker>
            </div>
          </div>
        </div>
        <TableLite
          class="p-3"
          :is-loading="table.isLoading"
          :columns="table.columns"
          :rows="table.rows"
          @do-search="doSearch"
          @is-finished="tableLoadingFinish"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.vtl.vtl-card) {
  border-radius: 20px;
}
:deep(.vtl-thead-tr .vtl-thead-th:first-child) {
  border-top-left-radius: 20px;
}
:deep(.vtl-thead-tr .vtl-thead-th:last-child) {
  border-top-right-radius: 20px;
}
:deep(.vtl-paging-count-label),
:deep(.vtl-paging-count-dropdown) {
  display: none;
}
:deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #000000;
  background-color: #f3e9ff;
  border-color: #f3e9ff;
  border: none;
}

:deep(.vtl-table td),
:deep(.vtl-table tr) {
  border: none;
  border-bottom: 1px solid #9d9d9d;
  vertical-align: middle;
}

:deep(.vtl-paging-info),
:deep(.vtl-paging-pagination-div),
:deep(.vtl-paging-change-div) {
  padding-top: 30px;
  color: #7e6492;
}

:deep(.vtl-paging-count-label),
:deep(.vtl-paging-page-label) {
  color: #7e6492;
}

:deep(.vtl-paging-pagination-page-link) {
  border: none;
}
</style>
