import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function index(action) {
  const axios = axiosInstance().primoLab;
  let clinics = [];

  await axios
    .get(`/clinics`, {
      params: { action: action }
    })
    .then(async (response) => {
        clinics = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { clinics };
}
