<script>
import { ref, onMounted, inject, computed } from 'vue';
import { DatePicker } from 'v-calendar';
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { success } from "@/use/toast/success";

import { fetchByType } from '@/use/repositories/feedbacks/fetchByType';
import { update as updateTestWorkQueue } from '@/use/repositories/testWorkQueue/update';
import Toggle from '@vueform/toggle';
import _head from "lodash/head";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { show as showClinicDeliveries } from '@/use/repositories/clinic-deliveries/show';
import { print } from "@/use/repositories/devices/print";
import moment from "moment";

import { dateFormatter } from '@/use/utilities/time/dateFormatter';
import Brand from "@/components/Brand";
export default {
  name: "IsoInChangedDate",
  inheritAttrs: false,
  props: [
    'test_work_queue_id',
    'test_work_queue_phase_id',
    'internal_phase_id',
    'test_id',
    'state_slug',
    'delivery_date',
    'prescription',
  ],
  components: {
    DatePicker,
    Toggle,
    Brand
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const {
      devicesType,
      departments,
    } = inject("constants");
    const date = ref();
    const deliveryDate = ref();
    const feedbacks = ref();
    const feedbackId = ref(null);
    const printer = ref(null);
    const printLabel = ref(true);

    const isAllowedToSubmit = computed(() => (
      deliveryDate.value && feedbackId.value)
      && ((printLabel.value && printer.value) || (!printLabel.value && !printer.value))
    );

    const calculateDeliveryDate = () => {
      showClinicDeliveries(route.query.prescription_id, {
        action: "with_delivery_date",
        appointment_date: moment(date.value).format("YYYY-MM-DD HH:mm:ss"),
      }).then(async (response) => {
        deliveryDate.value = moment(response.clinicDelivery).format("YYYY-MM-DD HH:mm:ss");
      });
    }

    const updateDeliveryDate = () => {
      updateTestWorkQueue({
        action: "update_delivery_date",
        id: props.test_work_queue_id,
        delivery_date: dateFormatter(deliveryDate.value, { onlyDate: false, onlyTime: false, dash: true, iso: false }),
        feedback_id: feedbackId.value
      }).then(async () => {
        if (printer.value) {
          // Print Label
          fetchAllTesWorkQueue({
            action: 'with_base_64_labels',
            ids: [
              props.test_work_queue_id,
            ]
          }, true)
            .then((response) => print({
              device_type_slug: devicesType.ANALOG_LABEL,
              department_slug: printer.value,
              data: _head(response.testWorkQueue).raw_data,
            }));
        }

        success(i18n.t("Barcodes print successfully!"));
        router.go(-1);
      })
    }

    const getFeedbackId = (value) => {
      feedbackId.value = value.value;
    };

    onMounted(() => {
      fetchByType('delivery-date-change', {
        action: 'with_feedbacks',
        internal_phase_id: props.internal_phase_id,
        state_slug: 'in-the-works',
      }).then((response) => {
        feedbacks.value = response.feedbacks;
      });
    });

    return {
      date,
      feedbacks,
      feedbackId,
      printer,
      departments,
      updateDeliveryDate,
      getFeedbackId,
      printLabel,
      isAllowedToSubmit,
      deliveryDate,
      showClinicDeliveries,
      calculateDeliveryDate,
    }
  }
}
</script>

<template>
  <div class="d-flex vw-100 vh-100 justify-content-center align-items-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
      <Brand :width="120" :height="120" />
      <div class="card rounded-custom shadow border-0 p-4 flex-column justify-content-center">
        <h4 class="mb-4 fw-normal text-center">{{ `Seleziona la nuova data di consegna per la prescrizione
          n. ${$props.prescription}` }}</h4>
        <DatePicker class="m-auto mb-3" v-model="date" mode="dateTime" is24hr locale="it" />
        <div class="d-grid gap-2 col-6 mx-auto" v-if="date">
          <button type="button" class="btn btn-violet text-uppercase me-3" @click="calculateDeliveryDate()">Calcola data etichetta</button>
        </div>
        <div class="p-5">
          <div class="mb-3">
            <label class="form-label">Data etichetta</label>
            <DatePicker v-model="deliveryDate" :update-on-input="false" mode="dateTime" is24hr locale="it">
              <template v-slot="{ inputValue, inputEvents }">
                <input class="bg-white border px-2 py-1 rounded" :value="inputValue" v-on="inputEvents" />
              </template>
            </DatePicker>
          </div>
          <label class="form-label">Seleziona la motivazione</label>
          <select class="form-control mb-3" v-model="feedbackId">
            <option :value="null">... </option>
            <option v-for="(feedback, index) in feedbacks" :key="index" :value="feedback.id">
              {{ feedback.description }}
            </option>
          </select>
          <div class="mb-3">
            <Toggle v-model="printLabel" />
            <label class="ms-2">Stampa nuova etichetta</label>
          </div>
          <template v-if="printLabel">
            <label class="form-label">Seleziona la stampante per la nuova etichetta</label>
            <select class="form-control" v-model="printer">
              <option :value="null">... </option>
              <option :value="departments.LOGISTICS">Stampante logistica</option>
              <option :value="departments.CAM">Stampante fresaggio</option>
              <option :value="departments.ISO_PROGETTAZIONE">Stampante iso progettazione</option>
            </select>
          </template>
        </div>
        <button type="button" :disabled="!isAllowedToSubmit" class="btn btn-violet text-uppercase mt-4 m-auto"
          @click="updateDeliveryDate">conferma</button>
      </div>
    </div>
  </div>
</template>