import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function fetchClinics() {
  let clinics = [];

  await axios
    .get(`/clinics`, {
      params: {
        action: "by_user",
      },
    })
    .then(async (response) => {
      clinics = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { clinics };
}
