<script setup>
import { defineProps, onMounted, ref } from "vue";
import _head from "lodash/head";
import _last from "lodash/last";

const props = defineProps({
  data: Object,
});

const bridges = ref([]);

// Function to get the range of teeth
function getTeethRange() {
  // Define the layout as it appears in the image, indicating the order for upper and lower rows
  const layout = {
    upperRow: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
    lowerRow: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
  };

  props.data.bridges.forEach((bridge) => {
    // Extract the zone numbers and convert them to integers
    const zoneNumbers = bridge.tooth.map((item) => parseInt(item.zone_number));

    // Determine if the zone numbers belong to the upper or lower row based on the first tooth
    const isUpperRow = layout.upperRow.includes(_head(zoneNumbers));

    // Sort the zone numbers based on their position in the appropriate layout
    if (isUpperRow) {
      zoneNumbers.sort((a, b) => layout.upperRow.indexOf(a) - layout.upperRow.indexOf(b));
    } else {
      zoneNumbers.sort((a, b) => layout.lowerRow.indexOf(a) - layout.lowerRow.indexOf(b));
    }

    // Determine the range for the current bridge
    bridges.value.push(
      `Ponte D${_head(zoneNumbers)}/${_last(zoneNumbers)} ${
        props.data.tooth.length > 0 ? " - " : ""
      }`
    );
  });
}

onMounted(() => {
  getTeethRange();
});

const getLabel = (el) => {
  let label = null;

  switch (el.application.toLowerCase() + el.zone_number.toLowerCase()) {
    // Emiarc.
    case "emiarcata1":
      label = "D11/D18";
      break;
    case "emiarcata2":
      label = "D21/D28";
      break;
    case "emiarcata3":
      label = "D31/D38";
      break;
    case "emiarcata4":
      label = "D41/D48";
      break;

    // Sexstant.
    case "sestante1":
      label = "D14/D18";
      break;
    case "sestante2":
      label = "(D11/D13 - D21/D23)";
      break;
    case "sestante3":
      label = "D24/D28";
      break;
    case "sestante4":
      label = "D34/D38";
      break;
    case "sestante5":
      label = "(D31/D33 - D41/D43)";
      break;
    case "sestante6":
      label = "D44/D48";
      break;
  }

  switch (el.application.toLowerCase()) {
    // Arc.
    case "arcata":
      label = el.zone_number;
      break;
    // If application is null.
    case "":
      label = `D${el.zone_number}`;
      break;
  }

  return `${el.application} ${label}`;
};
</script>

<template>
  <template v-if="bridges.length > 0">
    <template v-for="(bridgeLabel, index) in bridges" :key="index">{{ bridgeLabel }}</template>
  </template>

  <template v-if="props.data.tooth.length > 0">
    <template v-for="(el, index) in props.data.tooth" :key="index">
      <!-- Arc an semiarc -->
      <template v-if="el.zone_number !== null && el.application.toLowerCase() !== 'dente'"
        >{{ getLabel(el) }} {{ index + 1 !== props.data.tooth.length ? " - " : "" }}</template
      >

      <!-- mouth -->
      <template v-if="el.zone_number === null && el.application.toLowerCase() !== 'dente'">{{
        el.application
      }}</template>

      <!-- Tooth -->
      <template v-if="el.zone_number !== null && el.application.toLowerCase() === 'dente'"
        >D{{ el.zone_number }}{{ index + 1 !== props.data.tooth.length ? " - " : "" }}</template
      >
    </template>
  </template>
</template>
