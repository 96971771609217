<template>
  <div class="d-flex align-items-center position-relative">
    <router-link to="/manager" class="btn rounded-btn-custom shadow bg-violet text-white">
      <div class="d-flex align-items-center">
        <img v-if="showChevronLeft" class="mr-1" src="@/assets/icons/manager/chevron-left.svg" width="20" alt="chevron-left" />
        <strong class="text-uppercase">manager</strong>
        <img v-if="showChevronRight" class="ms-1" src="@/assets/icons/manager/chevron-right.svg" width="20" alt="chevron-right" />
        <img v-if="showBell" class="bell" src="@/assets/icons/manager/bell.svg" width="35" alt="bell" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    showBell: {
      type: Boolean,
      required: true,
    },
    showChevronRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    showChevronLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>

<style scoped>
  .bell {
    position: absolute;
    top: -15px;
    right: -15px;
  }
</style>
