<template>
  <div></div>
</template>

<script>
import { redirectTo } from "@/use/repositories/redirectTo";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Home",
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      redirectTo(store.state.user.roles, store.state.user.departments).then(
        async (response) => {
          await router.push({
            name: response.routeName,
          });
        }
      );
    });
  },
};
</script>
