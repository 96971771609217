import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function deleteRejected(id) {
  const axios = axiosInstance().primoLab;
  let prescriptionRejected = [];

  await axios
    .delete(`/prescriptions-rejected/${id}`)
    .then(async (response) => {
      prescriptionRejected = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionRejected };
}
