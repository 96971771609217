import router from "../../router/index";

export async function goToConsulting(prescriptionId, inNewTab= false) {

  const resolvedRoute = router.resolve({
    name: "ConsultingDashboard",
    params: { prescription_id: prescriptionId },
  });

  if (inNewTab) return window.open(resolvedRoute.href, "_blank");

  return window.location.href = resolvedRoute.href;
}
