import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function countByDepartment(department) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let data = [];

  await axios
    .get(`/departments/${department}`, {
      params: {
        action: 'with_total_devices',
      }
    })
    .then(async (resp) => {
      data = resp.data;
    })
    .catch(async (error) => {
      return error;
    });

  return { data };
}
