<template>
  <div id="wrapper-loading" v-show="$store.state.loading">
    <div class="spinner-border" id="loading" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
#wrapper-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(16, 16, 16, 0.5);
  z-index: 99999999;
}
#loading {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: whitesmoke;
  width: 6rem;
  height: 6rem;
}
</style>
