import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAll() {
  const axios = axiosInstance().primoLab;
  let shippingProviders = [];

  await axios
    .get("/shipping-providers", {
      params: {
        action: 'get_all' 
      }
    })
    .then(async (response) => {
      shippingProviders = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { shippingProviders };
}

