<template>
    <div class="card shadow rounded-custom overflow-hidden w-100 h-100">

      <div class="card-header rounded-only-top bg-dark-violet p-3 text-center">
        <h3 class="text-white text-uppercase m-0">
          <!--        &lt;!&ndash; TODO link tot page &ndash;&gt;-->
          <!--        <img class="float-start" src="@/assets/icons/more-info.svg" width="50" alt="more-info"/>-->
          ordini in entrata
        </h3>
      </div>

      <div class="card-body p-0 overflow-auto">

        <div class="table-responsive h-100">
          <table class="table">
            <thead>
              <tr>
                <th><InputSearchMinimal :name="'prescrizione'" @termToSearch="prescriptionTerm"/></th>
                <th><InputSearchMinimal :name="'data'" @termToSearch="dataTerm"/></th>
                <th><InputSearchMinimal :name="'tecnico'" @termToSearch="technicianTerm"/></th>
                <th>quantità</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(order, index) in pendingOrdersFiltered" :key="index">
                <OrderPending
                  :data="order"
                  @selected="readSelected"
                />
              </template>
            </tbody>
          </table>
          <template v-if="pendingOrdersFiltered.length === 0">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
              <h3 class="text-center">Nessun ordine in entrata presente</h3>
            </div>
          </template>
        </div>

      </div>
    </div>

</template>

<script>
import {onMounted, ref, watch, toRaw, getCurrentInstance, onUnmounted} from "vue";
import _ from "lodash";
import moment from "moment";

import OrderPending from "@/components/warehouse/supervisor/OrderPending";
import InputSearchMinimal from "@/components/InputSearchMinimal";

import {warehouseRequest} from '@/use/repositories/testWorkQueue/warehouseRequest';

export default {
  name: "RequestedOrders",
  props: {
    resetSelection: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    OrderPending,
    InputSearchMinimal
  },
  emits: [
    'orderDetails',
    'updateApprovedOrders',
    'resetComplete',
    'resetOrderDetails',
  ],
  setup(props, {emit}) {
    const orders = ref([]);
    const pendingOrdersFiltered = ref([]);
    const orderedTestWorkQueues = ref({
      pending: [],
      approved: [],
    });
    // To search.
    const prescription = ref("");
    const data = ref("");
    const technician = ref("");
    // Pusher.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;

    watch(() => props.resetSelection, (toResetSelection) => {
      if (toResetSelection) {
        pendingOrdersFiltered.value.forEach((order) => {
          order.selected = false;
        });

        emit('resetComplete', false);
      }
    });

    onMounted(() => {
      warehouseRequest(['pending', 'approved']).then((response) => {
        // Add utils property.
        response.orders.value.map((testWorkQueue) => {
          testWorkQueue.test_work_queue_phases.map((testWorkQueuePhase) => {
            orders.value.push(addUtilsProperty(testWorkQueuePhase, testWorkQueue));
          });
        });

        // Oder by priority date.
        orders.value = _.orderBy(toRaw(orders.value),'date', 'asc');

        // Filter by state.
        orderedTestWorkQueues.value.pending = filterByState(toRaw(orders.value), 'pending');
        pendingOrdersFiltered.value = orderedTestWorkQueues.value.pending; // Create copy for filtered.
        orderedTestWorkQueues.value.approved = filterByState(toRaw(orders.value), 'approved');

        if (orderedTestWorkQueues.value.approved.length > 0) {
          emit('updateApprovedOrders', orderedTestWorkQueues.value.approved);
        }
      });

      // Subscribe to channel.
      subscribe("private-live", "warehouse-request_change-state");
    });

    const filterByState = (array, stateSlug) => {
      return array.map(testWorkQueue => {
        const articles = testWorkQueue.articles.filter(article => article.state.slug === stateSlug);
        if (articles.length) {
          return { ...testWorkQueue, articles };
        }
        return null;
      }).filter(item => item);
    }

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel("private-live");
    });

    const removeFromPending = (order) => {
      let testWokQueuePhaseIndex = pendingOrdersFiltered.value.findIndex(item => item.id === order.test_work_queue_phase.id);

      if (testWokQueuePhaseIndex !== -1) {
        let articleIndex = pendingOrdersFiltered.value[testWokQueuePhaseIndex].articles.findIndex(item => item.id === order.id);
        if (articleIndex !== -1) {
          pendingOrdersFiltered.value[testWokQueuePhaseIndex].articles.splice(articleIndex, 1);

          // Remove testWorkQueuePhase if empty.
          if (pendingOrdersFiltered.value[testWokQueuePhaseIndex].articles.length === 0) {
            pendingOrdersFiltered.value.splice(testWokQueuePhaseIndex, 1);
          }
        }
      }
    }

    const updateOrCreateOrder = (order, state_slug) => {

      if (state_slug === "rejected") {
        return removeFromPending(order);
      }

      // Check if test work queue is already in the list.
      let testWokQueuePhaseIndex = orderedTestWorkQueues.value[state_slug].findIndex((o) => o.id === order.test_work_queue_phase.id);

      if (order.approved_quantity === null) {
        order.approved_quantity = order.requested_quantity;
      }

      // Create order if not found.
      if (testWokQueuePhaseIndex === -1) {
        let testWorkQueue = {
          id: order.test_work_queue_phase.id,
          articles: [order],
          date: renderPriorityDate(order.test_work_queue_phase.test_work_queue),
          department: renderDepartmentDetails(order.test_work_queue_phase),
          number_text: renderNumberText(order.test_work_queue_phase, order.test_work_queue_phase.test_work_queue),
          selected: false,
          technician: `${order.user.first_name} ${order.user.last_name}`,
        };

        // Add new order.
        orderedTestWorkQueues.value[state_slug].push(testWorkQueue);
      } else {
        let articleIndex = orderedTestWorkQueues.value[state_slug][testWokQueuePhaseIndex].articles.findIndex((a) => a.id === order.id);

        // Article not exists.
        if (articleIndex === -1) {
          orderedTestWorkQueues.value[state_slug][testWokQueuePhaseIndex].articles.push(order);
        } else {
          orderedTestWorkQueues.value[state_slug][testWokQueuePhaseIndex].articles[articleIndex] = order;
        }
      }

      // Remove from pending.
      if (state_slug === 'approved') {
        removeFromPending(order);
      }

      // Sort by priority date.
      orderedTestWorkQueues.value[state_slug] = _.orderBy(toRaw(orderedTestWorkQueues.value[state_slug]),'date');

      // Update approved orders.
      if (state_slug === 'approved') {
        emit('updateApprovedOrders', orderedTestWorkQueues.value['approved']);
      }
    }

    // Subscribe to channel
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, async (event) => {
        console.log("event received", event);
        updateOrCreateOrder(event.warehouse_request, event.state_slug);
      });
    };

    // Unsubscribe to channel
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, {
        $pusher: pusher,
      });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    const addUtilsProperty = (testWorkQueuePhase, testWorkQueue) => {
        let articles = testWorkQueuePhase.warehouse_requests.map((order) => {
            if (order.approved_quantity === null) {
              return {
                ...order,
                approved_quantity: order.requested_quantity,
              }
            } else {
              return {
                ...order,
              };
            }
          });

        return {
          id: testWorkQueuePhase.id,
          selected: false,
          technician: renderTechnicianDetails(testWorkQueuePhase),
          department: renderDepartmentDetails(testWorkQueuePhase),
          date: renderPriorityDate(testWorkQueue),
          number_text: renderNumberText(testWorkQueuePhase, testWorkQueue),
          articles: articles,
        }
    }

    const renderNumberText = (testWorkQueuePhase, testWorkQueue) => {
      return testWorkQueuePhase.department.slug === 'cam' ? '-' : testWorkQueue.prescription.number_text;
    }

    const filter = (term, field) => {
      watch(term, (value) => {
        // Clear data on details order box.
        emit('resetOrderDetails');
        // Reset selected.
        pendingOrdersFiltered.value.forEach((order) => order.selected = false);
        // Filter.
        pendingOrdersFiltered.value = orderedTestWorkQueues.value.pending.filter((order) => {
          return value.toLowerCase().split(' ').every(v => order[field].toLowerCase().includes(v));
        });
      });
    }

    // Watcher listeners.
    filter(prescription,'number_text');
    filter(data,'date');
    filter(technician,'technician');

    const renderTechnicianDetails = (testWorkQueuePhase) => {
      return `${_.head(testWorkQueuePhase.warehouse_requests)?.user?.first_name} ${_.head(testWorkQueuePhase.warehouse_requests)?.user?.last_name}`;
    }

    const renderDepartmentDetails = (testWorkQueuePhase) => {
      return testWorkQueuePhase.department.name;
    }

    const renderPriorityDate = (testWorkQueue) => {
      return moment(testWorkQueue.delivery_date).utc(0).format('DD/MM/YYYY HH:mm:ss');
    }

    const prescriptionTerm = (term) => { prescription.value = term }
    const dataTerm = (term) => { data.value = term }
    const technicianTerm = (term) => { technician.value = term }

    const readSelected = (orderSelected) => {
      pendingOrdersFiltered.value.forEach((order) => {
        order.selected = order.id === orderSelected.id;
      });

      emit('orderDetails', {
        order: orderSelected,
        readOnlyMode: false
      });
    }

    return {
      prescriptionTerm,
      dataTerm,
      technicianTerm,
      prescription,
      data,
      technician,
      readSelected,
      orders,
      orderedTestWorkQueues,
      pendingOrdersFiltered,
    }
  }
}
</script>
<style scoped>
.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #E1D2F2;
}
tr th,
tr td {
  text-align: center;
  border: 0;
  padding: 1em;
  text-transform: uppercase;
}
</style>
