import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function login(param) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .post("/auth/login/", param)
    .then(async (response) => {
      data = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
