export function formatDate(date) {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
  return "";
};
