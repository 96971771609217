<template>
  <div class="box d-flex flex-column bg-home-violet p-2">
    <div class="d-flex align-items-center" v-for="(item, index) in array" :key="index">
      <img class="checkbox" src="@/assets/icons/checkbox.svg" width="15" height="15" alt="checkbox" style="background-color: white;"
           v-if="! item.checked"
           @click="choose(index, true)"/>
      <img v-else class="checkbox" src="@/assets/icons/checkbox-checked.svg" width="15" height="15" alt="checkbox-checked"
           @click="choose(index, false)"/>
      <small class="mx-2">{{ item.name }}</small>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "FilterTableColumn",
  props: [
    'fields',
    'type',
    'filterSelectedList'
  ],
  emits: [
    'filterBy',
  ],
  setup(props, {emit}) {
    const array = ref(props.fields);
    const nameField = ref(props.type);
    const filterChecked = ref(props.filterSelectedList);

    onMounted(() => {
      // Add checked bool for field before render.
      array.value.forEach((item) => {
        // Checked only filter name in list selected.
        item.checked = !!filterChecked.value.includes(item.name);
      });
    });

    const choose = (index, value) => {
      array.value[index].checked = value;
      emit('filterBy', {
        'name': array.value[index].name,
        'type': nameField.value,
      });
    }

    return {
      array,
      choose,
      nameField,
    }
  }
}
</script>

<style scoped>
.box {
  border-radius: 0.5em;
  border: 2px solid #A58ABA;
  position: absolute;
  left: 0;
  top: 100%;
}
.checkbox:hover {
  cursor: pointer;
}
</style>
