
import { departmentException } from "@/use/departmentException";
export async function canDashboard({ next, store }) {
  if (
    typeof store.state.user.state !== "undefined"
    &&
    (store.state.user.state.slug == 'working' || departmentException(store.state.userData))
  ) {
    return next();
  } else {
    return next("/check-in");
  }
}
