<template>
  <Loader />
  <main>
    <UserPanel />
    <router-view />
  </main>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
@import "./scss/style.scss";

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Switch */
:root {
  --toggle-bg-on: #a58aba;
  --toggle-border-on: #a58aba;
  --toggle-ring-color: white;
}
</style>

<script>
import Loader from "@/components/Loader.vue";
import UserPanel from "@/components/auth/UserPanel.vue";

export default {
  components: { UserPanel, Loader },
};
</script>
