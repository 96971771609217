<template>
  <div class="messages">
    <div class="messages__body">
      <div @click="callback(toRaw(message))" class="messages__body__single__message ps-3" v-for="(message, index) in $props.messages" :key="index">
        <div :class="{ 'fw-bold': !message.has_been_read }" class="d-flex align-items-center w-70">
           <span v-show="!message.has_been_read" class="me-2 badge" :style="{ backgroundColor: badgeColor }">
             {{ message.messages.length }}
          </span>
          <span class="messages__body__single__message__text">
            {{ message.name }}
          </span>
          <small class="messages__body__single__message_separator">-</small>
          <span class="messages__body__single__message__customer text-truncate w-55" data-toggle="tooltip" data-placement="top"
                :title="`${message.chat_channelable.customer.first_name} ${message.chat_channelable.customer.last_name}`">
            {{ message.chat_channelable.customer.first_name }} {{ message.chat_channelable.customer.last_name }}
          </span>
        </div>
        <small class="mx-2">
           {{ getDateTimeLastMessage(message.messages) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import {toRaw} from 'vue';
import _head from "lodash/head";
import {dateFormatter} from '@/use/utilities/time/dateFormatter';
import moment from "moment/moment";

export default {
  name: "Messages",
  props: {
    messages: {
      type: Array,
      required: true,
    },
    badgeColor: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      default: 140,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const getDateTimeLastMessage = (messages) => {
      // Reverse array.
      let messagesOrdered = toRaw(messages).reverse();
      // Exclude message has empty body.
      messagesOrdered = messagesOrdered.filter((message) => {
        return message.body !== null;
      });

      // Show date is same or before today.
      let messageCreatedAt = moment(_head(messagesOrdered).created_at).format("YYYY-MM-DD HH:mm:ss");
      let now = moment().format("YYYY-MM-DD HH:mm:ss");

      if (moment(messageCreatedAt).isBefore(now)) {
        return dateFormatter(_head(messagesOrdered).created_at);
      } else {
        // Show only time... it's today.
        return dateFormatter(_head(messagesOrdered).created_at, {onlyDate: false, onlyTime: true, dash: false, iso: false});
      }
    }

    return {
      toRaw,
      getDateTimeLastMessage,
    };
  },
}
</script>

<style scoped lang="scss">
  .ps-3 {
    padding-left: 5px;
  }
  .py-3 {
    padding: 5px 0;
  }
  .messages {
    background-color: #FFFFFF;
  }
  .messages__counter {
    margin-left: 5px;
  }
  .messages__header {
    font-weight: bold;
  }
  .messages__body {
    overflow: auto;
  }
  .messages__body__single__message_separator {
    margin: 0 5px;
  }
  .messages__header,
  .messages__body__single__message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
  }
  .messages__body__single__message:hover {
    &:hover {
       cursor: pointer;
       background-color: #F3E9FF;
    }
  }
</style>
