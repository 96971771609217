<template>
  <div class="container-fluid">
    <div class="row vw-100 vh-100 p-4">
      <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 d-flex flex-column justify-content-start align-items-center">
        <brand :width="150" />
        <div class="mb-5"></div>
        <TimestampInRealTime />
        <ButtonBack :label="'indietro'" />
      </div>
      <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex h-100">
        <WorkSummary />
      </div>
    </div>
  </div>
</template>

<script>
import Brand from '@/components/Brand';
import TimestampInRealTime from '@/components/TimestampInRealTime';
import WorkSummary from '@/components/checkout/WorkSummary';
import ButtonBack from "@/components/general/ButtonBack";

export default {
  name: "Checkout",
  components: {
    ButtonBack,
    Brand,
    TimestampInRealTime,
    WorkSummary,
  },
}
</script>
