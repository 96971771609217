<template>
  <div class="flex-column">
    <h2>{{ $props.stationName }}</h2>
    <p>Lavori attivi in corso ({{ $props.countSlots }})</p>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: [
    'countSlots',
    'stationName',
  ]
}
</script>
