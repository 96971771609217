<template>
  <template v-if="allWithMedia">
    <Carousel :items-to-show="itemsToShow">
      <Slide v-for="(element, index) in $props.elements" :key="index">
        <div @click="setSelectedImage(element, index)"
          class="carousel__item card shadow border-light mb-3"
          style="cursor: pointer; max-width: 18rem;">
          <div class="card-header d-flex justify-content-between">
            <small class="fw-bold">{{ moment.unix(element.createdAt).subtract(1, 'hours').format("DD/MM/YYYY") }}</small>
            <small class="fw-bold">{{ moment.unix(element.createdAt).subtract(1, 'hours').format("HH:mm") }}</small>
          </div>
          <div class="card-body">
            <p class="card-text">
              <template v-if="isPdf(element)">
                <img src="@/assets/images/pdf.png" alt="pdf" class="img-thumbnail card-img-top" />
              </template>
              <template v-else>
                <template v-if="element.temporaryUrl">
                  <img :src="element.temporaryUrl" alt="" class="img-thumbnail card-img-top"/>
                </template>
              </template>
            </p>
          </div>
        </div>
      </Slide>
      <template #addons v-if="$props.elements.length > itemsToShow">
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </template>
  <template v-else>
    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="spinner-border text-violet" role="status"></div>
    </div>
  </template>
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { computed, onMounted, ref, onUnmounted } from "vue";
import { api as viewerApi } from "v-viewer";
import moment from 'moment';

export default {
  name: "SliderImage",
  props: {
    elements: {
      type: Array,
      required: true,
    },
    itemToShow: {
      type: Number,
      required: true,
    },
    itemToShowWhenResize: {
      type: Number,
      required: false,
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup(props) {
    let windowWidth = ref(window.innerWidth);
    const selectedImage = ref(null);

    const openPreview = () => {
      viewerApi({
        options: {
          toolbar: true,
          url: "data-source",
          initialViewIndex: selectedImage.value,
        },
        images: previewImages.value,
      });
    };

    const isPdf = (element) => {
      return (element.mime_type === 'application/pdf');
    }

    const setSelectedImage = (element, index) => {
      selectedImage.value = index;
      if (isPdf(element)) {
        return pdfPreview(element);
      }
      openPreview();
    };

    const previewImages = computed(() => {
      return props.elements.map((image) => {
        return {
          "data-source": image.temporaryUrl,
        };
      });
    });

    const itemsToShow = computed(() => {
      if (windowWidth.value <= 1550 || props.elements.length < 2) {
        return 1;
      }

      return props.itemToShowWhenResize;
    });

    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    onMounted(() => {
      window.addEventListener("resize", onWidthChange);
    });

    const allWithMedia = computed(() => {
      return props.elements.every((element) => {
        return element.temporaryUrl !== false;
      });
    });

    const pdfPreview = (element) => {
      window.open(element.temporaryUrl);
    }

    return {
      allWithMedia,
      itemsToShow,
      windowWidth,
      setSelectedImage,
      pdfPreview,
      isPdf,
      moment,
    };
  },
};
</script>

<style>
  .carousel__prev,
  .carousel__next {
    margin: -10px;
  }
  .carousel__pagination {
    margin-top: 0;
  }
  .img-thumbnail {
    width: 300px;
    height: 200px;
    object-fit: cover;
  }
</style>
