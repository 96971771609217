<template>

  <Choose
    :test_work_queue_id="$props.test_work_queue_id"
    :test_work_queue_phase_id="$props.test_work_queue_phase_id"
    :internal_phase_id="$props.internal_phase_id"
    :prescription_test_id="$props.prescription_test_id"
    :state_slug="$props.state_slug"
    :title="'Riassegnazione lavoro'"
    :prescription="$props.prescription"
    :subtitle="'Selezionare la fase interna al quale assegnare il lavoro'">
  </Choose>

</template>

<script>
import Choose from "@/components/Iso-in/Choose";
import {useRoute} from "vue-router";

export default {
  name: "IsoInReassign",
  inheritAttrs: false,
  props: [
    'test_work_queue_id',
    'test_work_queue_phase_id',
    'internal_phase_id',
    'prescription_test_id',
    'state_slug',
    'prescription',
  ],
  components: {
    Choose,
  },
  setup(props) {
    const route = useRoute();

    console.log("page: iso in reassign");

    console.log(`state_slug: ${props.state_slug}`);
    console.log(`test_work_queue_id: ${props.test_work_queue_id}`);
    console.log(`test_work_queue_phase_id: ${props.test_work_queue_phase_id}`);
    console.log(`internal_phase_id: ${props.internal_phase_id}`);
    console.log(`prescription_test_id: ${route.query.prescription_test_id}`);
  }
}
</script>
