export function backofficeUrl() {
  // Original URL
  const url = new URL(process.env.VUE_APP_API_BASE_URL);

  // Change the subdomain 'api' to 'admin'
  url.hostname = url.hostname.replace("api.", "admin.");

  // Remove the '/v1' path
  url.pathname = "/";

  // Output the new URL as a string
  return url.toString();
}
