<template>
  <div class="mb-3">
    <label for="note" class="form-label">{{ $props.label }}</label>
    <textarea
        class="form-control"
        id="note"
        rows="3"
        v-model="message">
    </textarea>
  </div>
</template>

<script>
import {ref, watch} from 'vue';

export default {
  name: "note",
  props: [
    'label',
    'clipboard',
  ],
  emits: [
    'message'
  ],
  setup(props, {emit}) {
    const message = ref("");
    watch(() => props.clipboard, (clipboard) => {
      message.value = clipboard;
    });
    watch(message, async () => {
      emit("message", message.value);
    })

    return {
      message
    };
  }
}
</script>

<style scoped>
  .form-control:focus {
    border-color: rgb(225 210 242) !important;
    box-shadow: 0 0 0 0.25rem rgb(225 210 242) !important;
  }
</style>
