<template>
  <div class="card rounded-custom shadow border-0">
    <div class="card-header text-center rounded-only-top p-3">
      <template v-if="isIso">
        <strong class="text-uppercase">lavori sospesi ({{ $store.state.mainRole.team.display_name }})</strong>
      </template>
      <template v-else>
        <strong class="text-uppercase">info clinica - aiuto - sospesi</strong>
      </template>
    </div>
    <div class="card-body">
      <ul class="list-group">
        <template v-for="(testWorkQueuePhase, index) in $props.testWorkQueuePhases" :key="index">
            <li class="list-group-item d-flex justify-content-between align-items-start border-0 px-0">
                <div class="card shadow-sm w-100">
                  <p class="card-header fw-bold" data-bs-toggle="collapse" :href="`#collapseTestWorkQueue${testWorkQueuePhase.test_work_queue.id}`" role="button" aria-expanded="false" aria-controls="collapseExample">
                    {{ testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text }} /
                    {{ testWorkQueuePhase.test_work_queue.prescription_test.prescription.customer.first_name }}
                    {{ testWorkQueuePhase.test_work_queue.prescription_test.prescription.customer.last_name }}
                    <span v-if="isMissing(testWorkQueuePhase.feedbacks)" class="float-end">?</span>
                  </p>
                  <div class="collapse p-3" :id="`collapseTestWorkQueue${testWorkQueuePhase.test_work_queue.id}`">
                    <p>Nota clinica: <small>{{ testWorkQueuePhase.test_work_queue.prescription_test.notes ?? '-' }}</small></p>
                    <p>Data sospensione: <small>{{ getOnHoldData(testWorkQueuePhase.feedbacks) }}</small></p>
                    <template v-for="(feedback, index) in testWorkQueuePhase.feedbacks" :key="index">
                      <p>Motivo della sospensione: <small>{{ feedback?.feedback?.description }}</small></p>
                      <p>Nota: <small>{{ feedback.note ?? '-' }}</small></p>
                      <template v-if="canResume(testWorkQueuePhase.feedbacks)">
                        <button class="btn btn-outline-violet text-uppercase mt-2" @click="resume(testWorkQueuePhase)">riprendi</button>
                      </template>
                    </template>
                  </div>
                </div>
              </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _head from "lodash/head";
import store from "../../store";

export default {
  name: "OnHoldPhases",
  props: {
    testWorkQueuePhases: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'resume',
  ],
  setup(props, {emit}) {
    const isIso = (store.state.mainRole.team.name === 'iso-fissa' || store.state.mainRole.team.name === 'iso-mobile');
    const canResume = (feedbacks) => {
      let noFeedbacks = (Object.keys(feedbacks).length === 0);
      if (noFeedbacks) return false;

      return  _head(feedbacks)?.feedback?.types.some(item => item.slug === 'resumable' || item.slug === 'missing-box');
    }

    const getBorderColorByFeedbackType = (testWorkQueuePhase) => {
      switch (_head(testWorkQueuePhase.feedbacks)?.feedback?.type?.slug) {
        case 'info':
          return 'bg-color-info';
        case 'warning':
          return 'bg-color-warning';
      }
    }

    const resume = (testWorkQueuePhase) => {
      emit('resume', testWorkQueuePhase);
    }

    const getOnHoldData = (feedbacks) => {
      let dataTime = _head(feedbacks).created_at;

      return moment(dataTime).format("DD/MM/YYYY - HH.mm");
    }

    const isMissing = (feedbacks) => {
      return (_head(feedbacks)?.feedback?.type?.slug === 'missing-box');
    }

    return {
      moment,
      resume,
      canResume,
      isIso,
      getBorderColorByFeedbackType,
      getOnHoldData,
      isMissing,
    };
  },
};
</script>

<style scoped>
  .card-body { max-height: 350px; overflow: auto; }
  .bg-color-info { background-color: rgb(165, 138, 186) }
  .bg-color-warning { background-color: #AF0B55 }
  .bg-color-info, .bg-color-warning { color: #FFFFFF }
</style>
