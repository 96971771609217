<script setup>
import { onMounted, ref, defineProps } from "vue";
import { fetchById as fetchPrescriptionById } from "@/use/repositories/prescriptions/fetchById";
import _ from "lodash";
import moment from 'moment';

const props = defineProps({
  prescriptionId: String,
});

const prescriptionTests = ref([]);

onMounted(() => {
  fetchPrescriptionById(props.prescriptionId, "show_with_test_work_queue_phase_warehouse_requests", false).then((response) => {
    prescriptionTests.value = parseData(response.prescription.prescription_tests);
  });
});

const parseData = (data) => {
  let requests = [];

  data.forEach((item) => {
    let data = {
      name: item.name,
      departments: [],
    };

    data.departments = _.chain(item.test_work_queue.test_work_queue_phases)
      .groupBy("internal_phase.department.name")
      .map((value, key) => {
        return {
          key: key,
          users: _.groupBy(value, "user.id"),
        };
      })
      .value();

    requests.push(data);
  });

  return requests;
};
</script>

<template>

  <template v-if="prescriptionTests.length > 0">
    <template v-for="(prescriptionTest, index) in prescriptionTests" :key="index">
      <div class="orders d-flex flex-column align-items-center p-3 overflow-auto h-100 w-100">

        <div class="text-uppercase bg-dark-violet px-4 py-1 rounded text-white mb-3 text-center">{{ prescriptionTest.name }}</div>

        <template v-for="(department, index) in prescriptionTest.departments" :key="index">

          <div class="d-flex flex-column w-100">
            <div class="d-flex">
              <span class="fw-bold">{{ department.key }}</span>
            </div>
            <template class="mb-3" v-for="(users, index) in Object.values(department.users)" :key="index">
              <template v-for="(user, index) in users" :key="index">

                <div class="d-flex flex-column bg-gray-lab rounded p-3 mb-2">
                  <template v-for="(warehouseRequest, index) in user.warehouse_requests" :key="index">
                    <div class="d-flex fw-bold mb-1">{{ moment(warehouseRequest.created_at).format("DD/MM/YYYY") }} - {{ moment(warehouseRequest.created_at).format("HH:mm") }}</div>
                    <div class="article d-flex">{{ warehouseRequest.article.code }} - {{ warehouseRequest.article.name }} - {{ warehouseRequest.approved_quantity }}</div>
                  </template>
                  <div class="d-flex fw-bold mt-2 justify-content-end">{{ user.user.last_name }} {{ user.user.first_name }}</div>
                </div>

              </template>
            </template>
          </div>

        </template>

      </div>
    </template>
  </template>

  <template v-else>
    <div class="orders d-flex flex-column align-items-center p-3 overflow-auto h-100 w-100">
      <span>Nessun ordine trovato</span>
    </div>
  </template>

</template>

<style scoped>
.article {
  font-size: 14px;
}
</style>
