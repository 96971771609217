<template>
  <div class="modal fade" :id="$props.modal_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="'modal-' + $props.size">
      <div class="modal-content rounded-3">
        <div class="modal-body p-0 overflow-auto">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {onMounted} from "vue";

export default {
  name: "Standard",
  props: [
    'size',
    'modal_id',
    'options',
  ],
  emits: [
    'hidden'
  ],
  setup(props, context) {

    onMounted(() => {
      showModal(props.modal_id);
      dismissModal(props.modal_id, "hidden");
    });

    // Modal functions -> show/dismiss
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal), props.options);
      modal.show();
    }
    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

  }
}
</script>
