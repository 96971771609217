import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function storeNote(formData) {
  const axios = axiosInstance().primoLab;
  let result = [];

  await axios
    .post(`/prescription-test-notes`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(async (response) => {
      result = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { result };
}
