<template>
  <div class="d-flex flex-column w-100 p-4" @click="selected(test)"
    :class="[{ 'bg-light-violet': test.selected, 'test': isClickable(test.state_slug) }]">
    <div class="d-flex align-items-start" :class="{ 'text-secondary': isSelected(test.state_slug) === false }">
      <img class="state" :src="require(`@/assets/icons/${getIconByState(test.state_slug)}.svg`)" width="25"
        :alt="`icon-${test.state_slug}-state`" />
      <div class="number">{{ test.code }}</div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex fw-bold text-uppercase justify-content-between align-items-center">
          <div>
            {{ test.name }} {{ test.state_slug === 'without-state' ? '(Senza stato)' : '' }}
            <em v-if="isSelected(test.state_slug) === false"><span class="ms-1 me-1">-</span>Prova {{ test.state_slug
            }}</em>
          </div>
          <!-- Notes -->
          <img
            src="@/assets/icons/note.svg"
            width="25"
            alt="note"
            @click.stop.prevent
            @click="notesModal = true; prescriptionTestIdSelected = test.id;"
          >
        </div>
        <div class="d-flex align-items-center justify-content-between me-4">
          <div class="d-flex align-content-center text-uppercase flex-wrap">
            <template v-if="test.delivery_date">
              <div class="d-flex align-items-center me-2">
                <span class="material-icons-outlined me-2">
                  flag
                </span>
                {{ test.delivery_date }}
              </div>
            </template>
            <template v-if="test.created_at">
              <div class="d-flex align-items-center me-2">
                <span class="material-icons-outlined me-2">
                  qr_code_scanner
                </span>
                {{ test.created_at ?? '' }}
              </div>
            </template>
          </div>
        </div>
        <template v-if="isCurrentDepartment(departments.LOGISTICS) && test.is_current_test && [states.PENDING, states,IN_THE_WORKS, states.ON_HOLD].includes(test.state_slug)">
          <div class="d-flex align-items-center me-2">
            <img class="me-2" src="@/assets/icons/barcode.svg" width="25" alt="check-circle-fill-green">
            <span class="text-decoration-underline"  @click="printBarcode()">
              Stampa etichetta
            </span>
          </div>
        </template>
        <template v-if="[states.SHIPPED, states.NEGATIVE_FEEDBACK].includes(test.state_slug)">
          <div class="test-info d-flex flex-wrap mt-1">
            <div class="d-flex align-items-center me-2">
              <span class="material-icons-outlined me-2">
                local_shipping
              </span>
              <span>{{ test.checkout_date }}</span>
            </div>
            <template v-if="test.shipping_codes.length > 0">
              <template v-for="(shippingCode, index) in test.shipping_codes" :key="index">
                <ShippingCode :shipping-code="shippingCode" />
              </template>
            </template>
          </div>
        </template>
        <template v-if="test.media?.length">
          <div class="d-flex align-items-center me-2">
            <span class="material-icons-outlined me-2 md-24">
              play_circle
            </span>
            <span class="text-decoration-underline" @click="showVideo(test.media[0]?.temporary_url)">
              {{ getMedia(test.media) }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- Video modal -->
  <Empty v-if="showVideoModal" :size="'xl'" :modal_id="'showing'" @hidden="videoCloseModal">
    <video-player :options="{
      autoplay: true,
      controls: true,
      fluid: true,
      sources: [
        {
          src: dataVideo.url,
          type: 'video/mp4',
        },
      ],
    }">
    </video-player>
  </Empty>

  <Empty v-if="notesModal" :size="'lg'" :modal_id="'notesModal'" @hidden="closeNotesModal">
    <div class="p-5">
      <Dropzone :upload="upload" :assign-url-s3="assignUrlPrescriptionTests" :store-url="storeNotePrescriptionTests"
        :form-data="formData" @increment="mediaCounter++" @decrease="mediaCounter--" @restore="restore" />
      <textarea class="form-control" rows="10" v-model="note">
      </textarea>
      <!-- Buttons -->
      <div class="modal-footer p-1 border-0 justify-content-center">
        <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="saveNota()"
          :disabled="(!note.length)">salva nota</button>
        <button type="button" class="btn btn-outline-violet text-uppercase mt-4"
          @click="closeNotesModal()">Chiudi</button>
      </div>
    </div>
  </Empty>
</template>

<script>
import { onMounted, ref, reactive, inject, computed } from "vue";
import { Modal } from 'bootstrap';
import _head from 'lodash/head';
import { useI18n } from "vue-i18n";
import { success } from "@/use/toast/success";

import Empty from "@/components/Modal/Empty";
import VideoPlayer from "@/components/checkout/VideoPlayer";

import { hideModal as closeModal } from "@/use/modal/hide";
import Dropzone from "@/components/upload/Dropzone";

import { storeNote as storeNotePrescriptionTests } from "@/use/repositories/prescriptionTests/storeNote";
import { assignUrl as assignUrlPrescriptionTests } from "@/use/repositories/prescriptionTests/assignUrl";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import { print } from "@/use/repositories/devices/print";
import { useStore } from "vuex";
import ShippingCode from "./ShippingCode.vue";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";

export default {
  name: "Test",
  props: {
    data: Object,
  },
  components: {
    Dropzone,
    Empty,
    VideoPlayer,
    ShippingCode
},
  emits: [
    'selected'
  ],
  setup(props, { emit }) {
    const { states, devicesType, departments } = inject("constants");
    const i18n = useI18n();
    const SKIPPED = "saltata";
    const WITHOUT_STATE = "without-state";
    const test = ref(props.data);
    const showVideoModal = ref(false);
    const dataVideo = reactive({
      url: null,
    });
    const notesModal = ref(false);
    const note = ref("");
    const prescriptionTestIdSelected = ref(null);
    const upload = ref(false);
    const mediaCounter = ref(0);

    const videoCloseModal = () => {
      const videoModal = document.querySelector('#showing');
      if (videoModal) {
        const modal = Modal.getInstance(videoModal);
        modal.hide();
        showVideoModal.value = false;
      }
    }
    const store = useStore();

    const formData = computed(() => {
      let formData = new FormData();
      if (note.value.trim().length !== 0) {
        formData.append("note", note.value);
      }
      formData.append("prescription_test_id", parseInt(prescriptionTestIdSelected.value));
      if (mediaCounter.value !== 0) {
        formData.append("action", "with_media");
      } else {
        formData.append("action", "without_media");
      }

      return formData;
    });

    onMounted(() => {
      if (test.value.selected) {
        emit("selected", { id: test.value.id, preselect: true });
      }
    });

    const saveNota = () => {
      if (mediaCounter.value !== 0) {
        // Exists media.
        upload.value = true;
      } else {
        // Exists only nota.
        if (note.value.trim().length !== 0) {
          storeNotePrescriptionTests(formData.value).then(() => {

            store.dispatch("setManagerNotesHaveChanged", true);
            closeNotesModal();
          });
        }
      }

    }

    const restore = () => {
      store.dispatch("setManagerNotesHaveChanged", true);
      closeNotesModal();
    }

    const isSelected = (slug) => {
      return slug !== SKIPPED;
    };

    const isClickable = (slug) => {
      return (slug !== SKIPPED && slug !== WITHOUT_STATE);
    }

    const showVideo = async (temporaryUrl) => {
      dataVideo.url = temporaryUrl;
      showVideoModal.value = true;
    };

    const getIconByState = (slug) => {
      switch (slug) {
        case "pending":
          return "consulting/pending-violet";

        case "without-state":
          return "consulting/question";

        case "in-the-works":
        case "bagged":
        case "packaged":
          return "gear-violet";

        case "manager-changed-test":
        case "iso-in-changed-test":
          return "change-test";

        case "non-complaint":
          return "blocked";

        case states.SHIPPED:
        case states.COMPLETED:
          return "check-circle-fill-green";

        case states.ON_HOLD:
          return "consulting/pause";
        case SKIPPED:
          return "move-up";

        default:
          return "close-circle-fill-red";
      }
    };

    const selected = (test) => {
      console.log(test.state_slug);
      if (test.state_slug !== WITHOUT_STATE) {
        if (isSelected(test.state_slug)) {
          emit("selected", { id: test.id, preselect: false });
        }
      }
    };

    // Close notes modal.
    const closeNotesModal = async () => {
      await closeModal('notesModal');
      notesModal.value = false;
      upload.value = false;
      note.value = "";
      mediaCounter.value = 0;
      prescriptionTestIdSelected.value = null;
    }
    const printBarcode = () => {
      fetchAllTesWorkQueue({
        action: 'with_base_64_labels',
        ids: [ props.data.test_work_queue.id ]
      }, true)
        .then((response) => print({
          device_type_slug: devicesType.ANALOG_LABEL,
          department_slug: departments.LOGISTICS,
          data: _head(response.testWorkQueue).raw_data,
        }))
        .then(() => {
          success(i18n.t("Barcodes print successfully!"));
        })
    }
    const getMedia = (media) => {
      return _head(media)?.file_name
    }

    return {
      test,
      getIconByState,
      selected,
      isSelected,
      showVideo,
      showVideoModal,
      dataVideo,
      videoCloseModal,
      closeNotesModal,
      notesModal,
      note,
      prescriptionTestIdSelected,
      states,
      saveNota,
      upload,
      mediaCounter,
      restore,
      isClickable,
      storeNotePrescriptionTests,
      assignUrlPrescriptionTests,
      formData,
      printBarcode,
      departments,
      isCurrentDepartment,
      getMedia
    };
  },
};
</script>

<style scoped>
.test:hover {
  cursor: pointer;
}

.state,
.number {
  margin-right: 0.8em;
}

.icon {
  margin-right: 0.2em;
}

@media screen and (max-width: 1350px) {
  .test-info {
    flex-direction: column !important;
  }
}
</style>
