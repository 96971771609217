import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function fetchNotes(prescriptionId, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let notes = [];

  await axios
    .get(`/prescriptions/${prescriptionId}`, {
      params: {
        action: "show_with_prescription_test_notes",
      },
    })
    .then(async (response) => {
      notes = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { notes };
}
