import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(deviceId) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let data = [];

  await axios
    .get(`/devices/${deviceId}`, {
      params: {
        action: 'with_checkin_validation',
      }
    })
    .then(async (resp) => {
      data = resp.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
