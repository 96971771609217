import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByState(action, stateSlug) {
  const axios = axiosInstance().primoLab;
  let testWorkQueues = [];

  await axios
    .get(`/test-work-queues`, {
      params: {
        action: action,
        states: stateSlug,
      }
    })
    .then(async (response) => {
      testWorkQueues = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueues };
}
