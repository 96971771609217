<template>
  <div class="d-flex align-items-center justify-content-center flex-column">
    <Brand :width="200" :height="200" />
    <div class="badge bg-violet text-wrap fs-3 text-uppercase">
      {{ $props.type }}
    </div>
    <h1>{{ $props.title }}</h1>
    <small class="mb-5">{{ $props.subtitle }}</small>
    <Spinner />
  </div>
</template>

<script>
import Brand from "@/components/Brand";
import Spinner from "../Spinner";

export default {
  name: "Loading",
  components: {
    Spinner,
    Brand,
  },
  props: [
    'title',
    'subtitle',
    'type',
    'sizeTitle',
    'sizeSubtitle',
  ]
}
</script>

<style scoped>
h1 {
  font-size: 7em;
}
small {
  font-size: 3em;
  font-weight: 200;
}
</style>
