import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import _sortBy from 'lodash/sortBy';

export async function fetchAll(params) {
  const axios = axiosInstance().primoLab;
  let listRows = [];

  await axios
    .get("/list-rows", {
      params
    })
    .then(async (response) => {
      listRows = _sortBy(response.data.data, 'name');
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { listRows };
}
