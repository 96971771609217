<template>

  <div class="box d-flex align-items-center justify-content-center flex-column p-5 rounded-custom w-100" :class="{'create-bridge-style': $props.bridgeStyle }">

    <template v-if="$props.bridgeStyle">
      <h2>Stai creando un ponte?</h2>
      <p>Seleziona tutti gli elemneti che formano il ponte</p>
      <div class="d-flex">
        <button type="button" class="btn btn-violet text-uppercase mt-4 me-2" @click="confirm()">Crea ponte</button>
        <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="decline()">annulla</button>
      </div>
    </template>

    <template v-else>
      <h2 v-if="$props.title !== null">{{ $props.title }}</h2>
      <p v-if="$props.subtitle !== null">{{ $props.subtitle }}</p>

<!--      <label for="upload" class="btn btn-outline-violet mt-3 p-3" @click="uploadFile($event)">Seleziona il file</label>-->
<!--      <input id="upload" :disabled="Object.keys($props.upload).length === 0" style="display:none" type="file" name="stl" accept="application/sla, application/vnd.ms-pki.stl, application/x-navistyle">-->

      <div class="mb-3">
        <label for="formFile" class="form-label"></label>
        <input @change="uploadFile($event)"
               :disabled="(($props.upload.length === 0 && $props.model.length === 0) || $props.materialSlug === null || $props.colorId === null)"
               class="form-control" type="file" id="formFile">
      </div>

    </template>

  </div>

</template>

<script>
import {inject, ref, toRaw} from 'vue';
import {useRoute} from "vue-router";
import {create as createAsset} from "@/use/repositories/assets/create";
import _head from "lodash/head";

export default {
  name: "DragAndDropArea",
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    bridgeStyle: {
      type: Boolean,
      default: false
    },
    upload: {
      type: Array,
      required: true
    },
    model: {
      type: Array,
    },
    material: {
      type: [String, null, Object],
      required: true
    },
    colorId: {
      type: [Number, null],
      required: true
    }
  },
  emits: [
    'createBridge',
    'assetIdCreated',
    'resetSelection',
    'sync-models',
  ],
  setup(props, {emit}) {
    const route = useRoute();
    const idsToUpload = ref([]);
    let assetType = null;
    const { assetsType } = inject("constants");

    const getAssetType = (model) => {
      switch (model.type) {
        case 'gums':
          return 'gum';
        case 'stumps':
          return 'stump';
        case 'custom-pins':
          return 'custom-pin';
        default:
          return model.slug;

      }
    }

    // Upload stl file.
    const uploadFile = async (e) => {
      let file = null;
      let data = {
        ids: null,
        asset: null
      }

      // Fetch file
      file = e.target.files[0];

      // Read type slug.
      if (props.model.length === 1) {
        assetType = getAssetType(_head(props.model));
      } else {
        assetType = assetsType.MILLING;
      }

      //Read ids and create assets.
      createAsset(
        route.params.test_work_queue_phases_id,
        toRaw(props.upload),
        file,
        props.material.id,
        props.colorId,
        assetType,
      ).then((response) => {
        // Set response.
        data.ids = toRaw(props.upload);
        data.asset = response.asset;

        // Emits.
        emit('assetIdCreated', data);
        emit('resetSelection', []);

        // Sync models to upload.
        if (assetType !== "milling") {
          emit('sync-models', {
            'type': _head(props.model).type,
            'slug': _head(props.model).slug,
            'asset': response.asset,
          });
        }

        // Reset.
        document.getElementById("formFile").value = null;

      }).catch(() => {
        // Reset.
        document.getElementById("formFile").value = null;

        emit('resetSelection', []);
        emit('assetIdCreated', []);
      });
    }

    const confirm = () => {
      emit('createBridge', true);
    }
    const decline = () => {
      emit('createBridge', false);
    }

    return {
      confirm,
      decline,
      idsToUpload,
      uploadFile,
    }
  }
}
</script>

<style scoped>
  .box {
    border: 5px dashed #CCCCCC;
    height: 300px;
  }
  .create-bridge-style {
    border: unset;
    background-color: #F3E9FF;
  }
</style>
