import {axiosInstance} from "@/use/utilities/axios/app/axios-instance";

export async function fetchDentalImpression() {
  const axios = axiosInstance().primoLab;
  let dentalImpressions = [];

  await axios
    .get("prescriptions", {
      params: {
        action: 'with_digital_check_in',
      },
    })
    .then(async (response) => {
      dentalImpressions = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { dentalImpressions };
}
