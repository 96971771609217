<template>
  <div class="modal fade" id="modalAssignNewTWQP" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-3 p-4">
        <div class="p-1 border-0 text-center">
          <h3 class="mb-4 fw-normal">Inizia nuovo lavoro</h3>
        </div>
        <div class="modal-body text-center">
          <p>Confermi di non potere riprendere nessun <br> lavoro sospeso?</p>
        </div>
        <div class="modal-footer p-1 border-0 justify-content-center">
          <button type="button" class="btn btn-violet text-uppercase mt-4" @click="confirm">
            conferma
          </button>
          <button type="button" class="btn btn-outline-violet text-uppercase mt-4" data-bs-dismiss="modal">
            annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from "bootstrap";
import {onMounted, ref} from "vue";

export default {
  name: "ConfirmNewJob",
  setup(props, context) {
    const bool = ref(false);

    onMounted(() => {
      showModal("modalAssignNewTWQP");
      dismissModal("modalAssignNewTWQP", "hidden")
    })

    // Modal fx
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal));
      modal.show();
    }

    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

    const confirm = async () => {
      bool.value = true;
      context.emit("confirm", true);
      let modalId = document.getElementById("modalAssignNewTWQP");
      const modal = Modal.getInstance(modalId);
      modal.hide();
    }

    return {confirm}

  }
}
</script>
