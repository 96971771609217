import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function assignUrl(fileName) {
  const axios = axiosInstance().primoLab;
  let assignUrl = null;

  await axios
    .post('/prescription-test-notes/media-signed-url', {
      filename: fileName,
    })
    .then(async (response) => {
      assignUrl = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return {assignUrl};
}
