import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchTemporaryUrl(id) {
  const axios = axiosInstance().primoLab;
  let temporary_url = null;

  await axios
    .get(`/assets/signed-url`, {
      params: {
        id: id,
      },
    })
    .then(async (response) => {
      temporary_url = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { temporary_url };
}
