<template>
  <div class="modal fade bd-example-modal-lg" :id="$props.data.modal_id" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content rounded-3 p-4">
        <div class="modal-body text-center" ref="modal">
          <Scene
            remote-file-type="stl"
            :remote-file-url="$props.data.temporary_url"
            :style="{
              width: '700',
              height: '700',
            }"
          />
        </div>
        <div class="modal-footer p-1 border-0 justify-content-center">
          <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="event('hidden')">
            {{ $props.data.decline_label }}
          </button>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from "bootstrap";
import {onMounted, ref} from "vue";
import Scene from './Scene.vue';

export default {
  name: "Modal",
  components: {
    Scene,
  },
  props: [
    'data'
  ],
  emits: [
    'hidden',
  ],
  setup(props, context) {
    const bool = ref(false);

    onMounted(() => {
      showModal(props.data.modal_id);
      dismissModal(props.data.modal_id, "hidden");
    });

    // Modal functions -> show/dismiss
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal), { backdrop: 'static', keyboard: false });
      modal.show();
    }

    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

    const event = async (emitName) => {
      bool.value = true;
      context.emit(emitName, false);
      let modalId = document.getElementById(props.data.modal_id);
      const modal = Modal.getInstance(modalId);
      modal.hide();
    }

    return {
      event
    }

  }
}
</script>
