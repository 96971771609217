import { axiosInstanceWithLoader } from "@/use/utilities/axios/up/axios-instance-primo-up-with-loader";
import { default as defaultAxios } from "@/use/utilities/axios/up/axios-instance-primo-up";

export async function update(id, params, loading) {
  const axios = loading ? axiosInstanceWithLoader().primoUpApi : defaultAxios;
  let prescriptionTest = [];
  await axios
    .put(`/prescription-tests/${id}`, params)
    .then(async (response) => {
      prescriptionTest = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionTest };
}
