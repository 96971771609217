<template>
  <div class="d-flex p-3 flex-column overflow-auto w-100" style="max-height: 500px;">
    <div class="d-flex flex-column">
      <h3 class="text-uppercase text-violet">file caricati</h3>
      <div class="d-flex flex-column overflow-auto">

        <!-- Project | Bridges -->
        <template v-for="(bridge, index) in $props.data.bridges" :key="index">
          <template v-if="bridge.tooth[0].assets.length > 0">
            <File
              :data="bridge"
              :bridge="true"
              :reset="resetSelection"
              @assetsToRemove="readAssetsToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

        <!-- Project | Tooth -->
        <template v-for="(teeth, index) in $props.data.tooth" :key="index">
          <template v-if="teeth.assets.length > 0">
            <File
              :data="teeth"
              :bridge="false"
              :reset="resetSelection"
              @assetsToRemove="readAssetsToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

        <!-- Models | Arches -->
        <template v-for="(arch, index) in $props.data.models.arches" :key="index">
          <template v-if="arch.assets.length > 0">
            <File
              :data="arch"
              :bridge="false"
              :reset="resetSelection"
              :isModel="true"
              @asset-model-to-remove="assetModelToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

        <!-- Models | Gums -->
        <template v-for="(gum, index) in $props.data.models.gums" :key="index">
          <template v-if="gum.assets.length > 0">
            <File
              :data="gum"
              :bridge="false"
              :reset="resetSelection"
              :isModel="true"
              @asset-model-to-remove="assetModelToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

        <!-- Models | stumps -->
        <template v-for="(stump, index) in $props.data.models.stumps" :key="index">
          <template v-if="stump.assets.length > 0">
            <File
              :data="stump"
              :bridge="false"
              :reset="resetSelection"
              :isModel="true"
              @asset-model-to-remove="assetModelToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

        <!-- Models | custom pins -->
        <template v-for="(pin, index) in $props.data.models.custom_pins" :key="index">
          <template v-if="pin.assets.length > 0">
            <File
              :data="pin"
              :bridge="false"
              :reset="resetSelection"
              :isModel="true"
              @asset-model-to-remove="assetModelToRemove"
              @resetCompleted="readResetCompleted"
              @preview="readAssetId"
            />
          </template>
        </template>

      </div>
    </div>
  </div>

  <div class="d-flex">

    <template v-if="$props.allUploaded && idsToRemoveAsset.length === 0 && assetsIdsToRemove.length === 0">
      <button type="button" class="btn btn-violet text-uppercase mb-3" data-bs-dismiss="modal">Prosegui</button>
    </template>

    <template v-if="idsToRemoveAsset.length > 0 || assetsIdsToRemove.length > 0">
      <button type="button" class="btn btn-violet text-uppercase mb-3" @click="remove">Rimuovi selezionati</button>
    </template>

  </div>

  <Modal v-if="showModal"
         :data="scene"
         @hidden="closeModal">
  </Modal>

</template>

<script>
import {reactive, ref, toRaw} from 'vue';

import _head from "lodash/head";
import _uniq from "lodash/uniq";

import File from "./File";
import Modal from '@/components/Scene/Modal';

import {remove as removeAssets} from "@/use/repositories/assets/remove";
import { fetchTemporaryUrl } from "@/use/repositories/assets/fetchTemporaryUrl";

export default {
  name: "FilesUploaded",
  components: {
    File,
    Modal,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    allUploaded: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'assetsIdsRemoved',
  ],
  setup(props, {emit}) {
    const idsToRemoveAsset = ref([]);
    const assetsIdsToRemove = ref([]);
    const resetSelection = ref(false);
    const showModal = ref(false);
    const scene = reactive({
      id: null,
      modal_id: null,
      title: null,
      subtitle: null,
      decline_label: null,
      temporary_url: null,
    });

    const syncAssetsIds = (id) => {
      if (assetsIdsToRemove.value.includes(id)) {
        assetsIdsToRemove.value.splice(assetsIdsToRemove.value.indexOf(id), 1);
      } else {
        assetsIdsToRemove.value.push(id);
      }
    }

    const readAssetsToRemove = (obj) => {
      obj.assetsIds = _uniq(obj.assetsIds);
      obj.assetsIds.forEach((id) => {
        if (assetsIdsToRemove.value.includes(id)) {
          assetsIdsToRemove.value.splice(assetsIdsToRemove.value.indexOf(id), 1);
        } else {
          assetsIdsToRemove.value.push(id);
        }
      });

      obj.prescriptionRowsIds.forEach((id) => {
        // Project sync with prescription rows id.
        if (idsToRemoveAsset.value.includes(id)) {
          idsToRemoveAsset.value.splice(idsToRemoveAsset.value.indexOf(id), 1);
        } else {
          idsToRemoveAsset.value.push(id);
        }
      });
    }

    const assetModelToRemove = (id) => {
      syncAssetsIds(id);
    }

    const remove = () => {
      console.log(toRaw(assetsIdsToRemove.value));
      removeAssets(toRaw(assetsIdsToRemove.value)).then(() => {
        // Push assets ids for models sync.
        assetsIdsToRemove.value.forEach((id) => {
          idsToRemoveAsset.value.push(id);
        });
        assetsIdsToRemove.value = [];
        emit('assetsIdsRemoved', toRaw(idsToRemoveAsset.value));
      }).catch((errors) => {
        // Show errors.
        alert(_head(errors));
      }).finally(() => {
        // Reset selected.
        idsToRemoveAsset.value = [];
        resetSelection.value = true;
      });
    }

    const readResetCompleted = (value) => {
      resetSelection.value = value;
    }

    const preview3D = async (assetId) => {
      // Set data for modal.
      scene.id = assetId;
      scene.modal_id = assetId;
      scene.decline_label = "chiudi";

      // Fetch temporary url.
      await fetchTemporaryUrl(assetId).then(async (response) => {
        scene.temporary_url = response.temporary_url;
        showModal.value = true;
      });
    }

    const closeModal = async (value) => {
      // Reset data modal.
      scene.show = null;
      scene.id = null;
      scene.decline_label = null;
      scene.temporary_url = null;

      showModal.value = value;
    }

    const readAssetId = (value) => {
      preview3D(value);
    }

    return {
      readAssetsToRemove,
      idsToRemoveAsset,
      remove,
      resetSelection,
      readResetCompleted,
      showModal,
      scene,
      closeModal,
      readAssetId,
      assetModelToRemove,
      assetsIdsToRemove,
    }
  }
}
</script>
