<template>
    <div id="column-days">

      <!-- TO WORK BEFORE TODAY -->
      <template v-if="Object.keys($props.data.toWorkBeforeDayGroupedByColor.works).length > 0">
        <RenderDay
          :data="$props.data.toWorkBeforeDayGroupedByColor"
          @filesReadyToDownload="readFilesReadyToDownload"
        />
      </template>

      <!-- TO WORK TODAY -->
      <template v-if="Object.keys($props.data.toWorkInTheDayGroupedByColor.works).length > 0">
        <RenderDay
          :data="$props.data.toWorkInTheDayGroupedByColor"
          @filesReadyToDownload="readFilesReadyToDownload"
        />
      </template>

      <!-- TO WORK AFTER TODAY -->
      <template v-if="Object.keys($props.data.toWorkGroupedByColor.works).length > 0">
        <template v-for="(day, index) in toWorkGroupedByColor.days" :key="index">
          <RenderDay
            :data="day"
            @filesReadyToDownload="readFilesReadyToDownload"
          />
        </template>
      </template>

    </div>

</template>

<script>
import RenderDay from "@/components/cam/RenderDay";
import {computed, reactive, toRaw} from "vue";

export default {
  name: "Day",
  components: {
    RenderDay,
  },
  props: [
    'data',
  ],
  emits: [
    'filesToDownload',
  ],
  setup(props, {emit}) {
    const toWorkGroupedByColor = computed(() => {
      let newDataStructure = {
        days: []
      }

      Object.entries(props.data.toWorkGroupedByColor.works).forEach((el) => {
        let date = el[0];
        let assets = toRaw(el[1]);

        let obj = {
          works: {
            [date]: []
          }
        }

        assets.forEach((asset) => {
          obj.works[date].push(asset);
        });

        newDataStructure.days.push(obj);
      });

      return reactive(newDataStructure);
    });

    // Read emits.
    const readFilesReadyToDownload = (filesToDownload) => {
      emit('filesToDownload', filesToDownload);
    }

    return {
      readFilesReadyToDownload,
      toWorkGroupedByColor,
    }
  }
}
</script>

<style scoped>
#column-days {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space:nowrap;
  height: 100%;
  display: flex;
}
#wrapper {
  width: 200px;
}
</style>
