<template>
  <div class="p-4 d-flex flex-column justify-content-between">
    <div>
      <h3 class="mb-4 fw-normal text-center">{{ $props.title }}</h3>
      <MultiselectCustom
        id="listRowSelect"
        :searchable="true"
        class="multiselect-violet mb-3"
        v-model="vModel"
        :placeholder="$props.placeholderNameSelect"
        :options="$props.optionsSelect"
        @clear="clear"
      />
      <slot></slot>
      <template v-if="notes">
        <Note class="mt-3"
              label="Nota:"
              @message="readNota" />
      </template>

    </div>
    <!-- Buttons -->
    <div class="modal-footer p-1 border-0 justify-content-center">
      <button class="btn btn-violet text-uppercase mt-4" @click="store()" :disabled="! canSubmitForm">conferma</button>
      <button class="btn btn-outline-violet text-uppercase mt-4" @click="close()">annulla</button>
    </div>

  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import MultiselectCustom from "@vueform/multiselect";
import Note from '@/components/general/Note';

export default {
  name: "SpecialActions",
  components: {
    MultiselectCustom,
    Note,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    modelNameSelect: {
      type: Number,
      require: false,
    },
    placeholderNameSelect:{
      type: String,
      require: true,
    },
    optionsSelect: {
      type: Array,
      required: true,
    },
    notes: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'clear',
    'store',
    'close',
    'selected',
  ],
  setup(props, {emit}) {
    const vModel = ref(props.modelNameSelect);
    const nota = ref(null);

    watch(() => vModel.value, () => {
      emit('selected', vModel.value);
    });

    const canSubmitForm = computed(() => {
      const { notes } = props;
      return notes ? vModel.value && nota.value : vModel.value;
    });

    const clear = () => { emit('clear') };
    const store = () => { emit('store', nota.value)};
    const close = () => { emit('close') };

    const readNota = (value) => {
      nota.value = value;
    }

    return {
      clear,
      store,
      close,
      vModel,
      readNota,
      canSubmitForm,
    }
  }
}
</script>
