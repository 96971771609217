<template>
  <Carousel :items-to-show="3" :breakpoints="breakpoints">
    <Slide v-for="(element, index) in items" :key="index">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <div class="d-flex flex-column">
            <small class="element p-1">{{ element.id }}</small>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex">
              <img src="@/assets/icons/characterization/dente-sup.svg" width="45" alt="tooth-corpo">
              <small>{{ renderColor(element.colorScale, element.colorId.corpo) }}</small>
            </div>
            <div class="d-flex">
              <img src="@/assets/icons/characterization/dente-med.svg" width="45" alt="tooth-colletto">
              <small>{{ renderColor(element.colorScale, element.colorId.colletto) }}</small>
            </div>
            <div class="d-flex">
              <img src="@/assets/icons/characterization/dente-inf.svg" width="45" alt="tooth-parteIncisale">
              <small>{{ renderColor(element.colorScale, element.colorId.parteIncisale) }}</small>
            </div>
          </div>
        </div>
      </div>
    </Slide>

    <template #addons v-if="items.length > 3">
      <Navigation/>
      <Pagination/>
    </template>

  </Carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { computed } from 'vue';

export default {
  name: 'Slider',
  props: [
    'teeth_characterization',
  ],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup(props) {
    const renderColor = (colorScale, key) => {
      return colorScale[key];
    }

    const items = computed(() => {
      return props.teeth_characterization.filter((item) => typeof item === "object" && !Array.isArray(item) && item !== null);
    })

    const breakpoints = {
      0: {itemsToShow: 1},
      1050: {itemsToShow: 3},
      1550: {itemsToShow: 3},
    };

    return {
      items,
      renderColor,
      breakpoints
    }
  }
};
</script>

<style>
:root {
  --vc-clr-primary: #A58ABA;
  --vc-clr-secondary: #e0d6e7;
}
.carousel__pagination {
  margin-top: 5%;
}
.element {
  line-height: normal;
  margin-right: 10px;
  border-radius: 50%;
  background-color: black;
  color: white;
}
</style>
