import _head from 'lodash/head';

export async function flattenForkTree(item, array) {
  if (item !== null) {
    if (item.children.length) {
      array.push(item);
      flattenForkTree(_head(item.children), array);
    } else { array.push(item);
    }
  }

  return array;
}
