<template>
  <div class="card shadow overflow-auto w-100 h-100 d-flex justify-content-center rounded-custom flex-column">
    <template v-if="Object.keys($props.data).length > 0">
      <div class="d-flex align-items-center">
        <img v-if="$props.data.completed === false" class="me-2" src="@/assets/icons/check-circle-fill-green.svg" width="25" alt="check-circle-fill-green">
        <img v-if="$props.data.completed === true" class="me-2" src="@/assets/icons/gear-violet.svg" width="25" alt="gear-violet">
        <strong>{{ $props.data.number_text }} - {{ $props.data.created_at }}</strong>
      </div>
      <div class="d-flex align-items-center mb-2">
        {{ $props.data.prescription_row_name }} (<Render :data="$props.data.elements"/>)
      </div>
      <ul class="m-0 p-3">
        <li><span class="label">Materiale:</span> {{ $props.data.material }}</li>
        <li><span class="label">Impinato:</span> {{ $props.data.plant_type }}</li>
        <li><span class="label">Bruxista:</span> {{ $props.data.bruxism }}</li>
        <li><span class="label">Tinta:</span> {{ $props.data.color_code }}</li>
        <li><span class="label">Elementi:</span>
          {{ getNumberElementsByPrescriptionRows(toRaw($props.data.prescription_rows)) }}
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <div class="spinner-border text-violet" role="status"></div>
      </div>
    </template>
  </div>
</template>

<script>
import {toRaw} from 'vue';
import Render from '@/components/PrescriptionRows/Render';
import {getNumberElementsByPrescriptionRows} from "@/use/repositories/prescriptionRows/getNumberElementsByPrescriptionRows";

export default {
  name: "PrescriptionDetailsMobileBox2",
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  components: {
    Render,
  },
  setup() {
    return {
      getNumberElementsByPrescriptionRows,
      toRaw,
    }
  }
}
</script>

<style scoped>

</style>
