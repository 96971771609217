<template>

  <div class="d-flex card-header bg-violet text-white text-center rounded-only-top p-3 text-uppercase justify-content-center">
    <h2>{{ $props.title }}</h2>
  </div>

  <div class="card-body p-0 overflow-auto">

    <template v-if="$props.test_work_queue_phases.length > 0 || testWorkQueuePhasesSelected.length > 0">
      <table class="table table-bordered">

        <thead>
        <tr class="text-center">
          <th scope="col">Prescrizione / Et. Elettronica</th>
          <th scope="col">Paziente</th>
          <th scope="col">Data</th>
        </tr>

        <template v-if="testWorkQueuePhasesSelected.length > 0">
          <template v-for="(twqp, index) in testWorkQueuePhasesSelected" :key="index">
            <TestWorkQueuePhase
              :test_work_queue_phase="twqp"
              :selected="true"
              :missingFlow="$props.missingFlow"
              @selected="pushInTestWorkQueuePhases"
            />
          </template>
        </template>

        </thead>
        <tbody>

        <template v-for="(twqp, index) in $props.test_work_queue_phases" :key="index">
          <TestWorkQueuePhase
            :test_work_queue_phase="twqp"
            :selected="false"
            :missingFlow="$props.missingFlow"
            @selected="pushInTestWorkQueuePhasesSelected"
          />
        </template>

        </tbody>
      </table>
    </template>

    <template v-if="$props.test_work_queue_phases.length === 0 && testWorkQueuePhasesSelected.length === 0">
      <div class="d-flex justify-content-center align-items-center w-100 h-100 fs-4">
        Nessuna lavorazione
      </div>
    </template>

<!--    <div class="d-flex w-100 h-100 justify-content-center align-items-center">-->
<!--      <div class="spinner-border text-violet" role="status"></div>-->
<!--    </div>-->

  </div>

  <input class="position-absolute" type="text" id="scanner" style="opacity: 0;">

</template>

<script>
import {onMounted, ref, watch } from 'vue';

import TestWorkQueuePhase from "@/components/plaster/table/TestWorkQueuePhase.vue";

import { groupedByBridgesAndTooth } from "@/use/repositories/prescriptionRows/groupedByBridgesAndTooth";
import { sortByPriorityDate } from "@/use/repositories/testWorkQueuePhase/sortByPriorityDate";

export default {
  name: "Table",
  components: {
    TestWorkQueuePhase,
  },
  props: {
    'title': {
      type: String,
      required: true,
    },
    'test_work_queue_phases': {
      type: Array,
      required: true,
    },
    'resetSelected': {
      type: Boolean,
      required: true,
    },
    'missingFlow': {
      type: Boolean,
      default: false,
    }
  },
  emits: [
      'add_twqp',
      'remove_twqp',
      'twqp_selected',
      'emptySelected',
  ],
  setup(props, {emit}) {
    const testWorkQueuePhasesSelected = ref([]);

    onMounted(() => {
      scanBarcode();
    });

    watch(() => props.resetSelected, (value) => {
      if (value) {
        testWorkQueuePhasesSelected.value = [];
        emit('emptySelected');
      }
    });

    // Order item when new item is added.
    watch(() => props.test_work_queue_phases.length, () => {
      sortByPriorityDate(props.test_work_queue_phases);
    });

    const pushInTestWorkQueuePhasesSelected = (value) => {
      if (typeof value === 'undefined') return;
      let index = props.test_work_queue_phases.findIndex((twqp) => twqp.id === value.id);
      emit('remove_twqp', index);
      emit('twqp_selected', testWorkQueuePhasesSelected.value);

      testWorkQueuePhasesSelected.value.push(value);
    }

    const pushInTestWorkQueuePhases = (value) => {
      emit('add_twqp', value);

      let index = testWorkQueuePhasesSelected.value.findIndex((twqp) => twqp.id === value.id);
      testWorkQueuePhasesSelected.value.splice(index, 1);
      emit('twqp_selected', testWorkQueuePhasesSelected.value);
    }

    const scanBarcode = () => {
      // Define variables.
      let isEvent = false;
      let input = document.getElementById('scanner');

      input.addEventListener("focus", function () {
        if (! isEvent) {
          isEvent = true;
          input.addEventListener("keypress", function (event) {
            setTimeout(function () {
              if (event.key === "Enter") {
                findPrescriptioncode(input.value);
                input.value = "";
                input.select();
              }
            }, 500);
          });
        }
      });

      document.addEventListener("keypress", function (e) {
        if (e.target.tagName !== "INPUT") {
          input.focus();
        }
      });
    }

    const findPrescriptioncode = (numberText) => {
      let phaseIsSelected = testWorkQueuePhasesSelected.value.find((el) => el.test_work_queue.prescription_test.prescription.number_text.toLowerCase() === numberText.toLowerCase());

      if (phaseIsSelected !== undefined) {
        pushInTestWorkQueuePhases(phaseIsSelected);

      } else {
        let phaseToBeSelected = props.test_work_queue_phases.find((el) => el.test_work_queue.prescription_test.prescription.number_text.toLowerCase() === numberText.toLowerCase());
        pushInTestWorkQueuePhasesSelected(phaseToBeSelected);
      }
    }

    return {
      groupedByBridgesAndTooth,
      testWorkQueuePhasesSelected,
      pushInTestWorkQueuePhasesSelected,
      pushInTestWorkQueuePhases,
    }
  }
}
</script>

<style scoped>
.table {
  font-size: 1.2em;
  white-space:nowrap;
  border: 1px solid #A58ABA;
}
thead {
  position: sticky;
  top: 0;
  background-color: white;
}
</style>
