<script>
export default {
  name: 'Teeth',
  props: {
    number: {
      required: true
    },
    selected: {
      required: true
    }
  }
}
</script>
<template>
  <svg v-if="$props.selected" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="250px" height="250px" viewBox="0 0 250 250"
    enable-background="new 0 0 250 250" xml:space="preserve">
    <g>
      <path fill="#A58ABA"
        d="M154.757,233.017C154.756,233.017,154.756,233.017,154.757,233.017c-3.893,0-16.591-1.835-16.591-25.437
		l0.001-4.571c0.001-3.044,0.002-6.089-0.004-9.133c-0.008-3.546-0.029-7.092-0.059-10.638c-0.013-1.536-0.043-3.073-0.097-4.609
		c-0.044-1.263-0.112-2.526-0.179-3.789l-0.059-1.104c-0.065-1.24-0.131-2.48-0.215-3.72c-0.111-1.645-0.223-3.29-0.371-4.931
		c-0.125-1.386-0.314-2.88-0.577-4.568l-0.015-0.113c-0.061-0.545-1.618-13.383-11.59-13.383c-10.054,0-11.535,12.824-11.593,13.37
		l-0.016,0.126c-0.263,1.688-0.452,3.182-0.577,4.568c-0.147,1.641-0.259,3.286-0.371,4.93c-0.084,1.24-0.149,2.48-0.215,3.721
		l-0.059,1.104c-0.067,1.263-0.135,2.525-0.179,3.788c-0.054,1.537-0.084,3.074-0.097,4.611c-0.029,3.544-0.051,7.09-0.059,10.636
		c-0.006,3.044-0.005,6.089-0.004,9.133l0.001,4.571c0,23.602-12.698,25.437-16.59,25.437c-1.278,0-2.125-0.169-2.282-0.203
		c-2.844-0.516-5.558-1.669-7.986-3.413c-2.062-1.481-3.798-3.352-5.308-5.719c-0.662-1.036-1.196-2.085-1.688-3.072
		c-0.988-1.985-1.919-4.041-2.767-6.11c-0.916-2.239-1.604-4.701-2.165-7.749c-0.323-1.75-0.547-3.511-0.763-5.213l-0.194-1.509
		c-0.077-0.58-0.15-1.16-0.223-1.74l-0.364-2.786c-0.114-0.842-0.228-1.684-0.329-2.527c-0.214-1.77-0.369-3.606-0.473-5.611
		c-0.097-1.867-0.16-3.735-0.219-5.604c-0.122-3.91-0.178-7.07-0.175-9.945l0.002-9.217c0.001-1.22-0.052-2.453-0.158-3.664
		c-0.109-1.237-0.274-2.534-0.49-3.853c-0.399-2.436-0.942-4.985-1.66-7.794c-0.74-2.9-1.595-5.786-2.407-8.493
		c-1.803-6.01-3.635-11.52-5.601-16.843c-1.443-3.91-2.545-7.103-3.466-10.049c-2.369-7.571-4.401-15.308-6.041-22.998
		c-0.385-1.804-0.707-3.629-1.018-5.395l-0.066-0.377c-0.25-1.418-0.457-2.826-0.625-4.016c-0.16-1.124-0.292-2.252-0.419-3.38
		c-0.116-1.034-0.218-2.07-0.306-3.106c-0.095-1.098-0.181-2.198-0.246-3.298c-0.038-0.651-0.062-1.303-0.085-1.954l-0.042-1.091
		c-0.005-0.111-0.012-0.222-0.022-0.332l-0.154-0.451v-4.359l0.193-0.565l0.058-0.84c0.047-0.712,0.095-1.424,0.17-2.133
		c0.184-1.743,0.469-3.508,0.848-5.245c0.917-4.21,2.382-8.203,4.356-11.869c2.903-5.393,6.795-10.144,11.566-14.121
		c3.472-2.894,7.333-5.163,11.474-6.745c2.666-1.019,5.504-1.756,8.436-2.191c1.514-0.225,3.003-0.332,4.399-0.421
		c1.807-0.12,4.686,0.122,4.784,0.13l0.127,0.014c1.462,0.202,2.947,0.515,4.413,0.932c2.315,0.658,4.694,1.549,7.484,2.805
		c2.599,1.169,5.134,2.506,7.271,3.661c2.301,1.242,4.256,2.195,6.151,2.999l0.122,0.056c0.015,0.007,2.465,1.162,5.647,1.245
		c3.182-0.083,5.632-1.237,5.657-1.249l0.112-0.051c1.896-0.804,3.851-1.757,6.152-2.999c2.136-1.154,4.671-2.491,7.271-3.66
		c2.79-1.255,5.168-2.146,7.484-2.805c1.466-0.417,2.951-0.73,4.413-0.932l0.127-0.014c0.098-0.008,2.975-0.25,4.784-0.13
		c1.396,0.09,2.886,0.196,4.399,0.421c2.931,0.436,5.77,1.173,8.436,2.191c4.142,1.582,8.002,3.851,11.474,6.745
		c4.771,3.978,8.663,8.729,11.566,14.121c1.974,3.666,3.439,7.659,4.356,11.869c0.378,1.736,0.664,3.501,0.848,5.246
		c0.075,0.708,0.123,1.42,0.17,2.132l0.066,0.98l0.185,0.425v4.359l-0.154,0.451c-0.011,0.112-0.017,0.221-0.022,0.331l-0.042,1.089
		c-0.024,0.652-0.048,1.304-0.086,1.955c-0.065,1.102-0.151,2.201-0.246,3.301c-0.088,1.034-0.19,2.07-0.306,3.104
		c-0.127,1.129-0.26,2.257-0.419,3.381c-0.169,1.19-0.375,2.598-0.625,4.015l-0.066,0.377c-0.311,1.766-0.633,3.591-1.018,5.395
		c-1.64,7.689-3.672,15.426-6.041,22.997c-0.921,2.946-2.023,6.139-3.466,10.048c-1.966,5.324-3.798,10.834-5.601,16.844
		c-0.812,2.708-1.667,5.593-2.407,8.492c-0.719,2.811-1.262,5.359-1.66,7.794c-0.217,1.32-0.382,2.617-0.491,3.855
		c-0.106,1.209-0.159,2.442-0.158,3.664l0.002,9.217c0.002,2.874-0.053,6.034-0.175,9.943c-0.06,1.869-0.123,3.737-0.219,5.604
		c-0.104,2.006-0.259,3.842-0.474,5.614c-0.101,0.841-0.215,1.683-0.329,2.524l-0.364,2.786c-0.073,0.58-0.146,1.16-0.223,1.74
		l-0.194,1.509c-0.216,1.703-0.44,3.463-0.763,5.214c-0.561,3.047-1.249,5.509-2.164,7.747c-0.848,2.07-1.779,4.125-2.767,6.111
		c-0.491,0.987-1.026,2.037-1.688,3.073c-1.51,2.366-3.246,4.236-5.308,5.717c-2.428,1.744-5.142,2.897-8.066,3.429
		C156.882,232.848,156.035,233.017,154.757,233.017z M141.556,159.801c0.276,1.773,0.475,3.356,0.608,4.836
		c0.151,1.678,0.266,3.36,0.379,5.042c0.086,1.263,0.153,2.528,0.22,3.794l0.059,1.101c0.069,1.292,0.138,2.586,0.183,3.879
		c0.055,1.58,0.086,3.161,0.1,4.742c0.029,3.557,0.051,7.113,0.059,10.669c0.006,3.049,0.005,6.098,0.004,9.146l-0.001,4.568
		c0,18.445,8.105,20.437,11.59,20.438c0,0,0,0,0,0c0.76,0,1.242-0.094,1.247-0.095c2.249-0.41,4.282-1.274,6.103-2.582
		c1.536-1.103,2.848-2.525,4.01-4.347c0.53-0.83,0.995-1.745,1.426-2.61c0.935-1.877,1.814-3.821,2.616-5.777
		c0.781-1.91,1.377-4.058,1.875-6.759c0.298-1.615,0.503-3.229,0.72-4.938l0.197-1.535c0.076-0.57,0.147-1.14,0.219-1.71l0.37-2.831
		c0.111-0.817,0.221-1.634,0.319-2.452c0.201-1.66,0.346-3.385,0.445-5.275c0.095-1.832,0.157-3.667,0.215-5.502
		c0.12-3.853,0.175-6.962,0.172-9.782l-0.002-9.216c-0.001-1.369,0.059-2.75,0.178-4.106c0.12-1.362,0.3-2.784,0.537-4.226
		c0.422-2.581,0.995-5.271,1.75-8.223c0.763-2.991,1.634-5.934,2.462-8.691c1.833-6.11,3.698-11.717,5.7-17.139
		c1.413-3.829,2.489-6.946,3.385-9.81c2.322-7.423,4.314-15.009,5.922-22.547c0.366-1.717,0.68-3.497,0.983-5.219l0.067-0.378
		c0.237-1.345,0.436-2.701,0.599-3.85c0.153-1.076,0.28-2.155,0.401-3.235c0.111-0.989,0.208-1.981,0.293-2.974
		c0.091-1.055,0.174-2.11,0.236-3.166c0.036-0.613,0.058-1.228,0.08-1.843l0.044-1.126c0.014-0.322,0.04-0.646,0.083-0.965
		c0.021-0.159,0.053-0.303,0.088-0.428v-2.509c-0.038-0.118-0.073-0.252-0.099-0.398c-0.04-0.214-0.059-0.448-0.072-0.682
		l-0.069-1.015c-0.042-0.646-0.085-1.293-0.153-1.937c-0.166-1.565-0.422-3.148-0.762-4.708c-0.817-3.754-2.12-7.309-3.873-10.563
		c-2.6-4.828-6.087-9.084-10.365-12.65c-3.048-2.54-6.432-4.53-10.057-5.915c-2.328-0.889-4.813-1.534-7.387-1.917
		c-1.324-0.197-2.694-0.294-3.985-0.377c-1.385-0.09-3.577,0.085-3.972,0.118c-1.211,0.17-2.443,0.432-3.664,0.779
		c-2.076,0.59-4.237,1.402-6.799,2.555c-2.454,1.104-4.888,2.389-6.946,3.5c-2.425,1.309-4.501,2.321-6.529,3.183
		c-0.535,0.249-3.669,1.627-7.712,1.716L125,33.859l-0.055-0.001c-4.043-0.089-7.178-1.467-7.712-1.716
		c-2.028-0.862-4.104-1.875-6.529-3.183c-2.059-1.112-4.493-2.397-6.946-3.501c-2.563-1.153-4.724-1.965-6.799-2.555
		c-1.221-0.347-2.453-0.609-3.664-0.779c-0.395-0.032-2.587-0.208-3.972-0.118c-1.291,0.083-2.662,0.181-3.985,0.377
		c-2.573,0.383-5.059,1.028-7.387,1.917c-3.625,1.384-7.009,3.375-10.057,5.915c-4.278,3.566-7.766,7.823-10.365,12.65
		c-1.752,3.254-3.055,6.809-3.873,10.563c-0.34,1.56-0.596,3.144-0.761,4.708c-0.068,0.645-0.111,1.291-0.153,1.938l-0.069,1.015
		c-0.013,0.234-0.032,0.467-0.074,0.696c-0.025,0.141-0.059,0.27-0.097,0.385v2.509c0.035,0.126,0.068,0.272,0.09,0.437
		c0.042,0.31,0.067,0.633,0.082,0.956l0.044,1.127c0.022,0.615,0.044,1.229,0.08,1.843c0.062,1.055,0.146,2.109,0.236,3.164
		c0.085,0.994,0.183,1.986,0.293,2.976c0.121,1.079,0.248,2.159,0.401,3.234c0.163,1.149,0.362,2.505,0.599,3.85l0.067,0.378
		c0.303,1.722,0.617,3.502,0.983,5.219c1.608,7.539,3.601,15.125,5.922,22.548c0.896,2.864,1.972,5.981,3.385,9.81
		c2.002,5.422,3.867,11.028,5.7,17.139c0.828,2.758,1.699,5.701,2.462,8.692c0.755,2.952,1.327,5.642,1.75,8.223
		c0.236,1.441,0.417,2.863,0.537,4.224c0.119,1.357,0.179,2.738,0.178,4.106l-0.002,9.216c-0.002,2.821,0.052,5.93,0.173,9.784
		c0.058,1.834,0.12,3.669,0.214,5.501c0.099,1.89,0.244,3.615,0.444,5.272c0.099,0.821,0.209,1.638,0.32,2.455l0.37,2.831
		c0.072,0.57,0.144,1.14,0.219,1.71l0.197,1.535c0.217,1.709,0.422,3.323,0.72,4.937c0.498,2.702,1.093,4.85,1.875,6.761
		c0.801,1.956,1.681,3.9,2.616,5.777c0.431,0.865,0.896,1.779,1.425,2.609c1.163,1.822,2.475,3.245,4.011,4.348
		c1.821,1.307,3.854,2.172,6.043,2.57c0.064,0.012,0.547,0.106,1.308,0.106c3.484,0,11.59-1.992,11.59-20.437l-0.001-4.569
		c-0.001-3.048-0.002-6.097,0.004-9.146c0.008-3.557,0.029-7.112,0.059-10.668c0.014-1.583,0.045-3.164,0.1-4.744
		c0.045-1.293,0.114-2.586,0.183-3.879l0.059-1.101c0.067-1.266,0.134-2.531,0.22-3.795c0.113-1.68,0.228-3.362,0.379-5.041
		c0.133-1.479,0.332-3.059,0.607-4.829c0.663-6.192,4.958-17.787,16.555-17.787C136.518,142.021,140.859,153.617,141.556,159.801z" />
    </g>
    <text transform="matrix(1 0 0 1 88.0596 111.6416)" fill="#A58ABA" font-family="'Roboto-Black'" font-size="63.7888">{{
      $props.number }}</text>
  </svg>
  <svg v-else version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" width="250px" height="250px" viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve">
    <g>
      <path fill="#CCCCCC"
        d="M154.757,233.017C154.756,233.017,154.756,233.017,154.757,233.017
		c-3.893-0.001-16.591-1.835-16.591-25.437l0.001-4.571c0.001-3.044,0.002-6.089-0.004-9.134c-0.008-3.545-0.029-7.092-0.059-10.637
		c-0.013-1.536-0.043-3.074-0.097-4.609c-0.044-1.264-0.112-2.526-0.179-3.789l-0.059-1.104c-0.065-1.24-0.131-2.48-0.215-3.72
		c-0.111-1.646-0.223-3.29-0.371-4.931c-0.125-1.387-0.314-2.881-0.577-4.568l-0.015-0.113c-0.061-0.545-1.618-13.383-11.59-13.383
		c-10.054,0-11.535,12.824-11.593,13.369l-0.016,0.127c-0.263,1.688-0.452,3.182-0.577,4.568c-0.147,1.641-0.259,3.285-0.371,4.93
		c-0.084,1.24-0.149,2.48-0.215,3.721l-0.059,1.104c-0.067,1.263-0.135,2.525-0.179,3.788c-0.054,1.536-0.084,3.074-0.097,4.611
		c-0.029,3.544-0.051,7.091-0.059,10.636c-0.006,3.045-0.005,6.09-0.004,9.134l0.001,4.571c0,23.602-12.698,25.437-16.59,25.437
		c-1.278,0-2.125-0.169-2.282-0.202c-2.844-0.516-5.558-1.67-7.986-3.413c-2.062-1.481-3.798-3.353-5.308-5.719
		c-0.662-1.036-1.196-2.085-1.688-3.071c-0.988-1.986-1.919-4.041-2.767-6.11c-0.916-2.239-1.604-4.701-2.165-7.749
		c-0.323-1.75-0.547-3.511-0.763-5.213l-0.194-1.51c-0.077-0.58-0.15-1.16-0.223-1.74l-0.364-2.785
		c-0.114-0.842-0.228-1.684-0.329-2.527c-0.214-1.77-0.369-3.605-0.473-5.611c-0.097-1.867-0.16-3.735-0.219-5.604
		c-0.122-3.91-0.178-7.07-0.175-9.945l0.002-9.217c0.001-1.221-0.052-2.453-0.158-3.664c-0.109-1.236-0.274-2.533-0.49-3.853
		c-0.399-2.437-0.942-4.985-1.66-7.794c-0.74-2.9-1.595-5.786-2.407-8.494c-1.803-6.01-3.635-11.52-5.601-16.843
		c-1.443-3.909-2.545-7.103-3.466-10.049c-2.369-7.571-4.401-15.308-6.041-22.997c-0.385-1.805-0.707-3.629-1.018-5.395
		l-0.066-0.377c-0.25-1.418-0.457-2.826-0.625-4.016c-0.16-1.124-0.292-2.252-0.419-3.381c-0.116-1.033-0.218-2.07-0.306-3.105
		c-0.095-1.098-0.181-2.197-0.246-3.299c-0.038-0.65-0.062-1.303-0.085-1.953l-0.042-1.092c-0.005-0.11-0.012-0.221-0.022-0.331
		l-0.154-0.45v-4.359l0.193-0.565l0.058-0.84c0.047-0.712,0.095-1.425,0.17-2.134c0.184-1.742,0.469-3.508,0.848-5.244
		c0.917-4.211,2.382-8.203,4.356-11.869c2.903-5.393,6.795-10.144,11.566-14.121c3.472-2.895,7.333-5.163,11.474-6.744
		c2.666-1.02,5.504-1.756,8.436-2.191c1.514-0.226,3.003-0.332,4.399-0.422c1.807-0.119,4.686,0.122,4.784,0.131l0.127,0.014
		c1.462,0.201,2.947,0.516,4.413,0.932c2.315,0.658,4.694,1.55,7.484,2.805c2.599,1.17,5.134,2.507,7.271,3.661
		c2.301,1.241,4.256,2.194,6.151,2.999l0.122,0.055c0.015,0.008,2.465,1.162,5.647,1.245c3.182-0.083,5.632-1.237,5.657-1.249
		l0.112-0.051c1.896-0.805,3.851-1.758,6.152-3c2.136-1.153,4.671-2.49,7.271-3.66c2.79-1.255,5.168-2.146,7.484-2.805
		c1.466-0.416,2.951-0.73,4.413-0.932l0.127-0.014c0.098-0.009,2.975-0.25,4.784-0.131c1.396,0.09,2.886,0.196,4.399,0.422
		c2.931,0.436,5.77,1.172,8.436,2.191c4.142,1.581,8.002,3.85,11.474,6.744c4.771,3.978,8.663,8.729,11.566,14.121
		c1.974,3.666,3.439,7.658,4.356,11.869c0.378,1.736,0.664,3.502,0.848,5.246c0.075,0.707,0.123,1.42,0.17,2.132l0.066,0.979
		l0.185,0.426v4.359l-0.154,0.45c-0.011,0.112-0.017,0.222-0.022,0.331l-0.042,1.09c-0.024,0.651-0.048,1.304-0.086,1.955
		c-0.065,1.102-0.151,2.201-0.246,3.301c-0.088,1.033-0.19,2.07-0.306,3.104c-0.127,1.129-0.26,2.257-0.419,3.381
		c-0.169,1.189-0.375,2.598-0.625,4.015l-0.066,0.378c-0.311,1.766-0.633,3.59-1.018,5.395c-1.64,7.689-3.672,15.426-6.041,22.997
		c-0.921,2.946-2.023,6.14-3.466,10.048c-1.966,5.324-3.798,10.834-5.601,16.844c-0.812,2.708-1.667,5.594-2.407,8.492
		c-0.719,2.811-1.262,5.359-1.66,7.795c-0.217,1.32-0.382,2.617-0.491,3.855c-0.106,1.209-0.159,2.441-0.158,3.663l0.002,9.218
		c0.002,2.873-0.053,6.033-0.175,9.942c-0.06,1.869-0.123,3.737-0.219,5.604c-0.104,2.006-0.259,3.842-0.474,5.613
		c-0.101,0.842-0.215,1.684-0.329,2.525l-0.364,2.785c-0.073,0.58-0.146,1.16-0.223,1.74l-0.194,1.51
		c-0.216,1.702-0.44,3.463-0.763,5.214c-0.561,3.047-1.249,5.509-2.164,7.747c-0.848,2.07-1.779,4.125-2.767,6.111
		c-0.491,0.986-1.026,2.036-1.688,3.072c-1.51,2.365-3.246,4.236-5.308,5.717c-2.428,1.744-5.142,2.898-8.066,3.43
		C156.882,232.848,156.035,233.017,154.757,233.017z M141.556,159.801c0.276,1.773,0.475,3.356,0.608,4.836
		c0.151,1.679,0.266,3.361,0.379,5.042c0.086,1.263,0.153,2.528,0.22,3.794l0.059,1.102c0.069,1.292,0.138,2.586,0.183,3.879
		c0.055,1.58,0.086,3.161,0.1,4.742c0.029,3.557,0.051,7.113,0.059,10.67c0.006,3.049,0.005,6.098,0.004,9.146l-0.001,4.568
		c0,18.445,8.105,20.437,11.59,20.438c0,0,0,0,0,0c0.76,0,1.242-0.094,1.247-0.095c2.249-0.41,4.282-1.274,6.103-2.582
		c1.536-1.104,2.848-2.525,4.01-4.347c0.53-0.83,0.995-1.745,1.426-2.61c0.935-1.877,1.814-3.82,2.616-5.777
		c0.781-1.91,1.377-4.059,1.875-6.759c0.298-1.614,0.503-3.229,0.72-4.938l0.197-1.534c0.076-0.57,0.147-1.14,0.219-1.71l0.37-2.831
		c0.111-0.817,0.221-1.634,0.319-2.452c0.201-1.66,0.346-3.385,0.445-5.275c0.095-1.832,0.157-3.667,0.215-5.502
		c0.12-3.854,0.175-6.962,0.172-9.781l-0.002-9.217c-0.001-1.369,0.059-2.75,0.178-4.105c0.12-1.363,0.3-2.784,0.537-4.227
		c0.422-2.58,0.995-5.271,1.75-8.223c0.763-2.991,1.634-5.934,2.462-8.691c1.833-6.111,3.698-11.717,5.7-17.139
		c1.413-3.829,2.489-6.946,3.385-9.811c2.322-7.423,4.314-15.01,5.922-22.547c0.366-1.717,0.68-3.498,0.983-5.22l0.067-0.378
		c0.237-1.346,0.436-2.701,0.599-3.85c0.153-1.076,0.28-2.156,0.401-3.236c0.111-0.988,0.208-1.98,0.293-2.974
		c0.091-1.056,0.174-2.11,0.236-3.165c0.036-0.613,0.058-1.229,0.08-1.844l0.044-1.127c0.014-0.322,0.04-0.645,0.083-0.965
		c0.021-0.159,0.053-0.303,0.088-0.428v-2.51c-0.038-0.117-0.073-0.252-0.099-0.398c-0.04-0.214-0.059-0.447-0.072-0.682
		l-0.069-1.014c-0.042-0.646-0.085-1.293-0.153-1.938c-0.166-1.564-0.422-3.148-0.762-4.707c-0.817-3.755-2.12-7.309-3.873-10.563
		c-2.6-4.827-6.087-9.084-10.365-12.65c-3.048-2.54-6.432-4.53-10.057-5.915c-2.328-0.889-4.813-1.533-7.387-1.916
		c-1.324-0.197-2.694-0.295-3.985-0.378c-1.385-0.09-3.577,0.085-3.972,0.118c-1.211,0.17-2.443,0.432-3.664,0.779
		c-2.076,0.59-4.237,1.401-6.799,2.555c-2.454,1.104-4.888,2.389-6.946,3.5c-2.425,1.309-4.501,2.32-6.529,3.184
		c-0.535,0.248-3.669,1.627-7.712,1.716L125,33.859l-0.055-0.001c-4.043-0.089-7.178-1.468-7.712-1.716
		c-2.028-0.863-4.104-1.875-6.529-3.184c-2.059-1.111-4.493-2.396-6.946-3.5c-2.563-1.153-4.724-1.965-6.799-2.555
		c-1.221-0.348-2.453-0.609-3.664-0.779c-0.395-0.033-2.587-0.208-3.972-0.118c-1.291,0.083-2.662,0.181-3.985,0.378
		c-2.573,0.383-5.059,1.027-7.387,1.916c-3.625,1.385-7.009,3.375-10.057,5.915c-4.278,3.566-7.766,7.823-10.365,12.65
		c-1.752,3.255-3.055,6.809-3.873,10.563c-0.34,1.56-0.596,3.144-0.761,4.708c-0.068,0.645-0.111,1.291-0.153,1.938l-0.069,1.014
		c-0.013,0.234-0.032,0.468-0.074,0.697c-0.025,0.141-0.059,0.27-0.097,0.385v2.51c0.035,0.125,0.068,0.271,0.09,0.436
		c0.042,0.311,0.067,0.633,0.082,0.956l0.044,1.128c0.022,0.614,0.044,1.229,0.08,1.842c0.062,1.055,0.146,2.109,0.236,3.164
		c0.085,0.994,0.183,1.986,0.293,2.977c0.121,1.078,0.248,2.158,0.401,3.234c0.163,1.148,0.362,2.504,0.599,3.85l0.067,0.378
		c0.303,1.722,0.617,3.503,0.983,5.219c1.608,7.538,3.601,15.125,5.922,22.548c0.896,2.864,1.972,5.981,3.385,9.811
		c2.002,5.422,3.867,11.027,5.7,17.139c0.828,2.758,1.699,5.7,2.462,8.691c0.755,2.952,1.327,5.643,1.75,8.224
		c0.236,1.441,0.417,2.862,0.537,4.224c0.119,1.357,0.179,2.738,0.178,4.106l-0.002,9.216c-0.002,2.821,0.052,5.93,0.173,9.784
		c0.058,1.834,0.12,3.669,0.214,5.501c0.099,1.891,0.244,3.615,0.444,5.273c0.099,0.82,0.209,1.637,0.32,2.454l0.37,2.831
		c0.072,0.57,0.144,1.14,0.219,1.71l0.197,1.534c0.217,1.709,0.422,3.324,0.72,4.938c0.498,2.701,1.093,4.85,1.875,6.761
		c0.801,1.956,1.681,3.899,2.616,5.776c0.431,0.865,0.896,1.779,1.425,2.609c1.163,1.822,2.475,3.244,4.011,4.348
		c1.821,1.308,3.854,2.172,6.043,2.57c0.064,0.013,0.547,0.106,1.308,0.106c3.484,0,11.59-1.991,11.59-20.437l-0.001-4.569
		c-0.001-3.048-0.002-6.097,0.004-9.146c0.008-3.557,0.029-7.113,0.059-10.668c0.014-1.583,0.045-3.164,0.1-4.744
		c0.045-1.293,0.114-2.587,0.183-3.879l0.059-1.102c0.067-1.266,0.134-2.531,0.22-3.795c0.113-1.68,0.228-3.362,0.379-5.041
		c0.133-1.479,0.332-3.059,0.607-4.828c0.663-6.192,4.958-17.787,16.555-17.787C136.518,142.021,140.859,153.617,141.556,159.801z" />
    </g>
    <text transform="matrix(1 0 0 1 88.0596 111.6416)" fill="#CCCCCC" font-family="'Roboto-Black'" font-size="63.7888">{{
      $props.number }}</text>
  </svg>
</template>
