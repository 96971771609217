<template>

  <div class="d-flex flex-column w-100" v-if="products.length">
    <template v-for="(product, index) in products" :key="index">
      <div class="d-flex align-items-center justify-content-between p-1 w-100">
        <div class="d-flex">
          <img class="remove" src="@/assets/icons/close-circle.svg" width="20" alt="close-circle" @click="remove(product.id)">
        </div>
        <div class="d-flex">
          {{ product.code }}
        </div>
        <div class="text-truncate w-50" data-toggle="tooltip" data-placement="top" :title="product.name">
          {{ product.name }}
        </div>

        <Range
          :value="product.quantity"
          :index="index"
          :min="1"
          :max="20"
          @value="readNewValue"
        />

      </div>
    </template>
  </div>

</template>

<script>
import {ref} from 'vue';
import Range from "@/components/Range";

export default {
  name: "RequestProducts",
  props: {
    data: {
      type: Object,
      required: false,
    }
  },
  components: {
    Range,
  },
  emits: [
    'remove',
  ],
  setup(props, {emit}) {
    const products = ref(props.data);

    const remove = (productId) => {
      emit('remove', productId);
    }

    const readNewValue = (obj) => {
      products.value[obj.index].quantity = obj.value;
    }

    return {
      remove,
      readNewValue,
      products,
    }
  }
}
</script>

<style scoped>
.remove:hover {
  cursor: pointer;
}
</style>
