import { default as axios } from "@/use/utilities/axios/up/axios-instance-primo-up";

export async function allPossibleTests(prescriptionTestId) {
  let tests = [];

  await axios
    .get(`/prescription-tests/${prescriptionTestId}`, {
      params: {
        action: 'all_possible_tests',
      }
    })
    .then(async (response) => {
        tests = response.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { tests };
}
