import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
export async function redirectTo(roles, departments, store) {
  let routeName;

  if (typeof store === "undefined") {
    routeName = "Login";
  } else if (store.state.twoFactorAuth.enabled) {
    routeName = "TwoFactorAuth";
  } else if (store.state.user.roles.length && ! store.state.mainRole) {
    routeName = "LoginChoose";
  } else if (isCurrentDepartment("amministrazione")) {
    routeName = "ConsultingSearch";
  } else if (
    typeof store.state.mainRole.team.name !== "undefined" &&
    isCurrentDepartment("logistica")
  ) {
    routeName = "Logistica";
  } else if (
    typeof store.state.mainRole.team.name !== "undefined" &&
    isCurrentDepartment("cam")
  ) {
    console.log("redirect to cam");
    routeName = "CheckinCam";
  }
  else if (roles.includes("checkin-colatura-gesso")) {
    console.log("redirect to checkin-colatura-gesso");
    routeName = "PlasterCheckinCasting";
  } else if (roles.includes("checkin-finitura-gesso")) {
    console.log("checkin-finitura-gesso");
    routeName = "PlasterCheckinFinish";
  } else if (
    roles.includes("postazione-colatura-gesso") ||
    roles.includes("postazione-finitura-gesso")
  ) {
    console.log("redirect to postazioni-gesso");
    routeName = "PlasterInTheWorks";
  } else if (roles.includes("vendor")) {
    console.log("redirect to Login");
    routeName = "Login";
  } else if (roles.includes("manager") && isCurrentDepartment("iso-progettazione")) {
    console.log("redirect to manager dashboard");
    routeName = "ManagerDashboard";
  } else {
    console.log("redirect to Checkin");
    routeName = "Checkin";
  }

  return { routeName };
}
