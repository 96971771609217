import getAuthToken from "@/use/get-auth-token";

export function pusherAuthorize(channel) {
  return {
    authorize: (socketId, callback) => {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/broadcasting/auth`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getAuthToken()}`
        }),
        body: JSON.stringify({
          socket_id: socketId,
          channel_name: channel.name,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Received ${res.statusCode} from ${res.url}`);
          }
          return res.json();
        })
        .then((data) => {
          callback(null, data);
        })
        .catch((err) => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: "",
          });
        });
    },
  };
}
