<template>
  <div class="card shadow rounded-custom w-100 h-100" style="opacity:unset">
    <div class="card-header rounded-only-top bg-dark-violet text-white text-uppercase text-center">pagamenti</div>

    <template v-if="show">
      <div class="tot card-body overflow-auto d-flex fw-bold align-items-center justify-content-center">
        Tot. {{ euroFormatter($props.total) }}
      </div>
      <button class="btn position-absolute bottom-0 left-0" @click="$emit('openModal')" style="pointer-events:pointer">
        <img src="@/assets/icons/external-link-violet.svg" width="25" alt="external-link">
      </button>
    </template>
    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <div class="spinner-border text-violet" role="status"></div>
      </div>
    </template>

  </div>
</template>

<script>
import { euroFormatter } from '@/use/utilities/euroFormatter';

export default {
  name: "Payments",
  props: {
    total: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'openModal',
  ],
  setup() {
    return {
      euroFormatter,
    }
  }
}
</script>

<style scoped>
.tot {
  font-size: 1.7em;
}
/* Responsive font */
@media screen and (max-width: 1560px) {
  .tot { font-size: 1.7em; }
}
@media screen and (max-width: 1390px) {
  .tot { font-size: 1.5em; }
}
@media screen and (max-width: 1370px) {
  .tot { font-size: 1.3em; }
}
@media screen and (max-width: 1200px) {
  .tot { font-size:2em; }
}
.btn-check:focus+.btn, .btn:focus {
  outline: unset;
  box-shadow: unset;
}
</style>
