import { axiosInstanceWithoutLoader } from "@/use/utilities/axios/app/axios-instance-without-loader.js";

export async function show(id, action) {
  const axios = axiosInstanceWithoutLoader().primoLab;
  let data = [];

  await axios
    .get(`/shipping-codes/${id}`, {
      params: {
        action
      },
    })
    .then(async (resp) => {
      data = resp.data;

    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
