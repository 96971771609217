import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import store from "@/store";

export async function changeState(stateSlug) {
  const axios = axiosInstance().primoLab;

  await axios
    .put("/users", {
      action: 'update_state',
      state_slug: stateSlug,
    })
    .then(async (response) => {
      let user = store.state.user;
      user.state.slug = response.data.data.state.slug;
      await store.dispatch("setUser", user);
      console.log(`USER: ${user.id} SET STATE: ${user.status}`);
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

}
