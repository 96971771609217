import axios from "axios";
import getAuthToken from "@/use/get-auth-token";

const primoUpApi = axios.create({
  baseURL: process.env.VUE_APP_API_PRIMO_UP_BASE_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

export default primoUpApi;
