<template>
  <div class="d-flex vw-100 vh-100 justify-content-center align-items-center">
    <div class="col-sm-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
      <brand :width="120" :height="120" />
      <div class="card rounded-custom shadow border-0 p-4 flex-column justify-content-center">
        <h3 class="mb-4 fw-normal text-center">{{ cardTitle }}</h3>

        <template v-if="['on-hold'].includes(stateSlug)">
          <div class="mb-3">
            <Toggle v-model="isInternalSuspension"/>
            <label class="ms-2">Sospensione interna</label>
          </div>
        </template>

        <!-- Reassign at internal phase -->
        <template v-if="stateSlug === 'iso-reassigned' || stateSlug === 'manager-reassigned'">
          <div v-if="debug" class="ms-1">nodes - {{ internalPhaseTestIdSelected }}</div>
          <!-- Choose internal phase -->
          <template v-if="nodes.length > 0">
            <Timeline
              class="text-center"
              :subtitle="'Seleziona la fase interna al quale assegnare la lavorazione'"
              :items="nodes"
              @selected="readSelected" />
          </template>
        </template>

        <!-- Change test -->
        <template v-if="stateSlug === 'iso-in-changed-test' || stateSlug === 'manager-changed-test'">

          <!-- Choose test -->
          <div>
            <label for="testsSelected" class="form-label">Seleziona la prova</label>
            <div v-if="debug" class="ms-1">tests - {{ testsIdSelected }}</div>
            <MultiselectCustom
              id="testsSelected"
              class="multiselect-violet mb-3"
              v-model="testsIdSelected"
              placeholder="Seleziona la prova"
              :options="tests"
              @clear="clearTests" />
          </div>

          <!-- Choose branch -->
          <div>
            <label for="branchesSelected" class="form-label">Seleziona il percorso</label>
            <div v-if="debug" class="ms-1">branches - {{ branchSlugSelected }}</div>
            <MultiselectCustom
              id="branchesSelected"
              class="multiselect-violet mb-3"
              v-model="branchSlugSelected"
              placeholder="Seleziona il percorso"
              :options="branches"
              @clear="clearBranches" />
          </div>

          <!-- Choose internal phase -->
          <template v-if="nodes.length > 0">
            <div v-if="debug" class="ms-1">nodes - {{internalPhaseTestIdSelected }}</div>
            <Timeline
              :subtitle="'Seleziona la fase interna al quale assegnare la lavorazione'"
              :items="nodes"
              @selected="readSelected" />
          </template>
        </template>

        <!-- Choose fork -->
        <template v-if="stateSlug === 'iso-in-choose-fork'">
          <div>
            <label for="forkSelected" class="form-label">Seleziona il percorso</label>
            <MultiselectCustom
              id="forkSelected"
              class="multiselect-violet mb-3"
              v-model="branchSlugSelected"
              placeholder="Seleziona il percorso"
              :options="branches"
              @clear="clear" />
            <template v-if="nodes.length > 0">
              <ForkPreview
                :items="nodes" />
            </template>
          </div>
        </template>

        <template v-if="stateSlug !== 'iso-in-choose-fork'">
          <!-- Actions always visible -->
          <Multiselect
            class="mb-3"
            :label="'Selezionare la motivazione'"
            :options="feedbacks"
            :showAlert="alert"
            @selected="readMotivationIdSelected" />

          <Note id="nota"
                class="mt-3"
                label="Nota:"
                :clipboard="nota"
                @message="readNota" />

          <template v-if="(isToFailed && conditionDisabledContinue && stateSlug != 'on-hold') || isExternalSuspention">
            <label class="form-label">Media:</label>
            <Dropzone
              :upload="upload"
              :assign-url-s3="assignUrlFeedback"
              :store-url="storeFeedback"
              :form-data="formData"
              :multi-upload="true"
              :max-files="5"
              @increment="mediaCounter++"
              @decrease="mediaCounter--"
              @upload-completed="redirectAfterUpload"
            />
            <template v-if="mediaCounter === 0">
              <div class="text-center">
                <a class="text-violet-dark text-decoration-underline" href="#" @click="showQrcodeModal = true;">Carica media dal cellulare</a>
              </div>
            </template>
          </template>

          <!-- Buttons -->
          <div class="modal-footer p-1 border-0 justify-content-center">
            <button class="btn btn-violet text-uppercase mt-4" @click="storeIsoInMotivation()" :disabled="!conditionDisabledContinue">conferma</button>
            <button class="btn btn-outline-violet text-uppercase mt-4" @click="comeBack()">annulla</button>
          </div>
        </template>

        <template v-else>
          <button class="btn btn-violet text-uppercase mt-4" @click="saveNewForkChoosed()" :disabled="!branchSlugSelected">conferma</button>
        </template>

      </div>
    </div>

    <!-- Modal -->
    <Modal v-if="showQrcodeModal"
           class="d-flex"
           :size="'md'"
           :modal_id="'qrcode'"
           :title="'Scansiona il QR'"
           :subtitle="'Scansionando il QR potrai caricare i files direttamente dal cellulare'"
           :decline_label="'chiudi'"
           :lightLayout="true"
           @hidden="closeQrcodeModal"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <!-- Qrcode -->
        <QRCode :text="externalDeviceRoute"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  ref,
  toRaw,
  watch,
  computed,
  onUnmounted,
  getCurrentInstance,
  inject,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _head from "lodash/head";
import Toggle from '@vueform/toggle';

import MultiselectCustom from "@vueform/multiselect";
import { useToast } from "vue-toastification";
import {useI18n} from "vue-i18n";

import {formatForMultiselectComponent} from "@/use/utilities/formatForMultiselectComponent";
import {hideModal as closeModal} from "@/use/modal/hide";
import {success} from "@/use/toast/success";

import Note from "@/components/general/Note";
import Brand from "@/components/Brand";
import Timeline from "@/components/Iso-in/Timeline";
import Multiselect from "@/components/Multiselect";
import ForkPreview from "@/components/Iso-in/ForkPreview";
import Dropzone from "@/components/upload/Dropzone";
import Modal from '@/components/Modal/Standard';
import QRCode from "@/components/QRCode.vue";

import {fetchByType} from '@/use/repositories/feedbacks/fetchByType';
import {treePreview} from "@/use/repositories/tests/treePreview";
import {changeTest} from "@/use/repositories/testWorkQueue/changeTest";
import {fetchTreeById} from "@/use/repositories/testWorkQueue/fetchTreeById";
import {reassign} from "@/use/repositories/testWorkQueue/reassign";
import {changeState} from '@/use/repositories/users/changeState';
import {update as updateTestWorkQueuePhase} from '@/use/repositories/testWorkQueuePhase/update';
import {fetchAllTypes as fetchAllBranches} from "@/use/repositories/internalPhaseTest/fetchAllTypes";
import { update as updateTestWorkQueue } from "@/use/repositories/testWorkQueue/update";
import {assignUrl as assignUrlFeedback} from "@/use/repositories/feedbacks/media/assignUrl";
import {store as storeFeedback} from "@/use/repositories/feedbacks/store";
import {useStore} from "vuex";
import { allPossibleTests } from "../../use/repositories/prescriptionTests/allPossibleTests";
import { flattenForkTree } from "@/utils/flattenForkTree.js";

export default {
  name: "Choose",
  props: [
    'test_work_queue_id',
    'test_work_queue_phase_id',
    'internal_phase_id',
    'prescription_test_id',
    'laboratory_list_row_id',
    'test_id',
    'state_slug',
    'title',
    'subtitle',
    'prescription',
  ],
  components: {
    ForkPreview,
    Multiselect,
    MultiselectCustom,
    Note,
    Brand,
    Timeline,
    Dropzone,
    Modal,
    QRCode,
    Toggle,
  },
  setup(props) {
    const { departments } = inject("constants");
    const { states } = inject("constants");
    const router = useRouter();
    const route = useRoute();
    const nota = ref("");
    const alert = ref(false);
    const feedbacks = ref([]);
    const motivations = reactive({ ids: [] });
    const items = ref([]);
    const placeholder = ref(null);
    const listRows = ref([]);
    const listRowIdSelected = ref(null);
    const testWorkQueuePhaseIdSelected = ref(null);
    const internalPhaseTestIdSelected = ref(null);
    const tests = ref([]);
    const testsIdSelected = ref(null);
    const branches = ref([]);
    const branchSlugSelected = ref(null);
    const nodes = ref([]);
    const toast = useToast();
    const store = useStore();
    const stateSlug = ref(props.state_slug);
    const isInternalSuspension = ref(false);
    const getCardTitle = () => {
      return  props.prescription ? `${props.title} prescrizione n. ${props.prescription}` : props.title;
    }
    const cardTitle = ref(getCardTitle());
    const isIsoProgettazione = ref(store.state.mainRole.team.name === departments.ISO_PROGETTAZIONE);
    const conditionDisabledContinue = computed(() => {
      if (stateSlug.value === 'iso-in-changed-test' || stateSlug.value === 'manager-changed-test') {
        return (testWorkQueuePhaseIdSelected.value !== null && motivations.ids.length > 0);
      }
      else if (stateSlug.value === 'iso-reassigned') {
        return (internalPhaseTestIdSelected.value !== null && motivations.ids.length > 0);
      }
      else if (isToFailed.value || isExternalSuspention.value) {
        return (motivations.ids.length > 0 && nota.value.trim() !== "");
      }
      else {
        return motivations.ids.length > 0;
      }
    });
    const debug = ref(false);
    const i18n = useI18n();
    const isToFailed = computed(() => {
      //TODO: occhio
      return ['iso-failed', 'manager-failed'].includes(props.state_slug);
    });

    const isExternalSuspention = computed(() => {
      return stateSlug.value === 'on-hold' && ! isInternalSuspension.value;
    })

    const isManagerActions = computed(() => {
      return (stateSlug.value === 'manager-changed-test' || stateSlug.value === 'manager-reassigned' || stateSlug.value === 'manager-failed');
    });
    const upload = ref(false);
    const mediaCounter = ref(0);
    const showQrcodeModal = ref(false);
    const existsMedia = computed(() => {
      return mediaCounter.value !== 0;
    })
    const formData = computed(() => {
      let formData = new FormData();
      if (existsMedia.value) {
        formData.append("action", "with_media");
      } else {
        formData.append("action", "without_media");
      }
      formData.append("state", stateSlug.value);
      formData.append('is_iso', !isInternalSuspension.value);
      formData.append("test_work_queue_phase_id", props.test_work_queue_phase_id);
      formData.append("feedback_id", _head(motivations.ids));
      if (nota.value.length !== 0) {
        formData.append("note", nota.value);
      }

      return formData;
    });
    const rand = () => {
      const d = new Date();
      return d.getTime();
    }
    const uniq = rand();
    const externalDeviceRoute = `${window.location.origin}/external/device/feedback/${uniq}`;
    // Pusher.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, async (event) => {
        console.log("event received", event);
        // Update form data.
        formData.value.set("action", "with_media");
        event.data.forEach((mediaName) => {
          formData.value.append("file_names[]", mediaName);
        });
        // Save.
        isoManagerFailedSaveAction();
      });
    };

    const fetchReasons = () => {
      // Motivations options.
      let feedbackType;
      let feedbackTypeApiAction = 'with_feedbacks';

      switch (stateSlug.value) {
        case 'on-hold':
          subscribe("private-live", `custom_event_${uniq}`);
          feedbackType = 'resumable';
          feedbackTypeApiAction = 'with_feedbacks_by_chat_action';
          break;
        case 'manager-changed-test':
        case 'iso-in-changed-test':
        case 'iso-reassigned':
        case 'manager-reassigned':
          feedbackType = 'rating';
          break;
        case 'iso-failed':
        case 'manager-failed':
          // Subscribe to custom event.
          subscribe("private-live", `custom_event_${uniq}`);
          feedbackType = 'warning';
          feedbackTypeApiAction = 'with_feedbacks_by_chat_action';
          break;
        default:
          throw new Error(stateSlug.value + 'Error iso change test');
      }

      // Fetch motivations.
      fetchByType(feedbackType, {
        action: feedbackTypeApiAction,
        internal_phase_id: props.internal_phase_id,
        state_slug: stateSlug.value,
      }).then((response) => {
        feedbacks.value = response.feedbacks;
      });
    }

    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    onMounted(async () => {
      if (props.state_slug === 'iso-in-changed-test' || props.state_slug === 'manager-changed-test') {
        listRowIdSelected.value = props.laboratory_list_row_id;
      }

      if (props.state_slug === 'iso-reassigned' || props.state_slug === 'manager-reassigned') {
        fetchTreeById(props.test_work_queue_id).then((response) => {
          checkTreeExists(response);
          let array = [];
          response.tree.meta.fork_tree.forEach(element => {
            flattenForkTree(element, array);
          });
          nodes.value = array;
        });
      }

      if (props.state_slug === 'iso-in-choose-fork') {
        fetchAllBranches(props.test_id).then((response) => {
          branches.value = response.branches.map((item) => {
            return {
              value: item.slug,
              label: item.description,
            }
          });
        });
      }

      fetchReasons();
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel("private-live");
    });

    // Fetch tests when selected list row.
    watch(() => listRowIdSelected.value, (value) => {
      if (value !== null) {
        tests.value = [];
        allPossibleTests(route.query.prescription_test_id).then((response) => {
          console.log(response.tests.data);
          tests.value = formatForMultiselectComponent(response.tests.data, 'name');
        });
      }
    });

    watch(() => isInternalSuspension.value, (value) => {
      if (value === true) {
        cardTitle.value = "Feedback sospensione interna";
        stateSlug.value = "on-hold";
      } else {
        cardTitle.value = getCardTitle();
        stateSlug.value = props.state_slug;
      }

      fetchReasons();
    });

    // Fetch tree preview when selected test.
    watch(() => testsIdSelected.value, (value) => {
      if (value !== null) {
        fetchAllBranches(value).then((response) => {
          branches.value = response.branches.map((item) => {
            return {
              value: item.slug,
              label: item.description,
            }
          });
        });
      }
    });

    // Fetch tree preview when selected branch.
    watch(() => branchSlugSelected.value, (value) => {
      // clear nodes.
      nodes.value = [];
      if (value !== null) {
        treePreview({
          action: "with_type",
          internal_phase_test_type_slug: value,
          id: (stateSlug.value === 'iso-in-choose-fork' ? props.test_id : testsIdSelected.value),
          test_work_queue_id: props.test_work_queue_id,
        }).then((response) => {
          checkTreeExists(response);
          let array = [];
          response.tree.meta.fork_tree.forEach(element => {
            flattenForkTree(element, array);
          nodes.value = array;
          });
        });
      }
    });

    const saveNewForkChoosed = () => {
      updateTestWorkQueue({
        action: 'update_fork_tree',
        id: props.test_work_queue_id,
        internal_phase_test_type_slug: branchSlugSelected.value
      }).then(() => {
        success(i18n.t('Change fork success'));
        setTimeout(() => {
          comeBack();
        }, 2000);
      });
    }

    const checkTreeExists = (response) => {
      if (response.tree.meta.fork_tree.length === 0) {
        // Show message.
        toast.warning('Nessun percorso trovato', {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    }

    const comeBack = async () => {
      // Manager actions.
      if (isManagerActions.value || isIsoProgettazione.value) {
        return router.go(-1);
      }

      // Iso actions.
      await router.push({
        name: "ConsultingDashboard",
        query: {
          test_work_queue_phase_id: props.test_work_queue_phase_id,
          internal_phase_id: props.internal_phase_id,
          read_only: false,
        },
      });
    }

    const readMotivationIdSelected = (value) => {
      motivations.ids = toRaw(value);
    };

    watch(() => motivations.ids, (motivationsIds) => {
      if (isToFailed.value || isExternalSuspention.value) {
        if (motivationsIds.length) {
          prefillNota();
        } else {
          nota.value = "";
        }
      }
    });

    const prefillNota = () => {
      let index = feedbacks.value.findIndex((feedback) => feedback.id === _head(motivations.ids));
      if (index !== -1) {
        if (feedbacks.value[index].default_note !== null) {
          nota.value = feedbacks.value[index].default_note;
        }
      }
    }

    const getNotes = (params) => {
      if (nota.value.trim() !== "") {
        params.note = nota.value.trim();
      }
    }

    const setAvailableAndRedirectToCheckIn = () => {
      changeState('available').then(async () => {
        if (isIsoProgettazione.value) {
          success(i18n.t('Dental impression suspended approved!'));
          return router.push({name: "ManagerDashboard"});
        }
        return router.push({name: "Checkin"});
      });
    }

    // Iso actions.
    const changedTestAction = async (stateSlug) => {
      const isIsoFlow = stateSlug === states.ISO_IN_CHANGED_TEST;
      const filteredNodes = nodes.value.filter((node, index) => index >= nodes.value.findIndex(node => node.internal_phase_id == internalPhaseTestIdSelected.value));
      const orderedPhasedIds = filteredNodes.map((node) => node.internal_phase_id);
      const params = {
        id: parseInt(props.test_work_queue_id),
        source_id: parseInt(props.test_work_queue_phase_id),
        test_id: testsIdSelected.value,
        internal_phase_ids: orderedPhasedIds,
        feedback_id: _head(motivations.ids),
        state_slug: stateSlug,
        should_duplicate_phase: isIsoFlow,
      }

      getNotes(params);

      await changeTest(params).then(() => {
        if (isIsoFlow) {
          return updateTestWorkQueuePhase({
            source_ids: [props.test_work_queue_phase_id],
            state: states.COMPLETED,
            action: "update",
          }).then(() => setAvailableAndRedirectToCheckIn());
        }
        return router.push({name: "ManagerDashboard"});
      });
    }

    const isoReassignedAction = async () => {
      let params = {
        action: 'update_root_tree',
        id: parseInt(props.test_work_queue_id),
        internal_phase_id: internalPhaseTestIdSelected.value,
        feedback_id: _head(motivations.ids),
        source_id: parseInt(props.test_work_queue_phase_id),
        action_by: 'iso',
      }
      getNotes(params);
      await reassign(params).then(async () => {
        setAvailableAndRedirectToCheckIn();
      });
    }

    const managerReassignedAction = async () => {
      let params = {
        action: 'update_root_tree',
        id: parseInt(props.test_work_queue_id),
        internal_phase_id: internalPhaseTestIdSelected.value,
        feedback_id: _head(motivations.ids),
        source_id: parseInt(props.test_work_queue_phase_id),
        action_by: 'manager',
      }
      getNotes(params);
      await reassign(params).then(async () => {
        // Redirect to manager dashboard.
        return router.push({ name: "ManagerDashboard" });
      });
    }

    const redirectAfterUpload = async () => {
      if (stateSlug.value !== states.ON_HOLD || (stateSlug.value === states.ON_HOLD && isInternalSuspension.value == false))
        await updateTestWorkQueue({
          id: props.test_work_queue_id,
          state_slug: stateSlug.value,
          action: "update_state"
        });

      if (isManagerActions.value || isIsoProgettazione.value) {
        if (isInternalSuspension.value) success(i18n.t('Dental impression suspended approved!'));
        else success(i18n.t('Dental impression successfully rejected!'));

        return router.push({ name: "ManagerDashboard" });
      }
      else {
        success(i18n.t('Job changed as failed'));

        return router.push({name: "Checkin"});
      }
    }

    const isoManagerFailedSaveAction = () => {
      storeFeedback(formData.value).then(() => {
        changeState('available').then(async () => await redirectAfterUpload());
      });
    }

    const isoManagerFailedAction = async () => {
     // Upload files if exists.
      if (existsMedia.value) {
        upload.value = true;
      } else {
        // Update data without media.
        isoManagerFailedSaveAction();
      }
    }

    const readNota = (value) => {
      nota.value = value;
    }

    const storeIsoInMotivation = async () => {
      if (motivations.ids.length === 0) {
        return alert.value = true;
      }

      switch (stateSlug.value) {
        case states.ISO_IN_CHANGED_TEST:
        case states.MANAGER_CHANGED_TEST:
          await changedTestAction(stateSlug.value);
          break;
        case states.ISO_REASSIGNED:
          await isoReassignedAction();
          break;
        case states.ON_HOLD:
        case states.ISO_FAILED:
        case states.MANAGER_FAILED:
          await isoManagerFailedAction();
          break;
        case states.MANAGER_REASSIGNED:
          await managerReassignedAction();
          break;
        default:
          updateTestWorkQueue({
            id: props.test_work_queue_id,
            state_slug: stateSlug.value,
            action: "update_state"
          }).then((response) => {
            console.log(`UPDATE TWQ ID: ${response.testWorkQueue.id} STATE IN ${response.testWorkQueue.state.slug}`);

            updateTestWorkQueuePhase({
              source_ids: [props.test_work_queue_phase_id],
              state: stateSlug.value,
              action: "update",
            }).then(async () => {
              setAvailableAndRedirectToCheckIn();
            });
          }).catch((error) => {
            console.log(error);
            // TODO: reset states
          });
      }
    }

    const readSelected = (node) => {
      if (stateSlug.value === 'iso-in-changed-test' || stateSlug.value === 'manager-changed-test') {
        testWorkQueuePhaseIdSelected.value = node.id;
        internalPhaseTestIdSelected.value = node.internalPhaseTestId;
      } else {
        internalPhaseTestIdSelected.value = node.internalPhaseTestId;
      }
    }

    const clearAll = () => {
      listRowIdSelected.value = null;
      testsIdSelected.value = null;
      branchSlugSelected.value = null;
      testWorkQueuePhaseIdSelected.value = null;
      tests.value = [];
      branches.value = [];
      nodes.value = [];
    }

    const clearTests = () => {
      testsIdSelected.value = null;
      branchSlugSelected.value = null;
      testWorkQueuePhaseIdSelected.value = null;
      branches.value = [];
      nodes.value = [];
    }

    const clearBranches = () => {
      branchSlugSelected.value = null;
      testWorkQueuePhaseIdSelected.value = null;
    }

    const clear = () => {
      branchSlugSelected.value = null;
      testWorkQueuePhaseIdSelected.value = null;
      nodes.value = [];
    }

    const closeQrcodeModal = async () => {
      await closeModal('qrcode');
      showQrcodeModal.value = false;
    }

    return {
      comeBack,
      readNota,
      storeIsoInMotivation,
      readMotivationIdSelected,
      feedbacks,
      alert,
      items,
      motivations,
      placeholder,
      listRowIdSelected,
      listRows,
      tests,
      testsIdSelected,
      nodes,
      readSelected,
      testWorkQueuePhaseIdSelected,
      nota,
      conditionDisabledContinue,
      internalPhaseTestIdSelected,
      branchSlugSelected,
      branches,
      clearAll,
      clear,
      saveNewForkChoosed,
      debug,
      clearBranches,
      clearTests,
      upload,
      mediaCounter,
      showQrcodeModal,
      closeQrcodeModal,
      router,
      externalDeviceRoute,
      assignUrlFeedback,
      storeFeedback,
      formData,
      redirectAfterUpload,
      isToFailed,
      stateSlug,
      isInternalSuspension,
      cardTitle,
      isExternalSuspention,
    }
  }
}
</script>
