<template>
  <template v-if="tests.length > 0">
    <div class="d-flex flex-column w-100 h-100" ref="list">
      <template v-for="(test, index) in tests" :key="index">
        <Test
          :data="test"
          @selected="readSelected"
        />
      </template>
    </div>
  </template>

  <template v-else>
    <div class="d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="spinner-border text-violet" role="status"></div>
    </div>
  </template>

</template>

<script>
import { onMounted, ref } from "vue";
import Test from '@/components/Consulting/Test';

export default {
  name: "Tests",
  components: {
    Test
  },
  props: {
    data: Array,
  },
  emit: [
    'show',
  ],
  setup(props, {emit}) {
    const tests = ref(props.data);

    const list = ref(null); // Create a ref for the list

    const readSelected = (obj) => {
      let atLeastOneSelected = tests.value.some((test) => test.selected);

      if (atLeastOneSelected) {
        let testAlreadySelected = tests.value.find((test) => test.selected );

        if (testAlreadySelected.id === obj.id && obj.preselect === false) {
          console.log("ALREADY SELECTED");
          return;

        } else {
          tests.value.forEach((test) => test.selected = false);
        }
      }

      let testToBeSelected = tests.value.find((test) => test.id === obj.id);
      testToBeSelected.selected = true;

      emit('show', obj.id);
    }

    const scrollToSelected = () => {
      const selectedItem = tests.value.find(item => item.selected);

      if (selectedItem && list.value) {
        const selectedItemIndex = tests.value.indexOf(selectedItem);
        const selectedListItem = list.value.children[selectedItemIndex];

        if (selectedListItem) {
          selectedListItem.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    onMounted(() => {
      scrollToSelected();
    });

    return {
      readSelected,
      tests,
      list,
    }
  }
}
</script>
