<template>
  <div v-if="show" class="d-flex vw-100 vh-100 justify-content-center align-items-center">
    <div class="col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-3">
      <brand :width="120" :height="120" />
      <!-- Feedbacks -->
      <div class="card rounded-custom shadow border-0 p-4 text-center flex-column justify-content-center">
        <h3 class="mb-3 fw-normal">Feedback</h3>
        <div class="card-body p-0">
          <div class="d-flex align-items-center justify-content-center mb-3">
            <h6 class="text-violet">
              <span class="fw-bold">{{ testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.number_text }}</span>
              -
              <span class="consulting-link text-violet text-uppercase text-decoration-underline" @click="consulting()">consultazione</span>
            </h6>
          </div>
          <p>Valutazione fase precedente</p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-uppercase">
              <span class="text-violet">
                <!-- Fase interna prec -->
                {{ getInternalPhasePrev() }}
              </span>
            </li>
          </ul>
          <div class="d-flex justify-content-center">
            <img class="p-3 like" src="@/assets/icons/like.svg" width="90" height="90" alt="like" @click="goToDashboard()"/>
            <img class="p-3 dislike" src="@/assets/icons/dislike.svg" width="90" height="90" alt="dislike" @click="goToFeedbackNegativeView()"/>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <div
              class="border-4 rounded-custom border-violet p-3 d-flex flex-column w-100">
              <h5 class="mb-2 fw-bold">Nota lavorazione</h5>
              <!-- Notes -->
              <template v-if="notes.data !== null">
                <template v-for="(note, index) in notes.data.feedback_notes" :key="index">
                  <p class="mb-1">{{ note.note }}</p>
                  <p class="fst-italic text-muted">
                    {{ note.user.first_name }}
                    {{ note.user.last_name }}
                  </p>
                </template>
              </template>
              <template v-else>
                <p>Nessuna nota interna presente.</p>
              </template>
              <small class="mt-4">
                <a href="#" class="text-violet text-decoration-underline" @click="showHistoryNotes = true">
                  Vedi tutte le note interne
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <InternalNotes  v-if="showHistoryNotes"
                  :title="'Note Interne'"
                  :test_work_queue_id="$route.query.test_work_queue_id"
                  @historyNotes="setVisible">
  </InternalNotes>

</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { onMounted, reactive, ref } from "vue";

import _head from "lodash/head";

import Brand from "@/components/Brand";
import InternalNotes from "@/components/InternalNotes";

import {fetchNotes} from "@/use/repositories/testWorkQueuePhase/fetchNotes";
import {fetchById as fetchTestWorkQueuePhaseById} from '@/use/repositories/testWorkQueuePhase/fetchById';
import {changeState as changeStateUser} from '@/use/repositories/users/changeState';
import {update as updateTestWorkQueuePhase} from "@/use/repositories/testWorkQueuePhase/update";

export default {
  name: "Feedback",
  components: {
    Brand,
    InternalNotes,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const notes = reactive({ data: null });
    const showHistoryNotes = ref(false);
    const show = ref(false);
    const testWorkQueuePhase = reactive({data: []});

    onMounted(() => {
      getNotes();
      setVisible();

      fetchTestWorkQueuePhaseById(route.query.test_work_queue_phase_id, "with_parent").then(async (response) => {
        testWorkQueuePhase.data = response.testWorkQueuePhase;
        show.value = true;
      });
    });

    const getInternalPhasePrev = () => {
      let parentInternalPhaseName = _head(testWorkQueuePhase.data.parent_test_work_queue_phases).internal_phase.name
      let parentDepartmentInternalPhaseName = _head(testWorkQueuePhase.data.parent_test_work_queue_phases).internal_phase.department.name.substring(0, 4);

      return `${parentInternalPhaseName} (${parentDepartmentInternalPhaseName})`;
    }

    const goToDashboard = async () => {
      await updateTestWorkQueuePhase({
        source_ids: [route.query.test_work_queue_phase_id],
        state: "in-the-works",
        action: "update",
      }).then(async () => {
        await changeStateUser("working");
        await router.push({
          name: "Dashboard",
          params: {
            test_work_queue_phases_id: route.query.test_work_queue_phase_id,
          },
        });
      });
    }

    const goToFeedbackNegativeView = async () => {
      await router.push({
        name: "NegativeFeedback",
        query: {
          source_id: route.query.test_work_queue_phase_id,
          destination_id: route.query.parent_test_work_queue_phase_id,
          internal_phase_id: route.query.internal_phase_id,
          parent_internal_phase_id: route.query.parent_internal_phase_id,
          test_work_queue_id: route.query.test_work_queue_id,
        },
      });
    };

    const getNotes = async () => {
      await fetchNotes(route.query.parent_test_work_queue_phase_id).then(async (response) => {
        notes.data = response.notes;
      })
    };

    const setVisible = (value) => {
      showHistoryNotes.value = value;
    };

    const consulting = () => {
        return router.push({
          name: 'ConsultingDashboard',
          params: {
            prescription_id: testWorkQueuePhase.data.test_work_queue.prescription_test.prescription.id
          },
          query: {
            read_only: true,
            from_iso: true,
          }
        });
    }

    return {
      goToDashboard,
      goToFeedbackNegativeView,
      notes,
      showHistoryNotes,
      setVisible,
      show,
      testWorkQueuePhase,
      getInternalPhasePrev,
      consulting,
    };
  },
};
</script>

<style scoped>
  .like:hover,
  .dislike:hover {
    cursor: pointer;
  }
</style>
