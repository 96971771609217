export function canCamSummary({ next, store }) {
  if (
    typeof store.state.user.state !== "undefined"
    &&
    store.state.mainRole.team.name === "cam"
  ) {
    return next();
  } else {
    return next("/login");
  }
}
