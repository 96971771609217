<template>
  <div class="search__messages">
    <template v-if="title !== null">
      <div class="search__messages__title">
        {{ title }}
      </div>
    </template>
    <div class="search__messages__input">
      <img class="search__messages__input__icon" src="./icons/search.svg" alt="search">
      <input
        id="search"
        type="text"
        autocomplete="off"
        class="form-control"
        placeholder="Cerca codice o cliente"
        v-model="keyword"
        @input="onInput"
      />
      <small class="text-danger" v-if="alert">inserisci minimo 3 caratteri</small>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import debounce from "lodash/debounce";

export default {
  name: "Search",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  emits: [
    'search',
  ],
  setup(props, {emit}) {
    const keyword = ref("");
    const alert = ref(false);

    const onInput = debounce(async () => {
      alert.value = false;

      // Check min length.
      alert.value = (keyword.value.length <= 3);

      if (alert.value === false) {
        emit('search', keyword.value);
      }
    }, 1000);

    return {
      keyword,
      alert,
      onInput,
    }
  }
}
</script>

<style scoped lang="scss">
#search {
  padding-left: 25px;
  border-radius: 10px;
  border: 1px solid #E1D2F2;
}
.search__messages {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.search__messages__title {
  font-weight: bold;
  flex-grow: 1;
}
.search__messages__input {
  position: relative;
  flex-grow: 2;
}
.rounded-custom {
  border-radius: 5px;
}
::-webkit-input-placeholder ,/* Edge */
::placeholder {
  color: #A58ABA !important;
}
.search__messages__input__icon {
  top: 10px;
  left: 5px;
  position: absolute;
}
</style>
