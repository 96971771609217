import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function updateReadings(ids) {
  let data = [];

  await axios
    .put(`/messages`, {
      action: 'update_readings',
      message_ids: ids
    })
    .then(async (response) => {
      data = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
