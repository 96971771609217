<template>
  <div class="card shadow overflow-auto rounded-custom w-100 h-100 d-flex align-items-center justify-content-center flex-column">
    <template v-if="Object.keys($props.data).length > 0">
      <img class="me-2" src="@/assets/icons/paziente.svg" width="25" alt="customer">
      <strong>{{ $props.data.customer_name }} {{ $props.data.customer_last_name }}</strong>
      <div>Studio: {{ $props.data.clinic_name }}</div>
      <div>Medico: {{ $props.data.doctor_name }} {{ $props.data.doctor_surname }}</div>
    </template>
    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center">
        <div class="spinner-border text-violet" role="status"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PrescriptionDetailsMobileBox1",
  props: {
    data: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped>

</style>
