import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {ref} from 'vue';

export async function warehouseRequest(states) {
  const axios = axiosInstance().primoLab;
  const orders = ref([]);

  await axios
    .get("/test-work-queues/warehouse-requests", {
      params: {
        state_slugs: states,
      }
    }).then(async (response) => {
      orders.value = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { orders };
}
