import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchById(id, action) {
  const axios = axiosInstance().primoLab;
  let prescriptionTest = [];

  await axios
    .get(`/prescription-tests/${id}`, {
      params: {
        action: action,
      }
    })
    .then(async (response) => {
      prescriptionTest = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { prescriptionTest };
}
