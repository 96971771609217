import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchPossibleToResume(stateSlug, internalPhaseId) {
  const axios = axiosInstance().primoLab;
  let feedbacks = [];

  await axios
    .get("/feedback-types", {
      params: {
        slug: stateSlug,
        internal_phase_id: internalPhaseId,
      }
    })
    .then(async (response) => {
      feedbacks = response.data.data.feedbacks.map((item) => {
        return {
          value: item.id,
          label: item.description,
        };
      });
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { feedbacks };
}
