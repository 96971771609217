import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import { ref } from "vue";

export async function saveNote(testWorkQueuePhaseId, note) {
  const axios = axiosInstance().primoLab;
  const responseData = ref({});

  await axios
    .post(`/feedbacks`, {
      test_work_queue_phase_id: testWorkQueuePhaseId,
      is_valid: 1,
      note: note,
    })
    .then(async (response) => {
      responseData.value = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { responseData };
}
