<template>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <Brand :width="120" :height="120" />
      <div
        class="
          card
          col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-3
          rounded-custom
          shadow
          border-0
          p-3
        "
      >
        <div class="card-body">
          <form @submit.prevent="change">
            <h1 class="h3 mb-3 fw-normal text-center">
              La tua password è scaduta, è necessario aggiornarla
            </h1>
            <div class="mb-3">
              <label for="password" class="form-label">Nuova password</label>
              <input
                v-model="data.password"
                type="password"
                class="form-control"
                id="password"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                :disabled="result.state === 'changed'"
                required
                autofocus
              />
            </div>
            <div class="mb-3">
              <label for="password_confirm" class="form-label">Conferma password</label>
              <input
                v-model="data.confirm_password"
                type="password"
                class="form-control"
                id="password_confirm"
                :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                :disabled="result.state === 'changed'"
                required
              />
            </div>

            <template v-if="result.message">
              <div class="alert alert-success" role="alert">
                {{ result.message }}
              </div>
            </template>

            <template v-if="errors.length > 0">
              <div class="alert alert-danger" role="alert">
                <ul v-for="error in errors" :key="error.index">
                  <li>
                    {{ error }}
                  </li>
                </ul>
              </div>
            </template>

            <div class="mb-3" v-if="result.state === 'changed'">
              <router-link to="/login" href="#" class="link-dark"> Vai alla login </router-link>
            </div>
            <button
              class="w-100 btn btn-lg btn-violet d-flex align-items-center justify-content-center"
              type="submit"
              :disabled="loading || result.state === 'changed'"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="loading">Loading...</span>
              <span v-else>Aggiorna</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Brand from "@/components/Brand.vue";
import { changePassword } from "@/use/repositories/auth/changePassword";

export default {
  name: "ExpiredPassword",
  components: {
    Brand,
  },
  setup() {
    let loading = ref(false);
    const errors = [];
    const data = reactive({
      password: "",
      confirm_password: "",
    });
    const result = reactive({
      state: "",
      message: "",
    });

    const setFocus = () => {
      let input = document.getElementById("password");
      input.focus();
    };

    const clearFileds = () => {
      data.password = "";
      data.confirm_password = "";
    };

    const change = async () => {
      loading.value = true;

      errors.splice(0);
      result.message = "";

      changePassword(data)
        .then(async (response) => {
          result.state = "changed";
          result.message = response.message;

        })
        .catch(async (error) => {
          error.forEach((message) => errors.push(message));
          setFocus();
        })
        .finally(() => {
          clearFileds();
          loading.value = false;
        });
    };

    return {
      data,
      change,
      result,
      loading,
      errors,
    };
  },
};
</script>

<style scoped>
.alert {
  padding: 0.5rem 0;
}
ul {
  padding-left: 1.5em;
}
</style>
