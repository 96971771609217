<template>
  <div></div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

import {useStore} from "vuex";

export default {
  name: "Logout",
  setup() {
    const router = useRouter();
    const axios = axiosInstance().primoLab;
    const store = useStore();

    const logout = async () => {
      axios
        .post("/auth/logout")
        .then(async () => {

          localStorage.clear();
          window.localStorage.removeItem("vuex");
          await store.dispatch("setUser", null);
          await router.push("/login");
        })
        .catch(async (error) => {
          console.log(error.response.data);
        });
    };

    onMounted(logout);
  },
};
</script>
