import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchAll() {
  const axios = axiosInstance().primoLab;
  let colors = [];

  await axios
    .get("/colors")
    .then(async (response) => {
      colors = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { colors };
}
