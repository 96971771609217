<template>
  <th class="fw-bold" nowrap scope="col" style="position: relative;"
      :style="{ backgroundColor: filter ? '#F3E9FF' : '' }">
    <span :style="{ color: filter ? '#A58ABA' : '' }">
        <span class="me-3">{{ $props.label }}</span>
        <span class="filter" @click="filter = ! filter">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
             :fill="filter ? '#A58ABA' : '' " class="bi bi-filter-right" viewBox="0 0 16 16">
        <path
          d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
        </svg>
        </span>
    </span>
    <template v-if="filter">
      <FilterTableColumn
        :fields="distinctGroupByField($props.data, $props.field)"
        :type="$props.field"
        :filter-selected-list="arrayWithFilterName"
        @filterBy="readFilter"
      />
    </template>
  </th>
</template>

<script>
import FilterTableColumn from "@/components/cam/FilterTableColumn";
import { distinctGroupByField } from "@/use/utilities/array/distinctGroupByField";
import {ref, toRaw, watch} from 'vue';

export default {
  props: {
    data: {
      Object,
      required: true,
    },
    label: {
      String,
      required: true,
    },
    field: {
      String,
      required: true,
    },
    restore: {
      Boolean,
      required: true,
    }
  },
  emits: [
    'dataProcess',
  ],
  components: {
    FilterTableColumn
  },
  setup(props, {emit}) {
    const filter = ref(false);
    const arrayWithFilterName = ref([]);

    watch(() => arrayWithFilterName.value, () => {
      emit('dataProcess', {
        'type': props.field,
        'filters': toRaw(arrayWithFilterName.value),
      });
    }, { deep: true });

    watch(() => props.restore, (value) => {
      if (value) {
        filter.value = false;
        arrayWithFilterName.value = [];
      }
    });

    const readFilter = (data) => {
      // Sync filters.
      if (arrayWithFilterName.value.includes(data.name)) {
        // Restore filter.
        arrayWithFilterName.value.splice(arrayWithFilterName.value.indexOf(data.name), 1);
      } else {
        // Filter records.
        arrayWithFilterName.value.push(data.name);
      }
    }

    return {
      filter,
      arrayWithFilterName,
      readFilter,
      distinctGroupByField,
    }
  }

}
</script>

<style scoped>
  .filter:hover {
    cursor: pointer;
  }
</style>
