<script>
import { ref, onMounted, reactive } from "vue";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _head from "lodash/head";
import _ from "lodash";
import { hideModal as closeModal } from "@/use/modal/hide";
import { index as findTags } from "@/use/repositories/devices/index";

import CardCheckbox from "@/components/general/multi_checkbox/CardCheckbox.vue";
import DeviceCommand from "@/components/devices/DeviceCommand.vue";
import LeafletMap from "./LeafletMap.vue";
import Empty from '@/components/Modal/Empty';

export default {
  name: "MissingWorksWizard",
  emits: ["update:selected", "suspend"],
  props: {
    selectableData: {
      type: Array,
      required: true,
    },
    selected: {
      required: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    verticalDesign: {
      type: Boolean,
      default: false,
    },
    canSuspend: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const worksIdsSelected = ref([]);
    const showMissingModal = ref(false);
    const searchedTagDepartments = ref([]);
    const searchedWorksIdsSelected = ref([]);
    const multipleLogic = ref(true)
    const activeMapData = reactive({
      show: false,
      positions: [],
    });

    onMounted(() => {
      if (props.selected) worksIdsSelected.value.push(props.selected);
      if (props.selectableData.length === 1) {
        multipleLogic.value = false;
        if (!props.selected) worksIdsSelected.value.push(_head(props.selectableData).prescription_test_id)
        findTags('by_ids', getTagIds(), 'ids').then(response => { generateLocationInfo(response) })
      }
    });

    const goBack = () => {
      searchedTagDepartments.value = [];
      searchedWorksIdsSelected.value = [];
    };

    const showMap = () => {
      searchedTagDepartments.value.forEach(element => activeMapData.positions.push({ ...element.position, prescription: element.name }));
      activeMapData.show = true;
    }

    const checkWork = (optionId, checked) => {
      if (checked) {
        worksIdsSelected.value.push(optionId);
      } else {
        _remove(worksIdsSelected.value, (value) => { return optionId === value; });
      }
    };

    const checkDepartment = (optionId, checked) => {
      if (checked) {
        searchedWorksIdsSelected.value.push(optionId);
      } else {
        _remove(searchedWorksIdsSelected.value, (value) => {
          return optionId === value;
        });
      }
    };

    const generateLocationInfo = (response) => {
      worksIdsSelected.value.forEach((elementId) => {
        const result = _.chain(props.selectableData)
          .find({ prescription_test_id: elementId })
          .thru(workSelected => ({
            workSelected,
            position: _.find(response.data, { id: workSelected.device_id }),
          }))
          .value();

        const { workSelected, position } = result;

        searchedTagDepartments.value.push({
          id: workSelected.id,
          prescription_test_id: workSelected.prescription_test_id,
          name: workSelected.name,
          first_name: workSelected.first_name,
          last_name: workSelected.last_name,
          delivery_date: workSelected.delivery_date,
          device_id: workSelected.device_id,
          position: {
            x: position?.extra_data?.x || undefined,
            y: position?.extra_data?.y || undefined,
            area: position?.extra_data?.area || "Disperso",
          },
        })
      });

      if (searchedTagDepartments.value.length === 1) checkDepartment(_head(searchedTagDepartments.value).prescription_test_id, true);
    }

    const works = ref(props.selectableData)

    const searchWorks = () => {
      const tagIds = getTagIds();
      findTags('by_ids', tagIds, 'ids').then(response => {
        generateLocationInfo(response);
      });
    };

    const getTagIds = () => {
      let ids = [];
      worksIdsSelected.value.forEach(elementId => {
        ids.push(_find(props.selectableData, (item) => {
          return item.prescription_test_id === elementId;
        }).device_id);
      });
      return ids;
    }

    const closeMissingModal = () => {
      worksIdsSelected.value = [];
      closeModal("missing_modal");
      showMissingModal.value = false;
    };

    const suspendWorks = () => {
      let worksToSuspend = [];
      searchedWorksIdsSelected.value.forEach(element => {
        const work = _find(props.selectableData, (data) => {
          return data.prescription_test_id === element;
        })
        worksToSuspend.push(work);
      });
      emit('suspend', worksToSuspend);
    }
    return {
      showMissingModal,
      closeMissingModal,
      works,
      worksIdsSelected,
      searchWorks,
      searchedTagDepartments,
      searchedWorksIdsSelected,
      checkWork,
      checkDepartment,
      goBack,
      suspendWorks,
      multipleLogic,
      showMap,
      activeMapData,
    };
  },
  components: {
    CardCheckbox,
    DeviceCommand,
    Empty,
    LeafletMap,
  },
};
</script>

<template>
  <div class="row d-flex" style="max-height: 70vh;">
    <!-- Header -->
    <div class="col" :class="{ 'd-none order-1': $props.verticalDesign, 'col-xs-12': !$props.verticalDesign }">
      <template v-if="!searchedTagDepartments.length">
        <h4 class="mt-3">Seleziona le caselle da ricercare</h4>
      </template>
      <template v-if="searchedTagDepartments.length">
        <div class="d-flex align-items-center justify-content-center position-relative p-3">
          <template v-if="!multipleLogic">
            <h4 class="mt-2">Vuoi illuminare la casella? Scegli il colore!</h4>
          </template>
          <template v-else>
            <img src="@/assets/icons/arrow-left.svg" width="90" alt="arrow-left" class="cp" style="filter: invert(0.5);"
              @click="goBack()">
            <h4 class="mt-2">Vuoi illuminare le caselle? Selezionale e scegli il colore!</h4>
          </template>
        </div>
      </template>
    </div>
    <div class="col overflow-auto"
      :class="{ ' d-flex flex-column order-1 align-items-center mt-5 pt-5': $props.verticalDesign, 'd-none': !$props.verticalDesign }">
      <template v-if="!searchedTagDepartments.length">
        <h4 class="mt-3">Seleziona le caselle da ricercare</h4>
      </template>
      <template v-if="searchedTagDepartments.length">
        <div class="d-flex align-items-center justify-content-center position-relative p-3">
          <img src="@/assets/icons/arrow-left.svg" width="90" alt="arrow-left" class="arrow-left cp"
            style="filter: invert(0.5);" @click="goBack()" />
          <h4 class="mt-2">Vuoi illuminare le caselle? Selezionale e scegli il colore!</h4>
        </div>
      </template>
      <div class="col" :class="{ 'order-3 w-100': $props.verticalDesign, 'col-xs-12': !$props.verticalDesign }">
        <template v-if="worksIdsSelected.length && !searchedTagDepartments.length">
          <button class="btn btn-violet rounded-custom text-uppercase d-flex align-items-center m-auto mt-5 p-2"
            @click="searchWorks">
            Cerca le caselle selezionate
          </button>
        </template>
        <template v-if="searchedTagDepartments.length">
          <template v-if="searchedWorksIdsSelected.length">
            <button
              type="button"
              class="btn btn-violet rounded-custom text-uppercase m-auto mb-3"
              @click="showMap"
            >
              Mostra mappa
            </button>
            <DeviceCommand :data="searchedTagDepartments" :multiple="true">
            </DeviceCommand>
          </template>
        </template>
      </div>
    </div>
    <!--  Body -->
    <div class=""
      :class="{ 'col d-flex order-2 justify-content-center': $props.verticalDesign, 'col-xs-12': !$props.verticalDesign }">
      <template v-if="!searchedTagDepartments.length">
        <div class="d-flex m-4  overflow-auto"
          :class="{ 'flex-column h-60vh': $props.verticalDesign, 'justify-content-center': !multipleLogic }">
          <CardCheckbox v-for="option in $props.selectableData"
            :checked="worksIdsSelected.includes(option.prescription_test_id)" :fieldId="option.name" :label="option.name"
            :key="option" :position="option.position" :prescription-test-id="option.prescription_test_id"
            @checked="checkWork(option.prescription_test_id, $event)" style="cursor: pointer">
            <span class="fw-bold fs-5 p-2">
              {{ option.delivery_date }}
            </span>
            <div class="d-flex align-items-center mt-3">
              <img class="me-3" src="@/assets/icons/paziente.svg" height="40" alt="customer" />
              <span class="d-flex flex-column">
                <small class="text-uppercase fw-bold">nome: {{ option.first_name }}</small>
                <small class="text-uppercase fw-bold">cognome: {{ option.last_name }}</small>
              </span>
            </div>
          </CardCheckbox>
        </div>
      </template>
      <template v-if="searchedTagDepartments.length">
        <div class="d-flex m-4 overflow-auto"
          :class="{ 'flex-column justify-content-center': $props.verticalDesign, 'justify-content-center': !multipleLogic }">
          <CardCheckbox v-for="option in searchedTagDepartments"
            :checked="searchedWorksIdsSelected.includes(option.prescription_test_id)" :fieldId="option.name"
            :label="option.name" :key="option" :position="option.position"
            :prescription-test-id="option.prescription_test_id"
            @checked="checkDepartment(option.prescription_test_id, $event)">
            <div style="cursor: pointer">
              <span class="fw-bold fs-5"> Dipartimento: {{ option.position.area }}</span>
              <hr>
              <span class="fw-bold fs-5">{{ option.delivery_date }}</span>
              <div class="d-flex align-items-center">
                <img class="me-3" src="@/assets/icons/paziente.svg" height="40" alt="customer" />
                <div class="d-flex flex-column">
                  <small class="text-uppercase fw-bold">nome: {{ option.first_name }}</small>
                  <small class="text-uppercase fw-bold">cognome: {{ option.last_name }}</small>
                </div>
              </div>
            </div>
          </CardCheckbox>
        </div>
      </template>
    </div>
    <!--  Action  -->
    <div class="col-xs-12" v-if="!$props.verticalDesign">
      <template v-if="worksIdsSelected.length && !searchedTagDepartments.length">
        <button class="btn btn-violet rounded-custom text-uppercase d-flex align-items-center m-auto mt-5 p-2"
          @click="searchWorks">
          Cerca le caselle selezionate
        </button>
      </template>
      <template v-if="searchedTagDepartments.length">
        <template v-if="searchedWorksIdsSelected.length">
          <button type="button" class="btn btn-violet rounded-custom text-uppercase m-auto" @click="showMap">Mostra
            mappa</button>
          <DeviceCommand :data="searchedTagDepartments" :multiple="true" />
          <template v-if="$props.canSuspend">
            <h5>Oppure</h5>
            <button class="btn btn-violet rounded-custom text-uppercase d-flex align-items-center m-auto p-2 my-3"
              @click="suspendWorks()">Sospendi le caselle selezionate</button>
          </template>
        </template>
      </template>
    </div>
  </div>
  <Empty
    v-if="activeMapData.show" 
    size="fullscreen"
    modal_id="tag_map"
    @hidden="() => activeMapData.show = false"
    >
    <button class="btn btn-violet rounded-custom text-uppercase m-auto my-3 p-2" data-bs-target="#tag_map"
      data-bs-toggle="modal">
      Chiudi la mappa
    </button>
    <LeafletMap v-if="activeMapData.show" :positions="activeMapData.positions" />
  </Empty>
</template>

<style lang="scss" scoped>
.h-60vh {
  height: 60vh;
}
</style>
