<template>
  <div class="position-relative p-2 rounded-circle bg-white shadow" style="z-index: 99;">
    <img class="chat" @click="toggleChat()" src="@/assets/icons/chat/chat.svg" width="50" alt="chat">
    <span v-if="badge" class="dot position-absolute p-2 bg-danger border border-light rounded-circle"></span>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue';
import store from "@/store";

export default {
  name: "Button",
  props: {
    newMessageCount: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  emits: [
    'selected',
  ],
  setup(props, {emit}) {
    const showChat = ref(false);
    const badge = computed(() => {
      return store.state.badge;
    });

    onMounted(() => {
      // Reset notification badge.
      store.dispatch("setBadge", false);
    });

    const toggleChat = () => {
      showChat.value = ! showChat.value;
      emit('selected', showChat.value);
    };

    return {
      toggleChat,
      badge,
    }
  }
}
</script>

<style scoped lang="scss">
.chat {
  &:hover {
     cursor: pointer;
   }
}
.dot {
  top: 5px;
  right: 0;
}
</style>
