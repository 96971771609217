import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchNotesById(twqId) {
  const axios = axiosInstance().primoLab;
  let testWorkQueuePhase = [];

  await axios
    .get(`/test-work-queues/feedbacks`, {
      params: { test_work_queue_id: twqId },
    })
    .then(async (response) => {
      testWorkQueuePhase = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueuePhase };
}
