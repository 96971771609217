import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchByRole(roleSlug, action) {
  const axios = axiosInstance().primoLab;
  let users = [];

  await axios
    .get(`/roles/${roleSlug}`, {
      params: {
        action: action,
      },
    })
    .then(async (response) => {
      users = response?.data?.data?.users;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { users };
}
