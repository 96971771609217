<template>
  <div class="d-flex bg-white shadow rounded-custom p-3 flex-column justify-content-between overflow-auto w-100">
    <div class="d-flex flex-column">
      <h4 class="text-center text-uppercase">
        File Selezionati <br>
        ({{ files.value.length }})
      </h4>
      <template v-for="(el, index) in files.value" :key="index">
        <template v-for="(file, index) in el.media" :key="index">
          <div class="text-truncate">
            <img src="@/assets/icons/checkbox-checked.svg" width="30" height="30" alt="checkbox-checked" />
            <span class="mx-2">
              <img v-if="el.bridge" src="@/assets/icons/project/bridge/bridge-sel.svg" height="25" alt="checkbox" />
              {{ file.file_name }}
            </span>
          </div>
        </template>
      </template>
    </div>
    <button type="button" class="btn btn-violet text-uppercase mt-4" @click="download" :disabled="!files.value.length">
      <img src="@/assets/icons/download.svg" width="20" height="20" alt="download">
      <span class="ms-2">Scarica i file</span>
    </button>
  </div>
</template>

<script>
import { inject, reactive, ref, toRaw, watch } from 'vue';
import { downloadZipByIds } from "@/use/repositories/assets/downloadZipByIds";
import { print } from "@/use/repositories/devices/print";
import { index as fetchAllTesWorkQueue } from '@/use/repositories/testWorkQueue/index';
import _ from "lodash";

export default {
  name: "Download",
  props: [
    'data',
  ],
  emits: [
    'reset',
  ],
  setup(props, { emit }) {
    const { states, devicesType, departments } = inject("constants");
    const fileIdsToDownload = ref([]);
    const files = reactive({
      value: [],
    });
    const printers = ref();

    watch(() => props.data.length, () => {
      // Dynamic visualize on file to download.
      files.value = [];
      fileIdsToDownload.value = [];
      props.data.forEach((item) => {
        files.value.push(item);
        fileIdsToDownload.value.push(item.id);
      });
    });

    const printDigitalPrescriptions = async () => {
      const uniqueCombinations = _.chain(toRaw(files).value)
        .filter({ is_digital: 1, has_prints: false})
        .map(item => ({
          identifier: `${item.prescription_id}_${item.test_work_queue_id}`,
          prescription_id: item.prescription_id,
          test_work_queue_id: item.test_work_queue_id
        }))
        .uniqBy('identifier')
        .map(item => _.pick(item, ['prescription_id', 'test_work_queue_id']))
        .value();

      uniqueCombinations.forEach((item) => {
        // Print Label
        fetchAllTesWorkQueue({
          action: 'with_base_64_labels',
          ids: [
            item.test_work_queue_id,
          ]
        }, true)
          .then((response) => print({
            device_type_slug: devicesType.ANALOG_LABEL,
            department_slug: departments.CAM,
            data: _.head(response.testWorkQueue).raw_data,
          }));
      });
    }

    const download = async () => {
      // Print digital prescription labels
      await printDigitalPrescriptions();

      // Download zip.
      await downloadZipByIds(fileIdsToDownload.value, states.IN_THE_WORKS).then(async () => {
        // Reset selection.
        emit('reset', fileIdsToDownload.value);
        files.value = [];
        fileIdsToDownload.value = [];
      });
    }

    return {
      download,
      files,
      printers
    }
  }
}
</script>
