<template>
    <div class="d-flex justify-content-between w-50">
        <Logout />
        <Brand :width="120" :height="120" />
    </div>

    <div class="container">
        <div class="main-body">
            <div class="row gutters-sm">
                <div class="col-md-4 mb-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex flex-column align-items-center">
                                <img src="@/assets/images/avatar.svg" alt="User" width="150">
                                <div class="mt-3 w-100">
                                    <h4 class="text-center">{{ $store.state.user.first_name }}</h4>
                                    <h5>Ruoli:</h5>
                                    <div>
                                        <template v-for="(role, index) in $store.state.user.roles" :key="index">
                                            <span class="badge bg-secondary mb-2 me-1">{{ role.toUpperCase() }}</span>
                                        </template>
                                    </div>

                                    <h5>Reparti:</h5>
                                    <div>
                                        <template v-for="(department, index) in $store.state.user.departments" :key="index">
                                            <span class="badge bg-light text-muted mb-1 me-1">{{ department.name }}</span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0">
                                  Ruolo attuale:
                                    <span class="fw-bold ms-1">{{ $store.state.mainRole.role.name }}</span>
                                </h6>
                                <router-link class="link-info" to="/login-choose">
                                  Cambia ruolo
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="card mt-3">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0"><svg fill="#000000" width="24" height="24" viewBox="0 0 32 32"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <title>zendesk</title>
                                        <path
                                            d="M24.068 20.44c-0.001 0-0.002 0-0.003 0-3.823 0-6.923 3.1-6.923 6.923 0 0.001 0 0.003 0 0.004v-0h13.854c0-0 0-0 0-0 0-3.825-3.101-6.926-6.926-6.926-0 0-0 0-0 0h0zM14.858 10.643l-13.854 16.724h13.854zM1.004 4.636c0 3.826 3.101 6.927 6.927 6.927s6.927-3.101 6.927-6.927v0zM17.142 4.633v16.728l13.854-16.726h-13.854z">
                                        </path>
                                    </svg>Zendesk</h6>
                                <span class="text-secondary"><a href="#" class="link-info" @click.prevent="ssoRedirect('zendesk')">Apri ticket</a></span>
                            </li>
                            <li
                                v-if="$store.state.user.roles.includes('admin')"
                                class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
                            >
                                <h6 class="mb-0"><svg fill="#000000" width="24" height="24" viewBox="0 0 32 32"
                                        version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <title>Backoffice</title>
                                        <path d="M24.068 16V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h13c.55 0 1-.45 1-1zM8 11h1c.55 0 1 .45 1 1s-.45 1-1 1H8v1.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5V13H6c-.55 0-1-.45-1-1s.45-1 1-1h1V5.5c0-.28.22-.5.5-.5s.5.22.5.5V11zm5-2h-1c-.55 0-1-.45-1-1s.45-1 1-1h1V5.5c0-.28.22-.5.5-.5s.5.22.5.5V7h1c.55 0 1 .45 1 1s-.45 1-1 1h-1v5.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5V9z"/>
                                    </svg>Backoffice</h6>
                                <span class="text-secondary"><a :href="adminUrl" class="link-info" target="_blank">Vai sul BO</a></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Full Name</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    {{ `${$store.state.user.first_name} ${$store.state.user.last_name}` }}
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Email</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    {{ $store.state.user.email }}
                                </div>
                            </div>
                            <hr>
                        </div>
                    </div>

                    <div class="row gutters-sm">
                        <div class="col-sm-12 mb-3">
                            <div class="card h-100">
                                <div class="card-body">
                                    <ul class="nav nav-pills nav-fill">
                                        <li class="nav-item">
                                            <a class="nav-link active bg-secondary fw-bold bg-color-info" aria-current="page" href="#">Lavori Sospesi</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1"
                                                aria-disabled="true">Completati ultimi 30 giorni</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link disabled" href="#" tabindex="-1"
                                                aria-disabled="true">Bocciati ultimi 90 giorni</a>
                                        </li>
                                    </ul>
                                    <div v-for="testWorkQueuePhase in  testWorkQueuePhases" class="pt-2"
                                        :key="testWorkQueuePhase.id">
                                        <small>{{
                                            testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text
                                        }}</small>
                                        <div class="progress mb-3" style="height: 5px">
                                            <div class="progress-bar bg-secondary"
                                                :style="{ width: calculateProgress(testWorkQueuePhase.id) + '%' }"
                                                role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Logout from "@/components/Logout.vue";
import Brand from "@/components/Brand.vue";
import { fetchBySlug as fetchTestWorkQueuePhaseBySlug } from "@/use/repositories/testWorkQueuePhase/fetchBySlug";
import { index } from "@/use/repositories/sso";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from 'vue';
import { backofficeUrl } from "@/use/utilities/backofficeUrl";

export default {
    name: "Profile",
    components: {
        Brand,
        Logout,
    },
    setup() {
        const store = useStore();
        const { states } = inject("constants");
        const testWorkQueuePhases = ref([]);
        
        const adminUrl = computed(() => {
            return backofficeUrl() + '?token=' + localStorage.authToken;
        });

        onMounted(() => {
            fetchTestWorkQueuePhaseBySlug(
                store.state.user.id, [states.ON_HOLD],
                store.state.mainRole.team.name
            ).then(async (response) => {
                testWorkQueuePhases.value = response.user.test_work_queue_phases;
            });
        });

        const ssoRedirect = (type) => {
            index(type).then((response) => window.location.href = response.url);
        }

        const calculateProgress = (id) => {
            return (id % 1000) / 10;
        }

        return {
            testWorkQueuePhases,
            calculateProgress,
            ssoRedirect,
            adminUrl,
        }
    },
}
</script>
