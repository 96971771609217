<template>
  <table class="table-sm table-responsive w-100">
    <thead>
      <tr>
        <th>stato</th>
        <th>prestazione</th>
        <th class="text-center">data</th>
        <th class="text-center">prezzo cad.</th>
        <th class="text-center">quantità</th>
        <th class="text-center">prezzo totale</th>
        <th class="text-center"></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="listRows.length > 0">
        <template v-for="(listRow, index) in listRows" :key="index">
          <PaymentItem
            :listRow="listRow"
            :index="index"
            @updateQuantity="updateInvoice"
            @remove="remove"
          />
        </template>
      </template>

      <template v-if="listRows.length === 0 && $props.isAddingItems === false">
        <tr>
          <td colspan="7" class="text-center py-3">Nessuna prestazione presente</td>
        </tr>
      </template>

      <template v-if="$props.isAddingItems">
        <tr class="text-center border-violet-row">
          <td>
            <img src="@/assets/icons/playlist-add.svg" width="30" alt="gear-violet" />
          </td>
          <td>
            <template v-if="laboratoryListRowsAll.length > 0 && !chosenListRow.name">
              <select
                class="form-select form-select-sm"
                @change="setChoose()"
                v-model="chosenListRowId"
                >
                <option v-for="(row, index) in laboratoryListRowsAll" :key="index" :value="row.id">
                  {{ row.name }}
                </option>
              </select>
            </template>
            <template v-else>
              {{ chosenListRow.name }}
            </template>
          </td>
          <td class="text-center">{{ moment().format("DD/MM/YYYY") }}</td>
          <td class="text-center">{{ euroFormatter(chosenListRow.netPrice) }}</td>
          <td class="text-center">
            <div class="d-flex align-items-center justify-content-between">
              <button
                class="
                  circle
                  btn
                  d-flex
                  bg-violet
                  text-white
                  fs-3
                  align-items-center
                  justify-content-center
                "
                :disabled="chosenListRow.quantity === 1"
                @click="decrease(chosenListRow, true)"
              >
                <span class="operator">-</span>
              </button>

              <div>{{ chosenListRow.quantity }}</div>

              <button
                class="
                  circle
                  btn
                  d-flex
                  bg-violet
                  text-white
                  fs-3
                  align-items-center
                  justify-content-center
                "
                @click="increment(chosenListRow, true)"
              >
                <span class="operator">+</span>
              </button>
            </div>
          </td>
          <td class="text-center">{{ euroFormatter(chosenListRow.totalAmount) }}</td>
          <td class="text-center">
            <div class="d-flex align-items-center justify-content-end">
              <img
                class="update"
                src="@/assets/icons/check-circle.svg"
                width="30"
                alt="check-circle"
                @click="create(chosenListRow)"
              />
              <img
                class="trash ms-3"
                src="@/assets/icons/close-circle-red.svg"
                @click="updateIsAddingItems(false)"
                width="25"
                alt="close-circle-red"
              />
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-4">
    <div class="d-flex flex-column">
      <button
      :disabled="$props.isAddingItems"
      type="button"
      class="btn btn-outline-violet text-uppercase mb-3"
      @click="addListRow()"
      @mouseover="hoverAdd = true"
      @mouseleave="hoverAdd = false"
    >
      <div class="d-flex">
        <img v-if="hoverAdd" src="@/assets/icons/plus-white.svg" width="25" alt="plus-white" />
        <img v-else src="@/assets/icons/plus-violet.svg" width="25" alt="telegram-violet" />
        <span class="ms-1">aggiungi prestazione</span>
      </div>
    </button>
    <button
      :disabled="$props.isAddingItems"
      type="button"
      class="btn btn-outline-violet text-uppercase"
      @click="createAutomatichPayment()"
    >
      <div class="d-flex">
        <span class="ms-1">aggiungi pagamento</span>
      </div>
    </button>
    </div>
  <div class="d-flex flex-column">
    <div class="fw-bold fs-3 mb-3">Totale: {{ euroFormatter(total) }}</div>
    <button type="button" :disabled="enabledPrintConformityDocument" class="btn btn-violet text-uppercase" @click="printConformityDocument()">
      <img src="@/assets/icons/printer-white.svg" width="20" alt="printer" />
      stampa dic. conformità
    </button>
  </div>
  </div>

</template>

<script>
import { onMounted, ref, watch, toRaw, reactive, computed } from "vue";
import _ from "lodash";
import moment from "moment";
import { euroFormatter } from "@/use/utilities/euroFormatter";

import { fetchAll as fetchAllListRows } from "@/use/repositories/listRow/fetchAll";
import { create as createReceipt } from "@/use/repositories/receipt/create";
import { update as updateByReceiptId } from "@/use/repositories/receipt/update";
import { fetchById as fetchPrescriptionById } from "@/use/repositories/prescriptions/fetchById";

import PaymentItem from "@/components/Consulting/PaymentItem.vue";
import { useRoute } from "vue-router";
import { fetchById as withTemporaryReceipt } from '@/use/repositories/prescriptions/fetchById';

export default {
  name: "PaymentsDetails",
  props: {
    data: {
      type: Array,
      required: true,
    },
    readOnlyMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAddingItems: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "total",
    "isAddingItems",
  ],
  components: {
    PaymentItem,
  },
  setup(props, { emit }) {
    const hoverAdd = ref(false);
    const hoverTelegram = ref(false);
    const laboratoryListRowsAll = ref([]);
    const listRows = ref(props.data);
    const total = ref(0);
    const chosenListRowId = ref(null);
    const chosenListRow = reactive({
      id: null,
      quantity: 1,
      netPrice: null,
      name: null,
      totalAmount: computed(() => chosenListRow.netPrice * chosenListRow.quantity),
    });
    const resetChosenListRow = () => {
      if (!chosenListRowId.value) return;

      chosenListRow.id = null;
      chosenListRow.quantity = 1;
      chosenListRow.netPrice = null;
      chosenListRow.name = null;
      chosenListRowId.value = null;
    }

    const route = useRoute();
    const enabledPrintConformityDocument = computed(() => {
      return total.value === 0;
    });

    const updateInvoice = ({ index, operation, listRow }) => {
      console.log(index, operation, listRow);

      if (operation === "increment") {
        increment(listRows.value[index], false);
      } else if (operation === "decrease") {
        decrease(listRows.value[index], false);
      }

      update(listRow);

      listRows.value[index].totalAmount = listRows.value[index].quantity * listRows.value[index].netPrice;
      total.value = _.sumBy(listRows, "totalAmount");
    };

    onMounted(() => {
      listRows.value.forEach((item) => {
        item.totalAmount = item.list_row.net_price * item.quantity;
      });

      total.value = _.sumBy(toRaw(listRows.value), "totalAmount");
    });

    watch(
      () => total.value,
      (value) => {
        emit("total", value);
      }
    );

    watch(() => listRows.value, (value) => {value.forEach((item) => {
          item.totalAmount = item.list_row.net_price * item.quantity;
        });
        total.value = _.sumBy(toRaw(listRows.value), "totalAmount");
      }, { deep: true }
    );

    const printConformityDocument = () => {
      fetchPrescriptionById(route.params.prescription_id, "conformity_document_url").then(
        (response) => {
          window.open(response.prescription.replace('/api/', '/'), '_blank').focus();
        }
      );
    };

    const setChoose = () => {
      createListRow();
    };

    const updateIsAddingItems = (value) => {
      emit('isAddingItems', value);
    }

    const createAutomatichPayment = () => {
      withTemporaryReceipt(route.params.prescription_id, "with_temporary_receipt").then((response) => {
      chosenListRowId.value = response.prescription.id;

      chosenListRow.id = response.prescription.id;
      chosenListRow.quantity = response.prescription.quantity;
      chosenListRow.netPrice = response.prescription.netPrice;
      chosenListRow.name = response.prescription.name;
      });
      updateIsAddingItems(true);
    }

    const addListRow = () => {
      resetChosenListRow();
      fetchAllListRows({
        action: 'by_prescription_id',
        prescription_id: route.params.prescription_id
      }).then((response) => {
        laboratoryListRowsAll.value = response.listRows;
        chosenListRowId.value = _.head(laboratoryListRowsAll.value).id;
        updateIsAddingItems(true);
        createListRow();
      });
    };

    const increment = (listRow, onCreate) => {
      if (!onCreate) {
        total.value += listRow.netPrice;
      }
      listRow.quantity++;
    };

    const decrease = (listRow, onCreate) => {
      if (listRow.quantity === 1) return;
      if (!onCreate) {
        total.value -= listRow.netPrice;
      }
      listRow.quantity--;
    };

    const create = (listRow) => {
      createReceipt(route.params.prescription_id, listRow.id, listRow.quantity).then((response) => {
        // Hide btn isAddingItems.
        updateIsAddingItems(false);
        // isAddingItems item to create.
        listRows.value.push(response.receipt);
      });
    };

    const update = (listRow) => {
      updateByReceiptId(listRow.id, listRow.quantity);
    };

    const createListRow = (param) => {
      if (param) {
        chosenListRow.quantity = param.quantity;
        chosenListRow.id = param.id;
        chosenListRow.netPrice = param.netPrice;
        chosenListRow.name = param.name;
        return
      }
      let index = laboratoryListRowsAll.value.findIndex((obj) => obj.id === chosenListRowId.value);
      let obj = laboratoryListRowsAll.value[index];

      chosenListRow.quantity = 1;
      chosenListRow.id = obj.id;
      chosenListRow.netPrice = obj.net_price;
    };

    const remove = (listRowId) => {
      console.log(listRowId);

      let index = listRows.value.findIndex((obj) => obj.id === listRowId);
      listRows.value.splice(index, 1);
    };

    return {
      addListRow,
      hoverAdd,
      hoverTelegram,
      laboratoryListRowsAll,
      listRows,
      total,
      euroFormatter,
      moment,
      chosenListRowId,
      setChoose,
      chosenListRow,
      increment,
      decrease,
      create,
      updateInvoice,
      remove,
      printConformityDocument,
      updateIsAddingItems,
      enabledPrintConformityDocument,
      createAutomatichPayment,
    };
  },
};
</script>

<style scoped>
tr th,
tr td {
  border-bottom: 1px solid lightgray;
  padding: 1em;
  vertical-align: middle;
  text-transform: uppercase;
}
tr th {
  background-color: #E1D2F2;
  white-space: nowrap;
  border: 0;
}
.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.operator {
  font-size: 20px;
}
.trash:hover,
.circle:hover,
.update:hover {
  cursor: pointer;
}
</style>
