import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function index(action, page = 1, id = null,) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .get(`/shipping-codes/`, {
      params: {
        action: action,
        clinic_id: id,
        page: page,
      }
    })
    .then(async (resp) => {
      data = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
