import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function remove(id, mediaUuid) {
  const axios = axiosInstance().primoLab;
  let testWorkQueue = [];

  await axios
    .delete("/test-work-queues/remove-video", {
      data: {
        test_work_queue_id: id,
        media_uuid: mediaUuid,
      },
    })
    .then(async (response) => {
      testWorkQueue = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { testWorkQueue };
}
