// Render icons by info teeth.
export function findIconByPrescriptionRows(prescriptionRows) {
  let file = null;
  let folder = null;
  let icons = [];
  let name = null;

  console.log(prescriptionRows.application.toLowerCase());

  switch (prescriptionRows.application.toLowerCase()) {
    // FIXME: optimized

    // Arch.
    case "arcata":
      if (
        prescriptionRows.zone_number.toLowerCase() === "sup" ||
        prescriptionRows.zone_number.toLowerCase() === "up"
      ) {
        name = "sup";
      }
      if (
        prescriptionRows.zone_number.toLowerCase() === "inf" ||
        prescriptionRows.zone_number.toLowerCase() === "down"
      ) {
        name = "inf";
      }
      folder = "arch/";
      file = "arch-" + name;
      icons.push(folder + file);
      break;

    // Stump.
    case "moncone":
      folder = "stump/";
      file = `stump`;
      icons.push(folder + file);
      break;

    // Gum.
    case "gengiva":
      folder = "gum/";
      file = 'gum';
      icons.push(folder + file);
      break;

    // All teeth.
    case "bocca":
      folder = "mouth/";
      file = `mouth`;
      icons.push(folder + file);
      break;

    // Emiarcata.
    case "emiarcata":
      folder = "emiarch/";
      switch (parseInt(prescriptionRows.zone_number)) {
        case 1:
          file = `emiarch-1`;
          icons.push(folder + file);
          break;
        case 2:
          file = `emiarch-2`;
          icons.push(folder + file);
          break;
        case 3:
          file = `emiarch-3`;
          icons.push(folder + file);
          break;
        case 4:
          file = `emiarch-4`;
          icons.push(folder + file);
          break;
      }
      break;

    // Sestante.
    case "sestante":
      folder = "sest/";
      switch (parseInt(prescriptionRows.zone_number)) {
        case 1:
          file = `sest-1`;
          icons.push(folder + file);
          break;
        case 2:
          file = `sest-2`;
          icons.push(folder + file);
          break;
        case 3:
          file = `sest-3`;
          icons.push(folder + file);
          break;
        case 4:
          file = `sest-4`;
          icons.push(folder + file);
          break;
        case 5:
          file = `sest-5`;
          icons.push(folder + file);
          break;
        case 6:
          file = `sest-6`;
          icons.push(folder + file);
          break;
      }
      break;

    // Dente.
    case "dente":
      folder = "teeth/";
      file = `teeth-${parseInt(prescriptionRows.zone_number)}`;
      icons.push(folder + file);
      break;

    // Custom pin.
    case "perno custom":
      folder = "custom-pin/";
      file = 'custom-pin';
      icons.push(folder + file);
      break;

    // If application is empty.
    case "":
      folder = "teeth/";
      file = `teeth-${parseInt(prescriptionRows.zone_number)}`;
      icons.push(folder + file);
      break;
  }

  return icons;
}
