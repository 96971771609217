import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function fetchBySlug(userId, state, currentDepartmentSlug) {
  const axios = axiosInstance().primoLab;
  let user = [];

  await axios
    .get(`/users/${userId}`, {
      params: {
        action: 'with_test_work_queue_phases',
        department_slug: currentDepartmentSlug,
        test_work_queue_state_slugs: state,
      },
    })
    .then(async (response) => {
      user = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { user };
}
