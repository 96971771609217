import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
import {ref} from 'vue';

export async function fetchAllAticlesCategories() {
  const axios = axiosInstance().primoLab;
  const articlesCategories = ref([]);

  await axios
    .get("/warehouse-articles")
    .then(async (response) => {
      articlesCategories.value = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { articlesCategories };
}
