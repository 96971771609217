<template>
  <div class="d-flex align-items-center">
    <template v-if="$props.label !== null">
      <div class="d-flex">
        <h3 class="text-violet mx-3 mb-0">{{ $props.label }}</h3>
      </div>
    </template>
    <div class="d-flex flex-column flex-grow-1">
      <input
        id="global-search"
        ref="globalSearch"
        :disabled="$props.disabled"
        type="text"
        autocomplete="off"
        class="form-control rounded-custom py-2"
        :placeholder="$props.placeholder"
        v-model="keyword"
        @input="onInput"
      />
      <div v-if="notify" class="invalid-feedback d-block">
        <small>Inserisci minimo {{ $props.minCharacters }} caratteri</small>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import debounce from "lodash/debounce";

export default {
  name: "GlobalSearch",
  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    debounceTime: {
      type: Number,
      default: 0,
    },
    minCharacters: {
      type: Number,
      default: null,
    }
  },
  setup(props, {emit}) {
    const keyword = ref("");
    const notify = ref(false);
    const globalSearch = ref(null);

    const onInput = debounce(async () => {
      notify.value = false;

      if (keyword.value.length === 0) {
        emit('toSearch', keyword.value);
        return;
      }

      if (props.minCharacters !== null) {
        notify.value = (keyword.value.length < props.minCharacters);
      }

      if (notify.value === false) {
        emit('toSearch', keyword.value);
      }

    }, props.debounceTime);

    watch(() => props.reset, (value) => {
      if (value) {
        keyword.value = "";
      }
    });

    return {
      keyword,
      onInput,
      notify,
      globalSearch
    }
  }
}
</script>
