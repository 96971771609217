import axios from "axios";
import router from "@/router";
import getAuthToken from "@/use/get-auth-token";

export function axiosInstanceWithoutLoader() {
  const primoLab = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
  });

  primoLab.interceptors.request.use(
    (config) => {
      const token = getAuthToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  primoLab.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.request.status === 401 && error.request.statusText === "Unauthorized") {
        router.push({ path: "/login" });
      }
      return Promise.reject(error);
    }
  );

  return { primoLab };
}
