<script>
export default {
  emits:['choose'],
  props: {
    data: {
      type: Array,
      required: false,
    }
  },
  setup() {
    return {}
  }
}
</script>

<template>
  <div class="d-flex flex-wrap justify-content-center">
    <div :class="{'bg-home-violet': item.selected}" class="d-flex card rounded-custom shadow m-4"
      v-for="(item, index) in $props.data" :key="index" @click="$emit('choose', item)">
      <div class="card-body align-items-center justify-content-center d-flex flex-column">
        <h5 class="text-violet text-uppercase text-center mb-4">{{ item.name }}</h5>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  width: 13rem;
  height: 13rem;
  transition: transform 0.3s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}
</style>
