<template>
  <div class="half-circle" @click="resize()">
    <img class="d-flex chevron-icon align-items-start" src="@/assets/icons/chevron-left-violet.svg" width="45" alt="chevron-left-violet">
  </div>
</template>

<script>
export default {
  name: "HalfCircle",
  emits: [
    'action',
  ],
  setup(props, {emit}) {
    const resize = () => {
      emit('action');
    }

    return {
      resize,
    }
  }
}
</script>

<style scoped>
.chevron-icon {
  transform: rotate(90deg);
}
.half-circle:hover {
  cursor: pointer;
}
.half-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background-color: white;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  transform: rotate(-90deg);
  position: absolute;
  right: -25px;
  top: 50%;
}
@media screen and (max-width: 1200px) {
  .half-circle {
    display: none !important;
  }
}
</style>
