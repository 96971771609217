<template>
  <Checkin
    :label="'Colatura'"
    :role="'postazione-colatura-gesso'"
  />
</template>

<script>
import Checkin from '@/components/plaster/Checkin';

export default {
  name: "Pouring",
  components: {
    Checkin,
  }
}
</script>
