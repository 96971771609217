<template>
  <div class="d-flex">
    <div class="d-flex px-3 py-1 border decrement" @click="decrease($props.value)">-</div>
    <div class="d-flex px-3 py-1 border border-top border-end">{{ $props.value }}</div>
    <div class="d-flex px-3 py-1 border increment" @click="increase($props.value)">+</div>
  </div>
</template>

<script>
export default {
  name: "Range",
  props: {
    value: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'value'
  ],
  setup(props, {emit}) {
    const decrease = (value) => {
      if (value === props.min) {
        return;
      }
      value--;
      emit('value', {value: value, index: props.index });
    }

    const increase = (value) => {
      if (value === props.max) {
        return;
      }
      value++;
      emit('value', {value: value, index: props.index });
    }

    return {
      increase,
      decrease,
    }
  }
}
</script>

<style scoped>
.decrement:hover,
.increment:hover {
  cursor: pointer;
}
</style>
