import {axiosInstance} from "@/use/utilities/axios/app/axios-instance";
import {axiosInstanceWithoutLoader} from "@/use/utilities/axios/app/axios-instance-without-loader";

export async function updateReadingNota(id, loading = true) {
  const axios = loading ? axiosInstance().primoLab : axiosInstanceWithoutLoader().primoLab;
  let result = [];

  await axios
    .post(`/prescription-test-note-users`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      prescription_test_note_ids: [
        { prescription_test_note_id: id },
      ]
    }).then(async (response) => {
      result = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { result };
}
