import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function storeIsoLog(testWorkQueueId, testWorkQueuePhaseId) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .post(`/iso-forces`, {
      test_work_queue_id: testWorkQueueId,
      test_work_queue_phase_id: testWorkQueuePhaseId,

    })
    .then(async (response) => {
      data = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
