<script>
import { storeIsoLog } from "@/use/repositories/testWorkQueuePhase/storeIsoLog";
import { createIsoPhase } from "@/use/repositories/testWorkQueuePhase/createIsoPhase";
export default {
  name: "AddIsoPhase",
  emits: ['pushIsoPhase'],
  props: {
    twqPhase: {
      type: Object,
    },
  },
  setup(props, {emit}) {
    const addIsoPhase = () => {
      createIsoPhase(props.twqPhase?.id ?? null, props.twqPhase?.child?.id ?? null, props.twqPhase?.parent?.id ?? null)
        .then((response) => {
          emit('pushIsoPhase', response.data);
          storeIsoLog(response.data.test_work_queue_id, response.data.id);
        })
    }
    return {
      addIsoPhase,
    }
  }
}
</script>
<template>
  <div class="p-3 d-flex add-iso-phase" role="button" @click="addIsoPhase()">
    <img src="@/assets/icons/plus-circle-fill.svg" width="25" alt="plus-circle"/>
    <span class="ms-2 fw-bold">Aggiungi fase ISO</span>
  </div>
</template>
