import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function attachShippingCode(testWorkQueueIds, shippingCodeId) {
  const axios = axiosInstance().primoLab;
  let shippingCode = [];

  await axios
    .post(`/test-work-queues/attach-shipping-code`, {
      test_work_queue_ids: testWorkQueueIds,
      shipping_code_id: shippingCodeId,
    })
    .then(async (response) => {
      shippingCode = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { shippingCode };
}
