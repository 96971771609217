<script>
import StepNavigationStep from './StepNavigationStep.vue';
import { inject } from 'vue';
export default {
  setup() {
    const steps = inject('steps');
    return { steps };
  },
  components: { StepNavigationStep }
}
</script>

<template>
  <ol class="step-indicator">
    <StepNavigationStep v-for="step in steps" :step="step" :key="step" />
  </ol>
</template>
<style lang="scss" scoped>
@import "../../scss/components/_wizard.scss";
</style>
