import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";
/**
 * @param action
 * @param  tagData
 * @param  type case byName => name_term case byIds => ids case byType => type_slug
 * @returns { object }
 */
export async function index(action, tagData = null, type = null) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .get(`/devices`, {
      params: {
        action: action,
        [type]: tagData,
      }
    })
    .then(async (resp) => {
      data = resp.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
