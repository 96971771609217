<template>
  <div class="d-flex flex-column w-100 h-100 justify-content-center align-items-center" :class="$props.bgColorClass">
    <div class="d-flex flex-column align-items-center text-center">
      <h2>{{ $props.title }}</h2>
      <p v-if="$props.subtitle !== null" :class="$props.subtitleWidthClass">{{ $props.subtitle }}</p>
      <div class="d-flex">
        <div class="d-flex mt-3">
          <button type="button" class="btn btn-violet text-uppercase me-3" @click="proceedAnyway()">{{ $props.confirmLabel }}</button>
          <button type="button" class="btn btn-outline-violet text-uppercase" @click="decline()">{{ $props.declineLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    subtitleWidthClass: {
      type: String,
      required: false,
      default: 'w-75',
    },
    bgColorClass: {
      type: String,
      required: false,
      default: 'bg-white',
    },
    confirmLabel: {
      type: String,
      required: false,
      default: 'Annulla',
    },
    declineLabel: {
      type: String,
      required: false,
      default: 'Conferma',
    },
  },
  emits: [
    'continue',
  ],
  setup(props, {emit}) {
    const proceedAnyway = () => {
      emit('continue', true);
    }
    const decline = () => {
      emit('continue', false);
    }

    return {
      proceedAnyway,
      decline,
    }
  }
}
</script>

<style scoped>

</style>
