import _keyBy from "lodash/keyBy";
import _map from "lodash/map";
import _find from "lodash/find";

export function sortAndAddParentState(testWorkQueuePhases) {
  const idToPhaseMap = _keyBy(testWorkQueuePhases, "id");

  function sortRecursively(phase) {
    const { parent_test_work_queue_phases } = phase;
    const parentId = parent_test_work_queue_phases.length === 0 ? null : parent_test_work_queue_phases[0].id;

    if (parentId) {
      const parentPhase = idToPhaseMap[parentId];
      sortRecursively(parentPhase);
    }
    phase.parent = parentId ? { ...idToPhaseMap[parentId].state, id: parentId } : null;
  }

  testWorkQueuePhases.forEach((phase) => sortRecursively(phase));

  if (testWorkQueuePhases.length > 1) testWorkQueuePhases.forEach((phase) => addChildAndState(phase))

  function addChildAndState(item) {
    if (item.parent) {
      _find(testWorkQueuePhases, (phase) => {
        return phase.id == item.parent.id }).child = { id: item.id, slug: item.state?.slug, created_at: item.state?.created_at }
    }
  }

  const sortedPhases = [];
  const visited = {};

  function visit(phase) {
    if (!visited[phase.id]) {
      if (phase.parent) {
        visit(idToPhaseMap[phase.parent.id]);
      }
      visited[phase.id] = true;
      sortedPhases.push(phase);
    }
  }

  testWorkQueuePhases.forEach((phase) => visit(phase));

  return _map(sortedPhases, (phase) => {
    const { ...rest } = phase;
    return {
      ...rest,
      parent: phase.parent,
    };
  });
}
