<template>
  <div class="modal fade" :id="$props.modal_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="'modal-' + $props.size">
      <div class="modal-content rounded-3">
        <div class="d-flex flex-column align-items-center justify-content-between p-3 border-0"
             :class="{'bg-violet': ! $props.lightLayout, 'text-white': ! $props.lightLayout}">
        <div class="d-flex align-items-center w-100">
          <h3 class="fw-normal text-uppercase modal-title text-center" :class="{'text-violet': $props.lightLayout}">
            {{ $props.title }}
          </h3>
          <template v-if="!$props.disabled">
            <div data-bs-dismiss="modal" class="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :fill="$props.lightLayout ? '#A58ABA' : '#FFF'" class="bi bi-x-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
            </div>
          </template>
        </div>
        <template v-if="$props.subtitle !== null">
          <p class="text-center" v-if="$props.subtitle">{{ $props.subtitle }}</p>
        </template>
        </div>
        <div class="modal-body text-center">
          <slot></slot>
        </div>
        <template v-if="$props.decline_label !== null">
          <div class="modal-footer p-1 border-0 justify-content-center">
            <button type="button" class="btn btn-outline-violet text-uppercase mt-4" @click="event('close')" :disabled="$props.disabled">
              {{ $props.decline_label }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import {onMounted, ref} from "vue";

export default {
  name: "Standard",
  props: [
    'size',
    'modal_id',
    'title',
    'subtitle',
    'decline_label',
    'lightLayout',
    'disabled',
    'options',
  ],
  setup(props, context) {
    const toggle = ref(false);

    onMounted(() => {
      showModal(props.modal_id);
      dismissModal(props.modal_id, "hidden")
    });

    // Modal functions -> show/dismiss
    const showModal = async (idModal) => {
      let modal = new Modal(document.getElementById(idModal), props.options);
      modal.show();
    }
    const dismissModal = async (idModal, nameEmit) => {
      let modal = document.getElementById(idModal);
      modal.addEventListener('hidden.bs.modal', function () {
        context.emit(nameEmit, false);
      });
    }

    const event = async (emitName) => {
      toggle.value = false;
      context.emit(emitName, false);
      let modalId = document.getElementById(props.modal_id);
      const modal = Modal.getInstance(modalId);
      modal.hide();
    }

    return {
      event
    }

  }
}
</script>

<style scoped>
.modal-title {
  width: 95%;
}
.modal-body {
  padding: 0;
  overflow: auto;
}
.close {
  width: 5%;
}
.close:hover {
  cursor: pointer;
}
</style>
