import moment from "moment/moment";

export function calcWeekDays(startDate) {
  // Parse the input date
  const endDate = moment(); // Current date

  // Initialize the weekday counter
  let weekdayCount = 0;

  // Iterate from startDate to endDate
  while (startDate.isBefore(endDate, 'day')) {
      const dayOfWeek = startDate.day(); // Day of the week (0 = Sunday, 6 = Saturday)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude weekends
          weekdayCount++;
      }
      startDate.add(1, 'day'); // Move to the next day
  }

  return weekdayCount;
}
