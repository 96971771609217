import store from "@/store";
import _ from "lodash";
import _head from "lodash/head";

import { changeState as changeStateUser } from "@/use/repositories/users/changeState";
import { fetchBySlug as fetchTestWorkQueuePhaseBySlug } from "@/use/repositories/testWorkQueuePhase/fetchBySlug";

export async function canCheckIn({next}) {
  if (typeof store.state.user.state !== "undefined") {
    await fetchTestWorkQueuePhaseBySlug(
      store.state.user.id,
      [
        "in-the-works",
      ],
      store.state.mainRole.team.name
    ).then(async (response) => {
      console.log(`COUNT USER TEST WORK QUEUE ${response.user.test_work_queue_phases.length}`);
      response.user.test_work_queue_phases.forEach((testWorkQueuePhase) => {
        console.log(`ID: ${testWorkQueuePhase.id} - NUMBER TEXT: ${testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text} - DEPARTMENT: ${testWorkQueuePhase.department.slug} - STATE: ${testWorkQueuePhase.state.slug} - DELIVERY DATE: ${testWorkQueuePhase.test_work_queue.delivery_date}`)
      });

      let existsTestWorkQueuePhases = (response.user.test_work_queue_phases.length > 0);

      if (existsTestWorkQueuePhases) {
        // In in-the-works or in-pause state.
        let testWorkQueuePhasesInWorking = response.user.test_work_queue_phases.filter((testWorkQueuePhase) => {
          return (testWorkQueuePhase.state.slug === "in-the-works");
        });

        if (testWorkQueuePhasesInWorking.length > 0) {
          // Order by delivery date.
          testWorkQueuePhasesInWorking = _.orderBy(testWorkQueuePhasesInWorking, testWorkQueuePhase => testWorkQueuePhase.test_work_queue?.delivery_date, ['delivery_date']);

          // Fetch first test work queue phase.
          let testWorkQueuePhase = _head(testWorkQueuePhasesInWorking);

          if (store.state.user.state.slug == 'available') {
            await changeStateUser('working');
          }

          let testWorkQueuePhaseMustDoIso = (
            testWorkQueuePhase.department.slug === "iso-mobile" ||
            testWorkQueuePhase.department.slug === "iso-fissa" ||
            testWorkQueuePhase.department.slug === "iso-preparazione" ||
            testWorkQueuePhase.department.slug === "iso-progettazione"
          );

          console.log(`MUST DO ISO: ${testWorkQueuePhaseMustDoIso}`);
          if (testWorkQueuePhaseMustDoIso) {
            return next({
              name: "ConsultingDashboard",
              params: {
                prescription_id: testWorkQueuePhase.test_work_queue.prescription_test.prescription_id,
              },
              query: {
                read_only: false,
                test_work_queue_phase_id: testWorkQueuePhase.id,
              },
            })
          }

          return next(`/dashboard/${testWorkQueuePhase.id}`);
        } else {
          return next();
        }
      } else {
        return next();
      }
    });
  } else {
    return next("/login");
  }
}
