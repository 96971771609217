<template>
  <div class="d-flex flex-column w-100">
    <table class="table table-bordered bg-white">
      <thead>
        <tr class="bg-violet text-white text-center rounded-only-top p-3 text-uppercase position-relative">
          <th colspan="8">
            <div class="d-flex justify-content-center align-items-center">
              <div class="icon-actions position-absolute start-0">
                <img class="sync me-3" src="@/assets/icons/arrow-left.svg" width="30" height="30" alt="sync" @click="redirectToCamCheckIn">
                <img class="sync" src="@/assets/icons/sync.svg" width="30" height="30" alt="sync" @click="orderByPriorityDate()">
              </div>
              <h3 class="m-0">Smistamento lavori</h3>
              <div class="form-group mx-2 position-absolute end-0">
                <!-- style="opacity: 0;" -->
                <input class="form-control d-inline-block rounded-custom" type="text" id="scanner" placeholder="scanner">
              </div>
            </div>
          </th>
        </tr>
        <tr class="text-center" v-if="worksWithInTheWorksStateNotFiltered.length > 0">
          <th class="fw-bold" nowrap scope="col">Etichetta digitale</th>
          <th class="fw-bold" nowrap scope="col">Prescrizione</th>
          <th class="fw-bold" nowrap scope="col">Paziente</th>
          <RowFilter
            :data="worksWithInTheWorksStateNotFiltered"
            :label="'Materiale'"
            :field="'material'"
            :restore="resetFilters"
            @dataProcess="syncData">
          </RowFilter>
          <RowFilter
            :data="worksWithInTheWorksStateNotFiltered"
            :label="'Colore'"
            :field="'code'"
            :restore="resetFilters"
            @dataProcess="syncData">
          </RowFilter>
          <RowFilter
            :data="worksWithInTheWorksStateNotFiltered"
            :label="'Applicazione'"
            :field="'application'"
            :restore="resetFilters"
            @dataProcess="syncData">
          </RowFilter>
          <th class="fw-bold" nowrap scope="col">Data consegna</th>
          <th class="fw-bold" nowrap scope="col">
            <span class="me-3">Anteprima</span>
            <img src="@/assets/icons/3d.svg" width="30" height="30" alt="3d">
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="worksWithInTheWorksStateNotFiltered.length > 0">
          <template v-for="(el, index) in worksWithInTheWorksState" :key="index">
            <tr class="text-center" :class="{'higthlight': el.checked}">
            <td class="p-2">
              <div class="d-flex align-items-center">
                <template v-if="el.prescription_test?.device">
                  <span class="position-relative cp me-4" @click="findDevice(el.prescription_test.device.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" :fill="'#725D82'" class="bi bi-inbox" viewBox="0 0 16 16">
                        <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
                    </svg>
                    <span class="question-icon position-absolute">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" :fill="'#725D82'" class="bi bi-question" viewBox="0 0 16 16">
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                    </span>
                  </span>
                  <span>{{ el.prescription_test?.device?.name }}</span>
                </template>
                <template v-else>-</template>
              </div>
            </td>
              <td class="d-flex align-items-center justify-content-between">
                <template v-if="!el.checked">
                  <span class="material-icons-round text-violet-dark" @click="rollBackToCheckIn(el)">
                  undo
                </span>
                </template>
                {{ el.number_text }}
              </td>
              <td nowrap>
                <span class="me-1">{{ el.first_name }}</span>
                <span>{{ el.last_name }}</span>
              </td>
              <td nowrap>{{ el.material }}</td>
              <td>{{ el.code }}</td>
              <td nowrap>
                <img v-if="isBridge(el)" src="@/assets/icons/project/bridge/bridge-sel.svg" height="20" alt="icon" />
                <img v-if="isModel(el)"
                  :src="require(`@/assets/icons/model/${getFolderNameIconModelBySlug(el.type_slug)}/${getFileNameIconModelBySlug(el.type_slug)}-sel.svg`)"
                  height="15"
                  alt="icon"
                />
                {{ el.application }}
              </td>
              <td>{{ moment(el.delivery_date).format("DD/MM/YYYY") }}</td>
              <td class="preview-3d text-violet text-decoration-underline" @click="modalScene(el)">Modello 3D</td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="7" class="text-center p-3 fw-bold">Nessun lavoro presente</td>
          </tr>
        </template>
      </tbody>
    </table>
    <Modal v-if="showModal"
           :data="scene"
           @hidden="closeModal">
    </Modal>
  </div>

  <Confirm v-if="showConfirmModal"
           :modal_id="'modalConfirmRollBackToCheckIn'"
           :title="modalMessage"
           :subtitle="modalSubtitle"
           :confirm_label="'Si'"
           :decline_label="'No'"
           @hidden="hideModal"
           @confirm="proceedAnyway"
           @decline="hideModal">
  </Confirm>
  <Standard
      v-if="showMissingModal"
      :size="'md'"
      :modal_id="'sorting_of_works_find_device'"
      :title="'Cerca la casella'"
      :subtitle="'Seleziona il colore e illumina il device selezionato'"
      :decline_label="null"
      :lightLayout="true"
      @hidden="closeMissingModal"
      >
      <DeviceCommand :data="deviceId"></DeviceCommand>
    </Standard>
</template>

<script>
import { reactive, ref, watch, toRaw, onMounted, inject, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import _ from 'lodash';
import moment from "moment";
import { hideModal as hideMissingModal } from "@/use/modal/hide";
import { warning } from "@/use/toast/warning";
import { success } from "@/use/toast/success";
import { getFileNameIconModelBySlug } from "@/use/utilities/getFileNameIconModelBySlug";
import { getFolderNameIconModelBySlug } from "@/use/utilities/getFolderNameIconModelBySlug";
import { fetchTemporaryUrl } from "@/use/repositories/assets/fetchTemporaryUrl";
import {  update as updateStateAsset } from "@/use/repositories/assets/update";

import Modal from '@/components/Scene/Modal';
import Confirm from "@/components/Modal/Confirm.vue";
import RowFilter from '@/components/cam/RowFilter';
import DeviceCommand from "@/components/devices/DeviceCommand.vue";
import Standard from "@/components/Modal/Standard";


export default {
  name: "SortingOfWorks",
  props: {
    dataAssets: {
      type: Array,
    },
    byPusher: {
      type: Object,
    },
  },
  emits: [
    'numberTextToBeSelected',
    'reset',
    'resetByPusher'
  ],
  components: {
    Modal,
    RowFilter,
    Confirm,
    DeviceCommand,
    Standard
  },
  setup(props, {emit}) {
    const deviceId = ref();
    const findDevice = (device) => {
      console.log(device);
      deviceId.value = device;
      showMissingModal.value = true;
    }
    const showMissingModal = ref(false);
    const closeMissingModal = () => {
      hideMissingModal('sorting_of_works_find_device');
      deviceId.value = null;
      showMissingModal.value = false;
    }

    const { states } = inject("constants");
    const { materials } = inject("constants");
    const router = useRouter();
    const showModal = ref(false);
    const filterInTheWorkState = ref([]);
    const worksWithInTheWorksState = ref([]);
    const worksWithInTheWorksStateNotFiltered = ref([]);
    const scene = reactive({
        id: null,
        modal_id: null,
        title: null,
        subtitle: null,
        decline_label: null,
        temporary_url: null,
    });
    const filters = [];
    const showConfirmModal = ref(false);
    const assetToRollBack = ref({});
    const modalMessage = computed(() => {
      return `Stai per rimandare la lavorazione del paziente
        <small class="fw-bold font-italic">${assetToRollBack.value.first_name} ${assetToRollBack.value.last_name}</small><br>
        con codice prescrizione <small class="fw-bold font-italic">${assetToRollBack.value.number_text}</small><br> al CHECK-IN`;
    });
    const modalSubtitle = `<h4>Sicuro di voler procedere?</h4>`;
    const i18n = useI18n();
    const resetFilters = ref(false);

    onMounted(() => {
      scanBarcode();
    });

    const prepareData = (assets) => {
      // Filter data only in the work state and distinct on bridge.
      filterInTheWorkState.value = _.uniqBy(assets.filter((work) => work.state === 'in-the-works'),'id');
      // Set data.
      worksWithInTheWorksState.value = filterInTheWorkState.value;
      // Add checked bool for field before render.
      if (!props.byPusher.value) worksWithInTheWorksState.value.forEach((item) => item.checked = false);
      // Reorder by priority date.
      worksWithInTheWorksState.value = _.orderBy(toRaw(worksWithInTheWorksState.value),'delivery_date', 'asc');
      // Clear data copy.
      worksWithInTheWorksStateNotFiltered.value = filterInTheWorkState.value;
      console.log(filterInTheWorkState.value);

      if (props.byPusher.value) {
        emit('resetByPusher', false);
      }
    }

    const isBridge = (el) => {
      return el.bridge !== false && el.bridge > 0;
    }

    const isModel = (el) => {
      if (el.material_slug === materials.PRINTED_RESIN && el.application.toLocaleLowerCase() !== "dente") {
        return el.material_slug === materials.PRINTED_RESIN;
      }

      return false;
    }

    watch(() => props.dataAssets.length, () => {
      console.log("SYNC TABLE");
      prepareData(toRaw(props.dataAssets));
    }, { deep: true });

    const modalScene = async (el) => {
      // Set data for modal.
      scene.id = el.id;
      scene.modal_id = el.id;
      scene.decline_label = "chiudi";
      // Fetch temporary url.
      await fetchTemporaryUrl(el.id).then(async (response) => {
        scene.temporary_url = response.temporary_url;
        showModal.value = true;
      });
    }

    const closeModal = async (value) => {
      // Reset data modal.
      scene.show = null;
      scene.id = null;
      scene.decline_label = null;
      scene.temporary_url = null;

      showModal.value = value;
    }

    const redirectToCamCheckIn = async () => {
      await router.push({ name: "CheckinCam" });
    }

    const syncData = (value) => {
      let keyExists = filters.some((filter) => filter.type === value.type);

      if (filters.length === 0 || keyExists === false) {
        filters.push(value);

      } else if (keyExists) {
        filters.forEach((obj) => {
          if (obj.type === value.type) {
            obj.filters = value.filters;
          }
        });
      }

      let rowsFilteredByFilters = worksWithInTheWorksStateNotFiltered.value.filter((row) => {
        return filtersChecked(row);
      });

      worksWithInTheWorksState.value = rowsFilteredByFilters;

      resetFilters.value = false;
    };

    const filtersChecked = (row) => {
      let countFilterTypeToBeApplied = Object.keys(filters).length;
      let countRespectedFilters = 0;

      filters.forEach((obj) => {
        let type = row[obj.type];

        if (obj.filters.length === 0) {
          countRespectedFilters++;

        } else {
          if (obj.filters.includes(type)) {
            countRespectedFilters++;
          }
        }

      });

      return (countFilterTypeToBeApplied === countRespectedFilters);
    }

    const orderByPriorityDate = () => {
      if (filterInTheWorkState.value.length <= 1) {
        return;
      }

      // Order by priority date.
      worksWithInTheWorksState.value = _.sortBy(toRaw(worksWithInTheWorksState.value), 'delivery_date');

      success(i18n.t('Processing sorting by priority date'));

      emit('numberTextToBeSelected', null);
    }

    const scanBarcode = () => {
      // Define variables
      let isEvent = false;
      let input = document.getElementById("scanner");

      input.addEventListener("focus", function () {
        if (!isEvent) {
          isEvent = true;
          input.addEventListener("keypress", function (event) {
            setTimeout(function () {
              if (event.key === "Enter") {
                // Find phase by number
                findByNumberText(input.value);
                input.value = "";
                input.select();
              }
            }, 500);
          });
        }
      });

      document.addEventListener("keypress", function (e) {
        if (e.target.tagName !== "INPUT") {
          input.focus();
        }
      });
    }

    const findByNumberText = (numberText) => {
      console.log("SELECTED ROWS...");
      let found = false;

      filterInTheWorkState.value.forEach((item) => {
        // Bool for show toast.
        if (item.number_text === numberText) {
          found = true;
          item.checked = !item.checked;
        }
      });

      if (found === false) {
        emit('numberTextToBeSelected', null);
        warning(i18n.t('The works of this prescription are not present in the table. Please download them and try again.'));
      }

      worksWithInTheWorksState.value = _.orderBy(toRaw(worksWithInTheWorksState.value), 'checked', 'desc');

      console.log('Found prescription with number_text ' + numberText);

      emit('numberTextToBeSelected', numberText);
    };

    const proceedAnyway = () => {
      updateStateAsset([assetToRollBack.value.id], states.PENDING).then(async () => {
        showConfirmModal.value = false;
        resetFilters.value = true;
        emit('reset');
        console.log(`Asset ID:${assetToRollBack.value.id} - Prescrizione N:${assetToRollBack.value.number_text} ritornata in check-in`);
        success(i18n.t('Processing postponed to check-in'));
      });
    }

    const hideModal = (value) => {
      showConfirmModal.value = value;
    }

    const rollBackToCheckIn = (asset) => {
      assetToRollBack.value = asset;
      showConfirmModal.value = true;
    }

    return {
      moment,
      closeModal,
      modalScene,
      scene,
      showModal,
      worksWithInTheWorksState,
      worksWithInTheWorksStateNotFiltered,
      redirectToCamCheckIn,
      syncData,
      orderByPriorityDate,
      rollBackToCheckIn,
      showConfirmModal,
      hideModal,
      proceedAnyway,
      assetToRollBack,
      modalMessage,
      modalSubtitle,
      resetFilters,
      isBridge,
      isModel,
      getFolderNameIconModelBySlug,
      getFileNameIconModelBySlug,
      showMissingModal,
      findDevice,
      closeMissingModal,
      deviceId,
    }
  }
}
</script>

<style scoped>
  .preview-3d:hover,
  .sync:hover {
    cursor: pointer;
  }
  .higthlight {
    border-color: rgb(225 210 242);
    background-color:rgb(225 210 242);
    box-shadow: 0 0 0 0.25rem rgb(225 210 242);
    outline: 0 none;
  }
  .table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgb(225 210 242);
  }
  .material-icons-round:hover {
    cursor: pointer;
  }
</style>
