import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function createIsoPhase(testWorkQueuePhaseId, childTestWorkQueuePhaseId, parentTestWorkQueuePhaseId) {
  const axios = axiosInstance().primoLab;
  let data = [];

  await axios
    .post(`/test-work-queue-phases/iso-phase`, {
      id: testWorkQueuePhaseId,
      child_id: childTestWorkQueuePhaseId,
      parent_id: parentTestWorkQueuePhaseId
    })
    .then(async (response) => {
      data = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { data };
}
