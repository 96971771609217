<template>
  <!-- Status -->
  <div class="status">
    <template v-if="isConnected">
      <img class="bg-white rounded-custom p-1" src="../chat/icons/online.svg" width="25" alt="online">
    </template>
    <template v-else>
      <img class="bg-white rounded-custom p-1" src="../chat/icons/offline.svg" width="25" alt="offline">
    </template>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store";

export default {
  name: "StatusConnection",
  setup() {
    const isConnected = computed(() => {
      return store.state.IsConnected;
    });

    return {
      isConnected,
    }
  }
}
</script>

<style scoped>

</style>
