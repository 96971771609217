<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="$props.width" :height="$props.height"
       :fill="$props.color" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path
      d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
  </svg>
</template>

<script>
export default {
  name: "CheckCircle",
  props: {
    color: {
      type: String,
      required: false,
      default: '#0000000',
    },
    width: {
      type: Number,
      required: false,
      default: 20,
    },
    height: {
      type: Number,
      required: false,
      default: 20,
    }
  }
}
</script>
