<template>
  <div class="d-flex flex-column p-3 vh-100 vw-100">
    <div class="d-flex justify-content-between p-2">
      <Brand :width="150"/>
      <div class="mb-2"></div>
      <template v-if="showOrdersApprovedBox === false && ordersApproved.length">
        <img class="history__orders" @click="showOrdersApprovedBox = true" src="@/assets/icons/history.svg" width="80" alt="history">
      </template>

    </div>
    <div class="d-flex h-90">
      <div class="w-40">
        <div class="d-flex w-100 h-100 p-2">
          <RequestedOrders
            :resetSelection="resetSelectionOnRequestedOrders"
            @resetComplete="restoreResetToDefault"
            @orderDetails="readOrderDetails"
            @updateApprovedOrders="syncUpdateApprovedOrders"
            @resetOrderDetails="resetOrderDetails"
          />
        </div>
      </div>
      <div class="w-60">
        <div class="d-flex flex-column w-100 h-100">
          <div class="w-100 p-2 h-60">
            <OrderDetails
              :data="order"
              :readOnlyMode="readOnly"
              @resetOrders="resetOrdersBox"
            />
          </div>
          <div v-if="ordersApproved.length && showOrdersApprovedBox" class="w-100 p-2 h-40">
            <OrdersApproved
              :data="ordersApproved"
              @orderDetails="readOrderDetails"
              @close="hideOrdersApprovedBox"
              @resetOrderDetails="resetOrderDetails"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  {ref, watch} from 'vue';

import RequestedOrders from '@/components/warehouse/supervisor/RequestedOrders';
import Brand from "@/components/Brand";
import OrderDetails from "@/components/warehouse/supervisor/OrderDetails";
import OrdersApproved from "@/components/warehouse/supervisor/OrdersApproved";

export default {
  name: "Dashboard",
  components: {
    OrdersApproved,
    OrderDetails,
    Brand,
    RequestedOrders,
  },
  setup() {
    const order = ref({});
    const readOnly = ref(false);
    const ordersApproved = ref([]);
    const showOrdersApprovedBox = ref(false)
    const resetSelectionOnRequestedOrders = ref(false);

    watch(showOrdersApprovedBox, (value) => {
      // Reset selection before show.
      if (value) {
        resetSelectionOnOrdersApproved();
      }
    });

    const readOrderDetails = (obj) => {
      // ReadOnlyMode = TRUE -> request details from orders approved.
      if (obj.readOnlyMode) {
        resetSelectionOnRequestedOrders.value = true;
      } else {
        resetSelectionOnOrdersApproved();
      }

      // Set order details.
      order.value = obj.order;
      readOnly.value = obj.readOnlyMode;
    }

    const resetSelectionOnOrdersApproved = () => {
      ordersApproved.value.forEach((order) => {
        order.selected = false;
      });
    }

    const syncUpdateApprovedOrders = (value) => {
      ordersApproved.value = value;
      showOrdersApprovedBox.value = ordersApproved.value.length;
    }

    const hideOrdersApprovedBox = (value) => {
      showOrdersApprovedBox.value = value;
      // Empty box only is in read only mode.
      if (readOnly.value) {
        // Empty box order details.
        order.value = {};
      }
    }

    const resetOrderDetails = () => {
      // Empty box order details.
      order.value = {};
    }

    const restoreResetToDefault = (value) => {
      resetSelectionOnRequestedOrders.value = value;
    }
    const resetOrdersBox = () => {
      order.value = [];
      resetSelectionOnRequestedOrders.value = true;
    }

    return {
      readOrderDetails,
      syncUpdateApprovedOrders,
      ordersApproved,
      order,
      readOnly,
      hideOrdersApprovedBox,
      showOrdersApprovedBox,
      resetSelectionOnRequestedOrders,
      restoreResetToDefault,
      resetOrdersBox,
      resetOrderDetails,
    }
  }
}
</script>

<style scoped>
.history__orders:hover {
  cursor: pointer;
}
</style>
