import { axiosInstance } from "@/use/utilities/axios/app/axios-instance";

export async function duplicateRelationship(testWorkQueuePhaseId, testWorkQueuePhaseChildId) {
  const axios = axiosInstance().primoLab;
  let responseData = [];

  await axios
    .post(`/test-work-queue-phases/duplicate-relationship`, {
      id: testWorkQueuePhaseId,
      child_id: testWorkQueuePhaseChildId,
    })
    .then(async (response) => {
      responseData = response.data.data;
    })
    .catch(async (error) => {
      throw error.response.data.errors;
    });

  return { responseData };
}
