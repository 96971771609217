<script>
import { computed, inject } from "vue";
export default {
  props: ["step"],
  setup(props) {
    const currentStep = inject("currentStep");
    const steps = inject("steps");

    const indicatorClass = computed(() => {
      return {
        active: props.step.id == currentStep.value,
        complete: currentStep.value > props.step.id,
      };
    });

    return {
      currentStep,
      steps,
      indicatorClass,
    };
  },
};
</script>

<template>
  <li
    :class="{
      active: $props.step.id == currentStep,
      complete: currentStep > $props.step.id,
    }"
  >
    <div class="step d-flex align-items-center justify-content-center">
      <img :src="require(`@/assets/icons/${$props.step.icon_path}`)" height="35" width="35"/>
    </div>
    <div class="caption hidden-xs hidden-sm">{{ $props.step.title }}</div>
  </li>
</template>
<style lang="scss" scoped>
@import "../../scss/components/_wizard.scss";
</style>
