import { default as axios } from "@/use/utilities/axios/chat/axios-instance-chat";

export async function edit(id, action, value) {
  let channel = {};

  await axios
    .put(`/channels/${id}`, {
      'action': action,
      'value': value
    })
    .then(response => channel = response.data.data)
    .catch(error => { throw error.response.data.errors });

  return { channel };
}
