
<script>
export default {
  emits: [
    "checked",
    "show-map",
  ],
  name: "CardCheckbox",
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    prescriptionTestId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <div class="card rounded-custom shadow m-2" @input="(event) => $emit('checked', event.target.checked)"
    :class="{ 'checked': checked }" style="min-width: 20rem;">
    <input type="checkbox" :checked="checked" :id="fieldId" class="mr-2 d-none" />
    <label :for="fieldId" class="cp">
      <div class="card-header text-dark d-flex justify-content-between align-items-center rounded-only-top p-3"
        :style="{ 'background-color': '#FFFFFF' }">
        <img v-show="!checked" class="checkbox bg-white" src="@/assets/icons/checkbox.svg" width="20" height="20"
          alt="checkbox" />
        <img v-show="checked" class="checkbox" src="@/assets/icons/checkbox-checked.svg" width="22" height="22"
          alt="checkbox-checked" />
        <h4 class="m-0"> {{ label }} </h4>
      </div>
      <div class="card-body d-flex flex-column">
        <slot></slot>
      </div>
    </label>
  </div>
</template>
<style lang="scss" scoped>
@import "@/scss/abstracts/_variables.scss";

.checked {
  border: 1px solid $green;
}</style>
