<template>
  <div class="d-flex shadow card rounded-custom text-center w-100 mb-5 py-5 px-1">
    <h3>{{ date }}</h3>
    <h4>{{ time }}</h4>
  </div>
</template>

<script>
import {ref, onMounted} from "vue";
import moment from 'moment';

export default {
  name: "TimestampInRealTime",
  setup() {
    const date = ref("");
    const time = ref("");

    onMounted(async () => {
      await getTime();
    });

    const getTime = async () => {
      setInterval(()=>{
        date.value = moment().format("DD/MM/YYYY");
        time.value = moment().format("HH:mm");
      },1000)
    }

    return {
      date,
      time,
    }
  }
}
</script>

<style scoped>

</style>
